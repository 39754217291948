export const collections = {
    notificationCollection: 'notifications',
    userCollection: 'users',
    adminCollection: 'admins',
    webcamWhitelistCollection: "webcamWhitelist",
    appointmentCollection: 'appointments',
    requestCollection: 'requests',
    locationCollection: 'locations',
    dogCollection: "dogs",
    dogRequestCollection: "dogRequestAccess",
    dropOffCollection: "dropoffs",
    inquiryCollection: "inquiry",
    meetAndGreetRequestCollection: "meetAndGreetRequest",
    leadCollection: "leads",
    petexecCredentialsCollection: "ExecutiveCredentials",
    accountCollection: "accounts",
    accountTemplateCollection: "accountTemplates",
    petexecPetCollection: "pets",
    environmentCollection: "Environment",
    clientAgreementCollection: "clientagreements",
    templateAgreementCollection: "templateagreements",
    vaccinationCollection: "vaccinations",
    petexecOwnerCredentialCollection: 'PetExecOwnerCredentials',
    meetAndGreetAppointmentCollection: "meetAndGreetAppointments",
    newMemberInviteCollection: "accountMemberInvites",
    petHistoryCollection: "petHistory",
    setPassword: "resetPasswordBlock",
    accessRequestCollection: "accessRequests",
    tempSignatureCollection: "tempSignature",
    messageQueueCollection: "messages",
    errorLogs: "ErrorLogs",
    vaccineReleaseFormTemplate: "vaccineReleaseFormTemplate",
    tempVaccinationReleaseForm: "tempVaccinationReleaseForm",
    vaccineReleaseForms: "vaccineReleaseForms"
}
