import { Pipe, PipeTransform } from "@angular/core";

import { UserService } from "src/services";

@Pipe({
	name: "formatPhoneNumber"
})

export class FormatPhoneNumberPipe implements PipeTransform {

	constructor() { }

	public transform(phoneNumber: string):string {

		let strippedPhoneNumber = UserService.cleanPhoneNumber(phoneNumber);

		// If the cleaned up phone number is less than 11 characters long, then it can't be formatted, so return the original value.
		if(strippedPhoneNumber.length < 11){
			
			return phoneNumber;
		
		}else{

			let countryCode = "+" + strippedPhoneNumber.substring(0, 1);

			let areaCode = "(" + strippedPhoneNumber.substring(1, 4) + ")";

			let centralOfficeCode = strippedPhoneNumber.substring(4, 7);

			let stationNumber = strippedPhoneNumber.substring(7);

			return countryCode + " " + areaCode + " " + centralOfficeCode + "-" + stationNumber;

		}
		
	}

}