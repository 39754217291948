import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

import firebase from "firebase/app";

import { FirestoreService, queryOptions } from "./firestore.service";

import { FileUploadService } from "./file-upload.service";

import { ClientAgreementStruct } from "src/models/structs";

import { ClientAgreement } from "src/models/entities";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class ClientAgreementService {

	public collection = "clientagreements";

	public fileUploadFolder = "agreements";

	constructor (private dbService: FirestoreService, private fileUploadService: FileUploadService){}

	public getByPetId(petId: string):Promise<ClientAgreement[]> {
		window.adminlog.print("getting client agreements for pet", petId);
		const queryOptions: queryOptions = {
			conditions: [
				["petId", "==", petId]
			],
			limit: 20
		};

		return new Promise((resolve, reject) => {

			this.dbService.getCollectionOnce(this.collection, queryOptions)
				.then((clientAgreementRecords) => {

					let clientAgreements = clientAgreementRecords.map((clientAgreementRecord) => {

						const clientAgreement = new ClientAgreement(clientAgreementRecord.data() as ClientAgreementStruct, clientAgreementRecord.id);

						return clientAgreement;

					});

					resolve(clientAgreements);

				})
				.catch((error) => {
					reject(error);
				});

		});

	}

    public getByPetIdObs( petId: string ): Observable<ClientAgreement[]>{ 
        if( !petId ){ 
            return of( [] as ClientAgreement[] );
        }

        const queryOptions: queryOptions = {
			conditions: [
				["petId", "==", petId]
			],
			limit: 20
		};

        return this.dbService.getCollection(this.collection, queryOptions)
            .pipe(map( clientAgreementRecords => { 
                return clientAgreementRecords.map((clientAgreementRecord) => {
                    const clientAgreement = new ClientAgreement(clientAgreementRecord.data() as ClientAgreementStruct, clientAgreementRecord.id);
                    return clientAgreement;
                })
            }));
    }

	public update(clientAgreementId: string, updatedData: any):Promise<void> {

		if(!updatedData.updatedAt){

			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, clientAgreementId, updatedData);

	}

	public createClientAgreement(clientAgreementData: ClientAgreementStruct):Promise<string> {

		if(!clientAgreementData.createdAt){

			let createAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			clientAgreementData.updatedAt = firebase.firestore.Timestamp.fromDate(createAtDate);

		}

		return this.dbService.insertDoc(this.collection, clientAgreementData).then((clientAgreementDoc) => {
			return clientAgreementDoc.id;
		});

	}

	public uploadAgreementDoc(agreementFile: File):Promise<string> {

		return this.fileUploadService.uploadFile(this.fileUploadFolder, agreementFile);

	}
	public setAgreementDoc(clientAgreementData: ClientAgreementStruct, id: string):Promise<void> {

		if(!clientAgreementData.createdAt){
			//
			// let createAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			clientAgreementData.updatedAt = firebase.firestore.FieldValue.serverTimestamp as any;

		}

		if ( !clientAgreementData.updatedAt){

			clientAgreementData.updatedAt = firebase.firestore.FieldValue.serverTimestamp as any;
		}

		return this.dbService.setDoc(this.collection, id, clientAgreementData ).then((_ca) => {
			return;
		});
	}

}
