import { Component, Input, ContentChild, TemplateRef, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from "@angular/core";

import {PopoverController, Events } from "@ionic/angular";

import { OverviewListItemBodyTemplateDirective } from "src/directives";

import {SortSelectionOveriewPopoverComponent} from "../sort-selection-overiew-popover/sort-selection-overiew-popover.component";

import { LeadSource, OverviewListItem, SearchResultUserStruct } from "src/models/structs";

import { faSlidersH } from '@fortawesome/pro-solid-svg-icons'; 

import { OnHoldWaitlistFilterPopoverComponent } from "../on-hold-waitlist-filter-popover/on-hold-waitlist-filter-popover.component";

import { CsvGenerator } from 'src/services/csvgenerator.service';
import { LeadService } from "src/services";
//import { Entity } from 'src/models/entity';
//import { MemoService } from 'src/services';


@Component({
	selector: "app-section-overview-column",
	templateUrl: "./section-overview-column.component.html",
	styleUrls: ["./section-overview-column.component.scss"],
})

export class SectionOverviewColumnComponent implements OnInit, OnDestroy {

    public faSliders = faSlidersH;

	@Input() title: string 						= "";
	@Input() headerBtnLink:	string				= "";
	@Input() listItems: OverviewListItem<any>[];
	@Input() hideListCount: boolean 			= false;
	@Input() listItemObjectType: string 		= "";
	@Input() listItemObjectTypePlural: string	= "";
	@Input() isLoading:	boolean 				= false;
	@Input() actionHeader: boolean = false;
    @Input() disableSort:boolean = false;

    @Input() dataType: string = "";

	@Input() isRoute: boolean = true;

	public _selectedSort = { value: 'default', label: 'Default' };

	@Input() public set selectedSort( selected: any ) {
		if (!selected) return;
		if ( !this._selectedSort || selected.value != this._selectedSort.value ) {
			this._selectedSort = selected;
		}
	}

	public get selectedSort() {
		return this._selectedSort || this.sortOptions[0];
	}

	@Output("selectSort") public onSelectSort: EventEmitter<string> = new EventEmitter<string>();
    @Output("onHoldWaitlist") public onHoldWaitList: EventEmitter<string> = new EventEmitter<string>();
	@Output("downloadCsv") public onDownloadAction:  EventEmitter<OverviewListItem<any>[]> = new EventEmitter<OverviewListItem<any>[]>();

	@Input() public sortOptions: { value: string, label: string }[] = [];


	@ContentChild(OverviewListItemBodyTemplateDirective, /* TODO: add static flag */ { static: false, read: TemplateRef }) overviewListItemBodyTemplate:TemplateRef<any>;
	@ViewChild('sortActionMenu', { static: false }) sortActionMenu;

	public isListFilteredOnHandler: (userObjToFilter:SearchResultUserStruct) => void;
	public isListFilteredOffHandler: () => void;
	public isListFiltered: boolean = false;
	public numLoadingItems: number = 10;

    public numberOfOnHoldWaitlistFiltersSelected;

    constructor(public ionEventBus: Events,
        public popoverController: PopoverController,
        private leadService: LeadService,
        ) { }

	public ngOnInit(): void {
    



        if( this.numberOfOnHoldWaitlistFiltersSelected === undefined ){ 
            this.numberOfOnHoldWaitlistFiltersSelected = 1;
        }
    
		this.isListFilteredOnHandler = (userObjToFilter: SearchResultUserStruct) => {
			this.isListFiltered = true;
		};

		this.isListFilteredOffHandler = () => {
			this.isListFiltered = false;
		};

		this.ionEventBus.subscribe("dashboard-searchbar:entity-selected", this.isListFilteredOnHandler);
		this.ionEventBus.subscribe("dashboard-searchbar:reset", this.isListFilteredOffHandler);
        this.ionEventBus.subscribe("user-sort-selected", data => { 
            window.adminlog.print( "from section over column" );
            window.adminlog.print( data );
            window.adminlog.print( this.title );
            //this.selectSortAction( data.value );
        });


	}
    
    public ngOnChanges():void {
        if(this.listItemObjectType === 'M&G appointment') {
            console.log("listItemObjectType", this.listItemObjectType);
            console.log("listItemObjectType listItem", this.listItems);
            if(this.listItems) {
                this.listItems.map((listItem) => {

                    this.leadService.getByIdOnce(listItem.contextObj.sourceLeadId).then((lead) => {
                        if(lead && lead.source === LeadSource.PartnersPromo) {
                            listItem.badgeText2 = lead.source === LeadSource.PartnersPromo ? 'PROMO': ''
                            listItem.badgeColor2 = 'tertiary';
                        }
                    });
    
                });
            }
            
            // this.leadService.getByIdOnce(this.)

        }
        console.log( this.listItems );      
    }

	public ngOnDestroy():void {
		this.ionEventBus.unsubscribe("dashboard-searchbar:entity-selected", this.isListFilteredOnHandler);
		this.ionEventBus.unsubscribe("dashboard-searchbar:reset", this.isListFilteredOffHandler);
    }

	public  getListItemObjectTypePlural():string {
		return (this.listItemObjectTypePlural) ? this.listItemObjectTypePlural : this.listItemObjectType + "s";
	}


	public getItemUrl(item: OverviewListItem<any>) {
		if (!item.itemUrlRoot) { 
      return null;
    }

    let urlRoot = item.itemUrlRoot;
    if(item.itemUrlRoot && item.itemUrlRoot === '/meet-greets') {
      urlRoot += '/status';
    }
    
		if (item.itemUrl) {
      return [urlRoot, ...item.itemUrl.split('/')];
    } else { 
      return [urlRoot, item.contextObj._id];
    }
   }

	public downloadAction() {
        window.adminlog.print( "csv download" );
        //window.adminlog.print( this.listItems );
        this.onDownloadAction.emit(this.listItems);
	}
	public showSortAction() {
		this.sortActionMenu.open();
	}
	public selectSortAction(new_value: string) {

		this.selectedSort = this.sortOptions.find(opt => opt.value === new_value);
        this.onSelectSort.emit(new_value);
        
	}

    
    public async showFilterPopover(evt: Event) : Promise<void>{ 
        const popover = await this.popoverController.create({
			component: OnHoldWaitlistFilterPopoverComponent,
            event: evt,
			componentProps: {
			},
            //cssClass: "header-menu-popover user-menu-popover",
            cssClass: "header-menu-popover",
			showBackdrop: false
        });

        this.ionEventBus.subscribe("user-onHoldWaitlist-selected", data => { 
            console.log( "data", data);
            this.numberOfOnHoldWaitlistFiltersSelected = data.amountOfFiltersSelected;
            this.onHoldWaitList.emit(data);
        });

        return await popover.present();
    }

    public async showSortPopover(evt:Event):Promise<void> {
        console.log(" from show sort popover ")
        this.ionEventBus.unsubscribe("user-sort-selected");
		const popover = await this.popoverController.create({
			component: SortSelectionOveriewPopoverComponent,
			event: evt,
			componentProps: {
                sortOptions: this.sortOptions,
                sortSelected : this.selectedSort,
			},
            //cssClass: "header-menu-popover user-menu-popover",
            cssClass: "header-menu-popover",
			showBackdrop: false
        });
        
        /*
		this.ionEventBus.subscribe("logout", () => {
			this.authService
				.logout()
				.then(() => {
					this.router.navigate(['/login']);
				});
		});
        */
		popover.onDidDismiss().then(() => {
			this.ionEventBus.unsubscribe("logout");
		});
        this.ionEventBus.subscribe("user-sort-selected", data => { 
            window.adminlog.print( "from section over column" );
            window.adminlog.print( data );
            window.adminlog.print( this.title );
            this.selectSortAction( data.value );
        });
		return await popover.present();

	}


}
