import { Component, OnInit, NgZone, HostListener } from "@angular/core";
import { Router, ActivationEnd, UrlSegment } from "@angular/router";
import { Location as NgLocation } from "@angular/common";
import { PopoverController, Events } from "@ionic/angular";

import { Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";

import { AuthService, AdminService, LocationService, FooterInfoService, BadgeCountService, SettingsLocationIdService } from "src/services";

import { Admin, Location } from "src/models/entities";

import { UserMenuPopoverComponent } from "../user-menu-popover/user-menu-popover.component";
import { LocationSelectPopoverComponent } from "../location-select-popover/location-select-popover.component";

// import { mobiscroll, MbscCalendarOptions } from "@mobiscroll/angular";

import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";


import { routeDataOptionsObj } from "../dashboard-routing.module";

import { faCalendarWeek, faColumns } from '@fortawesome/pro-regular-svg-icons';
import { faBooks } from '@fortawesome/pro-solid-svg-icons';
import { SettingsMobileNavService } from "src/services/settings-mobile-nav.service";
import { MobileNavPopoverComponent } from "../mobile-nav-popover/mobile-nav-popover.component";
import { NavOption } from "src/app/settings/definers/NavOption";
import { MatDialog } from "@angular/material";
import { AlertModelComponent } from "../alert-model/alert-model.component";

export interface MenuItem {
	label: 			string,
	link: 			string,
    extrernalLink? : boolean,
    icon?:            any,
	badgeCount?:	number,
	subMenu?:		MenuItem[]
}

@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard-frame.component.html",
	styleUrls: ["./dashboard-frame.component.scss"],
})

export class DashboardFrameComponent implements OnInit {
    public salesforceLocation: boolean = false;

    @HostListener('window:resize', ['$event'])
    public onResize(){ 
        this.setScreenType();
    }
    
	public unsubscriber: Subject<void> = new Subject();

	public user: Admin;

  public faCalendarWeek = faCalendarWeek;
  public faColumns = faColumns;
  public faCaretDown = faCaretDown;

  public faBooks = faBooks;

  public isIonIcon( value : any ) : boolean{ 
      //console.log( value, typeof value );
      if( typeof value === 'string' && value != null ){ 
          return true;
      }
      return false;
  }

	public mainMenu: {[menuId: string]: MenuItem} = {

		//"user-requests": {
		//	label: "New User Requests",
		//	link: "/user-requests",
		//	icon: "ios-person-add",
		//},
		//"dog-requests": {
		//	label: "New Dog Requests",
		//	link: "/dog-requests",
		//	icon: "ios-tennisball",
		//},
		"meet-greets": {
			label: 	"M&G Appointments",
			link:	"/meet-greets",
			icon:	"ios-home",
      subMenu: [
        {
          label: "Calendar",
          link: "calendar",
          icon: "" // use faCalendarWeek
        },
        {
          label: "Status",
					link: "status",
					icon: "" // use faColumns
        }

      ]
		},
		"appointments": {
			label:	"Appointments",
			link:	"/appointments",
			icon: 	"ios-calendar",
		},
		"access-requests":  {
			label: 	"Invite Requests",
			link:	"/access-requests",
			icon: 	"ios-person-add",
			subMenu: [
        {
          label: "Deprecated User Requests",
          link: "deprecated",
          icon: "ios-person-add"
        }
      ]
		},
		"drop-offs": {
			label:	"Drop-Off",
			link:	"/drop-offs",
			icon:	"ios-paw",
		},
		"leads": {
			label:	"Leads",
			link:	"/leads",
			icon: "ios-funnel",
			subMenu: [
        {
          label: "Archive",
          link: "archive",
          icon: "ios-folder-open",
        }
      ]
		},
        "approvals": {
            label: "Approvals",
            link: "/approvals",
            icon: "ios-notifications",
            
            subMenu: [
                {
                  label: "Updates",
                  link: "updates",
                  icon: "ios-folder-open",
                },
                {
                  label: "Meet & Greets",
                  link: "meet-and-greets",
                  icon: "ios-folder-open", // use faColumns
                }
        
              ]
        },
        "influencer": {
            label: "Influencer Report",
            link: "/influencer",
            icon: "ios-heart",
        },
		"separator": {
			label: 	"",
			link:	"separator",
		},
		"users": {
			label:	"Users",
			link:	"/users",
			icon:	"ios-contacts",
		},
		"admins": {
			label:	"Admins",
			link: 	"/admins",
			icon:	"ios-contact",
		},
		"accounts":{
			label: "Accounts",
			link: "/accounts",
			icon: "ios-today"
		},
        "separatorTwo": {
            label:     "",
            link:    "separator",
        },
        "training": {
            label: "Training Resources",
            extrernalLink: true,
            link: "https://kb-dashboard.dogtopia.com/?autologin_code=mpoeM6gqx2fGo0YtJYc6SuRYodo3tsMi",
            icon: faBooks
        }
		//"data-sync": {
		//	label: "Data Sync",
		//	link: "/data-sync",
		//	icon: "ios-sync",
		//	subMenu: {
		//		"auto-matched": {
		//			label: "Auto-matched",
		//			link: "auto-matched",
		//			icon: "ios-cloud-done"
		//		},
		//		"unmatched": {
		//			label: "Unmatched",
		//			link: "unmatched",
		//			icon: "ios-help-circle"
		//		}
		//	}
		//}
	};

	/*public mainMenu: {[menuId: string]: MenuItem} = {
		"user-requests": {
			label: 	"New User Requests",
			link:	"/user-requests",
			icon: 	"ios-person-add",
		},
		"dog-requests": {
			label: 	"New Dog Requests",
			link:	"/dog-requests",
			icon: 	"ios-tennisball",
		},
		"appointments": {
			label:	"Appointments",
			link:	"/appointments",
			icon: 	"ios-calendar",
		},
		"meet-greets": {
			label: 	"M&G Requests",
			link:	"/meet-greets",
			icon:	"ios-home",
		},
		"drop-offs": {
			label:	"Drop-Off",
			link:	"/drop-offs",
			icon:	"ios-paw",
		},
		"inquiries": {
			label:	"General Inquiries",
			link:	"/inquiry",
			icon:	"ios-list-box",
		},
		"separator": {
			label: 	"",
			link:	"separator",
		},
		"users": {
			label:	"Users",
			link:	"/users",
			icon:	"ios-contacts",
		},
		"admins": {
			label:	"Admins",
			link: 	"/admins",
			icon:	"ios-contact",
		},
		"data-sync": {
			label: "Data Sync",
			link: "/data-sync",
			icon: "ios-sync",
			subMenu: {
				"auto-matched": {
					label: "Auto-matched",
					link: "auto-matched",
					icon: "ios-cloud-done"
				},
				"unmatched": {
					label: "Unmatched",
					link: "unmatched",
					icon: "ios-help-circle"
				}
			}
		}
	};*/

	public userMenu:Array<MenuItem> = [
		{
			label: 	"Profile",
			link:	"/profile",
		},
		{
			label:	"Settings",
			link:	"/settings",
		},
		{
			label: 	"",
			link:	"separator",
		},
		{
			label: 	"Sign Out",
			link:	"logout()",
		},
	];

	public currentUserLocation: Location;

  public get currLocationTimezone() {
    if (this.currentUserLocation && this.currentUserLocation.timezone) return this.currentUserLocation.timezone
    else return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

	public userLocations: Location[];

	public userAvatarImgUrl: string = "/assets/images/default-user-avatar.svg";
	public contentInheritHeight: boolean = true;
	public pageHasFixedTitleBarFooter: boolean = false;
	public pageSpecificContentWrapClass: string = "";
	public pageSpecificCardContentClass: string = "";
	public fadedOptionKey: string = "";

    public mobileView: boolean = false;
    public mobileSettingsNavOpened:boolean = false;
    public selectedNavOption;

    public approvalsHeightMod: boolean = false;

	public actionableEnter(key:string): void{
		this.fadedOptionKey = key;
	}
	public actionableLeave(_key: string): void{
		this.fadedOptionKey = "";
	}

    public urlIncludesSettings : boolean = false;

	public currentRouteURL: UrlSegment[] = [];
	public get currentRouteGroup(): string{

		if(this.currentRouteURL[0]) {
			return this.currentRouteURL[0].path;
		}
		else return "";
	}

  public get currentSubGroup(): string {
    if(window.location.pathname.split('/').length > 1) {
      return window.location.pathname.split('/')[2];
    }
    else return "";
  }

  public isParent(key: string): boolean {
    if(!this.fadedOptionKey || !this.mainMenu[key].subMenu) {
      return false;
    } else {
      return this.mainMenu[key].subMenu.findIndex(item => item.label === this.fadedOptionKey) > -1;
    }
  }

  public get isCalendar(): boolean {
    return (this.currentRouteGroup === "meet-greets" && !this.currentSubGroup) || (this.currentRouteGroup === "meet-greets" && this.currentSubGroup === "calendar");
  }

    public setScreenType() {
        if (window.innerWidth < 768) {
            this.mobileView = true;
        }
        else {
            this.mobileView = false;
        }
    }
    public setMobileSettingsCompNav() {
        this.urlIncludesSettings = window.location.href.includes("settings");
    }
    public checkIfViewisApproval() {
        this.approvalsHeightMod = window.location.href.includes("approvals") && !window.location.href.includes("createUpdate");
    }
    public matchRouteGroup(group: string): boolean {
        window.adminlog.print("Route group?", group);
        return group === this.currentRouteGroup;
    }
    constructor(
        public ionEventBus: Events,
        public router: Router,
        public authService: AuthService,
        public adminService: AdminService,
        public locationService: LocationService,
        public footerInfoService: FooterInfoService,
        public badgeCountService: BadgeCountService,
        public popoverController: PopoverController,
        public settingsMobileNavService: SettingsMobileNavService,
        private urlLocation: NgLocation,
        private zone: NgZone,
        private dialog: MatDialog,
        private settingsLocationIdService: SettingsLocationIdService
    ) {
    }

    public ngOnInit(): void {
        console.log('router', this.router.url, this.router);
        //if( this.router.url === "/approvals" ){ 
        //    this.approvalsHeightMod = true;
        //}
        console.log( "fromNgOnInit" );
        this.setScreenType();
        this.router.events.subscribe((event) => {
            this.setMobileSettingsCompNav();
            this.checkIfViewisApproval();
        });
        this.settingsMobileNavService.getNavOptions().subscribe(navOptions => {
            if( navOptions.length > 0 ){ 
                let selectedOption = navOptions.filter(option => option.selected)[0];
                if( selectedOption ){ 
                    this.selectedNavOption = selectedOption.navItemName;
                }
            }
            
        });
		this.authService.getLoggedInUser()
			.then((loggedInUser: Admin) => {

				this.user = loggedInUser;
				if(this.user.role === Admin.ROLE_SUPER_ADMIN){

					this.mainMenu['webcam-access'] = {
						label:	"Webcam Whitelist",
						link:	"/webcam-access",
						icon:	"ios-videocam",
					};

					/*this.mainMenu['dog-migrations'] = {
						label:	"Dog Migrations",
						link:	"/migration-dog-requests",
						icon:	"ios-airplane",
					};*/

                }
                this.salesforceLocation = false;

                this.locationService.getCurrUserLocation()
                    .then((currUserLocation) => {

                        this.locationService.getCurrUserLocationObs().subscribe((currUserLocation) => {
                            console.log('currUserLocation', currUserLocation);
                            this.settingsLocationIdService.setLocationId(currUserLocation._id);
                            this.salesforceLocation = currUserLocation.salesforceConnection;
                        })
        
                        this.currentUserLocation = currUserLocation;

						this.setMenuBadgeCounts();

					})
					.catch((error) => {
						window.adminlog.print(error);
					});

				this.setUserAvatarImgUrl();

			})
			.catch((error) => {
				window.adminlog.print(error);
			});

		this.locationService.getUserLocations().subscribe((userLocations) => {
			this.userLocations = userLocations;
		});

		this.router.events
			.pipe(
				filter((event) => {
					return (event instanceof ActivationEnd);
				}),
				map((event:ActivationEnd) => {
					if(event.snapshot.url.length > 0){

						this.currentRouteURL = event.snapshot.url;

					}
					return event.snapshot.data;
				})
			)
			.subscribe((routeData:routeDataOptionsObj) => {


				if(routeData.hasOwnProperty("fixedTitleBarFooter")){
					this.pageHasFixedTitleBarFooter = routeData.fixedTitleBarFooter;
				}

				if(routeData.hasOwnProperty("contentWrapClass")){
					this.pageSpecificContentWrapClass = routeData.contentWrapClass;
				}

				if(routeData.hasOwnProperty("mainContentCardClass")){
					this.pageSpecificCardContentClass = routeData.mainContentCardClass;
				}

			});

		// mobiscroll.settings = {
		// 	theme: "ios",
		// 	display: "bubble",
		// 	touchUi: false,
		// 	animate: "fade",
		// } as MbscCalendarOptions;

	}
    public ngOnDestroy(){ 
    }
    public ionViewDidLeave(): void {
		this.ngOnDestroy();
	}
    public ionViewDidEnter(): void {
        //this.ngOnInit();
        if( !this.currentUserLocation ){ 
            this.ngOnInit();
        }
    }
    public async toggleMobileDropdown(evt){ 

        this.mobileSettingsNavOpened = !this.mobileSettingsNavOpened;
        this.settingsMobileNavService.setMobilveNavOpenStatus(this.mobileSettingsNavOpened);
        
        this.ionEventBus.unsubscribe("settingsMobildNav");
        
                //this.settingsMeetAndGreetAppointmentsCopyService.setDay(dayName);
        const popover = await this.popoverController.create({
                component: MobileNavPopoverComponent,
                event: evt,
                componentProps: {
                },
                cssClass: "header-menu-popover settings-mobile-nav-popover",
                showBackdrop: false,
                //backdropDismiss:false
        });

        this.ionEventBus.subscribe("settingsMobildNav", data => {    
            this.settingsMobileNavService.setMobilveNavOpenStatus(false);
        });

        popover.onDidDismiss().then(() => {
            this.mobileSettingsNavOpened = !this.mobileSettingsNavOpened;
            this.settingsMobileNavService.setMobilveNavOpenStatus(this.mobileSettingsNavOpened);
        });

        return await popover.present();
    
    }

	public setUserAvatarImgUrl():void {

		// Disabling until the ability to upload user images is added (currently not in scope)
		/*this.adminService.getAvatarImgUrl(this.user._id).then((imgUrl) => {
			if(imgUrl){
				this.userAvatarImgUrl = imgUrl;
			}
		});*/

	}

	public async showUserMenuPopover(evt:Event):Promise<void> {

		const popover = await this.popoverController.create({
			component: UserMenuPopoverComponent,
			event: evt,
			componentProps: {
				menuItems: this.userMenu
			},
			cssClass: "header-menu-popover user-menu-popover",
			showBackdrop: false
		});

		this.ionEventBus.subscribe("logout", () => {
			this.authService
				.logout()
				.then(() => {
                    //this.user = null;
                    this.locationService.setCurrUserLocationToNull();
					this.currentUserLocation = null;
                    this.user = null;
                    //this.router.navigate(['/login'], { replaceUrl: true });
                    
                    this.router.navigate( ["/login"] );
                    //this.zone.run(() => {
                        //this.router.navigateByUrl("/login");
                    //});
				});
		});

		popover.onDidDismiss().then(() => {
			this.ionEventBus.unsubscribe("logout");
		});

		return await popover.present();

	}

	public async showLocationSelectPopover(evt:Event):Promise<void> {

		const popover = await this.popoverController.create({
			component: LocationSelectPopoverComponent,
			event: evt,
			componentProps: {
				locations: this.userLocations
			},
			cssClass: "header-menu-popover location-select-popover",
			showBackdrop: false
		});
 
		let updateCurrUserLocationHandler = (selectedUserLocationId: string) => {
            
			this.locationService.setCurrUserLocation(selectedUserLocationId).then((currUserLocation) => {
				this.currentUserLocation = currUserLocation;
                this.settingsLocationIdService.setLocationId(currUserLocation.id);

				this.setMenuBadgeCounts();

			});

			if(this.user.defaultLocation !== selectedUserLocationId){

				this.adminService.update(this.user._id, {defaultLocation: selectedUserLocationId})
					.then(() => {
						this.user.defaultLocation = selectedUserLocationId;
					})
					.catch((error) => {
						window.adminlog.print("Failed to update admin's default location: " + error);
					});

			}

		};

		this.ionEventBus.subscribe("user-location-selected", updateCurrUserLocationHandler);

		popover.onDidDismiss().then(() => {
			this.ionEventBus.unsubscribe("user-location-selected", updateCurrUserLocationHandler);
		});

		return await popover.present();

	}

	public routeTo(...links: string[]) {

		this.zone.run(() => {this.router.navigate(links); });
	}

	public  keepOriginalOrder(a: any, b: any):string {
		return a.key;
	}

	public setMenuBadgeCounts():void {

		this.unsubscriber.next();

		// this.badgeCountService.getUserRequestsCountObs(this.currentUserLocation._id)
		// 	.pipe(
		// 		takeUntil(this.unsubscriber)
		// 	)
		// 	.subscribe((userRequestsBadgeCount) => {
		// 		this.mainMenu["access-requests"].subMenu["user-requests"].badgeCount = userRequestsBadgeCount;
		// 	});

		//this.badgeCountService.getDogRequestsCountObs(this.currentUserLocation._id)
		//	.pipe(
		//		takeUntil(this.unsubscriber)
		//	)
		//	.subscribe((dogRequestsBadgeCount) => {
		//		this.mainMenu["dog-requests"].badgeCount = dogRequestsBadgeCount;
		//	});

		this.badgeCountService.getMeetGreetAppointmentsCountObs(this.currentUserLocation._id)
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((meetGreetsBadgeCount) => {
				this.mainMenu["meet-greets"].badgeCount = meetGreetsBadgeCount;
			});

		this.badgeCountService.getAppointmentsCountObs(this.currentUserLocation._id)
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((appointmentsBadgeCount) => {
				this.mainMenu["appointments"].badgeCount = appointmentsBadgeCount;
			});

		this.badgeCountService.getDropoffsCountObs(this.currentUserLocation._id)
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((dropoffsBadgeCount) => {
				this.mainMenu["drop-offs"].badgeCount = dropoffsBadgeCount;
			});

		this.badgeCountService.getLeadsCountObs(this.currentUserLocation._id)
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((leadssBadgeCount) => {
				this.mainMenu["leads"].badgeCount = leadssBadgeCount;
			});

        this.badgeCountService.getAccessRequestsCountObs(this.currentUserLocation._id).pipe(takeUntil(this.unsubscriber)).subscribe(
            (requestsBadgeCount: number) => {
                this.mainMenu["access-requests"].badgeCount = requestsBadgeCount;
            }
        );

        this.badgeCountService.getAccessRequestsCountObs(this.currentUserLocation._id).pipe(takeUntil(this.unsubscriber)).subscribe(
            (requestsBadgeCount: number) => {
                this.mainMenu["access-requests"].badgeCount = requestsBadgeCount;
            }
        );

        this.badgeCountService.getUpdatesCountObs(this.currentUserLocation._id).pipe(takeUntil(this.unsubscriber)).subscribe(
            (requestsBadgeCount: number) => {
                this.mainMenu["approvals"].subMenu[0].badgeCount = requestsBadgeCount;
            }
        );
        this.badgeCountService.getNewUpdatesForMeetAndGreets(this.currentUserLocation._id).pipe(takeUntil(this.unsubscriber)).subscribe(
            (requestsBadgeCount: number) => {
                this.mainMenu["approvals"].subMenu[1].badgeCount = requestsBadgeCount;
            }
        );;

		/*this.badgeCountService.getInquiriesCountObs(this.currentUserLocation._id)
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((inquiriesBadgeCount) => {
				this.mainMenu["inquiries"].badgeCount = inquiriesBadgeCount;
			});*/

    }
    public salesforceDialog(event) {
        // event.stopPropagation();
        console.log('enters');
        const dialogRef = this.dialog.open(AlertModelComponent, {
            width: '600px',
            height: '150px',
        });
    }

}
