import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessRequestsOverviewComponent } from './access-requests-overview/access-requests-overview.component';
import { AccessRequestsDetailComponent } from './access-requests-detail/access-requests-detail.component';
import { AccessRequestsRoutingModule } from './access-requests-routing.module';
import { PipesModule } from 'src/pipes/pipes.module';
import { EntityDetailModule } from '../dashboard/entity-detail/entity-detail.module';
import { SectionOverviewModule } from '../dashboard/section-overview.module';
import { DashboardCommonModule } from '../dashboard/dashboard-common.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { PetexecAccountLookupSimpleComponent } from './petexec-account-lookup-simple/petexec-account-lookup.component';
import { PetexecAccountInfoTableComponent } from './petexec-account-info-table/petexec-account-info-table.component';
import { AppCommonModule } from '../app-common.module';
@NgModule({
  declarations: [
    AccessRequestsOverviewComponent,
    AccessRequestsDetailComponent,
    PetexecAccountLookupSimpleComponent,
    PetexecAccountInfoTableComponent
    ],
    entryComponents: [
        PetexecAccountLookupSimpleComponent
    ],
    imports: [
        AppCommonModule,
    DashboardCommonModule,
    AccessRequestsRoutingModule,
	SectionOverviewModule,
	EntityDetailModule,
    PipesModule,
    NgSelectModule
  ]
})
export class AccessRequestsModule { }
