import { Entity } from "src/models/entity";

import { NotificationStruct, NotificationType, NotificationMethod } from "src/models/structs";

export class Notification extends Entity<Notification> implements NotificationStruct {

	static readonly TYPE_APPOINTMENT:NotificationType	= "Appointment";
	static readonly TYPE_DROP_OFF:NotificationType 		= "Drop off";
	static readonly TYPE_REQUEST:NotificationType 		= "Request";
	static readonly TYPE_LEAD: NotificationType = "Lead";
	static readonly TYPE_LEAD_FOLLOW_UP: NotificationType = "In Progress (Follow Up) Leads";


	static readonly METHOD_EMAIL: NotificationMethod 		= "Email";
	static readonly METHOD_TEXT: NotificationMethod 		= "Text";
	static readonly METHOD_EMAIL_TEXT: NotificationMethod 	= "Email and Text";

	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	location: string;
	type: NotificationType;
	mean: NotificationMethod;
	createdBy: string;
	status: boolean;

	constructor(data?: NotificationStruct, id?: string){
		super(data, id);
	}
}
