import { Component, OnInit } from '@angular/core';

import { NavParams } from "@ionic/angular";

import moment from 'moment-timezone';

import { faCheckCircle, faExclamationCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';

import { DisplayableVaccine } from 'src/models/entities/displayableVaccine'; 

@Component({
    selector: 'app-vaccine-popover',
    templateUrl: './vaccine-popover.component.html',
    styleUrls: ['./vaccine-popover.component.scss'],
})
export class VaccinePopoverComponent implements OnInit {

    public faCheckCircle = faCheckCircle;
    public faMinusCircle = faMinusCircle;
    public faExclamationCircle = faExclamationCircle


    public vaccines: any[];
    public displayVaccines = [];
    public todaysDate: Date;

    // green vaccine is not expired
    // yellow -> vaccine exp within a month
    // red expired


    constructor(
        public navParams: NavParams,
    ) {
        this.todaysDate = new Date();
        this.vaccines = this.navParams.data.vaccines;
        const timezone = this.navParams.data.timezone;
        for (let x = 0; x < this.vaccines.length; x++) {
            let displayableVaccine: DisplayableVaccine = new DisplayableVaccine();
            displayableVaccine.shotname = this.vaccines[x].shotname;
            
            // const momentDate = moment(this.vaccines[x].expiration.toDate().setTime(43200000));
            const momentDate = moment(this.vaccines[x].expiration.toDate()).set({"hour": 12, "minute": 30});
            displayableVaccine.expiration = timezone ? momentDate.tz(timezone).format("M/D/YYYY") : momentDate.format("M/D/YYYY");
            displayableVaccine.isrequired = this.vaccines[x].isrequired;


            if (this.todaysDate < moment(displayableVaccine.expiration).toDate()) {
                displayableVaccine.color = "#00c6bc";
                displayableVaccine.icon = this.faCheckCircle;
            }
            else {
                displayableVaccine.color = "#FF0149";
                displayableVaccine.icon = this.faMinusCircle;
            }

            let warningColorDate: Date = new Date();
            warningColorDate.setMonth(this.todaysDate.getMonth() + 1)

            if (
                this.todaysDate < moment(displayableVaccine.expiration).toDate()
                &&
                warningColorDate > moment(displayableVaccine.expiration).toDate()
            ) {
                displayableVaccine.color = "#ffcc42";
                displayableVaccine.icon = this.faExclamationCircle;
            }



            this.displayVaccines.push({
                displayableVaccine
            });
        }
        console.log("vaccines", this.displayVaccines);
    }

    ngOnInit() {
        console.log("navParams", this.vaccines);
    }



}
