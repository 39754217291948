import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, catchError, first, take } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { UserService } from "./user.service";

import { DogService } from "./dog.service";

import { UserRequestStruct, RequestStatus } from "src/models/structs";

import { UserRequest, User, Location, Dog } from "src/models/entities";

import * as moment from "moment-timezone";
import { DogRequestService } from './dog-request.service';

@Injectable({
	providedIn: "root"
})

export class UserRequestService extends EntityServiceAbstract<UserRequest> {

	public collection = "requests";

	protected statusColorMap:object = {
		"pending": 		"primary",
		"approved":		"success",
		"cancelled":	"danger",
		"declined":		"danger",
	};

	private queryItemsLimit = 100;

	constructor (
		protected dbService: FirestoreService, 
		private logService: ActivityLogService,
		private userService: UserService,
		private dogService: DogService,
		private dogRequestService: DogRequestService
	){
		super();
	}

	public getById(userRequestId:string):Observable<UserRequest> {

		return this.dbService.getDoc(this.collection, userRequestId).pipe(
			map((userRequestDoc) => {
				
				if(userRequestDoc.exists){
					return new UserRequest(userRequestDoc.data() as UserRequestStruct, userRequestDoc.id);
				}

			})
		);

	}

	public getByUserId(userId: string, locationId: string = ""):Observable<UserRequest[]> {

		let queryConditions = [
			["userId", "==", userId],
		];

		if(locationId){
			queryConditions.push(["locationId", "==", locationId]);
		}

		return this.dbService.getCollection(this.collection, {conditions: queryConditions} as queryOptions).pipe(
			map((userRequestDocs) => {
				return this.mapDocsToObjs(userRequestDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public update(userRequestId:string, updatedData:any):Promise<void> {

		if(!updatedData.updatedAt){
			
			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, userRequestId, updatedData);

	}

	public getNewUserRequests(locationId:string, userIdToFilter:string = ""):Observable<UserRequest[]> {
		
		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", UserRequest.PENDING]
			],
			orderByField: "createdAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((userRequestDocs) => {
				return this.mapDocsToObjs(userRequestDocs);
			}),
			
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getCompletedUserRequests(locationId:string, userIdToFilter:string = ""):Observable<UserRequest[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((userRequestDocs) => {
				return this.mapDocsToObjs(userRequestDocs)
					.filter((userRequest:UserRequest) => {
						// Return true (and keep) when appointment DOES NOT have cancelled property
						return (userRequest.status !== UserRequest.PENDING);
					})
					.slice(0, this.queryItemsLimit);
			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw(error);
			})
		);

	}

	public async revokeUserAccess(user: User, location: Location, userDogs?: Dog[]):Promise<any> {

		const adminResponse = "The account is revoked by an admin.";
		
		if(userDogs === undefined){
			userDogs = await this.dogService.getDogsByUserIdObs(user._id).pipe(first()).toPromise();
		}

		let dogRequests = await this.dogRequestService.getDogRequestsByUserId(location._id, user._id).pipe(first()).toPromise();
		
		let dogRequestsByDogId = {};

		dogRequests.forEach((dogRequest) => {
			dogRequestsByDogId[dogRequest.dogId] = dogRequest._id;
		});

		return new Promise((resolve, reject) => {
			
			this.getByUserId(user._id, location._id).pipe(
				take(1)
			).subscribe(
				(userRequests) => {

					if(userRequests.length > 0){

						this.declineUserRequest(userRequests[0]._id, user, location, adminResponse).then(() => {

							let dogRequestPromises = [];

							userDogs.forEach((dog) => {
								
								let dogRequestPromise = this.dogRequestService.declineDogRequest(
									dogRequestsByDogId[dog._id], 
									dog, 
									location, 
									adminResponse
								);

								dogRequestPromises.push(dogRequestPromise);

							});

							if(dogRequestPromises.length > 0){

								Promise.all(dogRequestPromises).then(() => {
									resolve();
								}).catch((error) => { reject(error); });

							}else{
								resolve();
							}

						}).catch((error) => { reject(error); });

					}else{

						this.userService.updateLocationAccess(user, location, false).then(() => {
							resolve();
						}).catch((error) => { reject(error); });

					}
					
				},
				(error) => { window.adminlog.print(error); }
			);

		});

	}

	public approveUserRequest(userRequestId:string, user: User, location: Location, note: string):Promise<any> {

		return this.userService.updateLocationAccess(user, location, true).then(() => {

			return this.completeUserRequest(userRequestId, UserRequest.APPROVED, note);

		});

	}

	public declineUserRequest(userRequestId: string, user: User, location: Location, note: string):Promise<any> {

		return this.userService.updateLocationAccess(user, location, false).then(() => {

			return this.completeUserRequest(userRequestId, UserRequest.DECLINED, note);

		});

	}

	private completeUserRequest(userRequestId: string, status: RequestStatus, note: string):Promise<any> {

		let updatedValues = {
			status: status,
			note: note
		};

		let userRequestUpdatePromise = this.update(userRequestId, updatedValues);

		let logEntryPromise = this.logService.addLogEntry(this.collection, userRequestId, status, note);

		return Promise.all([userRequestUpdatePromise, logEntryPromise]);

	}

	private mapDocsToObjs(userRequestDocs:firebase.firestore.QueryDocumentSnapshot[]):UserRequest[] {

		return userRequestDocs.map((userRequestDoc) => {
					
			const userRequest = new UserRequest(userRequestDoc.data() as UserRequestStruct, userRequestDoc.id);

			return userRequest;

		});

	}

}