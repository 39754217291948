import { Injectable } from "@angular/core";
import { Observable, Subscription} from "rxjs";

import { AdminService } from './admin.service';
import { Admin } from "src/models/entities";
import firebase from 'firebase';
import { ISettingsService } from './interfaces';
import { rejects } from "assert";

@Injectable({
	providedIn: "root"
})

export class AuthService implements ISettingsService<Admin>{
	public getAllSettings(_prop?: string | Admin): Promise<{ [settings_id: string]: any; }> {
		return this.adminService.getAllSettings(this.user.id);
    }
	public getSettings(_configuration_id?: string, _user?: string | Admin): Promise<firebase.firestore.DocumentSnapshot> {
        const conf_id = _configuration_id ? _configuration_id : this.page_setting;
		return this.adminService.getSettings(conf_id, this.user.id);
    }
	public setSettings(settings: any, _configuration_id?: string, _user?: string | Admin): Promise<void> {
        const conf_id = _configuration_id ? _configuration_id : this.page_setting;
        console.log( "conf", conf_id );
		return this.adminService.setSettings(settings, conf_id,  this.user.id);
    }

	public listenforSettings: Subscription;
	public redirectUrl: string;

	public user: Admin;

	public settings: { [settings_path: string]: any } = {};
	private sanitizePath(path: string) {
		return path.replace('/', '_2f_');
	}
	protected get page_setting() {
		return this.sanitizePath(window.location.pathname);
	}

	public get pageSettings() {
		for (let path in this.settings) {
			if (path.charAt(0) == "~") {
				if (this.page_setting.match(new RegExp(path.slice(1)))){ 
                    return this.settings[path];
                } 
			} 
            else if (this.page_setting.match(this.sanitizePath(path))){ 
                return this.settings[path];
            }
		}
		return null;
	}


	public firebaseUser: firebase.User;

	constructor(
		private adminService: AdminService,
	) {}

	public getLoggedInUser():Promise<Admin> {

		return new Promise((resolve, reject) => {

			if(this.user && this.user._id !== ""){
				// Set settings
				return this.fetchAdminSettings().finally(() => resolve(this.user));

			}

			firebase.auth().onAuthStateChanged((firebaseUser: firebase.User) => {

				if(!firebaseUser){
					return reject();
				}

				this.firebaseUser = firebaseUser;

				this.adminService.getById(firebaseUser.uid)
					.then((loggedInAdmin) => {

						// User is an admin, save user object
						this.user = loggedInAdmin;

						// Set settings
						this.fetchAdminSettings().finally(() => resolve(this.user));
						

					})
					.catch((error) => {

						// User that is logged into firebase isn't an admin, so logout
						this.logout();

						reject(error);

					});

			});

		});

	}

    
	protected fetchAdminSettings(): Promise<any> {
		// Set settings
		const prom: Promise<any> = this.adminService.getAllSettings(this.user.id).then(settings => {
			this.settings = settings;
		}).catch(e => window.adminlog.error("Retrieved settings error", e)) // even if settings are undefined, or an error occur, resolve the promise correctly

		if (!this.listenforSettings) {
			this.listenforSettings = this.adminService.settings$.subscribe((newsettings) => {
				window.adminlog.print("Retrieved settings", this.settings);
				this.settings = newsettings;
			});
		}

		return prom;

	}

	public getLoggedInUserObs():Observable<Admin> {

		return this.adminService.getByIdObs(this.firebaseUser.uid);

	}

	public getBearerToken():Promise<string> {

		return this.firebaseUser.getIdToken(true);

	}

	/*async onAuth():Promise<boolean> {
		if(this.authState)
			return true;

		return await this.afAuth.authState.pipe(map(mappin => {this.authState = mappin; return !(!mappin)})).toPromise();


		// if( await onAuth() ){ resolve(true)}
	}
	isLoggedIn():Promise<boolean> {

		return new Promise((resolve, reject) => {

			this.user.subscribe((result) => {

				if(result){
					resolve(true);
				}else{
					reject();
				}

			});

		});

	}*/

	public updatePassword(newPassword: string):Promise<void> {

		return new Promise((resolve, reject) => {

			if(!this.firebaseUser){

				reject("There is no logged in user");

			}else{

				return  this.firebaseUser.updatePassword(newPassword) ;

			}

		})

	}
	public updateEmail(email: string): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!this.firebaseUser) {
				reject("There is no logged in user");
			} else {
				return this.firebaseUser.updateEmail(email);
			}
		})
	}
	public resetPassword(email: string):Promise<void> {

		return firebase.auth().sendPasswordResetEmail(email);

	}

	public loginWithEmail(email: string, password: string):Promise<any> {

		return new Promise((resolve, reject) => {

			firebase.auth()
				.signInWithEmailAndPassword(email, password)
				.then((result) => {

					let firebaseUser = result.user as firebase.User;

					this.adminService.isAdmin(firebaseUser.uid)
						.then((isAdmin) => {
							if(isAdmin){
								resolve(result);
							}else{
								reject("You don't have access to this dasboard. Please contact your admin.");
							}
						});
				})
				.catch((error) => {
					reject("The email or password entered was incorrect, please try again.");
				});

		});

	}

	public logout():Promise<any> {
        if (!this.user) {
            console.log('user not logged in');
            return;
        }

        return this.adminService.setSettings({ ["data_column_setting"]:{"onHoldFilter": null, "onHoldDateParam": null} }, '_2f_appointments', this.user._id).then(() => {
             return new Promise((resolve, reject) => {

			    // this.user = null;
                firebase.auth().signOut().then(() => {
                    resolve(true);
                }).catch(() => {
                    reject();
                });

            });
        }).catch((e) => {
            console.log(`${e.code}:Error loggin-out, ${e.message}, `);
        }) ;

	}

}
