import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, NgZone } from "@angular/core";

import { Location as NgLocation } from "@angular/common";

import { ActivatedRoute, ParamMap, Router } from "@angular/router";

import { Observable, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

import {
	ToastService,
	LocationService,
	AccessRequestService,
	UserService,
	  AccountService,
	  PetService,
	  FirestoreService,
	  LeadService,

  InviteService
} from "src/services";

import { User,Account, Dog, AccessRequest, Location, Pet } from "src/models/entities";
import { AccessRequestAction, AccessRequestStatus } from '../../../models/structs/access-request';
import firebase from 'firebase';
import { AccountStruct, PetStruct, AccountAccess, LeadState, LeadTag, LeadSource } from 'src/models/structs';
import { InviteMemberStruct } from 'src/models/structs/invite';

type DogDataObj = {
	dog: Dog,
	requestId?: string,
	applyChanges?: boolean
};

@Component({
	selector: "app-access-requests-detail",
	templateUrl: "./access-requests-detail.component.html",
	styleUrls: ["./access-requests-detail.component.scss"],
})

export class AccessRequestsDetailComponent implements OnInit, OnDestroy {

	public unsubscriber: Subject<void> = new Subject();

	public accessRequestObs: Observable<AccessRequest>;

	public accessRequest: AccessRequest;

	public accessRequestLocation: Location;

	public collectionName: string = this.accessRequestService.collection;

	public accessRequestId: string;

	public currLocation: Location;
	public userLocationSet = false;

	public isApproveBtnLoading = false;
	public isDeclineBtnLoading = false;

	public account: Account;

	public action_options = [
		{ value: AccessRequestAction.Invalid, label: "Invalid" },
		{ value: AccessRequestAction.Invite, label: "Account invite" },
		{ value: AccessRequestAction.Lead, label: "New lead" },
		{ value: AccessRequestAction.Existing, label: "Existing Customer"}
	]
	public access_options = [
		{ value: AccountAccess.None, label: "No access"},
		{ value: AccountAccess.Minimal, label: "Guest access"},
		{ value: AccountAccess.Full, label: "Full access"}
	];

	public action_label = {
		[AccessRequestAction.Pending]: "None selected",
		[AccessRequestAction.Invite]: "Account invite",
		[AccessRequestAction.Lead]: "New lead",
		[AccessRequestAction.Invalid]: "Invalid",
		[AccessRequestAction.Existing]: "Existing Customer"
	}

	constructor(
		private router: Router,
		private ngZone: NgZone,
		private route: ActivatedRoute,
		private urlLocation: NgLocation,
		private toastService: ToastService,
		private locationService: LocationService,
		protected accessRequestService: AccessRequestService,
		protected accountService: AccountService,
		protected petService: PetService,
		protected leadService: LeadService,
		protected userService: UserService,
		public firestore: FirestoreService,
		protected inviteService: InviteService
	) { }

	public ngOnInit(): void {

		this.accessRequestObs = this.route.paramMap.pipe(
			switchMap((params: ParamMap) => {
				return this.accessRequestService.getById(params.get("id"));
			})
		);

		this.accessRequestObs
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((accessRequest) => {

                //console.log( accessRequest )
				this.accessRequestId = accessRequest._id;

				this.accessRequest = accessRequest;
				if(this.accessRequest.accountId && !this.accessRequest.accountAccess){
					this.accessRequest.accountAccess = AccountAccess.None;
				}
				if(this.accessRequest.accountId && (!this.account || (this.account._id !== this.accessRequest.accountId))){
					this.accountService.getById(this.accessRequest.accountId).then(account => {
						if(account) this.account = account;
					}).catch(err => window.adminlog.error(err));
				}

				window.adminlog.print('access request', this.accessRequest);
				this.locationService.getById(accessRequest.locationId).then((location) => {
					this.accessRequestLocation = location;
				});




			});

		this.locationService.getCurrUserLocationObs()
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((currUserLocation) => {

				if (this.userLocationSet) {
					this.goBackToList();
				}
				this.currLocation = currUserLocation;
				this.userLocationSet = true;

			});

	}

	public ngOnDestroy(): void {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	public isActionBtnLoading(): boolean {

		return this.isApproveBtnLoading || this.isDeclineBtnLoading;

	}

	public approveAccessRequest(): void {

		this.isApproveBtnLoading = true;


	}

	public declineAccessRequest(): void {

		this.isDeclineBtnLoading = true;


	}
	public cancelSearch(){
		this.searchingPetexec = false;
	}
	public markInvalid(invalid: boolean = true){
		if (invalid) {
			this.accessRequestService.update(this.accessRequest._id, {
				status: AccessRequestStatus.Invalid
			});

		} else {
			this.accessRequestService.update(this.accessRequest._id, {
				status: AccessRequestStatus.Pending
			});

		}

	}
	public get isInvalidRequest() {
		return this.accessRequest && this.accessRequest.status === AccessRequestStatus.Invalid;
	}
	public showSuccessNotif(): Promise<void> {
		return this.toastService.show("Success!  You have updated this user request status", "medium");
	}

	public goBackToList(): void {
		this.urlLocation.back();
	}

	public trackByDogId(index: number, item: DogDataObj): string {
		return item.dog._id;
	}

	public onSelectActionChange(action: AccessRequestAction) {
		//this.accessRequest.action = action;
		this.accessRequestService.update(this.accessRequest._id, { action: action }).then(_s => window.adminlog.print("Updated action val", action));
	}
	public onSelectAccessChange(access: AccountAccess){
		this.accessRequestService.update(this.accessRequest._id, {accountAccess: access}).then(_s => window.adminlog.print("Updated account access val", access));
	}
	public searchingPetexec = false;
	public lookupPetexecAccount() {
		this.searchingPetexec = true;
	}


	public inviteAccount(){
		window.adminlog.print(this.accessRequest, this.accessRequest.accountId, this.accessRequest.accountAccess);
		if(!this.accessRequest || !this.accessRequest.accountId || !this.accessRequest.accountAccess) return;

			this.isSubmitting = true;
			const invitation = {
				firstName: this.accessRequest.firstName,
				lastName: this.accessRequest.lastName,
				phone: this.accessRequest.phoneNumber || '',
				email: this.accessRequest.email,
				access: this.accessRequest.accountAccess
			} as InviteMemberStruct;

			invitation.state = 0;
			invitation.accountId = this.accessRequest.accountId;
			if (this.accessRequest.userId) invitation.userId = this.accessRequest.userId;

			invitation.sent = 1;
			invitation.createdAt = firebase.firestore.FieldValue.serverTimestamp() as any;

			this.firestore.db.collection("accountMemberInvites").add(invitation).then(s => {
					this.isSubmitting = false;
					this.accessRequestService.update(this.accessRequest._id, {
						inviteId: s.id,
						status: AccessRequestStatus.Completed
					});
			}).catch(e => {
				window.adminlog.error(e);
				this.isSubmitting = false;

			});

	}

	public cancelInvite() {
		if (!this.accessRequest.inviteId) return;
		this.inviteService.cancelInvite({ _id: this.accessRequest.inviteId } as any).then(_s => {
			// Cancel other aspects if successfull.
			this.accessRequestService.update(this.accessRequest._id, {
				status: AccessRequestStatus.Pending,
				inviteId: firebase.firestore.FieldValue.delete(),
				accountId: firebase.firestore.FieldValue.delete(),
				accountAccess: firebase.firestore.FieldValue.delete()
			});

		}).catch(err => {
			window.adminlog.error(err);
			// Can show feedback via alertservice here (todo)
		});
	}

	public async unsetAccount() {
		this.account = null;
		if (this.accessRequest.inviteId) {
			this.cancelInvite();
		} else {
			this.accessRequestService.update(this.accessRequest._id, {
				status: AccessRequestStatus.Pending,
				accountId: firebase.firestore.FieldValue.delete(),
				accountAccess: firebase.firestore.FieldValue.delete()
			});
		}
	}
	// public autoLookUpAccount(){
	//
	// }

	protected updateAccountId(accountId: string){

		this.accessRequest.accountId = accountId;
		this.accessRequestService.update(this.accessRequest._id, {accountId: accountId, accountAccess: AccountAccess.Full});
	}

	public addAccount(account: Account): Promise<string> {
		if (!account.functional) account.functional = { access: {} } as any;
		account.functional.companyID = this.currLocation.credential;
		account.functional.locationId = this.currLocation._id;

		const pets = account.pets.map(pet => {
			return new Pet({ data: pet, fromExec: true });
		});

		if (account.pets) delete account.pets;

		return this.accountService.createAccount(<AccountStruct>account.prepare()).then(async (createdAccountId) => {
			this.account._id = createdAccountId;
			await Promise.all([this.accountService.setAccountCredentials(account)].concat(pets.map(pet => {
				pet.init();
				pet.functional.ownerId = createdAccountId;
				pet.functional.companyID = this.currLocation.credential;
				pet.functional.locationId = this.currLocation._id;
				return this.petService.createPet(<PetStruct>pet.prepare());
			})));

			return createdAccountId;


		}).catch(err => {
			window.adminlog.error(err);
			return err;
		})
	}


	public setAccount(account: Account){

		this.account = account;
		// Create or Find Account

		this.searchingPetexec = false;
		// If account exists, just need to set account. If not, add, then set.
		this.accountService.getByExecId(account.userid, this.accessRequest.locationId).then((account: Account) => {
			if(account){

				this.updateAccountId(account._id);
			} else {
				this.addAccount(account).then(createdAccountId => {
					this.updateAccountId(createdAccountId);
				}).catch(err => {
					//
				})

			}

		}).catch(err => {
			//window.adminlog.error(err);
			//Add Account
			this.addAccount(account).then(createdAccountId => {
				this.updateAccountId(createdAccountId);
			}).catch(err => {
				//
			})
		});


	}



	public isSubmitting: boolean = false;

	public async generateNewLead() {
		if(!this.accessRequest.userId){
			let user = await this.userService.getByEmail(this.accessRequest.email);
			if(user){
				this.accessRequest.userId = user._id;
			}
		}

		const leadId = await this.leadService.createLead({
			location: this.accessRequest.locationId,
			user: {
				firstName: this.accessRequest.firstName,
				lastName: this.accessRequest.lastName,
				email: this.accessRequest.email,
				phoneNumber: this.accessRequest.phoneNumber || ''
			},
			tag: LeadTag.NewLead,
			status: LeadState.New,
			source: LeadSource.WebMeetGreet,
			score: 0,
			tagHistory: [],
			tagMap: {
				[LeadTag.NewLead]: firebase.firestore.FieldValue.serverTimestamp() as any

			},
			createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
			updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
			records: {
				userId: this.accessRequest.userId || null
			}

		});
		this.accessRequestService.update(this.accessRequest._id, {
			leadId: leadId,
			status: AccessRequestStatus.Completed
		});
	}

	@ViewChild("petexec_action", {static: false}) petexecActionTemplate: TemplateRef<any>;
	@ViewChild("invalid_action", {static: false}) invalidActionTemplate: TemplateRef<any>;
	@ViewChild("lead_action", {static: false}) leadActionTemplate: TemplateRef<any>;
	@ViewChild("no_action", {static: false}) noActionTemplate: TemplateRef<any>;
	public getActionTemplate(){
		if(this.accessRequest){

			switch(this.accessRequest.action){
				case AccessRequestAction.Lead:
				return this.leadActionTemplate;
				case AccessRequestAction.Invalid:
				return this.invalidActionTemplate;
				case AccessRequestAction.Existing:
				return this.petexecActionTemplate;
				case AccessRequestAction.Invite:
				return this.petexecActionTemplate;

			}

		}
		return this.noActionTemplate;
	}

	public navigateToAccount(){
		this.ngZone.run(() => {
			this.router.navigate(['/accounts', this.accessRequest.accountId]);
		});
	}
	public goToLead(){
		this.ngZone.run(() => {
			this.router.navigate(['/leads', this.accessRequest.leadId]);
		});
	}
}
