/**
 * @swagger
 * components:
 *  schemas:
 *    LeadStruct:
 *      required: [userId, postedAt, source]
 *      properties:
 *        activities:
 *          type: array
 *          items:
 *            type: object
 *            properties:
 *              message:
 *                type: string
 *              createdAt:
 *                type: string
 *                format: date-time
 *        userId:
 *          type: string
 *          format: uuid
 *          description: User ID of document. Gauranteed to exist if a lead is created.
 *        renew:
 *          type: object
 *          properties:
 *            in:
 *              type: integer
 *            track:
 *              type: string
 *              enum: [month, day, hour, minute, second, millisecond]
 *        postedAt:
 *          type: string
 *          format: date-time
 *        source:
 *          type: string
 *          enum: []
 *        defaultLocation:
 *          type: string
 *          format: uuid
 *
 *  responses:
 *  requestBodies:
 *  parameters:
*/

import { ReadRecordStruct } from './record';
import { UserStruct} from './user';
import { DogStruct } from './dog';
import { PetStruct } from './pet';
import { DogRequestStruct} from './dog-request';
import { DropoffStruct} from './drop-off';
import { UserRequestStruct} from './user-request';
import firebase from 'firebase';
export enum LeadSource {
    WebInquiry              = 0,
    LocalEvent              = 1,
    Referral                = 2,
    WebMeetGreet            = 3,
    WalkIn                  = 4,
    PhoneCall               = 5,
    SearchAd                = 6,
    SocialAd                = 7,
    Other                   = 8,
    NoShow                  = 9,
    NoPass                  = 10,
    PerksForPups            = 11,
    AppRequest              = 12,
    ReacquisitionCampaign   = 13,
    foundersOffer           = 14,
    waitlistOffer           = 15,
    NationalCampaignSocial  = 16,
    NationalCampaignSearch  = 17,
    PhoneSearch             = 18,
    PhoneSocial             = 19,
    PartnersPromo           = 20,
    VirtualTour             = 21,
    RegionalMicrosite       = 22,
    Abandoned               = 23,
    AmazonLeadAd            = 24,
    AmazonAd                = 25,
    PreferredPartner        = 26,
    Influencer        = 27,
};

export enum LeadTag {
	GeneralInquiry			= 1,
	NewLead					= 2,
	Renewed                 = 3,
	ReEvaluated             = 4,
    foundersOffer           = 5,
    waitlistOffer           = 6,
    priorityOffer           = 7,
	InitInvalid				= 127,
	Progress1				= 128,
	Progress2				= 129,
	Progress3				= 130,
	FollowUp                = 131,
	FollowUp_Unresponsive	= 132,
	DNC_Unresponsive        = 133,
	NoShow                  = 134,
	NoPass                  = 135,
	ProgressInvalid			= 255,
	Confirmed				= 256,
	Done					= 257,
	FollowUp_Archived		= 258,
    Archived				= 300
};

export enum LeadState {
	New,
	InProgress,
	FollowUp                = 1.5,
	Confirmed               = 2,
	Done                    = 3,
	Archived                = 4
};

export type PreferredTime = "AM" | "PM" | "Anytime";

export type RecordMap = {
	userId?: string,
	accountId?: string,
	dogId?: string,
	petId?: string,
	requestAccessId?: string,
	inquiryId?: string,
	requestId?: string,
	dropoffId?: string

    locationId?: string;
};

export type LeadTagHistoryItem = {
	tag: LeadTag,
	date: firebase.firestore.Timestamp
};

export type LeadMeetGreetTemplate = {
	pet?: PetStruct,
	petId?: string[],
	date: firebase.firestore.Timestamp,
	meetGreetId?: string,
	petHistory?: any
};

export type InquiryServices = "training" | "spa" | "grooming" | "daycare" | "boarding" | "other";

export interface LeadStruct extends ReadRecordStruct {
	//Record holders and references. If a string, then can be interpretted as a document ID.
	user: UserStruct,
	dog?: DogStruct,
	// When Dog has execID for defaultLocation setup a request access doc, so admin can bypass m&g easier.
	request?: UserRequestStruct | string,
	requestAccess?: DogRequestStruct | string,
	// User Request ID ~ may be associated with lead.
	dropoff?: DropoffStruct | string,

	meetGreetTemplates?: {
		[hashId: string]: LeadMeetGreetTemplate;
	},

	location: string,
	tag: LeadTag,
	status: LeadState,
	source: LeadSource,

	campaign?: string,

	// User Preferences
	prefDate?: firebase.firestore.Timestamp,
	prefTime?: PreferredTime,

    services?: InquiryServices[];
    numOfDogs? : Number;
    
    optIntoMarketing? : boolean;
	// Id store
	records: RecordMap,

	// Scoring system. Order by score. Higher, more important. Lower = less important.
	tagMap?: {
		[tag: number]: number
	},
	tagHistory: LeadTagHistoryItem[],
	score: number,

	indexed?: number,

    trk?: any,

	renew?: {
		at: number,
		as?: LeadTag,
		isTask?: boolean
	},

	history?: {
		meetGreetIds: string[],
		leadIds: string[]
	},

	recent_history?: {
		meetGreetId: string,
		leadId: string
	}

    is_admin?: boolean,

    duplicate? : {
        status? : string, 
        updatedAt? : firebase.firestore.Timestamp
    },

}
