import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {ViewEncapsulation} from '@angular/core';

import { Location as RouteLocation } from '@angular/common';


import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import moment from 'moment-timezone';

import { AppointmentService ,LocationService } from "src/services";
import { PetExecService } from "../../../services/pet-exec.service";

import {faTimes, faCheckCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';

import { ConfirmMultiDaycareDayModel } from "./ConfirmMultiDaycareDaysModel";
import { Subject } from 'rxjs';


interface ShowableAppointmentInterface{ 
    appointmentId:string,
    date: string, 
    dogName: string,
    petexecResponseMsg?: string,
    success?: boolean,
    appointmentUploaded?: boolean
}

@Component({
  selector: 'app-confirm-multi-daycare-modal',
  templateUrl: './confirm-multi-daycare-modal.component.html',
  styleUrls: ['./confirm-multi-daycare-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ConfirmMultiDaycareModalComponent implements OnInit {


  public emailMesageText: string = "";
  public faTimes = faTimes;
  public faCheckCircle = faCheckCircle;
  public faMinusCircle = faMinusCircle;

  public multiDaycarePopup;

  public showableAppointments: any[]; 
  
  public appointmentsLoaded: Subject<boolean> = new Subject();


  public singleDayDaycareUploadComplete = false;
  public singleDayDaycareUploadFailure = false;
  public singleDayDaycarePetexecReturn = "";
  public singleDayDogName : string = "";
  public singleDayApptDate : string = "";

  public multiDayApprovedDaycareAppts: any[];

  constructor(
    public dialogRef: MatDialogRef<ConfirmMultiDaycareModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmMultiDaycareDayModel,

    public routeLocation: RouteLocation,
    public locationService: LocationService,
    public appointmentService: AppointmentService,
    public petExecService: PetExecService
  ) { 
    if( this.data.daycareType == "single" ){ 
      this.multiDaycarePopup = false;
    }
    else if( this.data.daycareType == "multi") { 
      this.multiDaycarePopup = true;
    }

    this.showableAppointments = [];
    
  }

  ngOnInit() {
    this.runAppointmentBooking();
  }

  closeDialog(){ 

      this.dialogRef.close();
  }


   runAppointmentBooking(){ 
    this.locationService.getCurrUserLocation().then( async location => {
        
      const actionableApp = this.data.appointments.filter( action => ( action.status == 'Approved' || action.status == 'Declined' ));
        
      for( let x = 0; x < actionableApp.length; x++ ){ 
            this.appointmentService.update( actionableApp[x].appointmentId, { 'petexec.serviceid': actionableApp[x].serviceid }).then(data=> { 
                this.appointmentService.getByIdPromise( actionableApp[x].appointmentId ).then( data => { 

                    const appointmentData = {
                        appointmentId: actionableApp[x].appointmentId,
                        date: moment(data.date.toDate()).format('ddd D/MM/YYYY, h:mm A'),
                        dogName: data.dogName,
                        appointmentUploaded: false,
                        success: false,
                        status:  actionableApp[x].status,
                        appointment: data // load appointmen in showable appointments to use later
                    };
                    this.showableAppointments[ x ] = appointmentData;
        
                    if( actionableApp[x].status == "Approved" ){
                        console.log( 'Trying to book:', appointmentData, location );
                        this.bookAppointment( appointmentData, location ).then( appointment => {
                            console.log( 'appontment booking response:', appointment );
                            this.showableAppointments[ x ] = appointment;
                            this.updateEmailContent();
                            if( appointment.success ){ 
                                let data = { status: "Approved", completed: true }
                                if( this.multiDaycarePopup ) { 
                                    data[ 'behaviors.bypass_trigger_notifications'] = true;
                                }
                                this.appointmentService.update( 
                                    appointment.appointmentId, 
                                    data
                                );
                            }
                        });
                      
                    }
                    else if( actionableApp[x].status == "Declined") { 
                        this.appointmentService.update( actionableApp[x].appointmentId, 
                            {
                                status: "Declined",
                                'behaviors.bypass_trigger_notifications': true,
                                completed: true
                            } 
                        ).then( data => { 
                            appointmentData.appointmentUploaded = true;
                            appointmentData.success = true;
                            this.showableAppointments[x] = appointmentData;
                        })
                    }
                    else{ 
            
                    }
        
                    
                })
            })
        
        
        //this.appointmentsLoaded.next( true );
      }
    });
  }

  async bookAppointment( appointment, location ){ 
            
    return await this.petExecService.createPetExecAppointment( 
        location._id , appointment.appointmentId
    ).then( petExecResponse => { 
        
        console.log( "pet exec api response " );

        if( petExecResponse["status"] == "success" ){ 
            appointment.success = true;
            appointment.petexecResponseMsg = petExecResponse["msg"];

        }
        else if( petExecResponse["status"] == "failed" ){ 
            appointment.success = false;

            if( petExecResponse["from"]=="petexec" ){ 
                let msg = "Petexec error: ";

                if( this.isJson( petExecResponse["msg"] ) ){

                    console.log( "msg", JSON.parse(petExecResponse["msg"]) );
                    let petexecMsg = JSON.parse(petExecResponse["msg"]);
                    for( let x = 0; x < petexecMsg["errors"].length; x++){ 
                        msg+=petexecMsg["errors"][x] + "\n";
                    }
                    appointment.petexecResponseMsg = msg;

                }else{
                    appointment.petexecResponseMsg = msg + petExecResponse["msg"];
                }
                
            }
            else if( petExecResponse["from"]="local" ){ 
                appointment.petexecResponseMsg = petExecResponse["msg"];
            }

        }
        appointment.appointmentUploaded = true;
        // log appointment data past manipulation and return
        console.log( appointment );
        return appointment;
    }).catch( e => { 
        
        console.log( "There was an error completing the petExecService.createPetExecAppointment Api call", e );
        return appointment;
    });

  }

    public getAppointmentDateText( appointment ) {
        const at = moment(appointment.date.toDate()).tz(appointment.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
        let str = at.format('ddd D/MM/YYYY, h:mm A');
        if (appointment.pickupDate) {
            const pickupdate = moment(appointment.pickupDate.toDate()).tz(appointment.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
            if (at.format('YYYY/MM/DD') == pickupdate.format('YYYY/MM/DD')) {
                str += ` to ${pickupdate.format('h:mm A')}`;

            } else {
                str += ` to ${pickupdate.format('ddd D/MM/YYYY, h:mm A')}`;
            }
        }
        return str;
    }

    updateEmailContent() {

        const paras = [];
        const actions = this.showableAppointments;
        console.log( 'Modal updateEmailContent actions', actions );

        const actions_approved = actions.filter(action => ( action.status == 'Approved' && action.success ) );
        const actions_declined = actions.filter(action => ( action.status == 'Declined' && action.success ) );
        //const actions_approved = actions.filter(action => ( action.status == 'Approved' ) );
        //const actions_declined = actions.filter(action => ( action.status == 'Declined' ) );
        // @todo check if there is a custom email 

        console.log( 'Modal updateEmailContent actions FILTERED', actions_approved );
        


        if (actions_approved.length || actions_declined.length) {
            paras.push(`Hi ${this.data.account.data.firstName}`);

            if (actions_approved.length) {
                paras.push('You are confirmed for the following daycare appointments:');

                const pets: string[] = [];
                const sorted_appointments: { [petindex: number]: any[] } = {};
                actions_approved.forEach(action => {
                    const appointment = action.appointment;
                    this.sortAppointmentIntoPetBucket(appointment, pets, sorted_appointments);
                });
                console.log( 'sorted_appointments***', sorted_appointments );

                for (let petindex in pets) {
                    const appointments = sorted_appointments[petindex];
                    paras.push(`Dog name: ${appointments[0].dogName}`);
                    appointments.forEach( appt => {
                        paras.push(this.getAppointmentDateText(appt));
                    });
                }
            }



            if (actions_declined.length) {
                paras.push('You are declined for the following appointments:');

                const pets: string[] = [];
                const sorted_appointments: { [petindex: number]: any[] } = {};
                actions_declined.forEach(action => {
                    const appointment = action.appointment;
                    this.sortAppointmentIntoPetBucket(appointment, pets, sorted_appointments);
                });

                for (let petindex in pets) {
                    const appointments = sorted_appointments[petindex];
                    paras.push(`Dog name: ${appointments[0].dogName}`);
                    appointments.forEach( appt => {
                        paras.push(this.getAppointmentDateText(appt));
                    });
                }
            }
            
        }
        const email_content = paras.join('\n\r');

        console.log("email content", email_content);
        this.emailMesageText = email_content;
    }

    public sendEmailAndClose(){
        console.log( this.emailMesageText );
        this.routeLocation.back();
        this.closeDialog();
    }

    protected sortAppointmentIntoPetBucket( appointment, petIds: string[], appointments: { [petindex: number]: string[] }) {
        const petindex = petIds.findIndex((id) => id == appointment.petId);
        //console.log(petindex);
        //console.log(appointment);
        // sort into bundles by petId
        if (petindex >= 0) {
            appointments[petindex].push(appointment);
        } else {

            petIds.push(appointment.petId);
            appointments[petIds.length - 1] = [appointment];

        }
        //console.log(petIds);
        //console.log(appointments);
    }

    private isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


}
