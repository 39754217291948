import { Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";
import { map, catchError, take } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { HttpHeaders, HttpClient } from "@angular/common/http";

import { FirestoreService, queryOptions } from "./firestore.service";


import { UserService } from "./user.service";

import { AdminStruct } from "src/models/structs";
import { Admin, User } from "src/models/entities";
import { ISettingsService } from './interfaces';

@Injectable({
	providedIn: "root"
})

export class AdminService implements ISettingsService<Admin> {

	public settings$: Subject<any> = new Subject<any>();


	public collection = "admins";

    constructor (private dbService: FirestoreService,
        private userService:UserService,

		private http: HttpClient
	) { }

	public isAdmin(adminId:string):Promise<boolean> {

		return this.dbService.getDocSnapshot(this.collection, adminId)
			.then((result) => {
				return result.exists;
			})
			.catch((error) => {
				return false;
			});

	}

	public getById(adminId:string):Promise<Admin> {

		return new Promise((resolve, reject) => {

			this.dbService.getDocSnapshot(this.collection, adminId)
				.then((result) => {
					resolve(new Admin(result.data() as AdminStruct, adminId));
				})
				.catch((error) => {
					reject(error);
				});

		});

	}

	public getByIdObs(adminId: string):Observable<Admin> {

		return this.dbService.getDoc(this.collection, adminId).pipe(
			map((adminDoc) => {

				if(adminDoc.exists){
					return new Admin(adminDoc.data() as AdminStruct, adminId);
				}

			})
		)

	}

	public getAvatarImgUrl(adminId:string):Promise<string> {

		return this.userService.getAvatarImgUrl(adminId);

	}

	public getAdmins(locationId: string, userIdToFilter:string = ""):Observable<Admin[]> {

		if(userIdToFilter){

			return this.getByIdObs(userIdToFilter).pipe(
				map((user) => {
					return user ? [user] : [];
				})
			)

		}else{

			const queryOptions:queryOptions = {
				orderByField: "email",
			};

			return this.dbService.getCollection(this.collection, queryOptions).pipe(
				map((adminDocs) => {

					return adminDocs.map((adminDoc) => {

						const admin = new Admin(adminDoc.data() as AdminStruct, adminDoc.id);

						return admin;

					}).filter((admin) => {
						return (admin.locations && (admin.locations.indexOf(locationId) >= 0));
					});

				}),
				catchError((error) => {
					window.adminlog.print(error);
					throw error;
				})
			);

		}

	}

	public update(adminId:string, updateData:any):Promise<void> {

		return this.dbService.updateDoc(this.collection, adminId, updateData);

	}

	public deleteAdmin(admin: Admin,locationId: string ):Promise<void> {

        // a list of locaitons
        //location that is wanted to be removed

        let locations = admin.locations.filter(location => location!=locationId);


        return this.dbService.updateDoc(this.collection, admin.adminId, {locations: locations, defaultLocation:locations[0]||null} );

	}

	public addAdmin(adminId: string, adminData: any):Promise<void> {

		delete adminData.password;

		return this.dbService.setDoc(this.collection, adminId, adminData, true);

	}

	public async createAdmin(adminData: AdminStruct, bearerToken: string):Promise<any> {
		// TODO Is there a better way to handle getting bearerToken
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		adminData.email = adminData.email.toLocaleLowerCase();
		window.adminlog.print("CREATED Admin: ", adminData);
		return new Promise<any>((resolve, reject) => {
			this.http.post(environment.firebaseFunctions.url, adminData, httpOptions)
				.pipe(
					take(1)
				)
				.subscribe(
					(result) => {
						window.adminlog.print(result);
						resolve(result);
					},
					(error) => {
						window.adminlog.print(error);
						reject(error);
					}
				);
		});

	}

	public updateAdmin(adminId: string, updatedData: any):Promise<void> {

		delete updatedData.password;

		return this.dbService.updateDoc(this.collection, adminId, updatedData);

	}
	protected userSettingsRef(userId: string) {
		return this.dbService.db.collection(this.collection).doc(userId).collection('settings');
	}

	public async getAllSettings(user: string | Admin): Promise<{ [settings_id: string]: any }> {
		const userId: string = user instanceof Admin ? user.id : user;
		return new Promise((res, rej) => {
      const settingssubscribeonce = this.settings$.subscribe(onsettings => {
				res(onsettings);
        //if(settingssubscribeonce) {
        //  settingssubscribeonce.unsubscribe();
        //}
			}, err => {
				rej(err);
        //if(settingssubscribeonce) {
        // settingssubscribeonce.unsubscribe();
        //}
			});
            try{ 
                this.userSettingsRef(userId).onSnapshot(settings_refs => {
                    const settings = {};
                    settings_refs.docs.forEach(docref => {
                        settings[docref.id] = docref.data();
                    });
                    this.settings$.next(settings);
                    
                }, err => /*this.settings$.error(err)*/ {} );
            }
            catch( error ){ 
                console.log( error );
            }
		});

	}

	public async getSettings(configuration_id?: string, user?: string | Admin) {
		const userId: string = user instanceof Admin ? user.id : user;
		return this.userSettingsRef(userId).doc(configuration_id).get();
	}

	public setSettings(settings: any, configuration_id?: string, user?: string | Admin) {
		const userId: string = user instanceof Admin ? user.id : user;
		console.log('*** setSettings: ', settings, configuration_id, userId);
		return this.userSettingsRef(userId).doc(configuration_id).set(settings, { merge: true });//.then(() => window.adminlog.print("success?")).catch(e => window.adminlog.error("Could not update user settings", e));
	}
}
