import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-alert-model',
    templateUrl: './alert-model.component.html',
    styleUrls: ['./alert-model.component.scss'],
})
export class AlertModelComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<AlertModelComponent>,
    ) { }

    ngOnInit() { }

    closeDialog() {
        this.dialogRef.close();
    }

}
