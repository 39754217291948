import { FirestoreService } from "./firestore.service";
import { Entity } from 'src/models/entity';
import { IEntityService } from './interfaces';


export abstract class EntityServiceAbstract<E extends Entity<any>> implements IEntityService<E> {

	public abstract collection:string;

	protected abstract dbService: FirestoreService;

	protected abstract statusColorMap:object;

	public getStatusColor(status: any): string {
		if (typeof status !== 'string') {
			return 'primary';
		}
		return this.statusColorMap[status.toLowerCase()] || "primary";
	}

	public getStatusBadgeText<T>(entityObj: T, badgeTextProperty: string):string {
		return entityObj[badgeTextProperty];
	}

}
