import { Injectable, NgZone } from "@angular/core";

import { Router } from "@angular/router";

import { ToastController } from "@ionic/angular";

type ToastSize = "small" | "medium" | "large";

type extraBtnParameterItem = {link: string, label: string};

@Injectable({
	providedIn: "root"
})

export class ToastService {

	constructor(private router: Router, private ngZone: NgZone, private toastController: ToastController) { }

	public async show(message: string, size: ToastSize = "small", duration: number = 5000, extraBtns: extraBtnParameterItem[] = []):Promise<void> {

		const toastParams = {
			message: message, 
			duration: duration || 0,
			cssClass: "toast-size-" + size,
			buttons: []
		};

		extraBtns.forEach((extraBtnParam) => {
			toastParams.buttons.push({
				text: extraBtnParam.label,
				side: "end",
				handler: () => {
					this.ngZone.run(() => {
						this.router.navigateByUrl(extraBtnParam.link);
					});
				}
			} as any);
		});

		toastParams.buttons.push({
			icon: "ios-close",
			role: "cancel",
			side: "end",
		});
		
		const toast = await this.toastController.create(toastParams);

		return toast.present();

	}

}