import { Injectable } from "@angular/core";
import firebase from 'firebase';

@Injectable({
	providedIn: "root"
})

export class FileUploadService {

	constructor() { }

	public getFileBasename(fileUrl: string):Promise<string> {

		//let fileRef = firebase.storage().refFromURL(fileUrl);

		return new Promise((resolve, reject) => {

            const fileName = fileUrl.split('/').pop().replace(/[\#\?].*$/,'');
            resolve( fileName );

            /*
			fileRef.getMetadata()
				.then((metadata) => {
					resolve(metadata.name);
				})
				.catch((error) => {
					reject(error);
                })
            */

		});

	}

    public deleteFile(fileUrl: string){ 
        return new Promise((resolve, reject) => {
            const fileRef = firebase.storage().refFromURL(fileUrl);
            fileRef.delete().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getFile( fileUrl: string ) : Promise<Blob> {
        return new Promise((resolve, reject) => {
            const fileRef = firebase.storage().refFromURL(fileUrl);

            fileRef.getDownloadURL().then((downloadUrl) => {
                resolve(downloadUrl);
            })
            .catch((error) => {
                //this.alertSrevice.downloadFileFileError(error);
                console.log( error );
                reject(error);
            });
        });
    }

	public uploadFile(path: string, file: File):Promise<string> {

		let fileRef = firebase.storage().ref().child(path + "/" + this.appendTimestamp(file.name));

		let metaData = {
			contentType: file.type
		};

		let uploadTask = fileRef.put(file, metaData);

		return new Promise((resolve, reject) => {

			uploadTask.on(
				"state_changed",
				(snapshot: firebase.storage.UploadTaskSnapshot) => {
					// Calculate upload progress percentage
					window.adminlog.print(snapshot);
				},
				(error: firebase.FirebaseError) => {
					window.adminlog.error("Image upload error", JSON.stringify(error));
					reject();
				},
				() => {
					uploadTask.snapshot.ref.getDownloadURL().then((uploadedFileUrl) => {
						resolve(uploadedFileUrl);
					})
					.catch((error) => {
						reject(error);
					});
				}
			);

		});

	}

	private appendTimestamp(fileName: string):string {

		return (new Date()).getTime() + "_" + fileName;

	}

}
