import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { collections } from 'src/models/collections';
import { VaccineReleaseForm } from 'src/models/entities/VaccineReleaseForm';
import { VaccineReleaseFormStruct } from 'src/models/structs/VaccineReleaseFormStruct';
import { FirestoreService, queryOptions } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class VaccineReleaseFormService {


  constructor(private dbService: FirestoreService,
    private httpClient: HttpClient
    ) { }


    public getById(vrfId:string):Promise<VaccineReleaseForm> {
        return new Promise((resolve, reject) => {
            this.dbService.getDocSnapshot(collections.vaccineReleaseForms, vrfId).then( (vrfDoc) => { 
                resolve(new VaccineReleaseForm(vrfDoc.data() as VaccineReleaseForm, vrfDoc.id));
            });
        });
	}

    public getByPetId( petId: string ) : Promise< any >{ 
        return new Promise((resolve, reject) => {

            this.dbService.db.collection(collections.vaccineReleaseForms)
            .where("petId", "==", petId)
            //.where("deleted", "")
            .get()
            .then( data => {
                let vrfs = [];

                vrfs = data.docs.filter( vrf => !vrf.data().deleted );
                /*
                for( let x = 0; x < data.docs.length; x++ ){ 
                    if( !data.docs[x].data().deleted ){ 
                        vrfs.push( data.docs[x].data() );
                    }
                }
                */
                resolve(vrfs);
                console.log( "data" , data );
            }).catch( error => {
                reject(error)
            });
        });
    }
    
    public getVRFByAccountIdObservable(petId: any) {
        return new Observable((subscriber) => {

            this.dbService.db.collection(collections.vaccineReleaseForms)
            .where('petId', '==', petId)
            .onSnapshot((querySnapshot) => {
                subscriber.next(querySnapshot.docs);
            },
            (error) => {
                window.adminlog.error("Get Collection error: ", error)
            })
        }).pipe(
			map((uploadDocs) => {
				return this.mapDocsToObjs(uploadDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
    }
        
/*
    return new Observable((subscriber) => {

        this.dbService.db.collection('uploads')
        .where('meetAndGreetId', '==', meetAndGreetId)
        //.where( "petexec.uploaded", "==", false )
        .where("deleted", "!=", true)
        .onSnapshot((querySnapshot) => {
            subscriber.next(querySnapshot.docs);
        },
        (error) => {
            window.adminlog.error("Get Collection error: ", error)
        })
    }).pipe(
        map((uploadDocs) => {
            return this.mapDocsToObjs(uploadDocs);
        }),
        catchError((error) => {
            window.adminlog.print(error);
            throw error;
        })
}
*/
    public getByPetIdAndAccountId( accountId: string, petId: string ): Promise<any> { 
        return new Promise((resolve, reject) => {

            this.dbService.db.collection(collections.vaccineReleaseForms)
                .where("petId", "==", petId)
                .where("accountId", "==", accountId)
                //.where("deleted", "!=", true)
                .get()
            .then( (data) => {    
                let vrfs = [];

                vrfs = data.docs.filter( (vrf) => !("deleted" in vrf.data() && vrf.data().deleted === true));
                console.log("vaccineReleaseForms", "vrfs", vrfs);
                let vaccineReleaseForms = vrfs.map((vrfs) => {

                    const vaccineReleaseForm = new VaccineReleaseForm(vrfs.data() as VaccineReleaseFormStruct, vrfs.id);
                    return vaccineReleaseForm;

                });

                resolve(vaccineReleaseForms);

            }).catch( error => {
                reject(error)
            });
        });
    }

    public getVRFByAccountIdPetIdObservable(accountId: string, petId: string) {
        return new Observable((subscriber) => {

            this.dbService.db.collection(collections.vaccineReleaseForms)
            .where( "accountId", "==", accountId )
            .where('petId', '==', petId)
            .onSnapshot((querySnapshot) => {
                subscriber.next(querySnapshot.docs);
            },
            (error) => {
                window.adminlog.error("Get Collection error: ", error)
            })
        }).pipe(
			map((uploadDocs) => {
				return this.mapDocsToObjs(uploadDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
    }


    public getDownloadBuffer( locationId: string, vaccineReleaseFormId: string ) : Promise< any >{ 
        let headers = new HttpHeaders();
        headers.set( "X-PDF-Return", "" );
        return new Promise( (resolve, reject) => {
            this.httpClient.post( environment.firebaseFunctions.vaccineReleaseFormBufferDownload + "?vaccineReleaseFormId="+vaccineReleaseFormId+"&locationId="+locationId, {
                locationId: locationId,
                vaccineReleaseFormId: vaccineReleaseFormId
            }, { responseType: 'blob' } ).toPromise().then( (data) => {
                resolve(data);
            }).catch( error => {
                reject(error)
            });
        });
    }


    public uploadVRFform(document) : Promise<any> { 
        return new Promise( async (resolve, reject) => { 
            let docRef;
            
            let idNotFound = true;
            let autoId = "";
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            while( idNotFound ){ 
                autoId           = '';
                for ( var i = 0; i < 20; i++ ) {
                    autoId += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                let existingUploadData = await this.dbService.db.collection("uploads").doc(autoId).get();
                if( !existingUploadData.exists ){ 
                    let newUploadDocument = await this.dbService.db.collection(collections.vaccineReleaseForms).doc(autoId).set(document);
                        resolve(docRef);    
                    docRef = newUploadDocument; 
                    console.log( "docRef", autoId );   
                    idNotFound = false;
                }
            }

            resolve( autoId );
        });
    }


    public mapDocsToObjs( vrfDocs ){ 
        return vrfDocs.map((vrfDoc) => {

			const upload = new VaccineReleaseForm(vrfDoc.data() as VaccineReleaseForm, vrfDoc.id);

			return upload;

		});

    }

    public deleteVFRform(vrfId: string): Promise<any> {

        return new Promise((resolve, reject) => { 
            this.dbService.db.collection(collections.vaccineReleaseForms).doc(vrfId).update({ deleted: true }).then( data => {
                resolve(data);
            }).catch( error  => {
                resolve(error)
            });
        });
    }

}
 