import { DropoffStruct, DropoffStatus } from "src/models/structs";

import { Entity } from "src/models/entity";
import firebase from 'firebase';

// Validates and Creates Admin. Publishes to Firebase a new admin or any update.
export class Dropoff extends Entity<Dropoff> implements DropoffStruct {

	static readonly NEW:DropoffStatus = "New";
	static readonly ARRIVED:DropoffStatus = "Arrived";
	static readonly UPDATED:DropoffStatus = "Updated";
	static readonly CANCELLED:DropoffStatus = "Cancelled";
	static readonly DONE:DropoffStatus = "Done";

	locationId: string;
	userId: string;
	status: DropoffStatus;
	dropOffTime: firebase.firestore.Timestamp;//Date;
	action?: string;
	accountId?: string;
	createdAt: firebase.firestore.Timestamp;
	updatedAt: firebase.firestore.Timestamp;
	timezone: string;
    vehicleDescription: string;
    previousStatus?: string;

	constructor(data?: DropoffStruct, id?: string){
		super(data, id);
	}
}
