import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

// import { MbscCalendarModule, MbscDatetimeModule } from "@mobiscroll/angular";


import {SortSelectionOveriewPopoverComponent} from "./sort-selection-overiew-popover/sort-selection-overiew-popover.component";

import { LoadingIconComponent } from "../loading-icon/loading-icon.component";
import { AddVaccinationModalComponent } from './dog-info-edit/add-vaccination-modal/add-vaccination-modal.component';
import { OptionsPopoverComponent } from './options-popover/options-popover.component';
import { DateSelectPopoverComponent } from './date-select-popover/date-select-popover.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule } from "@angular/material";
import { MbscModule } from "@mobiscroll/angular";

import { VRFDogVetComponent } from "./vrfdog-vet/vrfdog-vet.component";

@NgModule({
	declarations: [
		LoadingIconComponent,
		AddVaccinationModalComponent,
		OptionsPopoverComponent,
        DateSelectPopoverComponent,
        VRFDogVetComponent
        //SortSelectionOveriewPopoverComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		// MbscCalendarModule,
		// MbscDatetimeModule,

        FontAwesomeModule,

        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MbscModule,
        MatIconModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		IonicModule,
		// MbscCalendarModule,
		// MbscDatetimeModule,
		LoadingIconComponent,

        FontAwesomeModule,
        VRFDogVetComponent,

        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule
	],
	entryComponents: [AddVaccinationModalComponent, OptionsPopoverComponent, DateSelectPopoverComponent]
})

export class DashboardCommonModule { }
