import  firebase  from "firebase/app";

export class Update{ 

    id? : string;

    accountId: string;
    petId?: string;
    locationId: string;
    type : "vaccination";
    petexec: { 
        uploadedDate? : firebase.firestore.Timestamp;
        uploaded: boolean;
    };
    createdAt: firebase.firestore.Timestamp;
    archived: boolean;
    deleted: boolean;


    constructor( data?: Update, id?: string ) {
        //super(data, id);
        if( id ) { 
            this.id = id;
        }
        if( data ){ 
            this.accountId = data.accountId || "unknown";
            this.petId = data.petId || "unknown";
            this.locationId = data.locationId || "unknown";
            this.type = data.type || "vaccination";
            this.petexec = data.petexec || {
                uploaded: false
            };
            this.createdAt = data.createdAt || firebase.firestore.Timestamp.now();
            this.deleted = data.deleted || false;
        }
    }


}