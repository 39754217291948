import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivityLogService } from 'src/services/activity-log.service';
import { LocationService } from 'src/services/location.service';

@Component({
  selector: 'app-cancellation-policy-user-facing-msg-popup',
  templateUrl: './cancellation-policy-user-facing-msg-popup.component.html',
  styleUrls: ['./cancellation-policy-user-facing-msg-popup.component.scss'],
})
export class CancellationPolicyUserFacingMsgPopupComponent implements OnInit {
    
    @Input() currentMsg;
    @Input() locationId;

    public cancellationPolicyTextArea: string = "";

    constructor(
        private modalController: ModalController,
        public locationService: LocationService,
        public activityLogService: ActivityLogService
    ) { }

    ngOnInit() {
        console.log( "currentmsg" , this.currentMsg );
        if( this.currentMsg && this.currentMsg.length > 0 ){ 
            this.cancellationPolicyTextArea = this.currentMsg;
        }
    }


    public dismiss() { 
        this.modalController.dismiss({
            "noAction": true,
        });
    }

    update(){ 
        if( this.cancellationPolicyTextArea === null || this.cancellationPolicyTextArea === undefined ) { 
            this.cancellationPolicyTextArea = "";
        }
        this.activityLogService.addLogEntry("locations", this.locationId, "Update", "update to cancellationPolicyUserFacingMsg", {cancelationPolicyUserFacingMsg: this.cancellationPolicyTextArea});
        //this.locationService.update( this.locationId, {cancellationPolicyMsg: this.cancellationPolicyTextArea} );
        this.modalController.dismiss( {
            newMsg: this.cancellationPolicyTextArea
        } );

    }
}
