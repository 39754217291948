import { Component, OnInit } from "@angular/core";

import { NavParams, Events, PopoverController } from "@ionic/angular";

import { MenuItem } from "../dashboard-frame/dashboard-frame.component";

@Component({
	selector: "app-user-menu-popover",
	templateUrl: "./user-menu-popover.component.html",
	styleUrls: ["./user-menu-popover.component.scss"],
})

export class UserMenuPopoverComponent implements OnInit {

	public menuItems: Array<MenuItem>;

	constructor(
		public navParams: NavParams, 
		public ionEventBus: Events,
		public popoverController: PopoverController
	) { }

	ngOnInit():void {

		this.menuItems = this.navParams.data.menuItems;

	}

	public menuItemClicked(link:string):boolean {

		this.popoverController.dismiss();
		
		if(link.slice(-2) === "()"){

			this.ionEventBus.publish(link.slice(0, -2));

			// Needs to return false to disable default anchor tag behaviour
			return false;

		}

		return true;

	}

}
