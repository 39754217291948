import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/services';

import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Events, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-approvals-filter-popover',
  templateUrl: './approvals-filter-popover.component.html',
  styleUrls: ['./approvals-filter-popover.component.scss'],
})
export class ApprovalsFilterPopoverComponent implements OnInit {

    @Input() currentFilter: string;
    @Input() sortBelongsTo: string;

    public faCheck = faCheck;
    public selected: string = "all";

  constructor(
      public settingsService: AuthService,
      public ionEventBus: Events,
      public popoverController: PopoverController,
  ) { }

  ngOnInit() {
    this.selected = this.currentFilter;
}

public selectionChange( status ){
    this.selected = status;
    this.ionEventBus.publish("filter-approval-filter", { "status": this.selected } );
    this.popoverController.dismiss();
  }
}
