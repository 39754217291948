import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dropOffSortName'
})
export class DropOffSortNamePipe implements PipeTransform {

  transform(value: any): string {
    if( value === "all" || value == undefined){ 
        return "All";
    }   
    else if( value === "cancelled" ){
        return "Cancelled";
    }
    else if( value === "pastDue"){ 
        return "Past Due"
    }
    else if( value === "arrived"){ 
        return "Arrived";
    }
    return "none"
  }

}
