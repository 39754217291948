import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstLetter'
})
export class CapitalizeFirstLetterPipe implements PipeTransform {

  transform(value: string, args?: any): string {

    let valueArray = value.split("");
    valueArray[0] = valueArray[0].toUpperCase()

    return valueArray.join("");
  }

}
