import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { Invite } from '../models/entities/invite';

@Injectable({
	providedIn: "root"
})
export class InviteService {
	collection = "accountMemberInvites";
	user_collection = "users";
	constructor(
		protected dbService: FirestoreService
	) {

	}

	public async cancelInvite(invite: Invite) {
		if (!invite._id) throw new Error("Invite does not have id");
		return this.dbService.db.collection(this.collection).doc(invite._id).update({ state: -1 });
	}
	public async getToken(invite: Invite) {
		if (!invite._id) throw new Error("Invite does not have id");
		const tokenRefs = (await this.dbService.db.collection(this.collection).doc(invite._id).collection('tokens').get());
		if (tokenRefs.empty) throw new Error("Invite does not have any tokens");
		return tokenRefs.docs[0].data().token
	}

	public async getInviteLinkExtension(invite: Invite) {
		return `${invite._id}/${await this.getToken(invite)}`
	}
	public async getInviteLink(invite: Invite, host: string) {
		const isUser = !(await this.dbService.db.collection(this.user_collection).where('email', '==', invite.email).get()).empty;
		return `${host}/dashboard/invite/${isUser ? 'signin' : 'new'}/${await this.getInviteLinkExtension(invite)}`;
	}

}
