import { Entity } from "../entity";


type mappedExpirations = {userId: string, locationId: string};
export class RequestsAbstract<T> extends Entity<T>{
  // Cut off for requests, by days. By default, requests expire after 2 days.
  protected readonly _cutOff = 2;

/**
 * Acquires list of moderated documents, within a specified date range, which are of the pending status
 *
 * @return Array of mapped documents, containing userId and locationId
 */
  /*public async getExpired(at = this._cutOff): Promise<Array<mappedExpirations>>{
    try {
      const esQ = await Entity.db.collection(this._collection).where('status', '==', 'pending').where('createdAt', '<=', this.expiredAt(at)).get();
      return esQ.docs.map(req => {
        return { userId: req.data().userId, locationId: req.data().locationId };
      });

    } catch (err){
      window.adminlog.error(err);
    }
    return null;
  }

  public static async getExpired(_collection: string, at = 2): Promise<Array<mappedExpirations>>{
    try {
      const esQ = await Entity.db.collection(_collection).where('status', '==', 'pending').where('createdAt', '<=', Requests.expiredAt(at)).get();
      return esQ.docs.map(req => {
        return { userId: req.data().userId, locationId: req.data().locationId };
      });

    } catch (err){
      window.adminlog.error(err);
    }
    return null;
  }*/

  //Overridable method for asserting the expiration. Child classes can use different formats.
  protected expiredAt(at: number = this._cutOff): Date {
    const cutoff = new Date();
    cutoff.setDate(cutoff.getDate() - at);
    return cutoff;
  };
  public static expiredAt(at: number = 2): Date {
    const cutoff = new Date();
    cutoff.setDate(cutoff.getDate() - at);
    return cutoff;
  }
  /*
 * Maps expirations to an array of locations,
 * @param  expirations List of mapped expirations containing userId and defaultLocationId
 *
 * @return             returns a hash of locations fitted with an array of userIds
 */
  getUserListUnderLocation(expirations: Array<mappedExpirations>): any{
    const ubL: any = {};
    expirations.forEach(exp => {
      if(!exp.userId) return;
      ubL[exp.locationId] = ubL[exp.locationId] || {};
      ubL[exp.locationId].userIds? ubL[exp.locationId].userIds = [exp.userId]: ubL[exp.locationId].userIds.push(exp.userId);
    });
    return ubL;

  }
}
