import { NgModule } from "@angular/core";

import { IonicModule } from "@ionic/angular";

import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { RshftFormComponent } from "./rshft-form/rshft-form.component";

import { RefreshIconComponent } from "./refresh-icon/refresh-icon.component";
import { HoverLabelButtonComponent } from "./hover-label-button/hover-label-button.component";

import { TouchedToggleDirective } from "../directives";

@NgModule({
	declarations: [
		RshftFormComponent,
		RefreshIconComponent,
		HoverLabelButtonComponent,
		TouchedToggleDirective
	],
	imports: [
		IonicModule,
		ReactiveFormsModule,
		FormsModule
	],
	exports: [
		ReactiveFormsModule,
		FormsModule,
		RshftFormComponent,
		RefreshIconComponent,
		HoverLabelButtonComponent,
		TouchedToggleDirective
	]
})

export class AppCommonModule { }