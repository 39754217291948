import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map, catchError, take } from "rxjs/operators";

import firebase from "firebase/app";

import { environment } from "src/environments/environment";

import { FirestoreService, queryOptions } from "./firestore.service";

import { AuthService } from "./auth.service";

import { Account, Pet } from "src/models/entities";

import { AccountStruct, PetStruct, UserStruct, AccountAccess } from "src/models/structs";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class AccountService {

	public collection = "accounts";

	private queryItemsLimit = 10;

	private userToAccountMap = {
		username: "username",
		email: "email",
		firstName: "firstName",
		lastName: "lastName",
		phoneNumber: "cellPhone",
		address:{
			address: "addr1",
			city: "city",
			state: "state",
			zip: "zip",
		},
		emergencyContact: {
			name: "emergencyContact",
			phone: "emergencyPhone",
			email: "emergencyEmail",
		}
	};

	constructor (private dbService: FirestoreService, private authService: AuthService, private http: HttpClient){ }

	public getById(accountId: string):Promise<Account> {

		return new Promise((resolve, reject) => {

			this.dbService.getDocSnapshot(this.collection, accountId)
				.then((result) => {
					resolve(new Account({data: result.data() as AccountStruct, id: accountId}));
				})
				.catch((error) => {
					reject(error);
				});

		});

	}

	public getByIdObs(accountId: string):Observable<Account> {
        if( !accountId ){ 
            return of( {} as Account );
        }

		return this.dbService.getDoc(this.collection, accountId).pipe(
			map((accountDoc) => {
				return new Account({data: accountDoc.data() as AccountStruct, id: accountId});
			})
		)

	}

	public getByUserId(userId: string, locationId: string):Promise<Account> {

        return new Promise((resolve, reject) => {

            let userAccessLevelPromises = [];

            let locationIdCondition: any = ["functional.locationId", "==", locationId];

            const fullUserQuery: queryOptions = {
                conditions: [
                    locationIdCondition,
                    ["functional.access." + userId + ".granted", "==", AccountAccess.Full]
                ],
                limit: 1
            };

            //console.log(" fullUserQuery ****", this.collection, fullUserQuery )

            userAccessLevelPromises.push(this.dbService.getCollectionOnce(this.collection, fullUserQuery));

            //const limitedUserQuery: queryOptions = {
            //	conditions: [
            //		locationIdCondition,
            //		["functional.access." + userId + ".granted", "==", AccountAccess.Minimal]
            //	]
            //};

            //userAccessLevelPromises.push(this.dbService.getCollectionOnce(this.collection, limitedUserQuery));

			return Promise.all(userAccessLevelPromises)
				.then(([result]) => {
                // .then(([fullUserResult, limitedUserResult ]) => {

                    //console.log("fullUserResult ** acount :::", fullUserResult );
                    //console.log("limitedUserResult ** acount :::", limitedUserResult );
					//let result = fullUserResult.concat(limitedUserResult);

                    //console.log("resultt ** acount :::", result );
					if(result.length > 0 && result[0] ){
						let account = new Account({data: result[0].data() as AccountStruct, id: result[0].id});
                        //console.log("resultt ** fullUserQuery , acount :::", fullUserQuery, account );
						resolve(account);
					}else{
						reject();
					}

				})
				.catch((error) => {
					reject(error);
				});

		});

	}
	public getByExecId(execId: number, locationId: string): Promise<Account> {
		const queryOptions: queryOptions = {
			conditions: [
				["functional.locationId", "==", locationId],
				["userid", "==", execId]
			],
			limit: 1
		};
		return new Promise((resolve, reject) => {

			this.dbService.getCollectionOnce(this.collection, queryOptions)
				.then((result) => {

					if (result.length > 0) {

						let account = new Account({ data: result[0].data() as AccountStruct, id: result[0].id });

						resolve(account);

					} else {
						resolve(null);
					}

				})
				.catch((error) => {
					reject(error);
				});

		});
	}
	public getByEmail(userEmail: string, locationId: string):Promise<Account> {

		const queryOptions: queryOptions = {
			conditions: [
				["functional.locationId", "==", locationId],
				["email", "==", userEmail.toLowerCase()]
			],
			limit: 1
		};

		return new Promise((resolve, reject) => {

			this.dbService.getCollectionOnce(this.collection, queryOptions)
				.then((result) => {

					if(result.length > 0){

						let account = new Account({data: result[0].data() as AccountStruct, id: result[0].id});

						resolve(account);

					}else{
						resolve(null);
					}

				})
				.catch((error) => {
					reject(error);
				});

		});

	}

	public getAutoMatchedAccounts(locationId: string, limit?: number):Observable<Account[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["functional.locationId", "==", locationId],
				["functional.original_userId", "==", "automatched"],
			],
			limit: limit || this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((accountDocs) => {
				return accountDocs.map((accountDoc) => {

					const account = new Account({data: accountDoc.data() as AccountStruct, id: accountDoc.id});

					return account;

				});
			})
		);

	}


	public async getAutoMatchedAccountsPromise(locationId: string, limit?: number):Promise<Account[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["functional.locationId", "==", locationId],
				["functional.original_userId", "==", "automatched"],
			],
			limit: limit || this.queryItemsLimit
		};

		try {

			const docs = await this.dbService.getCollectionOnce(this.collection, queryOptions);

			if(!docs.length){
				return [];
			}

			return docs.map(accountDoc => new Account({data: <AccountStruct>accountDoc.data(), id: accountDoc.id}));

		} catch (err){
			window.adminlog.error(err);
		}

	}

	public async setAccountCredentials(account: Account): Promise<any> {
		const promises = [];
		promises.push(this.dbService.updateDoc(this.collection, account._id, {
			functional: account.functional
		}));
		
		promises.push(this.dbService.setSubCollectionDocument([[this.collection, account._id], ['credentials', account._id]], account.functional));

		window.adminlog.print("credentials", account.functional);
		return promises;
	}
	public async updateAccountCredentials(accountId: string, userId: string, access: AccountAccess): Promise<void> {
        return this.dbService.updateSubCollectionDocument([[this.collection, accountId], ['credentials', accountId]], { [`access.${userId}`]: { granted: access } });
	
        // Logic to delete the access field when the user has No access set.
		// if (access !== AccountAccess.None) {
		// }
        //  else {
			// return this.dbService.updateSubCollectionDocument([[this.collection, accountId], ['credentials', accountId]], { [`access.${userId}`]: firebase.firestore.FieldValue.delete() });
		// }
		
	}
	public async getAccountCredentials(accountId: string) {
		return this.dbService.getSubCollectionDocument([[this.collection, accountId], ['credentials', accountId]]);
	}

	public async archiveAccount(account: Account, archive_pets = true, note = ''): Promise<{ message: string }> {
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{ message: string }>((resolve, reject) => {

			if (!account._id) reject(new Error("Account Id is Invalid"));

			this.http.put(`${environment.firebaseFunctions.archiveAccount}/${account._id}`, { accountId: account._id, archive_pets: archive_pets, note: note }, httpOptions).subscribe(
				(result: { message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});

	}



	public async buildAccount(account: Account): Promise<{ message: string }> {
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{ message: string }>((resolve, reject) => {

			if (!account._id) reject(new Error("Account Id is Invalid"));

			this.http.put(`${environment.firebaseFunctions.buildAccount}/${account._id}`, { accountId: account._id }, httpOptions).subscribe(
				(result: { message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});

	}


	public async refreshAccountInformation(account: Account): Promise<{ message: string }> {
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{ message: string }>((resolve, reject) => {

			if (!account._id) reject(new Error("Account Id is Invalid"));

			this.http.put(`${environment.firebaseFunctions.refreshAccountInformation}/${account._id}`, { accountId: account._id, refresh_pets: false }, httpOptions).subscribe(
				(result: { message: string }) => {
				resolve(result);
			}, (error) => {
				reject(error);
			});

		});

	}

	public async refreshPets(account: Account): Promise<{ message: string }> {
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{ message: string }>((resolve, reject) => {

			if (!account._id) reject(new Error("Account Id is Invalid"));

			this.http.put(`${environment.firebaseFunctions.refreshAccountInformation}/${account._id}/pets`, { accountId: account._id }, httpOptions).subscribe(
				(result: { message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});

	}

	public async refreshAccount(account: Account):Promise<{account: Account, pets: Pet[]}> {
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{account: Account, pets: Pet[]}>((resolve, reject) => {
			if(!account._id) reject(new Error("Account Id is Invalid"));
			this.http.put(`${environment.firebaseFunctions.refreshAccount}/${account._id}`, {}, httpOptions).subscribe((result: {id: string, account: AccountStruct, pets: {
				pet: PetStruct, id: string
			}[]})=> {
				resolve({
					account: new Account({data: result.account, id: result.id}),
					pets: result.pets.map(pet => {return new Pet({data: pet.pet, id: pet.id})})
				});
			}, (error) => {
				reject(error);
			});
		});

	}

	public async getOfficialAccountsByEmail(location:string, email: string): Promise<AccountStruct[]>{

		let bearerToken = await this.authService.getBearerToken();

		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<AccountStruct[]>((resolve, reject) => {
			this.http.get(`${environment.firebaseFunctions.searchPetExecOwnersByEmail}/${location}/${email}`, httpOptions)
				.subscribe(
					(result) => {
						resolve((result as {owners:AccountStruct[]}).owners);
					},
					(error) => {
						reject(error);
					}
				);
		});

	}

	public update(accountId: string, updatedData: any):Promise<void> {

		if(!updatedData.updatedAt){

			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);
		}

		return this.dbService.updateDoc(this.collection, accountId, updatedData);

	}

	public createAccount(account: AccountStruct):Promise<string> {

		return this.dbService.insertDoc(this.collection, account).then((accountDoc) => {
			return accountDoc.id;
		});

	}

	public createAccountFromUser(user: UserStruct, addtnlInfo: {userId: string, locationId: string, companyId: string}):Promise<string> {

		window.adminlog.print(user);

		let accountData: AccountStruct = this.mapUserToAccount(user);

		accountData.functional = {
			original_userId: addtnlInfo.userId || "",
			locationId: addtnlInfo.locationId,
			companyID: addtnlInfo.companyId,
			access: {}
		};
		if(addtnlInfo.userId){
			accountData.functional.access[addtnlInfo.userId] = {
				granted: AccountAccess.Full
			};

		}

		return this.dbService.insertDoc(this.collection, accountData).then((accountDoc) => {
			return this.setAccountCredentials(new Account({data: accountData, id: accountDoc.id})).then(_s => {
				return accountDoc.id;
			}).catch(e => {
				window.adminlog.error("Could not update account credentials", e);
				return accountDoc.id;
			});
		});

	}

	public mapUserToAccount(userObj: UserStruct):AccountStruct {

		let accountObj = {} as AccountStruct;

		for(const property in userObj){

			if(this.userToAccountMap.hasOwnProperty(property)){

				if(typeof this.userToAccountMap[property] === "string"){

					let accountPropertyName = this.userToAccountMap[property];

					if(userObj[property]){
						accountObj[accountPropertyName] = userObj[property];
					}

				}else {

					for(const subProperty in this.userToAccountMap[property]){

						let accountPropertyName = this.userToAccountMap[property][subProperty];

						if(userObj[property].hasOwnProperty(subProperty)){
							if(userObj[property][subProperty]){
									accountObj[accountPropertyName] = userObj[property][subProperty];
							}

						}

					}

				}

			}

		}

		return accountObj;

	}

}
