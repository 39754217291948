import { DogRequestStruct, RequestStatus, RequestSource } from "src/models/structs";

import { Entity } from "src/models/entity";
import firebase from 'firebase';

export class DogRequest extends Entity<DogRequest> implements DogRequestStruct {

	static readonly APPROVED:RequestStatus 	= "approved";
	static readonly DECLINED:RequestStatus 	= "declined";
	static readonly CANCELLED:RequestStatus = "cancelled";
	static readonly PENDING:RequestStatus 	= "pending";

	locationId: string;
	dogId: string;
	userId: string;
	status: RequestStatus;
	note?: string;
	source?: RequestSource;
	version?: string;
	createdAt: firebase.firestore.Timestamp;
	updatedAt?: firebase.firestore.Timestamp;
	timezone: string;

	constructor(data?: DogRequestStruct, id?: string){
		super(data, id);
	}
}
