import { Entity } from "../entity";

import { PetStruct } from "../structs/pet";
import { VaccinesStruct } from "../structs/dog";

import * as moment from "moment-timezone";

import firebase from 'firebase';

export class VaccineMetaData{ 
    shotname;
    expirationDate?;
    expired:boolean;
}
export class Pet extends Entity<Pet> implements PetStruct {
	functional: {
		ownerId?: string;
		locationId: string;
		companyID: string | number;
		dogs: {
			[dogId: string]: boolean;
		};
		vaccines: VaccinesStruct[];
		access: "Pending" | "Pass" | "No Pass" | "Scheduled" | "Approved",
        snsOf?: "my pup"| "myself";
        userSNS?: {
            facebook?: string;
            twitter?: string;
            instagram?: string;
            tikTok? : string;
        },
        petSNS?: {
            facebook?: string;
            twitter?: string;
            instagram?: string;
            tikTok? : string;
        }
        petHistory?:{ 
            healthConcerns?: string;
            healthConcernsReason?: string,
            dissmisedFromDaycareBoarding: string,
            dissmisedFromDaycareBoardingReason?: string,
            uncomfortableEnvironmentPersonOrDog?: string,
            uncomfortableEnvironmentPersonOrDogReason?: string,
        },
        uploadedVaccines?: VaccineMetaData[] ,
        veterinaryName? : string,
        veterinaryPhone?: string,

        vaccineReleaseFormOverride? : boolean;
	};
	public get isValid() {
		return !(!(this.petName && this.breedid && this.birthDate && this.gender && this.breedname && this.ptid && this.vetid && this.userid && this.petid && this.passedtemptest !== null));
	}
	petid?: number;
	userid?: number;

	petName: string;
	breedid?: number;
	breedname?: string;
	birthDate: firebase.firestore.Timestamp | Date | string;
	gender: "Male" | "Female";

	petpic?: string;
	encodedpic?: string;

	authorizedToPickup?: string;
	passedtemptest?: number;
	neuteredState?: "Unknown" | "Not Fixed" | "Neutered" | "Spayed";
	ptid: number;
	vetid?: number;

	description?: string;
	allergies?: number;
	isKennelTrained?: number;
	lunchNap?: number;
	eatTimeAM?: number;
	feedingInstructionsAM?: string;
	eatTimeNoon?: number;
	feedingInstructionsLunch?: string;
	eatTimePM?: number;
	feedingInstructionsPM?: string;
	medsAM?: number;
	medDetailAM?: string;
	medsNoon?: number;
	medDetailLunch?: string;
	medsPM?: number;
	medDetailPM?: string;
	allowedTreat?: number;
	weight?: string;

    

	public static rawExecToPetMap = {
		petname: "petName",
		breedid: "breedid",
		breedname: "breedname",
		birthdate: "birthDate",
		iskenneltrained: "isKennelTrained",
		allergies: "allergies",
		vetid: "vetid",
		description: "description",
		neuteredstate: "neuteredState",
		medsam: "medsAM",
		medsnoon: "medsNoon",
		medspm: "medsPM",
		meddetail_am: "medDetailsAM",
		meddetail_lunch: "medDetailLunch",
		meddetail_pm: "medDetailPM",
		authorizedtopickup: "authorizedToPickup",
		allowedtreats: "allowedTreats",
		eattimeam: "eatTimeAM",
		eattimenoon: "eatTimeNoon",
		eattimepm: "eatTimePM",
		feedinginstructions_am: "feedingInstructionsAM",
		feedinginstructions_lunch: "feedingInstructionsLunch",
		feddinginstructions_pm: "feedingInstructionsPM",
		petpic: "petpic",
		userid: "userid",
		ptid: "ptid",
		passtedtempttest: "passedtemptest",
		pettype: "pettype",
		encodedpic: "encodedpic"
	}

	constructor(opts: { data: PetStruct, id?: any, fromExec?: boolean }) {
		super({}, opts.id);
		if (!opts.fromExec) {
			this.hydrate(opts.data);
		} else {
			this.executiveHydrate(opts.data);
		}
	}

	public init(): void {

		this.functional = (this.functional || {}) as any;
		this.functional.dogs = this.functional.dogs || {};

	}

	public executiveHydrate(data: any) {
		const remappeddata = {};
		for (const key in data) {
			let field = Pet.rawExecToPetMap[key] || key;
			remappeddata[field] = data[key];
		}
		return this.hydrate(remappeddata);
	}

	public addDogsToPet(dogIds: string[]): void {
		this.init();
		dogIds.forEach(dogId => this.functional.dogs[dogId] = true);
	}

	public getBirthDateStr(): string {

		if (!this.birthDate) {
			return "Unknown";
		}

		if (this.birthDate instanceof Date) {

			return moment(this.birthDate).format("M/D/YYYY");

		} else if (this.birthDate instanceof firebase.firestore.Timestamp) {

			return moment(this.birthDate.toDate()).format("M/D/YYYY");

		} else {

			return moment(this.birthDate, "YYYY-MM-DD").format("M/D/YYYY");

		}

	}

	//public get getNeuteredState() {
	//	console.log("are we doing this foooooorrrreevvvver");
	//       if( !this.neuteredState ){ 
	//           this.neuteredState = "Unknown";
	//       }
	//       else { 
	//           return this.neuteredState;
	//       }
	//   }






	public checkIfPetHasVaccines() {
		if (this.functional.vaccines.length > 0) {
			return true;
		}
		return false;
	}

	public getEarliestVaccineExpiration() {
		console.log("vaccines");
		console.log(this.functional.vaccines);
		if (this.functional.vaccines && this.functional.vaccines.length > 0) {
			let firstVaccineToExpire = this.functional.vaccines[0].shots[0];
			for (let x = 0; x < this.functional.vaccines.length; x++) {
				for (let y = 0; y < this.functional.vaccines[x].shots.length; y++) {
					if (this.functional.vaccines[x].shots[y].expiration.toDate() < firstVaccineToExpire.expiration.toDate()) {
						firstVaccineToExpire = this.functional.vaccines[x].shots[y];
					}
				}
			}
			return firstVaccineToExpire;
			console.log("pet vaccines");
			console.log(this.functional.vaccines);
			console.log(firstVaccineToExpire);
		}
		console.log("pet has no vaccine data ");
	}

	public get server() {
		if (!this.functional) return '';
		return (this.functional.locationId === 'hhU5LD6ZxGZjBlmOIBF3') ? 'https://dogtopiamadison.petexec.net/admin/' : "https://dogtopia.petexec.net/admin/";
	}
	public get ownerExecLink() {
		return `${this.server}ownerMod2.php?uid=${this.userid}`;
	}
	public petExecLink() {
		return `${this.server}petMod2.php?uid=${this.userid}&petId=${this.petid}`;
	}


}
