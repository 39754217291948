import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-on-hold-all-popover',
  templateUrl: './on-hold-all-popover.component.html',
  styleUrls: ['./on-hold-all-popover.component.scss'],
})
export class OnHoldAllPopoverComponent implements OnInit {

    public onHoldTextarea;

    constructor(
        private modalController: ModalController
    ) { }

    ngOnInit() {}
    
    public dismiss() { 
        this.modalController.dismiss({
            "noAction": true,
        });
    }

    update(){ 
        let returnObj = {};
        returnObj["noAction"] = false;
        if( this.onHoldTextarea === null || this.onHoldTextarea === undefined ) { 
            this.onHoldTextarea = "";
        } 
        returnObj["onHoldReason"] = this.onHoldTextarea;
            this.modalController.dismiss( returnObj );

    }
}
