import { Entity } from "../entity";

import { LocationStruct, StoreHoursDay, AppointmentProperty, ServicesPropertyEntry, AgreementTemplateStruct } from "../structs";
import firebase from 'firebase';
import { DaycareServiceSettings } from '../structs/location';
export class Location extends Entity<Location> implements LocationStruct {
    name: string;
    slug: string;
    email: string;
    address: any;
    country: string;
    phone: string;
    storeId: string;
    position: firebase.firestore.GeoPoint;
    hours: StoreHoursDay[];
    appointmentHours?: AppointmentProperty;
    services: {
	    [serviceCode: string]: ServicesPropertyEntry
    };
    vaccinations: { [property: string]: boolean; };
    agreementTemplate?: AgreementTemplateStruct;
    credential: string;
    credentials: string[];
    subscribed?: boolean;
    concierge: boolean;
    opening_soon?: boolean;
    fake?: boolean;

    meetAndGreetSettings? : {
        duration?: number,
        meetAndGreetAppointmentHours ? : { 
            ["daysOfTheWeek"] : [{
                "start": string,
                "end": string
            }]
        },
        meetAndGreetExceptionDates? : [{ 
            date: any,
            start: string,
            end: string
        }]
    }

    enrollmentEnabled? : boolean;
    textMessagesEnabled? : boolean;

    // sync
    unmatched: number;
    automatched: number;
    mapped: number;


    // petexec
    vetId: number;
    pet_shots: number[];
    meetgreet_serviceid: number;


    // timezone
    timezone: string;
    gmtoffset: number;

    daycare_service_settings: { [dayid: number]: DaycareServiceSettings; };

    cancellationPolicyEnabled? : boolean;
    cancellationPolicyTime?;
    cancellationPolicyMsg? : string;
    cancelationPolicyLink? : string;

    appointmentLeadTimeEnable? :boolean;
    appointmentLeadTime?: number;

    vaccinationReleaseForm?: { 
        enabled: boolean,
        title: string,
        contactName: string, 
        fax: string,
        phone: string,
        email: string
    }

    salesforceConnection? : boolean;

    constructor(data?: LocationStruct, id?: string){
	    super(data, id);
    }
}
