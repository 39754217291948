import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore.service';
import { Subject } from 'rxjs';
import { Entity } from 'src/models/entity';
import { IEntityMap, IQueryStore } from '../interfaces';
import firebase from 'firebase';
export class QueryStore<T extends Entity<any>> implements IQueryStore<T> {

    protected _result = new Subject<Array<T>>();


    private result_listener: () => void;

    public constructor(fs: FirestoreService, entityMap: IEntityMap<T>) {
        this.fs = fs;
        this.entityMap = entityMap;
    }

    public entityMap: IEntityMap<T>;
    public fs: FirestoreService;

    public queries: firebase.firestore.Query[] = [];
    public results: Array<Array<T>> = [];
    public names: { [index: number]: string } = [];

    public active_index: number;

    public get resultSet() {
        return this.results[this.active_index];
    }

    public get queryName() {
        return this.names[this.active_index] || '';
    }

    public get query() {
        return this.queries[this.active_index];
    }

    public get result() {
        return this._result;
    }


    protected switchTo(new_query: string | firebase.firestore.Query): void {

        if (typeof new_query == 'string') {
            if (this.queryName != new_query && new_query) {
                this.active_index = this.getState().findIndex(rt => rt.name == new_query);
            }
        } else if (typeof new_query != 'undefined') {
            this.active_index = (this.queries.findIndex((query) => query === new_query) || this.active_index );
        }


        if (this.result_listener) this.result_listener();

        //this.result.next(this.resultSet);
        this.result_listener = this.query.onSnapshot(this.emitResults.bind(this), (err) => {
            window.adminlog.error("::querySTore", "could not retrieve snap", err);
            this.result.next([]);
        }, () => {
        });

    }

    protected emitResults(results: firebase.firestore.QuerySnapshot) {
        //@to-do comment this out later.
        window.adminlog.print("We got results", this.queryName, results.docs);
        this.result.next(this.entityMap.mapDocsToObjs(results.docs));
    }

    public getState(): Array<{
        data: Array<T & { id: string, name?: string }>,
        query: firebase.firestore.Query,
        name: string;
    }> {
        return this.queries.map((query,i) => {
            return {
                data: this.results[i] || null,
                query: query,
                name: this.names[i] || ''
            }
        });
    }

    public addQuery(val: firebase.firestore.Query, name?: string) {
        const index = this.queries.findIndex(q => q === val);
        if (index === -1) {
            this.queries.push(val);
            this.results.push([]);
            this.names[this.results.length - 1] = name || '';
            this.active_index = this.queries.length - 1;
        }
    }

    /**
     * listen to query string, or to query object.
     * @param query if query is undefined, or not carried in list off queries in store, will listen to the active query.
     */
    public listen(query?: string | firebase.firestore.Query) {
        this.switchTo(query);
    }

    public clear() {
        if (this.result_listener) this.result_listener();
        this.result_listener = null;
        this.queries = [];
        this.results = [];
        this.names = [];
        this.active_index = null;
    }

    public addQueries(...options: Array<{ query: firebase.firestore.Query, name: string }>) {
        options.forEach(option => {
            this.addQuery(option.query, option.name);
        });
    }

}



@Injectable({ providedIn: 'root' })
export class QueryStoreFactoryService {
    constructor(private fs: FirestoreService) {

    }

    public createQuerySet<T extends Entity<any>>(collection: string, entityMap: IEntityMap<T>, ...options: Array<{ query: firebase.firestore.Query, name: string } >) {
        const query = new QueryStore<T>(this.fs, entityMap);
        query.addQueries(...options);

        return query;
    }
}



/**
 * Overview column config
 *
 *
 * store query Options & names
 * switch between them
 * expose results of queries
 *
 *
 *
 * */
