import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse
} from '@angular/common/http';

import { from, Observable } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { datastore } from '../stores/store.temporary';
import { environment } from '../../environments/environment';

import { AlertService } from '../alert.service';
@Injectable()
export class HttpInterceptService implements HttpInterceptor {
    constructor(private authService: AuthService, private _store: datastore, private alertService: AlertService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const InterceptorSkipHeader = 'X-Skip-Interceptor';
        const InterceptorSkipErrorHeader = 'X-Skip-Error-Alert';
        const InterceptorPDFReturn = 'X-PDF-Return';  
        let apiReq: any = {};
        const skipAlertError = req.headers.has(InterceptorSkipErrorHeader);
        //console.log( "httpHeaders", req.headers );
        //console.log( "from http intercept " + req.headers.has(InterceptorPDFReturn) );
        if (req.headers.has(InterceptorSkipHeader)) {
            window.adminlog.print('skip intercept');
            const headers = req.headers.delete(InterceptorSkipHeader);
            apiReq = req.clone({
                headers
            });

        } 
        else if(req.headers.has(InterceptorPDFReturn) && this.authService.firebaseUser){ 
                
            return from(this.authService.getBearerToken()).pipe(switchMap(token => {
                
                if (token) {
                    //headers.set( 'Content-Type',  'application/pdf' );
                    //headers.set( 'responseType'  , 'blob' );
                    //headers.set( 'Accept' , 'application/pdf' );
                    //headers.set( 'observe' , 'response' );
                    //headers.set( 'Authorization', `Bearer ${token}` );

                    apiReq = req.clone({
                        url: `${req.url}`, headers: new HttpHeaders({
                            'Content-Type' : 'application/pdf',
                            'responseType' : 'blob',
                            'Accept'       : 'application/pdf',
                            'observe'      : 'response',
                            'Authorization': 'Bearer '+token
                        })
                    });
                } else {
                    apiReq = req.clone({
                        url: `${req.url}`,
                        headers: new HttpHeaders({
                            'Content-Type' : 'application/pdf',
                            'responseType' : 'blob',
                            'Accept'       : 'application/pdf',
                            'observe'      : 'response'
                        })
                    });
                }

                console.log( apiReq.body );

                return next.handle(apiReq).pipe(map((ev: HttpEvent<any>) => {
                    if (ev instanceof HttpResponse) {
                        window.adminlog.print("Successful query", ev.body);
                    }

                    return ev;
                }), catchError((err: HttpErrorResponse) => {
                    if (req.method.toLowerCase() !== "get" && !skipAlertError ) {
                        console.log('showDiagnosticError 77', err);
                        // In case of error that is blank we define error.
                        if(err.statusText === 'Unknown Error' || err.status === 0) {
                            err.error.message = "PetExec API timeout."
                        }

                        window.adminlog.print("Request was not a 'get' and returned error, showing diag.");
                        this.alertService.showDiagnostic(err.error);
                    }
                    throw err;
                }))
            }));
        }
        else if(this.authService.firebaseUser) {
            return from(this.authService.getBearerToken()).pipe(switchMap(token => {
                
                if (token) {
                    apiReq = req.clone({
                        url: `${req.url}`, headers: new HttpHeaders({
                            ["Content-Type"]: "application/json",
                            Authorization: `Bearer ${token}`
                        })
                    });
                } else {
                    apiReq = req.clone({
                        url: `${req.url}`,
                        headers: new HttpHeaders({
                            ["Content-Type"]: "application/json"
                        })

                    });
                }

                return next.handle(apiReq).pipe(map((ev: HttpEvent<any>) => {
                    if (ev instanceof HttpResponse) {
                        window.adminlog.print("Successful query", ev.body);
                    }

                    return ev;
                }), catchError((err: HttpErrorResponse) => {
                    if (req.method.toLowerCase() !== "get" && !skipAlertError ) {
                        console.log('showDiagnosticError 111', err);
                        // In case of error that is blank we define error.
                        if(err.statusText === 'Unknown Error' || err.status === 0) {
                            err.error.message = "PetExec API timeout."
                        }
                        window.adminlog.print("Request was not a 'get' and returned error, showing diag.");
                        this.alertService.showDiagnostic(err.error);
                    }
                    throw err;
                }))
            }));
            
        }
    }

}

export const httpInterceptors = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptService, multi: true }
]
