import { Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import firebase from "firebase/app";

import { AuthService } from "./auth.service";
import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { AppointmentStruct } from "src/models/structs";

import { Appointment } from "src/models/entities";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class AppointmentService extends EntityServiceAbstract<Appointment> {

	public collection = "appointments";

	protected statusColorMap:object = {
		"pending": 		"primary",
		"approved":		"success",
		"cancelled":	"danger",
        "declined":		"danger",
        "cancel declined": "danger",
        "waitlist": "dark",
        "on hold": "dark",
	};

	private queryItemsLimit = 100;

    constructor (protected dbService: FirestoreService, 
        private logService: ActivityLogService,
        private authService: AuthService,
        private httpClient: HttpClient){
        super();
        
        
	}

	public getById(appointmentId:string):Observable<Appointment> {

		return this.dbService.getDoc(this.collection, appointmentId).pipe(
			map((appointmentDoc) => {

				if(appointmentDoc.exists){
					return new Appointment(appointmentDoc.data() as AppointmentStruct, appointmentDoc.id);
				}

			})
		);

    }
        
    public getByIdPromise( appointmentId:string ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.dbService.getDocSnapshot(this.collection, appointmentId).then( document => {
                if( document.exists ){ 
                    resolve(
                        new Appointment(document.data() as AppointmentStruct, document.id)
                    );
                }
                else { 
                    reject([]);
                }
                
            });
        });
    }

	public update(appointmentId:string, updatedData:any): Promise<void> {

		if(!updatedData.updatedAt) {

			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, appointmentId, updatedData);

	}

    public newAppointmentsQuery(locationId: string ){ 
        return this.dbService.db.collection( this.collection )
        .where( "locationId" , "==", locationId )
        .where( "status", "==", Appointment.PENDING )
        .limit( this.queryItemsLimit );
    }


	public getNewAppointments(locationId:string, userIdToFilter:string = ""):Observable<Appointment[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", Appointment.PENDING]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: 500 // Increase query limit to 500 for new appointments // this.queryItemsLimit
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}


    public getonHoldWaitlistAppointments(locationId:string, userIdToFilter:string = ""):Observable<Appointment[]> {

        let yesterday = new Date();
        yesterday.setDate( yesterday.getDate( ) - 1 );

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "in", [Appointment.WAITLIST, Appointment.ONHOLD]],
                ['date', '>', firebase.firestore.Timestamp.fromDate( yesterday ) ]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: 500 // Increase query limit to 500 for new appointments // this.queryItemsLimit
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

    public getOnHoldandWaitlistAppointmentsObs( locationId: string , orderBy ){ 
        console.log( "from query" );
        let yesterday = new Date();
        yesterday.setDate( yesterday.getDate( ) - 1 );
        
        const queryOptions : queryOptions = {
            conditions: [
                ["locationId", "==", locationId],
                ["status", "in", [Appointment.WAITLIST, Appointment.ONHOLD]],
                ['date', '>', firebase.firestore.Timestamp.fromDate( yesterday ) ]
            ],
            orderByField: "date",
            orderByDir: orderBy != "default" ? orderBy.substring( orderBy.lastIndexOf("_")+1, orderBy.length ) : "asc",//orderBy,
            limit: 500 // Increase query limit to 500 for new appointments // this.queryItemsLimit
        };
        
        return this.dbService.getCollection(this.collection, queryOptions).pipe(
            map((appointmentDocs) => {
                return this.mapDocsToObjs(appointmentDocs);
            }),

            catchError((error) => {
                window.adminlog.print(error);
                throw error;
            })
        ); 
    }
    public getOnHoldAppointmentsObs( locationId: string, orderBy ){ 

        let yesterday = new Date();
        yesterday.setDate( yesterday.getDate( ) - 1 );

        const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", Appointment.ONHOLD],
                ['date', '>', firebase.firestore.Timestamp.fromDate( yesterday ) ]
			],
			orderByField: "date",
			orderByDir: orderBy != "default" ? orderBy.substring( orderBy.lastIndexOf("_")+1, orderBy.length ) : "asc",
			limit: 500 // Increase query limit to 500 for new appointments // this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
    }
    public getOnWaitlistAppointmentsObs( locationId: string, orderBy ){ 
        let yesterday = new Date();
        yesterday.setDate( yesterday.getDate( ) - 1 );
        const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", Appointment.WAITLIST],
                ['date', '>', firebase.firestore.Timestamp.fromDate( yesterday ) ]
			],
			orderByField: "date",
			orderByDir: orderBy != "default" ? orderBy.substring( orderBy.lastIndexOf("_")+1, orderBy.length ) : "asc",
			limit: 500 // Increase query limit to 500 for new appointments // this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
    }

    // TODO 
    public getOnHoldWaitlistAppointmentQuery( locationId : string ){ 

        let yesterday = new Date();
        yesterday.setDate( yesterday.getDate( ) - 1 );


        return this.dbService.db.collection( this.collection )
        .where( "locationId" , "==", locationId )
        .where( "status", "in", [Appointment.WAITLIST, Appointment.ONHOLD] )
        //.where('date', '>', firebase.firestore.Timestamp.fromDate( yesterday ) )
        //.orderBy("date", "asc")
        //.startAt( yesterday )
        .limit( this.queryItemsLimit );
    }

    public getCancelledAppointmentsQuery( locationId ){ 
        return this.dbService.db.collection( this.collection )
        .where( "locationId" , "==", locationId )
        .where( "status", "==", Appointment.CANCELLED )
        .where( "completed", "!=", true )
        
        
        .limit( this.queryItemsLimit );
    }

	public getCancelledAppointments(locationId:string, userIdToFilter:string = ""):Observable<Appointment[]> {
        console.log( "from query" );
		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", Appointment.CANCELLED],
                //["completed", "!=", true]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: 500 // Increase query limit to 500 for new appointments // this.queryItemsLimit
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {

                
				return this.mapDocsToObjs(appointmentDocs).filter((appointment:Appointment) => {
					// Return true (and keep) when appointment DOES NOT have cancelled property or set to false while not being completed
					return ( ( !appointment.hasOwnProperty("cancelled") || !appointment.cancelled ) && !appointment.completed );
                    //return ( appointment.completed )
				});
                
                //return this.mapDocsToObjs(appointmentDocs).filter(appointment => 
                //    (!appointment.hasOwnProperty("cancelled") || !appointment.cancelled) && !appointment.completed
                //);
			})
		);

	}

    public getCompletedAppointmentsQuery( locationId: string ){ 
        return this.dbService.db.collection( this.collection )
        .where( "locationId" , "==", locationId )
        .where( "completed", "==", true )
        .limit( this.queryItemsLimit );
    }

	public getCompletedAppointments(locationId:string, userIdToFilter:string = ""):Observable<Appointment[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["completed", "==", true]
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw(error);
			})
		);

	}


	//#region appoint columns by accountId

	public getNewAppointmentsByAccountId(locationId: string, accountId: string): Observable<Appointment[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["locationId", "==", locationId],

				['accountId', '==', accountId],
				["status", "==", Appointment.PENDING]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};


		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getNewDaycareAppointmentsByAccountId(locationId: string, accountId: string): Observable<Appointment[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				['service', '==', 'Daycare'],
				['accountId', '==', accountId],
				["status", "==",  Appointment.PENDING ]//, Appointment.WAITLIST, Appointment.PENDING]]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};


		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

    public getonHoldWaitlistAppointmentsByAccountId(locationId:string, userIdToFilter:string = ""):Observable<Appointment[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "in", [Appointment.WAITLIST, Appointment.ONHOLD]]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: 500 // Increase query limit to 500 for new appointments // this.queryItemsLimit
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["accountId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}


	public getCancelledAppointmentsByAccountId(locationId: string, accountId: string): Observable<Appointment[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["locationId", "==", locationId],

				['accountId', '==', accountId],
				["status", "==", Appointment.CANCELLED],
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};


		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {

				//return this.mapDocsToObjs(appointmentDocs).filter((appointment: Appointment) => {
					// Return true (and keep) when appointment DOES NOT have cancelled property
				//	return !appointment.hasOwnProperty("cancelled");
				//});
                return this.mapDocsToObjs(appointmentDocs).filter((appointment:Appointment) => {
					// Return true (and keep) when appointment DOES NOT have cancelled property or set to false while not being completed
					return ( ( !appointment.hasOwnProperty("cancelled") || !appointment.cancelled ) && !appointment.completed );
                    //return ( appointment.completed )
				});

			})
		);

	}

	public getCompletedAppointmentsByAccountId(locationId: string, accountId: string): Observable<Appointment[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				['accountId', '==', accountId],
				["completed", "==", true]
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((appointmentDocs) => {
				return this.mapDocsToObjs(appointmentDocs);
			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw (error);
			})
		);

	}


	//#endregion

	public approveAppointment(appointmentId:string, updatedValues:any):Promise<any> {

		updatedValues.status = Appointment.APPROVED;

		return this.completeAppointment(appointmentId, updatedValues);

    }
    
    public approveSingleDayCareAppointment( appointmentId: string , updatedValues:any ) : Promise<any>{ 
        updatedValues.status = Appointment.APPROVED;

        return new Promise( ( resolve ) => {
            updatedValues.completed = true;
            let appointmentUpdatePromise = this.update(appointmentId, updatedValues).then( () =>{
                resolve(true);
            });
		
        });
    }

	public declineAppointment(appointmentId:string, updatedValues:any):Promise<any> {
		updatedValues.status = Appointment.DECLINED;

		return this.completeAppointment(appointmentId, updatedValues);

	}

	public completedCancelledAppointment(appointmentId:string, adminResponse:string):Promise<void> {

		let updatedValues:any = {
			cancelled: true,
			note: adminResponse,
			status: Appointment.CANCELLED,
		};

        //this.petExecService.deleteAppt();

		return this.completeAppointment(appointmentId, updatedValues, false);

	}


    public ignoreCancelledAppointment(appointmentId:string, adminResponse:string):Promise<void> {

		let updatedValues:any = {
			cancelled: true,
			note: adminResponse,
			status: Appointment.CANCELDECLINED,
		};

		return this.completeAppointment(appointmentId, updatedValues);

	}



	public completeAppointment(appointmentId:string, updatedValues:any, log = true):Promise<any> {
		updatedValues.completed = true;
        let appointmentUpdatePromise = this.update(appointmentId, updatedValues);
        let logEntryStatus = (updatedValues.status === Appointment.CANCELLED) ? "Completed" : updatedValues.status || null;
		let logEntryPromise = this.logService.addLogEntry(this.collection, appointmentId,  logEntryStatus, (updatedValues.note || ""));
		
        if( !log ) { 
            return Promise.all([appointmentUpdatePromise]);
        }
        return Promise.all([appointmentUpdatePromise, logEntryPromise]);
	}

	public mapDocsToObjs(appointmentDocs:firebase.firestore.QueryDocumentSnapshot[]):Appointment[] {

		return appointmentDocs.map((appointmentDoc) => {
			
			const appointment = new Appointment(appointmentDoc.data() as AppointmentStruct, appointmentDoc.id);
			return appointment;
		});

	}

}
