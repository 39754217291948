import { NgModule } from "@angular/core";

import { DashboardCommonModule } from "./dashboard-common.module";

import { DashboardRoutingModule } from "./dashboard-routing.module";

import { UserMenuPopoverComponent } from "./user-menu-popover/user-menu-popover.component";
import { LocationSelectPopoverComponent } from "./location-select-popover/location-select-popover.component";

import { DashboardSearchbarModule } from "../dashboard/dashboard-searchbar.module";

import {SortSelectionOveriewPopoverComponent} from "./sort-selection-overiew-popover/sort-selection-overiew-popover.component";

import { DashboardFrameComponent } from "./dashboard-frame/dashboard-frame.component";
import { DashboardTitleBarComponent } from "./dashboard-title-bar/dashboard-title-bar.component";
import { AccessRequestsModule } from '../access-requests/access-requests.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SearchMenuPopupComponent } from './search-menu-popup/search-menu-popup.component';
import { MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule } from '@angular/material';


import { ConfirmMultiDaycareModalComponent } from "./confirm-multi-daycare-modal/confirm-multi-daycare-modal.component";
import{ CompleteCancellationComponent } from "./complete-cancellation/complete-cancellation.component";
import { AppointmentChangeStatusComponent } from "../dashboard/appointment-change-status/appointment-change-status.component";

import { EntityDetailModule } from "../dashboard/entity-detail/entity-detail.module";

import {VaccinePopoverComponent} from "../dashboard/vaccine-popover/vaccine-popover.component";
import { OnHoldWaitlistFilterPopoverComponent } from "./on-hold-waitlist-filter-popover/on-hold-waitlist-filter-popover.component"
import { GroupedAppointmentChangeStatusComponent } from "./grouped-appointment-change-status/grouped-appointment-change-status.component"; 
import { OnHoldAllPopoverComponent } from "./on-hold-all-popover/on-hold-all-popover.component"
import { CancellationPolicyUserFacingMsgPopupComponent } from "./cancellation-policy-user-facing-msg-popup/cancellation-policy-user-facing-msg-popup.component";

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActiveDropOffSortPopoverComponent } from "./active-drop-off-sort-popover/active-drop-off-sort-popover.component";
import { ActiveCancelledDropoffSortPopoverComponent } from "./active-cancelled-dropoff-sort-popover/active-cancelled-dropoff-sort-popover.component";
import { SelectAllDropoffsPopoverComponent } from "./select-all-dropoffs-popover/select-all-dropoffs-popover.component";
import { CancellationPolicyLinkPopupComponent } from "./cancellation-policy-link-popup/cancellation-policy-link-popup.component";
import { ApprovalsSortPopoverComponent } from "./approvals-sort-popover/approvals-sort-popover.component";
import { ApprovalsFilterPopoverComponent } from "./approvals-filter-popover/approvals-filter-popover.component";
import { MobileNavPopoverComponent } from "./mobile-nav-popover/mobile-nav-popover.component";

import { MbscModule } from "@mobiscroll/angular";
import { RescheduleMeetGreetPopoverComponent } from "./mg-reschedule-popover/mg-reschedule-popover.component";
import { AlertModelComponent } from "./alert-model/alert-model.component";




@NgModule({
	declarations: [
		UserMenuPopoverComponent,
		LocationSelectPopoverComponent,
		DashboardFrameComponent,
		DashboardTitleBarComponent,
		SearchMenuPopupComponent,
        ConfirmMultiDaycareModalComponent,
        CompleteCancellationComponent,
        AppointmentChangeStatusComponent,

        VaccinePopoverComponent,
        OnHoldWaitlistFilterPopoverComponent,
        GroupedAppointmentChangeStatusComponent,
        OnHoldAllPopoverComponent,
        CancellationPolicyUserFacingMsgPopupComponent,
        RescheduleMeetGreetPopoverComponent,
        
        ActiveDropOffSortPopoverComponent,
        ActiveCancelledDropoffSortPopoverComponent,

        SelectAllDropoffsPopoverComponent,

        CancellationPolicyLinkPopupComponent,
        ApprovalsSortPopoverComponent,
        ApprovalsFilterPopoverComponent,
        MobileNavPopoverComponent,
        AlertModelComponent

        //ChangeAppointmentStatusComponent
        
        //SortSelectionOveriewPopoverComponent
	],
	entryComponents: [
		UserMenuPopoverComponent,
		LocationSelectPopoverComponent,
		SearchMenuPopupComponent,
        ConfirmMultiDaycareModalComponent,
        CompleteCancellationComponent,
        AppointmentChangeStatusComponent,
        VaccinePopoverComponent,
        OnHoldWaitlistFilterPopoverComponent,
        GroupedAppointmentChangeStatusComponent,
        OnHoldAllPopoverComponent,
        CancellationPolicyUserFacingMsgPopupComponent,
        RescheduleMeetGreetPopoverComponent,

        ActiveDropOffSortPopoverComponent,
        ActiveCancelledDropoffSortPopoverComponent,

        SelectAllDropoffsPopoverComponent,
        CancellationPolicyLinkPopupComponent,
        ApprovalsSortPopoverComponent,
        ApprovalsFilterPopoverComponent,
        MobileNavPopoverComponent,
        AlertModelComponent
        //ChangeAppointmentStatusComponent
	],
	imports: [
		//BrowserAnimationsModule,
		DashboardCommonModule,
		DashboardRoutingModule,
		DashboardSearchbarModule,
		AccessRequestsModule,
		MatDialogModule,
        NgxSpinnerModule,
        MbscModule,

		MatProgressSpinnerModule,
		FontAwesomeModule,
        EntityDetailModule,

        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
	],

    exports: [
        ActiveDropOffSortPopoverComponent,
        ActiveCancelledDropoffSortPopoverComponent,
        SelectAllDropoffsPopoverComponent,
        CancellationPolicyLinkPopupComponent,
        ApprovalsSortPopoverComponent,
        ApprovalsFilterPopoverComponent,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule

    ]
})

export class DashboardModule { }
