import { Pipe, PipeTransform } from "@angular/core";

import { AdminService } from "src/services";

@Pipe({
	name: "getAdminName"
})

export class GetAdminNamePipe implements PipeTransform {

	constructor(private adminService: AdminService) { }

	public async transform(adminId: string):Promise<string> {
        if (!adminId) return adminId;
		const admin = await this.adminService.getById(adminId);
		if(!admin || !admin.firstName ) return adminId;
		else return admin.firstName + " " + admin.lastName;
		
	}

}
