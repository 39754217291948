import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, catchError, first, take } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { UserService } from "./user.service";

import { DogService } from "./dog.service";

import { UserRequestStruct, RequestStatus } from "src/models/structs";

import { UserRequest, User, Location, Dog } from "src/models/entities";

import * as moment from "moment-timezone";
import { DogRequestService } from './dog-request.service';
import { AccessRequest } from '../models/entities/access-request';
import { AccessRequestStatus } from '../models/structs/access-request';
import { WhereFilterOp } from '@firebase/firestore-types';

@Injectable({
	providedIn: "root"
})

export class AccessRequestService extends EntityServiceAbstract<AccessRequest> {

	public collection = "accessRequests";

	protected statusColorMap: object = {
		0: "primary",
		1: "success",
		2: "medium",
		3: "tertiary"
	};
	//Must override status function since we use enums for access requests
	public getStatusColor(status: AccessRequestStatus): string {

		return this.statusColorMap[status] || "primary";
	}
	public statusTransform = {
		[AccessRequestStatus.Pending]: "Pending",
		[AccessRequestStatus.Completed]: "Completed",
		[AccessRequestStatus.Invalid]: "Invalid",
		[AccessRequestStatus.Existing]: "Customer"
	}

	private queryItemsLimit = 100;

	constructor(
		protected dbService: FirestoreService,
		private logService: ActivityLogService,
		private userService: UserService,
		private dogService: DogService,
		private dogRequestService: DogRequestService
	) {
		super();
	}

	public getById(accessRequestId: string): Observable<AccessRequest> {

		return this.dbService.getDoc(this.collection, accessRequestId).pipe(
			map((accessRequestDoc) => {

				if (accessRequestDoc.exists) {

					return new AccessRequest(accessRequestDoc.data() as UserRequestStruct, accessRequestDoc.id);

				}

			})
		);

	}

	public update(accessRequestId: string, updatedData: any): Promise<void> {

		if (!updatedData.updatedAt) {

			updatedData.updatedAt = firebase.firestore.FieldValue.serverTimestamp() as any;


		}

		return this.dbService.updateDoc(this.collection, accessRequestId, updatedData);

	}

	public getNewAccessRequests(locationId: string, custom_condition?: [string, WhereFilterOp, any]): Observable<AccessRequest[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", AccessRequestStatus.Pending]
			],
			orderByField: "createdAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		if(custom_condition) queryOptions.conditions.push(custom_condition);

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((accessRequestDocs) => {
				return this.mapDocsToObjs(accessRequestDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getCompletedAccessRequests(locationId: string, custom_condition?: [string, WhereFilterOp, any]): Observable<AccessRequest[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["locationId", "==", locationId],
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
		};


		if(custom_condition) queryOptions.conditions.push(custom_condition);
		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((accessRequestDocs) => {
				return this.mapDocsToObjs(accessRequestDocs)
					.filter((accessRequest) => { return accessRequest.status !== AccessRequestStatus.Pending })
					.slice(0, this.queryItemsLimit);

			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw (error);
			})
		);

	}


	public approveUserRequest(userRequestId: string, user: User, location: Location, note: string): Promise<any> {

		return this.userService.updateLocationAccess(user, location, true).then(() => {

			return this.completeUserRequest(userRequestId, UserRequest.APPROVED, note);

		});

	}

	public declineUserRequest(userRequestId: string, user: User, location: Location, note: string): Promise<any> {

		return this.userService.updateLocationAccess(user, location, false).then(() => {

			return this.completeUserRequest(userRequestId, UserRequest.DECLINED, note);

		});

	}

	private completeUserRequest(userRequestId: string, status: RequestStatus, note: string): Promise<any> {

		let updatedValues = {
			status: status,
			note: note
		};

		let userRequestUpdatePromise = this.update(userRequestId, updatedValues);

		let logEntryPromise = this.logService.addLogEntry(this.collection, userRequestId, status, note);

		return Promise.all([userRequestUpdatePromise, logEntryPromise]);

	}

	private mapDocsToObjs(accessRequestDocs: firebase.firestore.QueryDocumentSnapshot[]): AccessRequest[] {

		return accessRequestDocs.map((accessRequestDoc) => {

			const userRequest = new AccessRequest(accessRequestDoc.data() as UserRequestStruct, accessRequestDoc.id);

			return userRequest;

		});

	}
	public getStatusBadgeText<T>(entityObj: T, badgeTextProperty: string): string {
		return this.statusTransform[entityObj[badgeTextProperty]]
	}

}
