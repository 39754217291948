//import { AppointmentStruct } from "src/models/structs/appointment";

export class ConfirmMultiDaycareDayModel{ 

    daycareType: "single"|"multi";

    appointments: any[] = [];
    singleDayUpdatedValues: any;
    account?:any;
    emailMessageCopy?:string;

}
