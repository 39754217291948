import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class datastore {
    // Meant to be static, do nothing here.


}
