import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { setOptions } from "@mobiscroll/angular";

setOptions({
  theme: 'ios',
  themeVariant: 'light'
});

@Component({
	selector: "app-root",
	templateUrl: "app.component.html"
})
export class AppComponent {

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar
	){
		this.initializeApp();
	}

	initializeApp(){
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		});
	}
}
