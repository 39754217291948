import { AptStatus } from "src/models/structs";

export interface IAppointmentActionConfig{
    appointmentId: string, 
    Petindex: number, 
    appointmentindex: number, 
    action: AppointmentAction, 
    status: AptStatus, 
    serviceid?: number 
}

export enum AppointmentAction { 
    Approved = 'Approved', 
    Declined = 'Declined', 
    Pending = 'Pending',
    OnHold = 'On Hold',
    Waitlist = 'Waitlist'
}

export enum Entity_Details_ErrorMessages{
    IgnoreErrorTip = '<p>By ticking this box and clicking “proceed to email”, this appointment request will be cleared from the system. You should do this when you have manually added the appointment directly in PetExec, called the pet parent to make other arrangements, or otherwise address the issue.</p> <p>Please be aware you will not be able to recover this appointment once you have clicked the “proceed to email” button.</p>',
    SkipEmailTip = '<p>By choosing this option, you will complete the appointment booking process without sending an email to the pet parent to confirm appointment approvals or declines.</p><p>Note that the above email cannot be re-generated at a later time. If you would like to send an email separately, you may want to copy and save the above text before proceeding.</p>',
    SingleDaycareTip = '<p>By ticking this box and clicking “approve”, this appointment request will be cleared from the system. You should do this when you have manually added the appointment directly in PetExec, called the pet parent to make other arrangements, or otherwise address the issue.</p> <p>Please be aware you will not be able to recover this appointment once you have clicked the “approve” button.</p>'
    
}