import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { AuthService } from "./auth.service";

import { MemoStruct, DuplicateLeadMemo } from "src/models/structs/memo";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class MemoService {

	public collection = "memos";

	constructor (private dbService: FirestoreService, private authService: AuthService){}

	public getMemos(parentCollection:string, parentId:string):Observable<MemoStruct[]> {

		const collectionFullPath = parentCollection + "/" + parentId + "/" + this.collection;

		return this.dbService.getCollection(collectionFullPath, {orderByField: "createdAt", orderByDir: "desc"} as queryOptions).pipe(
			map((memoDocs) => {
				return memoDocs.map((memoDoc) => {
					
					return memoDoc.data() as MemoStruct;

				});
			})
		);

	}

    public getMemosOnce( parentCollection:string, parentId:string ):Promise<MemoStruct[]>{ 
        const collectionFullPath = parentCollection + "/" + parentId + "/" + this.collection;

        return this.dbService.db.collection( parentCollection ).doc(parentId).collection(this.collection).get().then( data => {
            return data.docs.map( memoRef => {
                return memoRef.data() as MemoStruct;
            } )
        });
    }

	public addMemo(parentCollection:string, parentId:string, memoText:string):Promise<string> {

		const collectionFullPath = parentCollection + "/" + parentId + "/" + this.collection;

		let now = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());
		
		let memoData:MemoStruct = {
			parentId: parentId,
			createdAt: firebase.firestore.Timestamp.fromDate(now),
			createdBy: this.authService.user._id,
			memo: memoText
		};

		return this.dbService.insertDoc(collectionFullPath, memoData).then((memoDoc) => {
			return memoDoc.id;
		});

	}

    public addMemoFromDuplicateLeadObject(leadId: string, memo: DuplicateLeadMemo ): Promise<string>{ 
        return this.dbService.insertDoc("leads/"+leadId+"/memos", memo).then((memoDoc) => {
			return memoDoc.id;
		});
    }

	
}