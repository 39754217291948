import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsLocationIdService {

    public behaviorSubject = new BehaviorSubject<string>('');

    constructor() { }

    public setLocationId(locationId: string) {
        this.behaviorSubject.next(locationId);
    }

    public getLocationId(): Observable<string> {
        return this.behaviorSubject.asObservable();
    }

}
