import { FormsModule } from '@angular/forms';
import { MbscModule } from '@mobiscroll/angular';
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { environment } from "../environments/environment";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { AppCommonModule } from "./app-common.module";

import { DashboardModule } from "./dashboard/dashboard.module";

import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { httpInterceptors } from '../services/api/http-intercept.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule, MatSelectModule, MatToolbarModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';



import  firebase from 'firebase';




enum LogLevel {
  all,
  warning,
  error
}
// for filtering out logs on production

class AdminLog {
  production: boolean;
  constructor(loglevel:boolean // LogLevel
  ){
    this.production = loglevel;
  }
  public print(...args: any[]){
    if(!this.production) console.log(...args);
  }
  public warn(...args: any[]){
    if(!this.production) console.warn(...args);
  }
  public error(...args: any[]){
    if(!this.production) console.error(...args);
  }
}

// import { AdminLog } from 'src/global';
//


window.adminlog = new AdminLog(false);


@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
        ForgotPasswordComponent,

        
	],
	entryComponents: [],
	imports: [ 
    FormsModule,  
    MbscModule, 
		BrowserAnimationsModule,
		HttpClientModule,
		IonicModule.forRoot({
			mode: "ios",
		}),
		AppRoutingModule,
		AppCommonModule,
        DashboardModule,

		//ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
	],
	providers: [
		StatusBar,
		SplashScreen,
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
		httpInterceptors
	],
	bootstrap: [AppComponent],
	exports: [
		HttpClientModule,
	]
})

export class AppModule {
	constructor() {
		firebase.initializeApp(environment.firebase);
	}
}
