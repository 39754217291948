/**
 * @swagger
 * components:
 *  schemas:
 *    OpenOnDay:
 *      required: [type, min, max]
 *      properties:
 *        close:
 *          description: Close at
 *          type: string
 *        closed:
 *          type: boolean
 *        day:
 *          type: string
 *          enum: [Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday]
 *        open:
 *          description: Open at
 *          type: string
 *    OpenOn:
 *      required: [friday,saturday,sunday,monday,tuesday,wednesday,thursday]
 *      properties:
 *        friday:
 *          $ref: "#/components/schemas/OpenOnDay"
 *        saturday:
 *          $ref: "#/components/schemas/OpenOnDay"
 *        sunday:
 *          $ref: "#/components/schemas/OpenOnDay"
 *        monday:
 *          $ref: "#/components/schemas/OpenOnDay"
 *        tuesday:
 *          $ref: "#/components/schemas/OpenOnDay"
 *        wednesday:
 *          $ref: "#/components/schemas/OpenOnDay"
 *        thursday:
 *          $ref: "#/components/schemas/OpenOnDay"
 *    ApptsOpenOnDay:
 *      required: [type, min, max]
 *      properties:
 *        max:
 *          type: string
 *        min:
 *          type: string
 *        closed:
 *          type: boolean
 *    ApptsOpenOn:
 *      required: [friday,saturday,sunday,monday,tuesday,wednesday,thursday]
 *      properties:
 *        friday:
 *          $ref: "#/components/schemas/ApptsOpenOnDay"
 *        saturday:
 *          $ref: "#/components/schemas/ApptsOpenOnDay"
 *        sunday:
 *          $ref: "#/components/schemas/ApptsOpenOnDay"
 *        monday:
 *          $ref: "#/components/schemas/ApptsOpenOnDay"
 *        tuesday:
 *          $ref: "#/components/schemas/ApptsOpenOnDay"
 *        wednesday:
 *          $ref: "#/components/schemas/ApptsOpenOnDay"
 *        thursday:
 *          $ref: "#/components/schemas/ApptsOpenOnDay"
 *    Service:
 *      required: [active, name]
 *      properties:
 *        active:
 *          type: boolean
 *        name:
 *          type: string
 *          enum: [Training, Spa, Grooming, Daycare, Boarding]
 *    LocationStruct:
 *      required: [name, storeId, email, phone, address, position, hours, services, country, slug, position, concierge]
 *      properties:
 *        agreementTemplate:
 *          $ref: "#/components/schemas/AgreementTemplateStruct"
 *        vaccinations:
 *          description: id -> required (boolean)
 *          type: object
 *          additionalProperties:
 *            type: boolean
 *        storeId:
 *          description: NOt the Location ID in the database. A foreign key to another storage system.
 *          type: string
 *        name:
 *          type: string
 *        email:
 *          type: string
 *          format: email
 *          description: email to use for location when sending to customers of location.
 *        phone:
 *          type: string
 *          description: phone to use for location
 *        address:
 *          type: string
 *        position:
 *          description: Firebase GeoPoint, Not exactly an array of strings.
 *          type: array
 *          minItems: 2
 *          maxItems: 2
 *          items:
 *            type: string
 *        hours:
 *          $ref: "#/components/schemas/OpenOn"
 *        subscribed:
 *          type: boolean
 *        concierge:
 *          type: boolean
 *        services:
 *          type: object
 *          properties:
 *            boarding:
 *              $ref: "#/components/schemas/Service"
 *            spa:
 *              $ref: "#/components/schemas/Service"
 *            grooming:
 *              $ref: "#/components/schemas/Service"
 *            daycare:
 *              $ref: "#/components/schemas/Service"
 *            training:
 *              $ref: "#/components/schemas/Service"
 *        appointmentHours:
 *          $ref: "#/components/schemas/ApptsOpenOn"
 *        country:
 *          type: string
 *        credentials:
 *          description: Executive Credential Keys
 *          type: array
 *          items:
 *            type: string
 *            format: uuid
 *        slug:
 *          description: Wordpress URL Plug
 *          type: string
 *  responses:
 *  requestBodies:
 *  parameters:
*/

import { AgreementTemplateStruct } from './agreement';
import { AptService} from './appointment';
import { AccountAccess } from './account';

import firebase from 'firebase';
export interface LocationStruct {
	name: string,
	slug: string,
	email: string,
	address: any,
	country:string,
	phone: string,
	storeId: string,
	position: firebase.firestore.GeoPoint,
	hours: StoreHoursDay[],
	appointmentHours?: AppointmentProperty,
	services: {
		[serviceCode: string]: ServicesPropertyEntry
	},
	// Vaccination ID, required as bool value: List
	vaccinations: {
		[property: string]: boolean
	},
	// Internal Object
	agreementTemplate?: AgreementTemplateStruct
	// Exec Server Credential, IDs of references
	credential: string,
	subscribed?: boolean,
	concierge: boolean,
	opening_soon?: boolean,
	fake?: boolean,
	unmatched?: number;
	automatched?: number;
    mapped?: number;

    // Petexec

    vetId?: number;
    pet_shots?: number[];
    meetgreet_serviceid?: number;

    // Where day id is the day of the week 0-6 monday - sunday
    daycare_service_settings: { [dayid: number]: DaycareServiceSettings };

    timezone?: string,
    gmtoffset?: number

}

export type DaycareServiceSettings = {
    full_day_serviceid?: number,
    half_day_serviceid?: number,
    half_day_threshold?: number
}
export type PetexecShot = {
	shotid: number,
	shotname: string,
	frequency?: string,
	isrequired?: number,
	ptid: number,
}
export type PetexecService = {
    serviceid: number,
    servicetype: string,
    servicename : string,
    [service_properties: string]: any
}
export type PetexecVet = {
    vetid: number,
    vetname: string,
    //[vet_properties]: any
}
export type ServicesPropertyEntry = {
	active: boolean,
	name: AptService
};

export type WeekdayDate = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type Weekday = {
    'monday': boolean,
    'tuesday': boolean,
    'wednesday': boolean,
    'thursday': boolean,
    'friday': boolean,
    'saturday': boolean,
    'sunday': boolean
 };
export type DayOfWeekLabel = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";


export type AppointmentProperty = {
    hours: {
        [property in keyof Weekday]: AppointmentHoursEntry
	},
    override: AppointmentHoursOverrideEntry[],
    blackout: AppointmentHoursBlackoutEntry[],
    dropOffOptions?: IDropOffTime[]
    
};

export type AppointmentHoursEntry = {
	min: string,
	max: string,
	closed: boolean
};

export type AppointmentHoursOverrideEntry = {
	date: string,
	min: string,
	max: string,
	closed: boolean
}

export type AppointmentHoursBlackoutEntry = {
    day: WeekdayDate,
    dayName: Weekday
    min: number,
    max: number
}

export type StoreHoursDay = {
	day: DayOfWeekLabel,
	open: string,
	close: string,
	closed: boolean
};


export interface LocationsProperty {
	[property: string]: {
		// PetExec Access
		access: boolean,
		name: string,
		storeId: string,
		// Executive ID
		execId?: string,
		execAccess?: AccountAccess,
		showPriority?: number,
		matchState?: MatchState
	}
}

export enum MatchState {
	Unmatched = 0,
	Automatched = 1,
	Mapped = 2
}

export interface IDropOffTime{
    hours: number;
    minutes: number;
}
