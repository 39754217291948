import { Injectable } from "@angular/core";

import { Observable, combineLatest } from "rxjs";
import { map, catchError  } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { Dropoff } from "src/models/entities";

import { DropoffStruct, DropoffStatus } from "src/models/structs";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class DropoffService extends EntityServiceAbstract<Dropoff> {

	public collection = "dropoffs";

	protected statusColorMap:object = {
		"new":			"primary",
		"arrived": 		"primary",
		"updated":		"secondary",
		"cancelled":	"danger",
		"done":			"completed",

        "pastDue":       "warning",
	};

	private queryItemsLimit = 100;

    private cutoffDate = 2;

	constructor (
		protected dbService: FirestoreService, 
		private logService: ActivityLogService
	){
		super();

	}



	public getStatusBadgeText<Dropoff>(entityObj: Dropoff, badgeTextProperty: string):string {
		
		return (entityObj[badgeTextProperty] === Dropoff.DONE) ? "completed" : entityObj[badgeTextProperty];

	}

    public getStatusColor(status: string){
        if( status === "Cancelled" ) { 
            return "danger";
        }
        if( status === "Done" ) {
            return "success";
        }
    }
	public getById(dropoffId:string):Observable<Dropoff> {

		return this.dbService.getDoc(this.collection, dropoffId).pipe(
			map((dropoffDoc) => {
				
				if(dropoffDoc.exists){
					return new Dropoff(dropoffDoc.data() as DropoffStruct, dropoffDoc.id);
				}

			})
		);

	}

	public update(dropoffId:string, updatedData:any):Promise<void> {

		if(!updatedData.updatedAt){
			
			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, dropoffId, updatedData);

	}

	public getActiveDropoffs(locationId:string, userIdToFilter:string = ""):Observable<Dropoff[]> {
		
        let date = new Date();
        date.setDate( date.getDate( ) - this.cutoffDate );

        const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
                ["dropOffTime", ">=", date],
				["status", "in", [Dropoff.NEW, Dropoff.UPDATED, Dropoff.ARRIVED]
            ]
			],
			orderByField: "dropOffTime",
			orderByDir: "desc",
			limit: this.queryItemsLimit // Increase query limit to 500 for new appointments // this.queryItemsLimit
		};


		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((dropoffs) => {
				return this.mapDocsToObjs(dropoffs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

        /*
		const activeStatuses = [Dropoff.NEW, Dropoff.UPDATED, Dropoff.ARRIVED];

		const dropoffObservables = [];

		activeStatuses.forEach((status: DropoffStatus) => {

			let queryOptions:queryOptions = {
				conditions: [
					["locationId", "==", locationId],
					["status", "==", status]
				],
				orderByField: "dropOffTime",
				orderByDir: "desc",
				limit: this.queryItemsLimit
			}

			if(userIdToFilter){
				queryOptions.conditions.push(["userId", "==", userIdToFilter]);
			}

			dropoffObservables.push(this.dbService.getCollection(this.collection, queryOptions));

		});

		return combineLatest(dropoffObservables).pipe(
			map((combinedActiveDropoffDocs) => {
				
				return this.mapDocsToObjs([].concat(...combinedActiveDropoffDocs))
					.sort((dropoffA:Dropoff, dropoffB:Dropoff):number => {
                        // sort by dropoff time descending (newest first)
                        return dropoffB.dropOffTime.toMillis() - dropoffA.dropOffTime.toMillis();
						//return (dropoffA.dropOffTime.toDate() > dropoffB.dropOffTime.toDate()) ? -1 : 1;
					});

			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
        */
	}

    public getActiveCancelledDropoffs( locationId:string, userIdToFilter:string = "" ):Observable<Dropoff[]> {
        
        let date = new Date();
        date.setDate( date.getDate( ) - this.cutoffDate );

        const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
                ["dropOffTime", ">=", date],
				["status", "in", [Dropoff.NEW, Dropoff.UPDATED, Dropoff.ARRIVED, Dropoff.CANCELLED],
            ]
			],
			orderByField: "dropOffTime",
			orderByDir: "desc",
			limit: this.queryItemsLimit // Increase query limit to 500 for new appointments // this.queryItemsLimit
		};


		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((dropoffs) => {
				return this.mapDocsToObjs(dropoffs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

        /*
        const activeStatuses = [Dropoff.NEW, Dropoff.UPDATED, Dropoff.ARRIVED, Dropoff.CANCELLED];

		const dropoffObservables = [];

		activeStatuses.forEach((status: DropoffStatus) => {

			let queryOptions:queryOptions = {
				conditions: [
					["locationId", "==", locationId],
					["status", "==", status]
				],
				orderByField: "dropOffTime",
				orderByDir: "desc",
				limit: this.queryItemsLimit
			}

			if(userIdToFilter){
				queryOptions.conditions.push(["userId", "==", userIdToFilter]);
			}

			dropoffObservables.push(this.dbService.getCollection(this.collection, queryOptions));

		});

		return combineLatest(dropoffObservables).pipe(
			map((combinedActiveDropoffDocs) => {
				return this.mapDocsToObjs([].concat(...combinedActiveDropoffDocs))
					.sort((dropoffA:Dropoff, dropoffB:Dropoff):number => {
                        console.log( dropoffA.dropOffTime )
						return ( dropoffA.dropOffTime.toDate() > dropoffB.dropOffTime.toDate()) ? 1 : -1;
					});

			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

        */
    }

	public getDoneDropoffs(locationId:string, userIdToFilter:string = ""):Observable<Dropoff[]> {

		const locationIdCondition:any = ["locationId", "==", locationId];
		const orderByField = "updatedAt";
		const orderByDir = "desc";

		let cancelledDropoffsQueryOptions:queryOptions = {
			conditions: [
				locationIdCondition,
				["status", "==", Dropoff.CANCELLED]
			],
			orderByField: orderByField,
			orderByDir: orderByDir,
			limit: this.queryItemsLimit,
		};

		let doneDropoffsQueryOptions:queryOptions = {
			conditions: [
				locationIdCondition,
				["status", "==", Dropoff.DONE]
			],
			orderByField: orderByField,
			orderByDir: orderByDir,
			limit: this.queryItemsLimit,
		};

		if(userIdToFilter){
			cancelledDropoffsQueryOptions.conditions.push(["userId", "==", userIdToFilter]);
			doneDropoffsQueryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		let cancelledDropoffsObs = this.dbService.getCollection(this.collection, cancelledDropoffsQueryOptions);

		let doneDropoffsObs = this.dbService.getCollection(this.collection, doneDropoffsQueryOptions);
		
		return combineLatest(cancelledDropoffsObs, doneDropoffsObs).pipe(
			map(([cancelledDropoffDocs, doneDropoffDocs]) => {
				
				return this.mapDocsToObjs(cancelledDropoffDocs.concat(doneDropoffDocs))
					.sort((dropoffA:Dropoff, dropoffB:Dropoff):number => {
						return (dropoffA[orderByField] > dropoffB[orderByField]) ? -1 : 1;
					});

			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw(error);
			})
		);

	}

	public markDropoffDone(dropoffId: string, previousStatus: string):Promise<any> {

		let dropoffUpdatePromise = this.update(dropoffId, {status: Dropoff.DONE, previousStatus: previousStatus});

		let logEntryPromise = this.logService.addLogEntry(this.collection, dropoffId, status, "");

		return Promise.all([dropoffUpdatePromise, logEntryPromise]);

	}

    public markDropoffInProgress( dropoffId :string, previousStatus: string  ){ 
        let dropoffUpdatePromise = this.update(dropoffId, {status: Dropoff.DONE,previousStatus:previousStatus,sendInProgressNotification: true});

		let logEntryPromise = this.logService.addLogEntry(this.collection, dropoffId, status, "");

		return Promise.all([dropoffUpdatePromise, logEntryPromise]);
    }

    public getCompletedDropOffs(locationId:string) {

		const locationIdCondition:any = ["locationId", "==", locationId];
		const orderByField = "dropOffTime";
		const orderByDir = "desc";

		let date = new Date();
        date.setDate( date.getDate( ) - this.cutoffDate );

		let doneDropoffsQueryOptions:queryOptions = {
			conditions: [
				locationIdCondition,
				["status", "==", Dropoff.DONE],
                ["dropOffTime", ">=", date]
			],
            
			orderByField: orderByField,
			orderByDir: orderByDir,
			limit: this.queryItemsLimit,
            startAt: null
		};

		return this.dbService.getCollection(this.collection, doneDropoffsQueryOptions);
		
	}

    

    public getCancelledDropOffs( locationId ){ 
        const locationIdCondition:any = ["locationId", "==", locationId];
		const orderByField = "dropOffTime";
		const orderByDir = "desc";

        let date = new Date();
        date.setDate( date.getDate( ) - this.cutoffDate );

		let doneDropoffsQueryOptions:queryOptions = {
			conditions: [
				locationIdCondition,
				["status", "==", Dropoff.CANCELLED],
                ["dropOffTime", ">=", date]
			],
            
			orderByField: orderByField,
			orderByDir: orderByDir,
			limit: this.queryItemsLimit,
		};

		return this.dbService.getCollection(this.collection, doneDropoffsQueryOptions);
    }

	private mapDocsToObjs(dropoffDocs:firebase.firestore.QueryDocumentSnapshot[]):Dropoff[] {

		return dropoffDocs.map((dropoffDoc) => {
					
			const dropoff = new Dropoff(dropoffDoc.data() as DropoffStruct, dropoffDoc.id);

			return dropoff;

		});

	}

}