import { Component, Inject, Input, Output, EventEmitter, forwardRef, ElementRef } from "@angular/core";

import { BaseWidget, NgAisInstantSearch } from "angular-instantsearch";

import { connectSearchBox } from "instantsearch.js/es/connectors";

@Component({
	selector: "custom-searchbar-widget",
	templateUrl: "custom-searchbar-widget.component.html",
	styleUrls: ["./custom-searchbar-widget.component.scss"],
})

export class CustomSearchbarWidgetComponent extends BaseWidget {

	public static Instance: {[id: string]: CustomSearchbarWidgetComponent} = {};
	@Input() search_id: string;
	@Input() placeholder: string;

	@Output("onChange") changeEvent: EventEmitter<string> = new EventEmitter<string>();
	@Output("onSearch") searchEvent: EventEmitter<string> = new EventEmitter<string>();

	@Input() queryValue: string = "";

	public hasFocus: boolean = false;

	public state: {
		query: string;
		refine: Function;
		clear: Function;
		isSearchStalled: boolean;
		widgetParams: object;
	};

	constructor(
		public element: ElementRef,
		@Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchParent: NgAisInstantSearch
	) {
		super("CustomSearchbar");
	}

	public ngOnInit():void {

		window.adminlog.print("init");
		this.createWidget(connectSearchBox, {
			// instance options
		});

		super.ngOnInit();

	}
	public ngAfterViewInit(): void {
		CustomSearchbarWidgetComponent.Instance[this.search_id || 'default'] = this;
	}

	public ngOnDestroy(): void {
		// CustomSearchbarWidgetComponent.Instance = null;
	}

	public getSearchQuery():string {
		return this.queryValue;
	}

	public setSearchQuery(value: string):void {

		this.queryValue = value;

	}


	public onChange():void {

		this.state.refine(this.queryValue);

		this.changeEvent.emit(this.queryValue);

	}

}
