import { Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";
import { map, catchError, take } from "rxjs/operators";
import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { UserService } from "./user.service";
import { AccountService } from "./account.service";
import { PetService } from "./pet.service";
import { MeetGreetAppointmentService } from "./meet-greet-appointment.service";

import { LeadStruct, LeadState, LeadTag, LeadSource } from "src/models/structs";

import { Lead } from "src/models/entities";

import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { IEntityMap } from './interfaces';

@Injectable({
    providedIn: "root"
})

export class InfluencerService extends EntityServiceAbstract<Lead> implements IEntityMap<Lead> {

    public collection: string = "leads";

    public enumLabels: {
        state: {
            [enumVal: number]: string
        },
        source: {
            [enumVal: number]: string
        },
        tag: {
            [enumVal: number]: string
        }
    };

    protected statusColorMap: object = {};

    public queryItemsLimit = 300;

    constructor(
        protected dbService: FirestoreService,
        private logService: ActivityLogService,
        private userService: UserService,
        private accountService: AccountService,
        private petService: PetService,
        private meetGreetAppointmentService: MeetGreetAppointmentService,
        private authService: AuthService,
        private http: HttpClient
    ) {

        super();

        console.log('influencerService loaded');

        this.enumLabels = {
            state: {},
            tag: {},
            source: {},
        };

        this.enumLabels.state[LeadState.New] = "New Lead";
        this.enumLabels.state[LeadState.InProgress] = "In Progress";
        this.enumLabels.state[LeadState.FollowUp] = "Follow Up";
        this.enumLabels.state[LeadState.Confirmed] = "Confirmed";
        this.enumLabels.state[LeadState.Done] = "Completed";
        this.enumLabels.state[LeadState.Archived] = "Archived";

        this.enumLabels.tag[LeadTag.GeneralInquiry] = "General Inquiry";
        this.enumLabels.tag[LeadTag.NewLead] = "New Lead";
        this.enumLabels.tag[LeadTag.Renewed] = "Renewed";
        this.enumLabels.tag[LeadTag.ReEvaluated] = "Re-evaluated";
        this.enumLabels.tag[LeadTag.InitInvalid] = "Invalid";
        this.enumLabels.tag[LeadTag.Progress1] = "In Progress #1";
        this.enumLabels.tag[LeadTag.Progress2] = "In Progress #2";
        this.enumLabels.tag[LeadTag.Progress3] = "In Progress #3";
        this.enumLabels.tag[LeadTag.FollowUp] = "Follow Up";
        this.enumLabels.tag[LeadTag.FollowUp_Unresponsive] = "Follow Up (Unresponsive)";
        this.enumLabels.tag[LeadTag.DNC_Unresponsive] = "DNC";
        this.enumLabels.tag[LeadTag.NoShow] = "No Show";
        this.enumLabels.tag[LeadTag.NoPass] = "Re-evaluated";
        this.enumLabels.tag[LeadTag.ProgressInvalid] = "Invalid";
        this.enumLabels.tag[LeadTag.Confirmed] = "Confirmed";
        this.enumLabels.tag[LeadTag.Done] = "Completed";
        this.enumLabels.tag[LeadTag.foundersOffer] = "Founders' Offer";
        this.enumLabels.tag[LeadTag.waitlistOffer] = "Waitlist";
        this.enumLabels.tag[LeadTag.priorityOffer] = "Priority Offer";

        this.enumLabels.source[LeadSource.Influencer] = "Influencer";
    }

    /**
     * Get limited number of influencer leads for display on page
     * @param locationId 
     * @returns 
     */
    public getInfluencerLeads(locationId: string): Observable<Lead[]> {
        console.log('** getInfluencerLeads called')
        const queryOptions: queryOptions = {
            conditions: [
                ["location", "==", locationId],
                ["source", "==", LeadSource.Influencer],
            ],
            orderByField: "createdAt",
            limit: this.queryItemsLimit
        };

        return this.dbService.getCollection(this.collection, queryOptions).pipe(
            map((leadDocs) => {
                return leadDocs.map((leadDoc) => {
                    const lead = new Lead(leadDoc.data() as LeadStruct, leadDoc.id);
                    const fullName = (lead.user) ? `${lead.user.firstName} ${lead.user.lastName}` : '';
                    lead.mgID = (lead.meetGreetTemplates && lead.meetGreetTemplates[0] && lead.meetGreetTemplates[0].meetGreetId) ? lead.meetGreetTemplates[0].meetGreetId : '';
                    console.log(lead.lead_data);
                    for (let datapoint of lead.lead_data) {
                        if (datapoint.adminLabel == "Influencer Code") {
                            lead.influencerCode = datapoint.value;
                        } else if (datapoint.adminLabel == "Influencer Name") {
                            lead.influencerName = datapoint.value;
                        } else if (datapoint.adminLabel == "Influencer ID") {
                            lead.influencerID = datapoint.value;
                        } else if (datapoint.adminLabel == "Promo offer:") {
                            lead.offer = datapoint.value;
                        }
                    };
                    console.log(lead);
                    return lead;

                });
            }),
            catchError((error) => {
                window.adminlog.print(error);
                throw error;
            })
        );
    }

    public mapDocsToObjs(leadDocs: firebase.firestore.QueryDocumentSnapshot[]): Lead[] {
        return leadDocs.map((leadDoc) => {
            const lead = new Lead(leadDoc.data() as LeadStruct, leadDoc.id);
            return lead;
        });
    }

}
