import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { faCheck } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-appointment-change-status',
  templateUrl: './appointment-change-status.component.html',
  styleUrls: ['./appointment-change-status.component.scss'],
})
export class AppointmentChangeStatusComponent implements OnInit {

    @Input() currentStatus;

    public faCheck = faCheck;

    public selected = "onhold";

    constructor(
        private modalController: ModalController,
    ) { }

    ngOnInit() {
        console.log( this.currentStatus.replace(/\s/g, "").toLocaleLowerCase() ); 
        this.selected = this.currentStatus.replace(/\s/g, "").toLocaleLowerCase()
    }

    selectionChange( status ){ 
        this.selected = status;
    }

    public approveAppt(){ 
        this.modalController.dismiss({
            dismiss: false,
            selected: this.selected 
        }); 
    }

    public dismiss(){ 

        this.modalController.dismiss({
            dismiss:true,
            selected: null
        });
    }

}
