
import { AccessRequestStruct } from '../structs/access-request';
import { RequestsAbstract } from './requests-abstract';
import firebase from 'firebase';

export class AccessRequest extends RequestsAbstract<AccessRequest> implements AccessRequestStruct{
    locationId: string;
    firstName: string;
    lastName: string;
    email?: string;
    userId?: string;
    phoneNumber?: string;
    customerMessage?: string;
    status: import("../structs/access-request").AccessRequestStatus;
    action?: import("../structs/access-request").AccessRequestAction;
    accountId?: string;
    accountAccess?: import("../structs").AccountAccess;
    leadId?: string;
    inviteId?: string;
    memos?: import("../structs").MemoStruct[];
    log?: import("../structs/access-request").LogStruct[];
    createdAt?: firebase.firestore.Timestamp;
    updatedAt?: firebase.firestore.Timestamp;
    timezone?: string;

    public constructor(data?: any, id?: string) {
        super(data, id);
    }

}
