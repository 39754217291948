import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavOption } from 'src/app/settings/definers/NavOption';

@Injectable({
  providedIn: 'root'
})
export class SettingsMobileNavService {

    public navOptions = new BehaviorSubject<Array<NavOption>>([]);
    public mobileNavOpened = new BehaviorSubject<boolean>(false);

    constructor() { }


    public setNavOptions( navOptions ){ 
        this.navOptions.next(navOptions);
    }
    public getNavOptions():Observable<Array<NavOption>>{
        return this.navOptions.asObservable();
    }

    public getMobileNavOpenStatus():Observable<boolean>{
        return this.mobileNavOpened.asObservable();
    }
    public setMobilveNavOpenStatus(status: boolean):void{
        this.mobileNavOpened.next(status);
    }


}
