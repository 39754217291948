import { Injectable } from "@angular/core";

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from "@angular/router";

import { AuthService } from '../auth.service';


@Injectable({
	providedIn: "root",
})

export class AuthGuard implements CanActivateChild, CanActivate {
	public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.canActivate(childRoute, state);
    }

	private currUrl: string;

	constructor(private authService: AuthService, private router: Router) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean> {
		this.currUrl = state.url;

		return this.checkUserLoggedIn();

	}

	private async checkUserLoggedIn():Promise<boolean> {
		
		return this.authService.getLoggedInUser()
					.then((admin) => {
						return true;
					})
					.catch((error) => {

						this.authService.redirectUrl = this.currUrl;

						this.router.navigate(['/login']);

						return false;
						
					});
		
	}

}
