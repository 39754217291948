import { Injectable } from "@angular/core";

import { HttpHeaders, HttpClient } from "@angular/common/http";

import { Observable, combineLatest, of, BehaviorSubject, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { FirestoreService, queryOptions } from "./firestore.service";
import { AuthService } from "./auth.service";

import { LocationStruct, PetexecShot } from "src/models/structs";
import { Location } from "src/models/entities";
import { PetexecService, PetexecVet } from '../models/structs/location';
import { IDatum } from './interfaces/datum';
import { DatumService, Datum } from './datum.service';
import { ActivityLogService } from "./activity-log.service";
import { collections } from "src/models/collections";


@Injectable({
	providedIn: "root"
})

export class LocationService {

	private locations = {};
	
	public collection = "locations";

	private currentUserLocation: Location = null;
	
	public currUserLocationObs: BehaviorSubject<Location> = new BehaviorSubject<Location>(null);

	public vetsDatum: IDatum<PetexecVet[]>;
	public daycareServiceDatum: IDatum<PetexecService[]>;
	public shotsDatum: IDatum<PetexecShot[]>;

	constructor(
        private dbService: FirestoreService,
        private authService: AuthService,
        private http: HttpClient, 
        private datumService: DatumService,
        private activityLogService: ActivityLogService
        ) {
		this.vetsDatum = this.datumService.createDatum<PetexecVet[]>(this, this._getVets)
		this.shotsDatum = this.datumService.createDatum<PetexecShot[]>(this, this._getVaccinationShots);
		this.daycareServiceDatum = this.datumService.createDatum<PetexecService[]>(this, this._getDaycareServices);
		console.log("location datums", this.vetsDatum, this.daycareServiceDatum, this.shotsDatum);
        
	}

	public getCurrUserLocation():Promise<Location> {

		return new Promise((resolve, reject) => {

			if(this.currentUserLocation){
				resolve(this.currentUserLocation);

			}else{

				this.setCurrUserLocation()
					.then(() => {
						resolve(this.currentUserLocation);
					})
					.catch((error) => {
						reject(error);
					});

			}

		});

	}
    
    public setCurrUserLocationToNull(): void { 
        this.currentUserLocation = null;
    }

	public getCurrUserLocationObs():Observable<Location> {

		return this.currUserLocationObs.asObservable();

	}

	public getById(locationId:string):Promise<Location> {

		return new Promise((resolve, reject) => {

			if(this.locations.hasOwnProperty(locationId)){
				
				resolve(this.locations[locationId]);
			
			}else{

				this.dbService.getDocSnapshot(this.collection, locationId)
					.then((result) => {

						if(result.exists){
						
							let location = new Location(result.data() as LocationStruct, locationId);

							this.locations[locationId] = location;

							resolve(location);

						}else{
							reject();
						}

					})
					.catch((error) => {
						reject(error);
					});

			}

		});

	}

	public getByIdObs(locationId: string):Observable<Location> {

		return this.dbService.getDoc(this.collection, locationId).pipe(
			map((locationDoc) => {

				return new Location(locationDoc.data() as LocationStruct, locationDoc.id);
				
			})
		);

	}

	public getLocations():Observable<Location[]> {

		return this.dbService.getCollection(this.collection, {orderByField: "name"} as queryOptions).pipe(
			map((locationDocs) => {
				return locationDocs.map((locationDoc) => {
					
					const location = new Location(locationDoc.data() as LocationStruct, locationDoc.id);

					this.locations[locationDoc.id] = location;

					return location;

				});
			})
		);

	}

	public getLocationsOnce():Promise<Location[]> {

		return this.dbService.getCollectionOnce(this.collection, {orderByField: "name"} as queryOptions).then((locationDocs) => {

			return locationDocs.map((locationDoc) => {
					
				const location = new Location(locationDoc.data() as LocationStruct, locationDoc.id);

				this.locations[locationDoc.id] = location;

				return location;

			});

		});

	}

	public getUserLocations():Observable<Location[]> {

		const allLocationsObs = this.getLocations();

		const userLocationIdsObs = of(this.authService.user.locations);

		return combineLatest(allLocationsObs, userLocationIdsObs).pipe(
			map(([allLocations, userLocationIds]) => {
				
				const userLocations = allLocations.filter((location) => {
					return userLocationIds.includes(location._id);
				})

				return userLocations;

			})
		);

	}

	protected async _getVaccinationShots(): Promise<PetexecShot[]> {
		if (!this.currentUserLocation.id) throw new Error("Could not retrieve shots. No location set");
		const locationId = this.currentUserLocation.id;
		let endpointUrl: string = environment.firebaseFunctions.getVaccinationShots.replace("{locationId}", locationId);
		console.log("VACCINATION SHOTS", locationId);
		return new Promise<PetexecShot[]>((resolve, reject) => {
			this.http.get(endpointUrl)
				.subscribe(
					(result) => {
						resolve(<PetexecShot[]>((result as any).shots));
					},
					(error) => {
						window.adminlog.print(error);
						reject(error);
					}
				);
		});

	}
	public getVaccinationShots(): Promise<PetexecShot[]> {
		return this.shotsDatum.getNewData()
	}
    protected async _getDaycareServices(): Promise<PetexecService[]> {
		if (!this.currentUserLocation.id) throw new Error("Could not retrieve daycare services. No location set");
		const locationId = this.currentUserLocation.id;
		let endpointUrl: string = environment.firebaseFunctions.getDaycareServices.replace("{locationId}", locationId);

		return new Promise<PetexecService[]>((resolve, reject) => {
			this.http.get(endpointUrl)
				.subscribe(
					(result: any) => {
						if (result.success) {
							resolve(<PetexecService[]>result.daycareservices);

						} else {
							console.error(result);
							reject(result.message);
						}
						
					},
					(error) => {
						window.adminlog.print(error);
						reject(error);
					}
				);
		});
	}
	public getDaycareServices(): Promise<PetexecService[]> {
		return this.daycareServiceDatum.getNewData()
	}
	protected async _getVets(): Promise<PetexecVet[]> {
		if (!this.currentUserLocation.id) throw new Error("Could not retrieve vets. No location set");
		const locationId = this.currentUserLocation.id;
		let endpointUrl: string = environment.firebaseFunctions.getVets.replace("{locationId}", locationId);

		return new Promise<PetexecVet[]>((resolve, reject) => {
			this.http.get(endpointUrl)
				.subscribe(
					(result: any) => {
						if (result.vets) {
							resolve(<PetexecVet[]>result.vets);

						} else {
							console.error(result);
							reject(result.message);
						}

					},
					(error) => {
						window.adminlog.print(error);
						reject(error);
					}
				);
		});
	}
	public getVets(): Promise<PetexecVet[]> {
		return this.vetsDatum.getNewData();
	}



	public update(locationId: string, updatedData: any):Promise<void> {
        //this.activityLogService.addLogEntry("locations", locationId, "Update", "bulk location update", {
        //    data : updatedData,
        //    bulkLocationUpdate : true
        //});
		return this.dbService.updateDoc(this.collection, locationId, updatedData);

	}

	public setCurrUserLocation(locationId:string = ""):Promise<Location> {

		return new Promise((resolve, reject) => {

			const user = this.authService.user;

			if(user !== undefined){
				
				let newCurrUserLocationId = "";

				if(locationId !== ""){
					newCurrUserLocationId = locationId;
				}else if((user.defaultLocation === "") && (user.locations.length > 0)){
					newCurrUserLocationId = user.locations[0];
				}else{
					newCurrUserLocationId = user.defaultLocation;
				}
				
				if(newCurrUserLocationId !== "" && (!this.currentUserLocation || (newCurrUserLocationId !== this.currentUserLocation._id))){

					this.getById(newCurrUserLocationId)
						.then((defaultLocationObj) => {
							console.log( "asdf", defaultLocationObj );
							this.currentUserLocation = defaultLocationObj;
							this.currUserLocationObs.next(defaultLocationObj);
							
							resolve(this.currentUserLocation);

						})
						.catch((error) => {
							window.adminlog.print("Couldn't get location: " + error);
							reject();
						});
		
				}else{
					window.adminlog.print("Admin doesn't have default location");
					reject();
				}

			}else{
				window.adminlog.print("No logged in admin object set");
				reject();
			}

		});

    }

    // vaccination release form
    public async getVaccinationReleaseForm(locationId: string): Promise<any> {
        return new Promise((resolve, reject) => {

            const queryOptions: queryOptions = {
                conditions: [
                    [ "locationId", "==", locationId ]
                ],
                limit: 1	
            };

            this.dbService.getCollectionOnce( collections.vaccineReleaseFormTemplate, queryOptions )
				.then((AgreementRecords) => {
                    resolve( AgreementRecords );
                    AgreementRecords
				})
				.catch((error) => {
					reject(error);
				});

        });
    }
    public async saveVaccinationReleaseForm( docData, id:string ):Promise<any>{
        return this.dbService.setDoc( collections.vaccineReleaseFormTemplate, id, docData ); 
    }

    // Pet Parent Agreement
    public async getPetParentAgreementTemplates(locationId: string):Promise<any> {

        return new Promise((resolve, reject) => {

            const queryOptions: queryOptions = {
                conditions: [
                    [ "locationId", "==", locationId ]
                ],
                limit: 1	
            };
		
			this.dbService.getCollectionOnce( 'templateagreements', queryOptions )
				.then((AgreementRecords) => {
                    resolve( AgreementRecords );
                    AgreementRecords
				})
				.catch((error) => {
					reject(error);
				});

        });
        
    }

    public async savePetParentAgreement( docData, id:string ):Promise<any>{
        return this.dbService.setDoc( 'templateagreements', id, docData ); 
    }

    public setDataToCurrUserLocationObs(defaultLocationObj){
        this.currUserLocationObs.next(defaultLocationObj);
    }


}
