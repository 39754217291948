import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { ActivityLogService } from 'src/services/activity-log.service';
import { LocationService } from 'src/services/location.service';

@Component({
  selector: 'app-cancellation-policy-link-popup',
  templateUrl: './cancellation-policy-link-popup.component.html',
  styleUrls: ['./cancellation-policy-link-popup.component.scss'],
})
export class CancellationPolicyLinkPopupComponent implements OnInit {

    @Input() cancellationLink;
    @Input() locationId;

    public cancellationPolicyTextArea: string;


    constructor(
        private modalController: ModalController,
        public locationService: LocationService,
        public activityLogService: ActivityLogService
    ) { }

    ngOnInit() {
        console.log( "currentmsg" , this.cancellationLink );
        if( this.cancellationLink != null || this.cancellationLink != undefined ) { 
            if( this.cancellationLink.length > 0 ){ 
                this.cancellationPolicyTextArea = this.cancellationLink;
            }
        }
    }
    public dismiss() { 
        this.modalController.dismiss({
            "noAction": true,
        });
    }

    update(){ 
        if( this.cancellationPolicyTextArea === null || this.cancellationPolicyTextArea === undefined ) { 
            this.cancellationPolicyTextArea = "";
        }
        //this.activityLogService.addLogEntry("locations", this.locationId, "Update", "update to cancellationPolicyLink", {cancelationPolicyLink: this.cancellationPolicyTextArea});
        //this.locationService.update( this.locationId, {cancelationPolicyLink: this.cancellationPolicyTextArea} );
        this.modalController.dismiss( {
            link: this.cancellationPolicyTextArea
        } );

    }
}
