import { Component, Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, FormArray } from '@angular/forms';

@Component({
	selector: 'app-rshft-form',
	templateUrl: './rshft-form.component.html',
	styleUrls: ['./rshft-form.component.scss'],
})
export class RshftFormComponent {

	@Input() formGroup: FormGroup;
	
	@Output() submitted = new EventEmitter();

	constructor() { }

	onSubmit():void {

		this.markChildrenAsTouched(this.formGroup);
		this.submitted.emit();
		//if(this.formGroup.valid){
		//	this.submitted.emit();
		//}

	}

	public markChildrenAsTouched(group: FormGroup | FormArray) {

		for(let field in group.controls){

			group.get(field).markAsTouched();

		}

	} 

}
