import { Component, OnInit, Input } from '@angular/core';
import { NavParams, Events, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-options-popover',
  templateUrl: './options-popover.component.html',
  styleUrls: ['./options-popover.component.scss'],
})
export class OptionsPopoverComponent implements OnInit {
    public options: { name: string }[] = [{ name: 'edit' }, { name: 'remove' }];
  constructor(
		public navParams: NavParams,
		public ionEventBus: Events,
		public popoverController: PopoverController
  ) { }

    ngOnInit(): void {
        window.adminlog.print("options navparam", this.navParams.get('options'));
        if(this.navParams.get('options'))this.options = this.navParams.get('options');
      //if (this.navParams.data.options && this.navParams.data.options.length > 0) {
      //    window.adminlog.print('options', this.navParams.data.options);
      //    this.options = this.navParams.data.options;
      //}

	}

  public optionSelected(name:string):void {

      this.ionEventBus.publish("option-selected", name);

		this.popoverController.dismiss();

	}

}
