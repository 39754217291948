import { Injectable } from "@angular/core";

import { combineLatest, Observable /*, zip */ } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { UserService } from "./user.service";
import { AccountService } from "./account.service";
import { PetService } from "./pet.service";
import { MeetGreetAppointmentService } from "./meet-greet-appointment.service";

import { LeadStruct, LeadState, LeadTag, LeadSource, PetStruct, MeetGreetAppointmentStruct, LeadMeetGreetTemplate } from "src/models/structs";

import { Lead, User, Account, MeetGreetAppointment } from "src/models/entities";

import * as moment from "moment-timezone";
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IEntityMap } from './interfaces';
import { collections } from "src/models/collections";

@Injectable({
	providedIn: "root"
})

export class LeadService extends EntityServiceAbstract<Lead> implements IEntityMap<Lead> {

	public collection: string = "leads";

	public enumLabels: {
		state: {
			[enumVal: number]: string
		},
		source: {
			[enumVal: number]: string
		},
		tag: {
			[enumVal: number]: string
		}
	};

	protected statusColorMap: object = {};

	private scoreDictionaryTemplate: any = {
		0: 5,
		1: 9,
		2: 10,


		127: 0,
		128: 10,
		129: 9,
		130: 8,
		131: 9,
		132: 6,
		133: 0,
		134: 5,
		255: 0,

		256: 5,
        300: 5,
	};

	private scoreSheet: any = {};

	public queryItemsLimit = 150;

	constructor(
		protected dbService: FirestoreService,
		private logService: ActivityLogService,
		private userService: UserService,
		private accountService: AccountService,
		private petService: PetService,
		private meetGreetAppointmentService: MeetGreetAppointmentService,
		private authService: AuthService, 
        private http: HttpClient
	) {

		super();

		this.enumLabels = {
			state: {},
			tag: {},
			source: {},
		};

		this.enumLabels.state[LeadState.New] = "New Lead";
		this.enumLabels.state[LeadState.InProgress] = "In Progress";
		this.enumLabels.state[LeadState.FollowUp] = "Follow Up";
		this.enumLabels.state[LeadState.Confirmed] = "Confirmed";
		this.enumLabels.state[LeadState.Done] = "Completed";
		this.enumLabels.state[LeadState.Archived] = "Archived";

		this.enumLabels.tag[LeadTag.GeneralInquiry] = "General Inquiry";
		this.enumLabels.tag[LeadTag.NewLead] = "New Lead";
		this.enumLabels.tag[LeadTag.Renewed] = "Renewed";
		this.enumLabels.tag[LeadTag.ReEvaluated] = "Re-evaluated";
		this.enumLabels.tag[LeadTag.InitInvalid] = "Invalid";
		this.enumLabels.tag[LeadTag.Progress1] = "In Progress #1";
		this.enumLabels.tag[LeadTag.Progress2] = "In Progress #2";
		this.enumLabels.tag[LeadTag.Progress3] = "In Progress #3";
		this.enumLabels.tag[LeadTag.FollowUp] = "Follow Up";
		this.enumLabels.tag[LeadTag.FollowUp_Unresponsive] = "Follow Up (Unresponsive)";
		this.enumLabels.tag[LeadTag.DNC_Unresponsive] = "DNC";
		this.enumLabels.tag[LeadTag.NoShow] = "No Show";
		this.enumLabels.tag[LeadTag.NoPass] = "Re-evaluated";
		this.enumLabels.tag[LeadTag.ProgressInvalid] = "Invalid";
		this.enumLabels.tag[LeadTag.Confirmed] = "Confirmed";
		this.enumLabels.tag[LeadTag.Done] = "Completed";
        this.enumLabels.tag[LeadTag.foundersOffer] = "Founders' Offer";
        this.enumLabels.tag[LeadTag.waitlistOffer] = "Waitlist";
        this.enumLabels.tag[LeadTag.priorityOffer] = "Priority Offer";

		this.enumLabels.source[LeadSource.WebInquiry] = "Web Inquiry";
		this.enumLabels.source[LeadSource.NoShow] = "No Show";
		this.enumLabels.source[LeadSource.WebMeetGreet] = "Web M&G";
		this.enumLabels.source[LeadSource.LocalEvent] = "Local Event";
		this.enumLabels.source[LeadSource.PerksForPups] = "Perks For Pups";
		this.enumLabels.source[LeadSource.Referral] = "Referral";
		this.enumLabels.source[LeadSource.PhoneCall] = "Phone Call";
		this.enumLabels.source[LeadSource.SearchAd] = "Search Ad";
		this.enumLabels.source[LeadSource.SocialAd] = "Social Ad";
		this.enumLabels.source[LeadSource.WalkIn] = "Walk In";
		this.enumLabels.source[LeadSource.Other] = "Other";
		this.enumLabels.source[LeadSource.NoPass] = "Meet and Greet: No Pass, Follow Up";
		this.enumLabels.source[LeadSource.AppRequest] = "App Request";
        this.enumLabels.source[LeadSource.ReacquisitionCampaign] = "Reacquisition Campaign";
        this.enumLabels.source[LeadSource.foundersOffer] = "Founders' Offer";
        this.enumLabels.source[LeadSource.waitlistOffer] = "Founders' Waitlist";
        this.enumLabels.source[LeadSource.NationalCampaignSocial] = "National Campaign Social";
        this.enumLabels.source[LeadSource.NationalCampaignSearch] = "National Campaign Search";
        this.enumLabels.source[LeadSource.PhoneSearch] = "Phone Call Search";
        this.enumLabels.source[LeadSource.PhoneSocial] = "Phone Call Social";
        this.enumLabels.source[LeadSource.PartnersPromo] = "Partner Promo";
        this.enumLabels.source[LeadSource.VirtualTour] = "Virtual Tour";
        this.enumLabels.source[LeadSource.RegionalMicrosite] = "Regional Microsite";
        this.enumLabels.source[LeadSource.Abandoned] = "Abandoned Form";
        this.enumLabels.source[LeadSource.AmazonLeadAd] = "Amazon Lead Ad";
        this.enumLabels.source[LeadSource.AmazonAd] = "Amazon Ad";
        this.enumLabels.source[LeadSource.PreferredPartner] = "Preferred Partner";

		this.statusColorMap[LeadTag.GeneralInquiry] = "tertiary";
		this.statusColorMap[LeadTag.NewLead] = "primary";
		this.statusColorMap[LeadTag.Renewed] = "tertiary";
		this.statusColorMap[LeadTag.NoShow] = "tertiary";
		this.statusColorMap[LeadTag.NoPass] = "secondary";
		//this.statusColorMap[LeadTag.GeneralInquiry]
		this.statusColorMap[LeadTag.InitInvalid] = "primary-muted";
		this.statusColorMap[LeadTag.Progress1] = "secondary";
		this.statusColorMap[LeadTag.Progress2] = "secondary";
		this.statusColorMap[LeadTag.Progress3] = "secondary";
		this.statusColorMap[LeadTag.FollowUp] = "secondary";
		this.statusColorMap[LeadTag.FollowUp_Unresponsive] = "secondary";
		this.statusColorMap[LeadTag.DNC_Unresponsive] = "danger";
		this.statusColorMap[LeadTag.ProgressInvalid] = "primary-muted";
		this.statusColorMap[LeadTag.Confirmed] = "success";
		this.statusColorMap[LeadTag.Done] = "completed";

		this.fillScoreSheet(300);

	}

	public getStatusColor(status: string): string {
		window.adminlog.print('status', status, this.statusColorMap[status]);
		return this.statusColorMap[status] || super.getStatusColor(status);

	}

	public getStatusBadgeText<Lead>(entityObj: Lead, badgeTextProperty: string): string {
		return (this.enumLabels.tag.hasOwnProperty(entityObj[badgeTextProperty])) ? this.enumLabels.tag[entityObj[badgeTextProperty]] : "";
	}

	public getById(leadId: string): Observable<Lead> {

		return this.dbService.getDoc(this.collection, leadId).pipe(
			map((leadDoc) => {

				if (leadDoc.exists) {
					const leadData = leadDoc.data() as LeadStruct;
					leadData.meetGreetTemplates = leadData.meetGreetTemplates || {};
					return new Lead(leadData, leadDoc.id);
				}

			})
		);

	}
	public getByIdOnce(leadId: string): Promise<Lead> {

		return this.dbService.getDocSnapshot(this.collection, leadId).then((leadDoc) => {

			if (leadDoc.exists) {
				const leadData = leadDoc.data() as LeadStruct;
				leadData.meetGreetTemplates = leadData.meetGreetTemplates || {};
				return new Lead(leadData, leadDoc.id);
			}

		});


	}

	public deleteSpamLeads() {
		const leadsCol = this.dbService.db.collection('leads');
		var spam_lead_query = leadsCol.where('user.email', '==', 'sample@email.tst');
		spam_lead_query.get().then(function (querySnapshot) {
			window.adminlog.print(querySnapshot);
			querySnapshot.forEach(function (doc) {
				//window.adminlog.print(doc.ref);
				//doc.ref.delete();
				window.adminlog.print(doc.id, '=>', doc.data());
				var deleteDoc = leadsCol.doc(doc.id).delete().then(
					(value) => {
						window.adminlog.print(value);
						// expected output: "Success!"
					},
					(err) => {
						window.adminlog.print(err);
						// expected output: "Error!"
					}
				)
			});
		});
	}

	/**
	 * Calculates the score for the lead locally.
	 * @param  lead [description]
	 * @return      [description]
	 */
	public calculateScore(lead: LeadStruct): number {
		window.adminlog.print("score sheet", lead.tag, this.scoreSheet);
		return this.scoreSheet[lead.tag] || 0;

	}

	/**
	 * scores the lead on the server.
	 * @param  leadId [description]
	 * @return        [description]
	 */
	public pollCalculateScore(leadId: string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			let bearerToken = await this.authService.getBearerToken();

			const httpOptions = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
					"Authorization": "Bearer " + bearerToken
				})
			}

			this.http.get(`${environment.firebaseFunctions.windigo}/lead/${leadId}/calculate`, httpOptions).subscribe((_success: { lead: LeadStruct }) => {
				resolve(_success.lead);
			}, err => {
				reject(err);
			})

		});
	}

	public async update(leadId: string, updatedData: any): Promise<void> {

		if (!updatedData.updatedAt) {

			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		window.adminlog.print("updating lead", updatedData);
		await this.dbService.updateDoc(this.collection, leadId, updatedData);
		window.adminlog.print('updated');

	}

	public setLead(leadId: string, updatedData: LeadStruct, merge: boolean = false): Promise<void> {
		if (updatedData['_id']) delete updatedData['_id'];
		if (updatedData['_preps']) delete updatedData['_preps'];

		if (!updatedData.updatedAt) {

			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}
		const setData = JSON.parse(JSON.stringify(updatedData));
		setData.createdAt = updatedData.createdAt;
		setData.updatedAt = updatedData.updatedAt;

		setData.meetGreetTemplates = Object.values(updatedData.meetGreetTemplates).map(mgt => {
			return Object.assign({}, mgt);
		});

		// const setData = {};
		// for(let field in updatedData){
		// 	if(updatedData[field] instanceof firebase.firestore.Timestamp){
		// 		setData[field] =( updatedData[field] as firebase.firestore.Timestamp ).toDate();
		// 	} else{
		// 		setData[field] = updatedData[field];
		// 	}
		// }

		window.adminlog.print("setting lead", setData);
		return this.dbService.setDoc(this.collection, leadId, setData, merge);

	}


    /**
     * Fetches all the leads that match the email or phone number
     * @param locationId 
     * @param email 
     * @param phoneNumber 
     * @returns Promise<Lead[]>
     */
    public findDuplicateLeadsPromise(locationId: string, email: string = '', phoneNumber: string = '',): Promise<Lead[]> {



        let queries = [];
        let phoneNumberQueryPromise, emailQueryPromise;
        if (phoneNumber !== '') {

            let phoneNumbers = [];
            
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1($1) $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1($1)-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1($1)$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1$1-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1$1 $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1$1$2$3"));

            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1)-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1)$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 $1-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 $1 $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 $1$2$3"));

            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 ($1) $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 ($1)-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 ($1)$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 $1-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 $1 $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 $1$2$3"));

            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1($1) $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1($1)-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1$1)$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1$1-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1$1 $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1$1$2$3"));

            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2-$3"));
            phoneNumbers.push(phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3"));

            const chunkSize = 10;
            let phoneNumbersList = [];
            
            for (let i = 0; i < phoneNumbers.length; i += chunkSize) {
                phoneNumbersList.push(phoneNumbers.slice(i, i + chunkSize));
                // do whatever
            }
            
            let leadPromise = [];
            phoneNumbersList.forEach((phoneNumberChunk) => {
                let phoneNumberQueryOptions: queryOptions = {
                    conditions: [
                        ["user.phoneNumber", "in", phoneNumberChunk],
                        ["status", "!=", 3],
                        ["location", "==", locationId]
                    ]
                };

                leadPromise.push(this.dbService.getCollectionOnce(this.collection, phoneNumberQueryOptions));
            })

            let leadArray: Lead[] = [];
            return new Promise((resolve, reject) => {

                Promise.all(leadPromise).then((leadDocs) => {

                    new Promise((resolve, reject) => {

                        leadDocs.forEach((leadPromiseDoc) => {
                            if(leadPromiseDoc.length > 0) {
                                let leadData = leadPromiseDoc.map((leadDoc) => {
                                    const lead = new Lead((leadDoc.data() as LeadStruct), leadDoc.id);
                                    return lead;
                                });
                                
                                leadArray = leadArray.concat(leadData);

                            }
                        });
                        
                        resolve(leadArray);
                    }).then((leadArray: Lead[]) => {
                        let arrayFilterFinal = 
                         leadArray.filter((lead) => {
                            if (lead.status === LeadState.Archived) {
                                return false;
                            }
                            if (lead.duplicate !== undefined) {
                                if (lead.duplicate.status !== "keepAsPrimary") {
                                    return false;
                                }
                            }
                            return true;
                        });
                        console.log("leadArray", arrayFilterFinal.length);
                        
                        
                        resolve(arrayFilterFinal);
                    }).catch((error) => {
                        console.log(error);
                        reject(error);
                    });
                });
            });
        }

        if (email !== '') {
            let emailQueryParams: queryOptions = {
                conditions: [
                    ["user.email", "==", email],
                    ["status", "!=", 3],
                    ["location", "==", locationId]
                ],
            };
            return new Promise<Lead[]>((resolve, reject) => {
                emailQueryPromise = this.dbService.getCollectionOnce(this.collection, emailQueryParams).then((leadDocs) => {
                    console.log("leadDocs", leadDocs);

                    const leadArray = new Promise<Lead[]>((resolve, reject) => {
                        let leadArray: Lead[] = leadDocs.map((leadDoc) => {
                            const lead = new Lead((leadDoc.data() as LeadStruct), leadDoc.id);
                            return lead;
                        });
                        resolve(leadArray);
                        console.log("leadArray", leadArray);
                        
                    }).then((leadArray: Lead[]) => {

                        return leadArray.filter((lead) => {

                            if (lead.status === LeadState.Archived) {
                                return false;
                            }
                            if (lead.duplicate !== undefined) {
                                if (lead.duplicate.status !== "keepAsPrimary") {
                                    return false;
                                }

                            }
                            return true;
                        });
                    });

                    resolve(leadArray);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
            });

            // console.log("emailQueryPromise", emailQueryPromise);
            // emailQueryPromise.mapDocsToObjs

            // emailQueryPromise.map((leadDoc) => {
            //     const lead = new Lead((leadDoc.data() as LeadStruct), leadDoc.id);
            //     return lead;
            // });
        }
    }


    public findDuplicateLeads(email: string, phoneNumber: string, locationId: string): Observable<Lead[]> {
        let phoneNumbers = [];
        phoneNumbers.push( phoneNumber.replace(/\D/g,'').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") );
        phoneNumbers.push( phoneNumber.replace(/\D/g,'').replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3") );
        phoneNumbers.push( phoneNumber.replace(/\D/g,'').replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3")  );
        phoneNumbers.push( phoneNumber.replace(/\D/g,'').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") );
        phoneNumbers.push( phoneNumber.replace(/\D/g,'').replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2-$3") );
        phoneNumbers.push( phoneNumber.replace(/\D/g,'').replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3") );

        let queries = [];
        let emailQueryParams : queryOptions = {
            conditions: [
                ["user.email", "==", email],
                ["status", "!=", 3],
                ["location", "==", locationId]
            ],
        };
        queries.push( this.dbService.getCollection(this.collection, emailQueryParams) );
        if(phoneNumber !== '') {
            let phoneNumberQueryOptions: queryOptions = {
                conditions: [
                    ["user.phoneNumber", "in", phoneNumbers],
                    ["status", "!=", 3],
                    ["location", "==", locationId]
                ]
            };
            queries.push( this.dbService.getCollection(this.collection, phoneNumberQueryOptions) );
        }

        return combineLatest( queries ).pipe(
			map((leadDocs) => {
                let returnableArray = [];
                for( let x = 0; x < leadDocs.length; x++ ){ 
                    let leadDocsArray = leadDocs[x] as Array<any>;
                    for( let y = 0; y < leadDocsArray.length; y++ ){ 
                        //console.log(  leadDocs[x][y].data() );
                        returnableArray.push(new Lead(leadDocs[x][y].data() as LeadStruct, leadDocs[x][y].id) );
                    }    
                }
                return returnableArray;
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
  
    }

    public findLeadsByEmail(email: string): Observable<Lead[]> /*Promise<Lead[]>*/ { 
        let queryOptions : queryOptions = {
            conditions: [
                ["user.email", "==", email]
            ],
            //orderByField: "createdAt"
        }
		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((leadDocs) => {
				return this.mapDocsToObjs(leadDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);  
    }


	/**
	 * REquests adding a tag to a lead on the server. Uses common infrastructure so it is easier to maintain. Obvious downside is that the server is shit right now because of phantomjs.
	 * @param  leadId    [description]
	 * @param  newStatus [description]
	 * @return           [description]
	 */
	public archiveLead(leadId: string): Promise<any> {
		return new Promise(async (resolve, reject) => {

            this.getByIdOnce(leadId).then((lead: LeadStruct) => {

                // archive lead
                const todaysDate = new Date();
                const tagMap = {};
                tagMap[LeadTag.Archived] = todaysDate.getTime();
                const tagHistory = lead.tagHistory;
                tagHistory.push({
                    "tag": LeadTag.Archived,
                    "date": firebase.firestore.Timestamp.fromDate(todaysDate)
                });
                
                const updateLeadObj = {
                    tag: LeadTag.Archived,
                    status: LeadState.Archived,
                    score: this.scoreDictionaryTemplate[LeadTag.Archived],
                    tagMap: tagMap,
                    tagHistory: tagHistory,
                }
                
                this.update(leadId, updateLeadObj).then(_s => {
                    window.adminlog.print("Polled new score", _s, "for", leadId);
                    console.log("Lead archive: here to update");
                    resolve(leadId);
                }).catch(_e => {
                    reject(_e);
                    console.error(_e);
                });
            });
            reject();
		});
	}

	/**
	 * Default lead states from status. Where status determines which column they are in.
	 * @param  status [description]
	 * @return        [description]
	 */
	public getTagFromState(status: LeadState) {
		let newTag: LeadTag;

		if (status === LeadState.New) {
			newTag = LeadTag.NewLead;
		} else if (status === LeadState.InProgress) {
			newTag = LeadTag.Progress1;
		} else if (status === LeadState.Confirmed) {
			newTag = LeadTag.Confirmed;
		} else if (status === LeadState.Done) {
			newTag = LeadTag.Done;
		}
		return newTag;

	}

	public async updateStatus(leadId: string, newStatus: LeadState, append: any = {}): Promise<any> {

		const updatedValues = {
			status: newStatus,
			tag: this.getTagFromState(newStatus),
			score: this.scoreSheet[this.getTagFromState(newStatus)],
			...append
		};

		let leadUpdatePromise = this.update(leadId, updatedValues);

		let newStatusLabel = this.enumLabels.state[newStatus];

		let logEntryPromise = this.logService.addLogEntry(this.collection, leadId, "Status Change to " + newStatusLabel, newStatusLabel);

		await Promise.all([leadUpdatePromise, logEntryPromise]);

		// Run async.
		// this.pollCalculateScore(leadId).then(_s => window.adminlog.print("Calculated score for lead", leadId)).catch(e => window.adminlog.error("Failed to calculate score for lead", leadId, e));

		return;
	}

	public newLeadsQuery(locationId: string) {
		return this.dbService.db.collection(this.collection)
			.where("location", "==", locationId)
			.where("status", "==", LeadState.New)
			.limit(this.queryItemsLimit);
	}

	public getNewLeads(locationId: string, userIdToFilter: string = ""): Observable<Lead[]> {

		// window.adminlog.print("Location:", locationId);
		const queryOptions: queryOptions = {
			conditions: [
				["location", "==", locationId],
				["status", "==", LeadState.New]
			],
			orderByField: "createdAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		if (userIdToFilter) {
			queryOptions.conditions.push(["records.userId", "==", userIdToFilter]);
			// window.adminlog.print("getting user id", userIdToFilter);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((leadDocs) => {
				// window.adminlog.print("LEAD DOCS", leadDocs.map(leadDoc => leadDoc.data()));
				return this.mapDocsToObjs(leadDocs);
			}),

			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}
	public inProgressLeadsQuery(locationId: string) {
		return this.dbService.db.collection(this.collection)
			.where("location", "==", locationId)
			.where("status", "==", LeadState.InProgress)
			.limit(250);
	}

	public inProgressFollowUpLeadsQuery(locationId: string) {
		return this.dbService.db.collection(this.collection)
			.where("location", "==", locationId)
			.where("status", "==", LeadState.FollowUp)
			.limit(this.queryItemsLimit);
	}


	public getInProgressLeads(locationId: string, userIdToFilter: string = ""): Observable<Lead[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["location", "==", locationId],
				["status", "==", LeadState.InProgress],
			],
			orderByField: "score",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		if (userIdToFilter) {
			window.adminlog.print("filter by userID?", userIdToFilter);
			queryOptions.conditions.push(["records.userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((leadDocs) => {
				return this.mapDocsToObjs(leadDocs);
			})
		);

	}

	public confirmedLeadsQuery(locationId: string) {
		return this.dbService.db.collection(this.collection)
			.where("location", "==", locationId)
			.where("status", "==", LeadState.Confirmed)
			.limit(350); // Increase limit for Booking column, Ticket 4149
	}

    public getAllConfirmedLeadsForCVSDownload( locationId: string ){ 
        return this.dbService.db.collection(this.collection)
			.where("location", "==", locationId)
			.where("status", "==", LeadState.Confirmed)
    }

	public getConfirmedLeads(locationId: string, userIdToFilter: string = ""): Observable<Lead[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["location", "==", locationId],
				["status", "==", LeadState.Confirmed],
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: 350  // Increase limit for Booking column, Ticket 4149
		};

		if (userIdToFilter) {
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((leadDocs) => {
				return this.mapDocsToObjs(leadDocs);
			})
		);

	}

	public completedLeadsQuery(locationId: string) {
		return this.dbService.db.collection(this.collection)
			.where("location", "==", locationId)
			.where("status", "==", LeadState.Done)
			.limit(this.queryItemsLimit);
	}

	public getCompletedLeads(locationId: string, userIdToFilter: string = ""): Observable<Lead[]> {

		const queryOptions: queryOptions = {
			conditions: [
				["location", "==", locationId],
				["status", "==", LeadState.Done]
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		if (userIdToFilter) {
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((leadDocs) => {
				return this.mapDocsToObjs(leadDocs);
			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw (error);
			})
		);

	}

	public createLead(leadData: LeadStruct): Promise<string> {

		leadData.user.email = leadData.user.email.toLocaleLowerCase();

		if (!leadData.createdAt) {

			let createAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			leadData.updatedAt = firebase.firestore.Timestamp.fromDate(createAtDate);

		}
        else{ 
            let createAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			leadData.updatedAt = firebase.firestore.Timestamp.fromDate(createAtDate);
        }

        /*
        if( leadData.user.phoneNumber && leadData.source === LeadSource.PhoneCall ){
            if( leadData.user.phoneNumber.charAt(0) === "1" ){ 
                leadData.user.phoneNumber.substring( 1 , leadData.user.phoneNumber.length - 1 );
            } 
            return this.http.get( 
                environment.firebaseFunctions.sbaCheckPhoneNumber+"+1"+leadData.user.phoneNumber.replace(/\D/g, '')
             ).toPromise().then( sbaData => { 
                console.log( sbaData["data"]["type"] );
                if( sbaData["msg"] === "success" ){
                    console.log("successFunction"); 
                    if( sbaData["data"]["type"] === "search" ){ 
                        console.log( "fromSearch");
                        leadData.source = LeadSource.PhoneSearch;
                    }
                    else if( sbaData["data"]["type"] === "social" ){
                        console.log("fromSocial"); 
                        leadData.source = LeadSource.PhoneSocial;
                    } 

                    let newLead = Object.assign({}, leadData)
                    console.log("newLead", newLead );
                    return this.dbService.insertDoc(this.collection, newLead).then((leadDoc) => {
                        return leadDoc.id;
                    });
                }
                else{ 
                    return this.dbService.insertDoc(this.collection, Object.assign({}, leadData)).then((leadDoc) => {
                        return leadDoc.id;
                    });
                }
             } ).catch( error => {
                return this.dbService.insertDoc(this.collection, Object.assign({}, leadData)).then((leadDoc) => {
                    let errorLog = {};
                    errorLog["errorCode"] = "LEAD_CREATION_SBA_ENDPOINT_FAILED";
                    errorLog["firstSeen"] = Date.now();
                    errorLog["objectId"] = leadDoc.id;
                    return this.dbService.db.collection(collections.errorLogs).add( Object.assign({},errorLog) ).then(() => {
                        return leadDoc.id; 
                    });
                })
              });
        }
        */
        //else{ 
            let newLead = Object.assign({}, leadData)
            return this.dbService.insertDoc(this.collection, newLead).then((leadDoc) => {
                return leadDoc.id;
            });
        //}
        
        
        //console.log( "lead data", leadData );
        

	}

	/**
	 * Oh boy what to say about this function. It reinforces the user selection, possibly doubling up but I thought the redundency would make things safer.
	 * It finds user, and account, if those exist and then adds them. These processes are semi independent. But if a user exists we try to find the account by userId first.
	 * It will also create the meet and greet appointment, pet information if it does not exist, and so on.
	 * It's magic. It ties things up with a post request, which will build whatever needs to be built on petexec, create an invitation, and so on. I added comments for src/api/endpoints/petexec/create_stack.ts so you can look up the other half of the pie if you so desire.
	 *
	 * @param  leadId     [description]
	 * @param  lead       [description]
	 * @param  addtnlInfo [description]
	 * @param  companyId  [description]
	 * @return            [description]
	 */
    public async finalizeLead(leadId: string, lead: Lead, addtnlInfo: { locationId: string, companyId: string, locationTimezone:string }): Promise<any> {

		let attachedItemIds = lead.records;
		// If a user id exists or we can find a user, then we submit the userId and not the user structure. If no user exists, we submit the user structure instead.
		let requireCreateUser = false;
		let requireAttachUser = true;
		if (!attachedItemIds.userId) {

			let existingUser: User;

			try {

				existingUser = await this.userService.getByEmail(lead.user.email || "");

			}
			catch (error) {
				window.adminlog.print("Error retrieving user by email: ", error);
			}

			if (!existingUser) {

				try {
					requireCreateUser = true;
					// Upload user.
					// attachedItemIds.userId = await this.userService.createUser(lead.user);

				}
				catch (error) {
					window.adminlog.print("Error creating user record: ", error);
				}

			} else {

				attachedItemIds.userId = existingUser._id;

			}

		}

		if (!attachedItemIds.accountId) {

			let existingAccount: Account;

			try {
				if (attachedItemIds.userId) {

					existingAccount = await this.accountService.getByUserId(attachedItemIds.userId, addtnlInfo.locationId);
				}

				if (!existingAccount) {
					existingAccount = await this.accountService.getByEmail(lead.user.email, addtnlInfo.locationId);

				}
				if (existingAccount) {
					requireAttachUser = false;
				}

			} catch (error) {
				window.adminlog.print("Error retrieving account by userId and/or email: ", error);
			}

			if (!existingAccount) {

				try {

					let addtnlAccountInfo = {
						userId: attachedItemIds.userId,
						locationId: addtnlInfo.locationId,
						companyId: addtnlInfo.companyId
					};

					attachedItemIds.accountId = await this.accountService.createAccountFromUser(lead.user, addtnlAccountInfo);

				}
				catch (error) {
					window.adminlog.print("Error creating account record: ", error);
				}

			} else {

				attachedItemIds.accountId = existingAccount._id;

			}

		}

		let updatedMeetGreetTemplates = lead.meetGreetTemplates;

		let addtnlPetInfo = {
			accountId: attachedItemIds.accountId,
			locationId: addtnlInfo.locationId,
			companyId: addtnlInfo.companyId,
			access: "Scheduled",
		};

		const petIds = [];

		await Promise.all(Object.keys(lead.meetGreetTemplates).map(async hashId => {
			let meetGreetTemplate = lead.meetGreetTemplates[hashId];

			let petId: string = "";
            
			if (meetGreetTemplate.petId && meetGreetTemplate.petId[0] && meetGreetTemplate.petId[0] != "") {

				petId = meetGreetTemplate.petId[0];
                //console.log( "petId", petId )
                //uncomment
				await this.petService.update(petId, { "functional.access": "Scheduled" });
				petIds.push(petId);

			} else {

				try {

					let petData: PetStruct = meetGreetTemplate.pet;
					// window.adminlog.print("pet data shots", meetGreetTemplate.pet.functional.vaccines[0]);
					window.adminlog.print("pet data create", petData, meetGreetTemplate.petHistory);
					petId = await this.petService.createPet(petData, addtnlPetInfo);

					if (meetGreetTemplate.petHistory) {
						// Remove document Url.
						if (meetGreetTemplate.petHistory.docURL) delete meetGreetTemplate.petHistory['docURL'];

						// Add account Id
						meetGreetTemplate.petHistory.accountId = attachedItemIds.accountId || null;

						// Add user Id
						meetGreetTemplate.petHistory.userId = attachedItemIds.userId || null;

						meetGreetTemplate.petHistory.petId = petId;

						// Create Pet History.
						await this.petService.insertPetHistory(petId, meetGreetTemplate.petHistory);

					}


					petIds.push(petId);
					window.adminlog.print("creating pet:", petId);

				}
				catch (error) {
					window.adminlog.print("Error creating pet record: ", error);
				}

			}

            if ( petId === "" ) { 
                updatedMeetGreetTemplates[hashId].petId = [];
            }
            else{ 
                updatedMeetGreetTemplates[hashId].petId = [petId];
            }

			let meetGreetId: string = "";

			try {

				let meetGreetData: MeetGreetAppointmentStruct = {
					petId: [petId],
					accountId: attachedItemIds.accountId,
					userId: attachedItemIds.userId || "",
					locationId: addtnlInfo.locationId,
					leadId: leadId,
					timezone: addtnlInfo.locationTimezone || moment.tz.guess(),
					date: meetGreetTemplate.date,
					status: MeetGreetAppointment.PENDING,
					sourceLeadId: leadId,
					userInfoComplete: false,
					petInfoComplete: false,
                    
				};

				meetGreetId = await this.meetGreetAppointmentService.createMeetGreetAppointment(meetGreetData);

			}
			catch (error) {
				window.adminlog.print("Error creating M&G record: ", error);
			}

			updatedMeetGreetTemplates[hashId].meetGreetId = meetGreetId;
		}));


		let leadUpdatePromises = [];

		let updatedLeadData = {
			records: attachedItemIds,
			meetGreetTemplates: updatedMeetGreetTemplates,
		};

		updatedLeadData['status'] = LeadState.Done;
		updatedLeadData['tag'] = this.getTagFromState(LeadState.Done);


		try {

			await this.update(leadId, updatedLeadData as any);
		} catch (e) {
			window.adminlog.error(e);
		}


		let bearerToken = await this.authService.getBearerToken();

		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		}


		// Request finalizes lead, loads structures into petexec, schedules meet and greet appointments, and ensures creation of user & user auth.
		const payload = {
			petIds: petIds || [],
			meetGreetAppointmentIds: Object.values(lead.meetGreetTemplates).map(mgt => mgt.meetGreetId) || [],
			accountId: attachedItemIds.accountId || "",
			locationId: addtnlInfo.locationId || "",
			userId: attachedItemIds.userId || "",
			appointment_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
		}

		if (requireCreateUser) {
			payload['userData'] = lead.user;
		}
		if (requireAttachUser) {
			payload['attach_user'] = true;
		}


		return new Promise((resolve, reject) => {
			this.http.post(`${environment.firebaseFunctions.windigo}/lead/${leadId}/finalize`, payload, httpOptions).subscribe((result) => {
				window.adminlog.print("Successfully finalized lead", result);
                //console.log( "finalize lead function", result );
				resolve(result);
                this.updateStatus(leadId, LeadState.Done);
			}, err => {
				window.adminlog.error("Error finalizing lead", err);
				reject(err);
			});
		});





	}

	public mapDocsToObjs(leadDocs: firebase.firestore.QueryDocumentSnapshot[]): Lead[] {

		return leadDocs.map((leadDoc) => {

			const lead = new Lead(leadDoc.data() as LeadStruct, leadDoc.id);

			return lead;

		});

	}

	public scheduleLeadForFollowUp(lead: Lead, date: Date) {
		lead.renew = {
			at: date.getTime(),
			as: lead.tag,
			isTask: true
		};
		return this.update(lead.id, {
			renew: lead.renew, status: lead.status
		});
	}

	/**
	 * From a time when we wanted things to be complicated. Until we figured out that a franchise with so many locations probably wants things to be straightforward and simple if it's not customizable.
	 * Still would not go back on this design though. This is essentially giving tags weights.
	 * @param count [description]
	 */
	private fillScoreSheet(count: number): void {

		const fill = new Array(count + 1).join(',').split(',');

		let score = 0;

		fill.forEach((val, index) => {



			if (typeof this.scoreDictionaryTemplate[index] !== 'undefined') {
				this.scoreSheet[index] = this.scoreDictionaryTemplate[index];
				score = this.scoreDictionaryTemplate[index];
			} else {
				this.scoreSheet[index] = score;
			}

        });
        
       // console.log("scoreSheet" ,this.scoreSheet );

	}


}
