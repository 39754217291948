import { Component } from "@angular/core";

@Component({
	selector: "app-section-overview-columns",
	templateUrl: "./section-overview-columns.component.html",
	styleUrls: ["./section-overview-columns.component.scss"],
})

export class SectionOverviewColumnsComponent {

	constructor() { }

}