import { Pipe, PipeTransform } from "@angular/core";

import { FileUploadService } from "src/services";

@Pipe({
	name: "getUploadedFilename"
})

export class GetUploadedFileNamePipe implements PipeTransform {

	constructor(private fileUploadService: FileUploadService) { }

	public async transform(uploadedFileUrl: string):Promise<string> {

		const filename = await this.fileUploadService.getFileBasename(uploadedFileUrl);
			
		return filename;
		
	}

}