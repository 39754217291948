import { Component, OnInit, ViewChild } from '@angular/core';

import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Events, PopoverController } from '@ionic/angular';
import moment from 'moment-timezone';
import { AuthService } from 'src/services';


@Component({
  selector: 'app-on-hold-waitlist-filter-popover',
  templateUrl: './on-hold-waitlist-filter-popover.component.html',
  styleUrls: ['./on-hold-waitlist-filter-popover.component.scss'],
})
export class OnHoldWaitlistFilterPopoverComponent implements OnInit {

    public faCheck = faCheck;
    @ViewChild("onHoldWaitlistDateSearch", { static: false }) onHoldWaitlistDateSearch: any;

    public selected = "onhold";
    public onHoldWaitlistDate :any;

    public amountOfFiltersSelected = 1;

    public datePickerOptions = {
        controls: ['calendar'],
        display: 'anchored',
        dateFormat: "MMM D, YYYY",
        };

    constructor(
        public settingsService : AuthService,
        public ionEventBus: Events,
        public popoverController: PopoverController
    ) { }

    ngOnInit() {
        
        this.settingsService.getSettings("_2f_appointments").then( pageSettings => { 

            if( pageSettings.exists ){ 
                if(pageSettings.data()["data_column_setting"]){ 
                    if(pageSettings.data()["data_column_setting"]["onHoldFilter"]){ 
                        this.selected = pageSettings.data()["data_column_setting"]["onHoldFilter"]
                    }
                    if(pageSettings.data()["data_column_setting"]["onHoldDateParam"] ){ 
                        this.onHoldWaitlistDate = pageSettings.data()["data_column_setting"]["onHoldDateParam"]
                    }
                }
            }

        });
    }

    public async selectionChange( status ){
        if( this.onHoldWaitlistDate != null ) { 
            this.amountOfFiltersSelected = 2;
        }
        //this.settingsService.pageSettings["onHoldWaitlistSetting"] = status;
        await this.settingsService.setSettings({ ["data_column_setting"]:{"onHoldFilter": status} });
        this.selected = status;
        this.ionEventBus.publish("user-onHoldWaitlist-selected", { "filter":this.selected, 'date':this.onHoldWaitlistDate,amountOfFiltersSelected:this.amountOfFiltersSelected});
        console.log({ "filter":this.selected, 'date':this.onHoldWaitlistDate });
        //this.popoverController.dismiss();
    }
    public async dateChanged( date ){
        if( this.amountOfFiltersSelected < 2){ 
            this.amountOfFiltersSelected++;
        }
        
        let newDate = date.valueText;
        //this.settingsService.pageSettings["onHoldWaitlistSettingDate"] = newDate;
        await this.settingsService.setSettings({ ["data_column_setting"]:{"onHoldDateParam": newDate} });
        this.onHoldWaitlistDate = newDate;
        this.ionEventBus.publish("user-onHoldWaitlist-selected", { "filter":this.selected, 'date':this.onHoldWaitlistDate,amountOfFiltersSelected:this.amountOfFiltersSelected});
    }
    public async clearDate(){ 
        this.amountOfFiltersSelected--;

        //this.settingsService.pageSettings["onHoldWaitlistSettingDate"] = null;
        await this.settingsService.setSettings({ ["data_column_setting"]:{"onHoldDateParam": null} });
        this.onHoldWaitlistDate = null;
        this.ionEventBus.publish("user-onHoldWaitlist-selected", { "filter":this.selected, 'date':this.onHoldWaitlistDate,amountOfFiltersSelected:this.amountOfFiltersSelected});
    }

}
