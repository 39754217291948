import { UserStruct } from "./user";
import { DogStruct, VaccinesStruct } from "./dog";
import { LocationStruct } from "./location";
import { PetStruct } from "./pet";
import { AccountStruct } from "./account";
import firebase from 'firebase';
import { MeetGreetAppointmentStruct } from './meet-greet-appointment';

export enum AccessLevel {
    User = 0,
    Admin = 1,
    Webcam = 2,
    SuperAdmin = 3
}

//#region Notification Transaction 
export enum MessageStatus {
    Failed,
    Cancelled,
    Ready,
    Queued,
    Sending,
    Delivered
}
export type CoreStruct = {

    location?: string,
    locationId?: string,

    // Date related objects
    date?,
    createdAt?,
    timezone?: string,

    // Substitutes for user
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string

};
export interface IMessage {
    created: {
        at: firebase.firestore.Timestamp,
        id: string,
        access: AccessLevel,
        source: string
    },

    status: MessageStatus,
    sendAt: number

    transaction: Transaction<any>

}

export type Transaction<T = CoreStruct> = {

    core: T,
    coreId: string,
    noteFor: string,
    options: MessageQueueOptions

};

//#endregion

export type NotedInterpolatedFields = {
    user?: UserStruct,
    dog?: DogStruct,
    location?: LocationStruct,
    createdAt?: string,
    appointmentAt?: string,
    core?: any,
    coreId?: string,
    email?: string,
    pet?: PetStruct,
    account?: AccountStruct,
    meetGreetAppointment?: MeetGreetAppointmentStruct,
    vaccination?: VaccinesStruct
};



//#region Notifier Options
export enum outbox {
    customer, registrar, internal
};

export interface TextOptions extends TransportOptions {
    from?: string,
    to?: string[]
}

export interface EmailOptions extends TransportOptions {
    from?: string,
    to?: string | string[],
    subject?: string | ((opts: NotedInterpolatedFields) => string)
};
export type TransportOptions = {
    content: string | ((opts: NotedInterpolatedFields) => string),
    // may want to modify the template in the future.
    // e.g. emails to users use an email template for frame and spacing of message content
    templateMode?: (opts: TemplateOptions) => string;
    templateData?: TemplateOptions;
}

export type TransportOptionsSignature = {
    content: string,
    subject?: string,
    templateMode?: string,
    templateData?: TemplateOptions,
    from?: string,
    to?: string | string[],
}


export type TemplateOptions = {

    paras: string[],
    greeting?: string,
    location?: {
        name: string,
        extension: string
    },
    signature?: string
    button?: {
        url: string,
        text: string
    },
    title?: string,
    preheader_text?: string

}

export type TransactionOptions = {
    // Collect this Data?
    loaders: {
        [option_property: string]: {
            id: string,
            entity?: {
                fetch: (id?: string) => Promise<any>,
                [props: string]: any
            }

        }
    },
    loaded?: {
        [option_property: string]: any
    },

    // Fields that are prepared by moment
    at?: string,
    created?: string,
    // Destination Mode
    to: outbox
}

export type MessageQueueOptions = {
    email?: TransportOptionsSignature,
    text?: TransportOptionsSignature,
    cloud?: TransportOptionsSignature

} & TransactionOptions;
export type NotifyOptions = {

    // Sending Options
    email?: EmailOptions,
    text?: TextOptions,
    cloud?: TransportOptions

} & TransactionOptions;
//#endregion
