import { Component, OnInit } from '@angular/core';

import { Events, PopoverController } from '@ionic/angular';
import { AuthService } from 'src/services';

import { faCheck } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-active-cancelled-dropoff-sort-popover',
  templateUrl: './active-cancelled-dropoff-sort-popover.component.html',
  styleUrls: ['./active-cancelled-dropoff-sort-popover.component.scss'],
})
export class ActiveCancelledDropoffSortPopoverComponent implements OnInit {

  public faCheck = faCheck;
  public selected = "all";
  
  constructor(
      public ionEventBus: Events,
      public settingsService : AuthService,
      public popoverController: PopoverController
  ) { }

  ngOnInit() {
      console.log( this.settingsService.pageSettings ); 
      if( this.settingsService.pageSettings != null ){ 
          if( this.settingsService.pageSettings["activeCancelledDropOff"] != undefined ){ 
              this.selected = this.settingsService.pageSettings["activeCancelledDropOff"];
          }
      }
      else if( this.settingsService.pageSettings === null) { 
          this.selected = "all";
          this.settingsService.pageSettings["activeCancelledDropOff"] = "all";
      }
  }

  public selectionChange( status ){
      this.settingsService.pageSettings["activeCancelledDropOff"] = status;
      this.selected = status;
      this.ionEventBus.publish("sort-active-cancelled-dropoffs", { "status": this.selected } );
      this.popoverController.dismiss();
    }

  public _(){ 
      this.ionEventBus.publish("sort-active-cancelled-dropoffs", { } );
  }

}
