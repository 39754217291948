import { Component, OnInit, OnDestroy } from "@angular/core";

import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Events } from "@ionic/angular";

import { LocationService, UserRequestService, AccessRequestService } from "src/services";

import { SectionOverviewAbstractComponent } from "../../dashboard/section-overview-abstract.component";

import { UserRequest } from "src/models/entities";

import { OverviewListItem, SearchResultUserStruct } from "src/models/structs";
import { AccessRequestStruct } from '../../../models/structs/access-request';
import { AccessRequest } from '../../../models/entities/access-request';

@Component({
	selector: "app-access-requests-overview",
	templateUrl: "./access-requests-overview.component.html",
	styleUrls: ["./access-requests-overview.component.scss"],
})
export class AccessRequestsOverviewComponent extends SectionOverviewAbstractComponent<AccessRequest> implements OnInit, OnDestroy {

	protected qry: string;

	public unsubscriber: Subject<void> = new Subject();
	public locationUnsubscriber: Subject<void> = new Subject();

	public sectionUrlRoot: string = "/access-requests";

	public badgeTextProperty: string = "status";

	public newAccessRequestsObs: Observable<AccessRequest[]>;
	public completedAccessRequestsObs: Observable<AccessRequest[]>;

	constructor(
		private ionEventBus: Events,
		private locationService: LocationService,
		private accessRequestService: AccessRequestService
	) {

		super(accessRequestService);

		this.overviewColumns = {
			"new": {
				title: "New Requests",
				listItems: null,
				isLoading: true,
			},
			"completed": {
				title: "Completed",
				listItems: null,
				hideListCount: true,
				isLoading: true,
			}
		};

	}

	public ngOnInit(): void {

		this.locationService.getCurrUserLocationObs()
			.pipe(
				takeUntil(this.locationUnsubscriber)
			)
			.subscribe((currUserLocation) => {
				if (!this.currUserLocationId || currUserLocation._id !== this.currUserLocationId) {
					if (this.currUserLocationId) {

						this.overviewColumns['new'].isLoading = true;
						this.overviewColumns['completed'].isLoading = true;

						this.unsubscriber.next();

					}

					this.currUserLocationId = currUserLocation._id;


					// Fetch Content

					this.newAccessRequestsObs = this.accessRequestService.getNewAccessRequests(this.currUserLocationId);
					this.completedAccessRequestsObs = this.accessRequestService.getCompletedAccessRequests(this.currUserLocationId);

					this.subscribeToColumnListObservables();
				}

			});

		this.ionEventBus.subscribe("dashboard-searchbar:query", this.searchQuery);
		this.ionEventBus.subscribe("dashboard-searchbar:reset", this.searchbarReset);

	}
	protected searchQuery = (qry: string) => {

		// To do listen for changes in qry. get list of requests.

		if (qry.length > 0) {
			if (this.qry !== qry) {

				this.overviewColumns['new'].isLoading = true;
				this.overviewColumns['completed'].isLoading = true;
				this.qry = qry;


				// Unsubscribes all column observables
				this.unsubscriber.next();


				this.newAccessRequestsObs = this.accessRequestService.getNewAccessRequests(this.currUserLocationId, ['email', '==', qry]);
				this.completedAccessRequestsObs = this.accessRequestService.getCompletedAccessRequests(this.currUserLocationId, ['email', '==', qry]);


				this.subscribeToColumnListObservables();
			}

		} else {

			this.overviewColumns['new'].isLoading = true;
			this.overviewColumns['completed'].isLoading = true;

			// Unsubscribes all filtered column observables
			this.unsubscriber.next();
			this.newAccessRequestsObs = this.accessRequestService.getNewAccessRequests(this.currUserLocationId);
			this.completedAccessRequestsObs = this.accessRequestService.getCompletedAccessRequests(this.currUserLocationId);

			this.subscribeToColumnListObservables();

		}
	}

	protected searchbarReset = () => {
		this.overviewColumns['new'].isLoading = true;
		this.overviewColumns['completed'].isLoading = true;

		// Unsubscribes all filtered column observables
		this.unsubscriber.next();

		this.subscribeToColumnListObservables();
	}

	public ngOnDestroy(): void {
		this.ionEventBus.unsubscribe("dashboard-searchbar:query", this.searchQuery);
		this.ionEventBus.unsubscribe("dashboard-searchbar:reset", this.searchbarReset);
		this.unsubscriber.next();
		this.unsubscriber.complete();
		this.locationUnsubscriber.next();
		this.locationUnsubscriber.complete();
	}

	public convertToListItemsArr(requests: AccessRequest[]): OverviewListItem<AccessRequest>[] {

		return super.convertToListItemsArr(requests, this.badgeTextProperty, this.sectionUrlRoot)

	}

	public subscribeToColumnListObservables(): void {

		this.newAccessRequestsObs
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((accessRequests: AccessRequest[]) => {
				this.overviewColumns['new'].listItems = this.convertToListItemsArr(accessRequests);
				this.overviewColumns['new'].isLoading = false;
			});

		this.completedAccessRequestsObs
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((accessRequests: AccessRequest[]) => {
				this.overviewColumns['completed'].listItems = this.convertToListItemsArr(accessRequests);
				this.overviewColumns['completed'].isLoading = false;
			});

	}

}
