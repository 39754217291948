import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { Account, Pet } from "src/models/entities";

@Component({
	selector: "petexec-account-info-table",
	templateUrl: "./petexec-account-info-table.component.html",
	styleUrls: ["./petexec-account-info-table.component.scss"],
})

export class PetexecAccountInfoTableComponent implements OnInit {

	@Input() account: 		Account;
	@Input() pets: 			Pet[];
	@Input() displayMode: 	"card" | "table" 	= "table";
	@Input() showRefresh: 	boolean 			= false;
	@Input() isSelectable: 	boolean 			= false;
	@Input() isLoading:		boolean 			= false;

	@Output("onSelected") public selectedEvt: EventEmitter<Account> = new EventEmitter();
	@Output("onRefresh") public refreshEvt: EventEmitter<Account> = new EventEmitter();

	constructor() { }

	public ngOnInit():void { }
	
	public  onSelectBtnClicked():void {
		this.selectedEvt.emit(this.account);
	}

	public  onRefreshBtnClicked():void {
		this.refreshEvt.emit(this.account);
	}

}
