import { Entity } from '../entity';

import { AccountStruct, AccountAccess, PetStruct } from '../structs';

import { PartialCreditCardStruct } from '../structs/owner';

export class Account extends Entity<Account> implements AccountStruct {
	functional: {
		original_userId: string;
		locationId: string;
		companyID: string | number;
		credentialId?: string;
		access: {
			[accountId: string]: { granted: AccountAccess; };
		};
        username? : string;
        password? : string;
        social? : {
            facebook?: string;
            twitter?: string;
            instagram?: string;
            tiktok?: string;
        }
	};
	public get isValid(){
    return !(!(this.userid && this.email && this.firstName && this.lastName && this.employer && this.addr1 && this.city && this.state && this.zip && this.emergencyContact && this.emergencyPhone));
  }
	userid?: number;
	lastmodified?: Date;
	primarycard?: PartialCreditCardStruct;
	username?: string;
	password?: string;
	password2?: string;
	email: string;
	firstName: string;
	lastName: string;
	employer: string;
	addr1: string;
	addr2?: string;
	city: string;
	state: string;
	zip: string;
	emergencyContact: string;
	emergencyPhone: string;
	emergencyEmail?: string;
	vaccinationEmail?: number;
	homePhone?: string;
	workPhone?: string;
	howFoundId?: number;
	howFoundOther?: string;
	additionalOwner?: string;
	birthdayEmail?: number;
	boardingEmail?: number;
	boardingReminderEmail?: number;
	daycarePackageEmail?: number;
	enableEmail?: number;
	groomingEmail?: number;
	newOwnerEmail?: number;
	orderReceiptEmail?: number;
	scheduledServiceEmail?: number;
	trainingClassEmail?: number;
	temptestEmail?: number;
	pets?: PetStruct[];
	cellPhone: string;

    postalCode?:string;

    
	public static rawExecToOwnerMap = {
    firstname: 'firstName',
    lastname: 'lastName',
    additionalowner: 'additionalOwner',
    homephone: 'homePhone',
    cellphone: 'cellPhone',
    workphone: 'workPhone',
    emergencycontact: 'emergencyContact',
    emergencyphone: 'emergencyPhone',
    emergencyemail: 'emergencyEmail',
    howfoundid: 'howFoundId',
    howfoundother: 'howFoundOther',
    birthday_email: 'birthdayEmail',
    boarding_email: 'boardingEmail',
    boarding_reminder_email: 'boardingReminderEmail',
    daycarepackage_email: 'daycarePackageEmail',
    enableemail: 'enableEmail',
    grooming_email: 'groomingEmail',
    new_owner_email: 'newOwnerEmail',
    order_receipt_email: 'orderReceiptEmail',
    scheduledservice_email: 'scheduledServiceEmail',
    trainingclass_email: 'trainingClassEmail',
    temptest_email: 'temptestEmail',
    vaccination_email: 'vaccinationEmail'
  };

	constructor(opts?: {data: AccountStruct, id?: string, fromExec?: boolean}){
		super({}, opts.id);
		if(  !opts.fromExec) {
			this.hydrate(opts.data);
		} else {
			this.executiveHydrate(opts.data);
		}
	}

	public executiveHydrate(data: any){
		const remappeddata = {};
		for(const key in data){
			let field = Account.rawExecToOwnerMap[key] ||  key;
			remappeddata[field] = data[key];
		}
		return this.hydrate(remappeddata);
	}

	public init():void {
		this.functional = (this.functional || {original_userId: 'n/a'}) as any;
		this.functional.access = this.functional.access || {};
	}

	public intiLocation(location: string, company: string): void {
		this.functional.locationId = location;
		this.functional.companyID = company;
	}

	public addUserToAccount(userId: string, access: AccountAccess):void {
		this.init();
		this.functional.access[userId] = this.functional.access[userId] || <any>{};
		this.functional.access[userId].granted = access;
		this.functional.original_userId = (this.functional.original_userId === 'automatched' || this.functional.original_userId === "unmatched") ? userId : this.functional.original_userId;
	}

	public get server() {
		if (!this.functional) return '';
		return (this.functional.locationId === 'hhU5LD6ZxGZjBlmOIBF3') ? 'https://dogtopiamadison.petexec.net/admin/' : "https://dogtopia.petexec.net/admin/";
	}
	public get ownerExecLink() {
		return `${this.server}ownerMod2.php?uid=${this.userid}`;
	}
	

}
