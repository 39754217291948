import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        console.log("filterValue", value);
        
        switch (value) {
            case 10:
                return 'Full Access';
            case 1:
                return 'Guest Access';
            case 0:
                return 'No Access'
            default:
                return "Show All";
        }
    }

}
