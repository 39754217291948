import { Pipe, PipeTransform } from "@angular/core";

import { Observable, interval, timer, of } from "rxjs";
import { takeWhile, map } from "rxjs/operators";

import * as moment from "moment-timezone";

@Pipe({
	name: "minutesAway"
})

export class MinutesAwayPipe implements PipeTransform {

	private lastValue:Date;

	private minutesAwayObs:Observable<string>;

	constructor() { }

	public transform(value: Date):Observable<string> {

		if(this.getMinutesFromNow(value) > 0){

			if(!this.minutesAwayObs || (moment(value).unix() !== moment(this.lastValue).unix())){
				window.adminlog.print("VALUE CHANGED");
				this.lastValue = value;

				this.minutesAwayObs = this.getObservable(value);

			}

			return this.minutesAwayObs;

		}else{

			return of("Should have arrived by now!");

		}
		
	}

	private getObservable(value: Date):Observable<string> {

		// Emit right away and then once a minute
		return timer(0, (1000 * 60)).pipe(
			takeWhile((i) => {
				return (this.getMinutesFromNow(value) >= 1);
			}),
			map((i) => {
				
				let returnStr:string = "";

				let minutesAway = this.getMinutesFromNow(value);

				if(minutesAway > 60){
					
					returnStr = "more than an hour away";
				
				}else{

					returnStr = minutesAway + " minute" + ((minutesAway > 1) ? "s" : "") + " away";
					
				}


				return returnStr;
			})
		);

	}

	private getMinutesFromNow(value:Date):number {

		const now = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

		return Math.round((moment(value).unix() - now.unix()) / 60);

	}

}