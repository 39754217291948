import { Directive, Input, HostBinding} from "@angular/core";

import { FormControl } from "@angular/forms";

@Directive({
	selector: "[appTouchedToggle]"
})

export class TouchedToggleDirective {

	@Input("appTouchedToggle") formControl: FormControl;

	@HostBinding("class.ion-untouched")
	get untouched() {
		return this.formControl.untouched;
	}

	@HostBinding("class.ion-touched")
	get touched() {
		return this.formControl.touched;
	}

}