import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
import { Appointment } from "../models/entities/";

@Pipe({
    name: 'getAppointmentDateText'
})
export class GetAppointmentDateTextPipe implements PipeTransform {

    transform(appt: Appointment): string {

        const at = moment(appt.date.toDate()).tz(appt.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
        let str = at.format('ddd M/D/YYYY, h:mm A');
        if (appt.pickupDate) {
            const pickupdate = moment(appt.pickupDate.toDate()).tz(appt.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
            if (at.format('YYYY/MM/DD') == pickupdate.format('YYYY/MM/DD')) {
                str += ` to ${pickupdate.format('h:mm A')}`;

            } else {
                str += ` to ${pickupdate.format('ddd M/D/YYYY, h:mm A')}`;
            }
        }
        let firstDigitIndex = str.search(/\d/);
        let strArray = str.split("");
        for (let x = 0; x < firstDigitIndex; x++) {
            strArray[x] = strArray[x].toUpperCase();
        }

        return strArray.join("");

    }

}

