import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "../../services/guards/auth.guard";
import { SuperAdminGuard } from "../../services/guards/super-admin.guard";

import { DashboardFrameComponent } from "./dashboard-frame/dashboard-frame.component";

const dashboardRoutes: Routes = [
	{
		path: "",
		component: DashboardFrameComponent,
		//canActivateChild: [AuthGuard],
		canActivate:[AuthGuard],
		children: [
			//{
			//	path: "data-sync",
			//	loadChildren: () => import("../data-sync/data-sync.module").then(module => module.DataSyncModule),
			//},
			//{
			//	path: "archive",
			//	loadChildren: () => import("../archives/archives.module").then(module => module.ArchivesModule),
			//},
			{
				path: "settings",
				children: [
					{
						path: "notification",
						children: [
							{
								path: "",
								loadChildren: () => import("../settings/notifications/notifications.module").then((mod) => mod.NotificationsModule),
							},
						]
					},
					{
						path: "",
						loadChildren: () => import("../settings/settings.module").then(module => module.SettingsModule),
					}
				]
			},
			{
				path: "profile",
				loadChildren: () => import("../profile/profile.module").then(module => module.ProfileModule),
			},
			{
				path: "notifications",
				children: [
					{
						path: "",
						redirectTo: "settings",
						pathMatch: "full"
					},
					{
						path: "appointments",
						loadChildren: () => import("../appointments/appointments.module").then((mod) => mod.AppointmentsModule),
					}
				]
			},
			{
				path: "admins",
				loadChildren: () => import("../admins/admins.module").then(module => module.AdminsModule),
			},
			{
				path: 'accounts',
				loadChildren: () => import("../accounts/accounts.module").then(module => module.AccountsModule)
			},
			{
				path: "users",
				loadChildren: () => import("../users/users.module").then(module => module.UsersModule),
			},
			{
				path: "webcam-access",
				loadChildren: () => import("../webcam-access/webcam-access.module").then(module => module.WebcamAccessModule),
				canActivate: [SuperAdminGuard]
			},
			{
				path: "inquiry",
				loadChildren: () => import("../general-inquiries/inquiries.module").then(module => module.InquiriesModule),
			},
			{
				path: "leads",
				loadChildren: () => import("../leads/leads.module").then(module => module.LeadsModule),
			},
			{
				path: "drop-offs",
				loadChildren: () => import("../drop-offs/drop-offs.module").then(module => module.DropoffsModule),
			},
            {
                path: "approvals",
                loadChildren: () => import("../approvals/approvals.module").then(module => module.ApprovalsModule),
            },
			{
				path: "meet-greets",
				loadChildren: () => import("../meet-greets/meet-greets.module").then(module => module.MeetGreetsModule),
			},
      {
        path: "meet-greets/calendar",
				loadChildren: () => import("../meet-greets/meet-greets.module").then(module => module.MeetGreetsModule),
			},
      {
				path: "meet-greets/status",
				loadChildren: () => import("../meet-greets/meet-greets.module").then(module => module.MeetGreetsModule),
			},
			// {
			// 	path: "dog-requests",
			// 	loadChildren: () => import("../dog-requests/dog-requests.module").then(module => module.DogRequestsModule),
			// },
			{
				path: "access-requests/deprecated",
				loadChildren: () => import("../user-requests/user-requests.module").then(module => module.UserRequestsModule),
			},
			{
				path: "access-requests",
				loadChildren: () => import("../access-requests/access-requests.module").then(module => module.AccessRequestsModule),
			},
			{
				path: "",
				children: [
					{
						path: "appointments",
						loadChildren: () => import("../appointments/appointments.module").then((mod) => mod.AppointmentsModule),
					},
					{
						path: "",
						redirectTo: "appointments",
						pathMatch: "full"
					}
				]
			},
		]
	},
];

@NgModule({
	imports: [RouterModule.forChild(dashboardRoutes)],
	exports: [RouterModule],
	bootstrap: [DashboardFrameComponent]
})

export class DashboardRoutingModule { }

export type routeDataOptionsObj = {
	// Can't have default values when options are missing
	// If option is missing, it won't be changed when the page changes
	// Therefore, need to make all root level options required since we don't want the values to carry over page to page by default
	fixedTitleBarFooter: boolean,
	contentWrapClass: string,
	mainContentCardClass: string,
	titleBarOptions: {
		title: string,
		showCreateBtn?: boolean,
		createBtnUrl?: string,
		showSearchbar?: boolean,
		searchbarPlaceholder?: string,
		searchByLocation?: boolean,
		useAutocomplete?: boolean,
		showDogNameInAutocomplete?: boolean,
		useSearchbarResults?: boolean,
		numAutocompleteItems?: number,
		searchConfigurations?: { filters?: string, indexName: string, tag?: string }[],

	}
};
