
import firebase from 'firebase';
import { Entity } from "src/models/entity";

import { VaccineReleaseFormStruct } from "../structs/VaccineReleaseFormStruct";

export class VaccineReleaseForm extends Entity<VaccineReleaseForm> implements VaccineReleaseFormStruct {
    isPdfReady?: boolean;
    finalText?: string;
    signature?: { dateTime: firebase.firestore.Timestamp; dataURL: string; ip: string; sigId: string; };
    locationId: string;
    meetGreetId: string;
    timezone?: string;
	petId: string;
	userId?: string;
	accountId: string;
	signed?: boolean;
	agreementDoc?: string;
	createdAt: firebase.firestore.Timestamp;
	updatedAt: firebase.firestore.Timestamp;
    filename?: string;
    deleted?: boolean;
    isAdminUploaded?: boolean;

	constructor(data?: VaccineReleaseFormStruct, id?: string){
		super(data, id);
	} 
    
}