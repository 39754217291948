import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, DialogPosition } from '@angular/material';

@Component({
  selector: 'app-search-menu-popup',
  templateUrl: './search-menu-popup.component.html',
  styleUrls: ['./search-menu-popup.component.scss'],
})
export class SearchMenuPopupComponent implements OnInit {

	public searchbarPlaceholder: string;
	public searchByLocation: boolean;
	public useAutocomplete: boolean = true;
	public showDogNameInAutocomplete: boolean;
	public numAutocompleteItems: number = 20;
	public searchConfigurations: { filters: string, indexName: string }[] = [];

	public locationId: string = "nolocation";
	public showSearchbar: boolean = true;
	public titleBarOptions: any;

	public container: ElementRef;
	public results: any[];

	public get primaryConfig() {
		if (this.searchConfigurations.length > 0) {
			return this.searchConfigurations[0];
		} else return null;
	}

  constructor(
    @Inject(MAT_DIALOG_DATA) public routeData,
    public dialogRef: MatDialogRef<SearchMenuPopupComponent>
  ) {

	this.locationId = routeData.locationId
    if (!routeData.titleBarOptions && !this.titleBarOptions) return;

    if (routeData.titleBarOptions) {

      this.titleBarOptions = JSON.parse(JSON.stringify(routeData.titleBarOptions));
    }
	this.container = routeData.containerRef
    this.compileConfiguration();
  }

	ngOnInit() {
		//this.onResize();
	}

	public selected(entity: any) {
		this.dialogRef.close(entity);
	}
	public cancel(entity: any) {
		this.dialogRef.close(null);
	}
  public compileConfiguration() {
		if (this.locationId && this.titleBarOptions) {
			// Set Interface Options
			
			this.searchbarPlaceholder = this.titleBarOptions.searchbarPlaceholder || "";
			this.searchByLocation = (this.titleBarOptions.searchByLocation === undefined) ? true : this.titleBarOptions.searchByLocation;
			this.showDogNameInAutocomplete = (this.titleBarOptions.showDogNameInAutocomplete === undefined) ? true : this.titleBarOptions.showDogNameInAutocomplete;
			this.numAutocompleteItems = this.titleBarOptions.numAutocompleteItems || this.numAutocompleteItems;

			window.adminlog.print(this.titleBarOptions.searchConfigurations);
			if (this.locationId !== 'nolocation' && (typeof this.titleBarOptions.searchByLocation === 'undefined' || this.titleBarOptions.searchByLocation)) {
				if (this.titleBarOptions.searchConfigurations) {
					this.searchConfigurations = this.titleBarOptions.searchConfigurations.map(config => {
						const modconfig = JSON.parse(JSON.stringify(config));

						window.adminlog.print(modconfig.filters);
						if (modconfig.filters) {
							modconfig.filters = (<string>modconfig.filters).concat(` AND locList:${this.locationId}`)
						} else {
							modconfig.filters = `locList:${this.locationId}`;
						}
						window.adminlog.print(modconfig.filters);
						return modconfig;
					});
				}

			} else {
				//
				this.searchConfigurations = [];
			}

			window.adminlog.print(this.searchConfigurations);



		}
	}


	public selectEntity(entity: any) {
		window.adminlog.print(entity);
		this.dialogRef.close(entity);
	}

	public onCloseBtnClick():void {
		this.dialogRef.close();
	}

	public onResultsPublished(searchResults: { entities: any[], indexName: string, tag: string }): void {
		window.adminlog.print(searchResults);
		this.results = searchResults.entities;

		//this.ionEventBus.publish("dashboard-searchbar:search-results", searchResults);

	}
	public onResize(_dome?) {
		this.dialogRef.updatePosition(getPosition(this.container));
	}
}
export function getPosition(container: ElementRef): DialogPosition{
	const matdiaconfig = new MatDialogConfig();
	const rect: DOMRect = container.nativeElement.getBoundingClientRect();
	window.adminlog.print(rect);
	matdiaconfig.position = { left: `${rect.left}px`, top: `${rect.bottom + 2}px` };
	return matdiaconfig.position;
}
