import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/services';

import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Events, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-approvals-sort-popover',
  templateUrl: './approvals-sort-popover.component.html',
  styleUrls: ['./approvals-sort-popover.component.scss'],
})
export class ApprovalsSortPopoverComponent implements OnInit {

    @Input() currentSort: string;
    @Input() sortBelongsTo: string;

    public faCheck = faCheck;
    public selected: string = "all";

  constructor(
      public settingsService: AuthService,
      public ionEventBus: Events,
      public popoverController: PopoverController,
  ) { }

  ngOnInit() {
    console.log( this.currentSort ); 
    this.selected = this.currentSort;
    if( this.currentSort === null) { 
        this.selected = "default";
    }
}

public selectionChange( status ){
    this.selected = status;
    this.ionEventBus.publish("sort-approval-sort", { "status": this.selected } );
    this.popoverController.dismiss();
  }
}
