import { Entity } from "../entity";

import  firebase  from "firebase/app";


export class Upload { 

    id? : string;

    accountId:string;
    userId: string;
    locationId? : string;
    meetAndGreetId? : string;
    updateId? : string;
    type: 'vaccination' | 'document';
    userType: 'admin' | 'user';
    from: 'meetAndGreet' | 'vaccinationUpdate';
    file;
    fileName: string ;
    petexec : { 
        uploadedAt? : Date | firebase.firestore.Timestamp | string;
        uploaded: boolean;
    }
    createdAt: Date | firebase.firestore.Timestamp | string;
    deleted : boolean;

    constructor( data?: Upload, id?: string ) {
        //super(data, id);
        if( id ) { 
            this.id = id;
        }
        if( data ){ 
            if( data.from === 'vaccinationUpdate' ) {
                this.updateId = data.updateId;
            }
            else{ 
                this.meetAndGreetId = data.meetAndGreetId;
            }
            this.type = data.type;
            this.userType = data.userType;
            this.from = data.from;
            this.file = data.file;
            this.fileName = data.fileName;
            
            if( data.petexec.uploaded ){ 
                this.petexec = {
                    uploadedAt: data.petexec.uploadedAt,
                    uploaded: data.petexec.uploaded
                };
            }
            else{ 
                this.petexec = {
                    uploaded: data.petexec.uploaded
                };
            }
            this.createdAt = data.createdAt;
            this.deleted = data.deleted;
        }
    }
    
}
