import { Pipe, PipeTransform } from '@angular/core';

import { Dropoff } from "src/models/entities";

@Pipe({
  name: 'dropOffBadgeText'
})
export class DropOffBadgeTextPipe implements PipeTransform {

  transform( dropOff : Dropoff ): any {
      /*if( typeof dropOff === "string"){ 
        if( dropOff === "Cancelled" ) { 
            return "Cancelled";
        }
        if( dropOff === "Done" ) {
            return "Complete";
        }
        if( new Date() > dropOff.dropOffTime && dropOff === "New" || dropOff=== "Updated" ) {
          return "PAST DUE";
        }
        return dropOff.status;
      }
      */
     // else{ 
        if( dropOff.status === "Cancelled" ) { 
            return "Cancelled";
        }
        if( dropOff.status === "Done" ) {
            return "Complete";
        }
        if( dropOff.status === "Updated" ) { 
            return "Delayed";
        }
        if( new Date() > dropOff.dropOffTime.toDate() && dropOff.status === "New" ) {
          return "PAST DUE";
        }
        return dropOff.status;
      //}
    
    

  }

}
