import { Injectable } from '@angular/core';
import { Entity } from 'src/models/entity';
import { ICsvGeneratorOptions, ICsvPrep } from './interfaces';




@Injectable({
  providedIn: 'root'
})
export class CsvGeneratorFactoryService {
    default_opts: ICsvGeneratorOptions = {};

    constructor() { }
    public createCsvGenerator<E extends Entity<any>, S>(titles: string[], prep: ICsvPrep<E, S> , opts: ICsvGeneratorOptions = {}) {
        // we could add composite sservices to the csv generator as needed as well, like have it use a database service that we give structure to with an interface
        const csvgen = new CsvGenerator<E, S>(titles, prep as ICsvPrep<E, S>);
        Object.assign(csvgen, this.default_opts, opts);
        return csvgen;

    }

}


export class CsvGenerator<T extends Entity<any>, S> implements ICsvGeneratorOptions {

    public file_prefix: string = 'temporary';
    public titles: string[]
    public cleanRegex: RegExp = /[&/\#+()$~%'"*<>{}]/g;
    public maskChar: string = '_';
    public csvEncoding: string = "data:text/csv;charset=utf-8,";


    protected dataStore: ICsvPrep<T, S>;

    protected get header() {
        return this.csvEncoding + this.titles.join(',') + "\r\n";
    }

    public constructor(titles: string[], prep: ICsvPrep<T, S>){
        this.titles = titles;
        this.dataStore = prep;
    }

    public downloadCsvExtract(dataList: T[]): Promise<void> {
        return this.prepareData(dataList).then(data => {
            // console.log( dataList );
            this.openCsv(this.conversion(data))});
    }

    protected prepareData(items: T[]): Promise<(T & S)[]> {
        return Promise.all(items.map(item => {
            return this.dataStore.prepareCsvData(item);
        }));
    }

    public loopData(dataList: any){
        let csv = [];
        try {
            for( let x = 0; x < dataList.length;x++ ){
                const cdata = this.dataStore.csvItemMap(dataList[x]);
               // console.log(cdata)
                if(cdata){
                csv.push(cdata );
                }
            }
            
        } catch (error) {
            
        }
        return csv;
    }

    public conversion ( dataList:(T & S)[] ) {
        let csv = [];
        let csvString = this.header;
        csv = this.loopData(dataList);
        csv.forEach( rowArray => {
            let row = rowArray.map(col => col && col.replace(this.cleanRegex, this.maskChar).replace(/(,|\n|\r|\r\n)/g, " ")).join(",");
            csvString+=row+"\r\n";
        });
        return csvString;
    }

    public openCsv(csvText: string){
        var encodedUri = encodeURI(csvText);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${this.file_prefix}_${Date.now()}.csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
        link.remove();
    }

}
