import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { AuthService } from "./auth.service";

import { LogEntryStruct } from "src/models/structs";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class ActivityLogService {

	public collection = "logs";

	constructor (private dbService: FirestoreService, private authService: AuthService){}

	public getEntityLogEntries(parentCollection:string, parentId:string):Observable<LogEntryStruct[]> {

		const collectionFullPath = parentCollection + "/" + parentId + "/" + this.collection;
		return this.dbService.getCollection(collectionFullPath, {orderByField: "createdAt", orderByDir: "desc"} as queryOptions).pipe(
			map((logEntryDocs) => {
				return logEntryDocs.map((logEntryDoc) => {
                    //console.log(collectionFullPath)
					//console.log(logEntryDoc.data())
					return logEntryDoc.data() as LogEntryStruct;

				});
			})
		);

	}

	public addLogEntry(parentCollection:string, parentId:string, action:string, message: string, dataObject:any = {}):Promise<string> {

        const collectionFullPath = parentCollection + "/" + parentId + "/" + this.collection;
        console.log("from log entry");
        console.log( collectionFullPath );
		return new Promise((resolve, reject) => {

			let now = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());
			
			const logEntryData: LogEntryStruct = {
				action: action,
				createdAt: firebase.firestore.Timestamp.fromDate(now),
				editedBy: this.authService.user._id,
                msg: message,
                ...dataObject
			}
			const id = this.dbService.db.collection(collectionFullPath).doc().id;
			this.dbService.setDoc(collectionFullPath, id, logEntryData).then((logEntryDoc) => {
                console.log(logEntryDoc);
                console.log( "after log created" );
                resolve(id);
			}).catch( err => { 
                console.log( "error", err );
            } );;

		});

	}
	
}
