import { Component, OnInit, Input } from "@angular/core";

import { Observable } from "rxjs";

import { MemoService } from "src/services";

import { MemoStruct } from "src/models/structs";
import { Router } from "@angular/router";

@Component({
	selector: "entity-memos",
	templateUrl: "./entity-memos.component.html",
	styleUrls: ["./entity-memos.component.scss"]
})

export class EntityMemosComponent implements OnInit {

	@Input() parentCollection: string = "";
	@Input() parentId: string = "";
	@Input() configurableAddendum: string = "";
	@Input() header: string = "Notes";
	@Input() hide_form: boolean = false;

    

	public newMemoText: string = "";

	public memos: Observable<MemoStruct[]>;

	public addBtnDisabledOverride = false;

	constructor(public memoService: MemoService, public router: Router) { }

	public ngOnInit():void {

		if(this.parentCollection && this.parentId){
			this.memos = this.memoService.getMemos(this.parentCollection, this.parentId);
		}

	}

    public goToDuplicateLead( leadId: string): void {
        console.log( "leadId", leadId );
        this.router.navigate(['/leads', leadId]);
    }

	public onAddBtnClicked(evt:MouseEvent):void {

		this.addBtnDisabledOverride = true;

		this.memoService.addMemo(this.parentCollection, this.parentId, this.newMemoText).then((result) => {
			this.newMemoText = "";
			this.addBtnDisabledOverride = false;
		});

	}

	public isAddBtnDisabled():boolean {

		return (this.addBtnDisabledOverride || this.newMemoText === "");

	}

}
