import { Pipe, PipeTransform } from "@angular/core";

import { Observable } from "rxjs";

import { UserService } from "src/services";

@Pipe({
	name: "getUserName"
})

export class GetUserNamePipe implements PipeTransform {

	constructor(private userService: UserService) { }

	public async transform(userId: string):Promise<string> {

        if( userId ){

            const user = await this.userService.getById(userId);
		    return user.firstName + " " + user.lastName;

        }else{
            return '';
        }
		
		
	}

}