import { Entity } from "../entity";

import { UserStruct, EmergencyContact, LocationsProperty } from "../structs";
import firebase from 'firebase';
// Validates and Creates User. Publishes to Firebase a new user or any update.
export class User extends Entity<User> implements UserStruct {
	userId: string;
	email: string;
	username?: string;
	firstName: string;
	lastName: string;
	address: {
		address: string;
		zip: string;
		city: string;
		state: string;
	};
	phoneNumber: string;
	photo: string;
	defaultLocation: string;
	locations?: LocationsProperty;
	//dogs: any[];
	emergencyContact?: EmergencyContact;
	approved?: boolean;
	marketing?: boolean;
	notifications?: boolean;
	pushTokens: any;
	showPriority?: number;
	createdAt: firebase.firestore.Timestamp;
	updatedAt?: firebase.firestore.Timestamp;
	timezone?: string;

    vehicleDescription?: string;

	protected readonly _preps = {
		username: (_u: User) => _u.firstName.toLowerCase()
	}

	constructor(data?: UserStruct, id?: string) {

		super({}, id);

		// Whenever a default value needs to be set for a class property, it needs to be set inside the constructor
		this.photo = "/assets/images/default-user-avatar.svg";
		this.notifications = true;

		// Then need to call hydrate() after setting default property values
		this.hydrate(data);

	}

	public get isValid():boolean {
		// TODO Need to test this when email = undefined
		return !(this.email === "");
	}
}
