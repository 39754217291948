import { Component, OnInit } from "@angular/core";

import { NavParams, Events, PopoverController } from "@ionic/angular";

import { Location } from "src/models/entities";

@Component({
    selector: "app-location-select-popover",
    templateUrl: "./location-select-popover.component.html",
    styleUrls: ["./location-select-popover.component.scss"],
})

export class LocationSelectPopoverComponent implements OnInit {

    public locations: Location[];
    public searchbarPlaceholder: string;
    public searchedLocations: Location[];

    constructor(
        public navParams: NavParams,
        public ionEventBus: Events,
        public popoverController: PopoverController
    ) {
        this.searchbarPlaceholder = "Search locations";
        this.searchedLocations = [];
    }

    ngOnInit(): void {

        this.locations = this.navParams.data.locations;

        for (let x = 0; x < this.locations.length; x++) {
            if (this.locations[x].salesforceConnection !== true) {
                this.searchedLocations.push(this.locations[x]);
            }
        }
    }

    onSearchQueryChange(event) {
        this.searchedLocations = [];
        for (let x = 0; x < this.locations.length; x++) {
            if (this.locations[x].name.toLocaleLowerCase().includes(event.detail.value.toLocaleLowerCase())) {
                this.searchedLocations.push(this.locations[x]);
            }
        }
    }

    public locationSelected(locationId: string): void {

        this.ionEventBus.publish("user-location-selected", locationId);

        this.popoverController.dismiss();

    }

}
