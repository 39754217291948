import { Pipe, PipeTransform } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { LocationService } from "src/services";

@Pipe({
	name: "getLocationName"
})

export class GetLocationNamePipe implements PipeTransform {

	constructor(private locationService: LocationService) { }

	public transform(locationId: string):Observable<string> {

		return this.locationService.getByIdObs(locationId).pipe(
			map((location) => {
				return location.name;
			})
		);
		
	}

}