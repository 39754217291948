import { Injectable } from "@angular/core";

import { HttpHeaders, HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import firebase from "firebase/app";

import { environment } from "src/environments/environment";

import { FirestoreService, queryOptions } from "./firestore.service";

import { AuthService } from "./auth.service";

import { FileUploadService } from "./file-upload.service";

import { Dog, Location, Pet, MeetGreetAppointment } from "src/models/entities";
import { DogStruct, LocationsProperty, PetStruct, PetExecBreed, ShotDetailsStruct } from "src/models/structs";

import * as moment from "moment-timezone";
import { ValidationErrors } from '@angular/forms';

@Injectable({
	providedIn: "root"
})

export class PetService {

	public collection = "pets";

	public fileUploadFolder = "vaccines";
	public historyUploadFolder = "history";
	public historyCollection = "petHistory";


	constructor (private http: HttpClient, private dbService: FirestoreService, private authService: AuthService, private fileUploadService: FileUploadService){}

	public getById(petId: string):Observable<Pet> {
        if( !petId ){ 
            return of( {} as Pet );
        }

		return this.dbService.getDoc(this.collection, petId).pipe(
			map((dogDoc) => {
				return new Pet({data: dogDoc.data() as PetStruct, id: petId});
			})
		)

    }

    public getByIdPromise( petId: string ) : Promise<any>{  
        return new Promise((resolve, reject) => {
            this.dbService.getDocSnapshot(this.collection, petId).then( document => {
                if( document.exists ){ 
                    resolve(
                        new Pet({ data: document.data() as Pet , id : document.id })
                    );
                }
                else { 
                    reject([]);
                }
                
            });
        });
    }
    
    public getPetIdByPetexecId( petexecId: number ): Promise<any>{ 
        return new Promise((resolve, reject) => {
            return this.dbService.db.collection(this.collection).where("petid" ,"==", petexecId).get().then(snapshot=> { 
                if( snapshot.empty ){ 
                    reject();
                }
                snapshot.forEach( doc => {
                    return resolve( doc.id );
                })
                
            });
        });

       }

	public getSnapshotById(petId: string):Promise<Pet> {

		return new Promise((resolve, reject) => {

			this.dbService.getDocSnapshot(this.collection, petId)
				.then((result) => {
					resolve(new Pet({data: result.data() as PetStruct, id: petId}));
				})
				.catch((error) => {
					reject(error);
				});

		});

	}

	public async getPetsByAccountId(accountId: string):Promise<Pet[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["functional.ownerId", "==", accountId]
			],
			limit: 20
		};

		try {

			const petDocs = await this.dbService.getCollectionOnce(this.collection, queryOptions);

			return petDocs.map(petDoc => new Pet({data: (petDoc.data() as PetStruct), id: petDoc.id}));

		}
		catch (err) {
			throw err;
		}

	}

	public getPetsByAccountIdObs(accountId:string):Observable<Pet[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["functional.ownerId", "==", accountId]
			]
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((dogDocs) => {
				return dogDocs.map((dogDoc) => {

					const dog = new Pet({data: dogDoc.data() as PetStruct, id: dogDoc.id});

					return dog;

				});
			})
		);

	}

    public getPetUploadedVaccineData( petId: string ): Promise<any> {
        return new Promise((resolve, reject) => {
            return this.dbService.db.collection(this.collection).doc(petId).get().then(snapshot=> {
                if( !snapshot.exists ){ 
                    reject( false )
                }
                if( !snapshot.data().functional.uploadedVaccines || Object.keys( snapshot.data().functional.uploadedVaccines ).length == 0 ){ 
                    resolve(false);
                }
                return resolve( snapshot.data().functional.uploadedVaccines );
                
                
            });
        });
    }

	public async buildPet(pet: Pet): Promise<{ message: string }> {
		const petId = pet._id;
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{ message: string }>((resolve, reject) => {

			this.http.put(`${environment.firebaseFunctions.buildPet}/${petId}`, { petId: petId }, httpOptions).subscribe(
				(result: { message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});

	}

	public async archivePet(pet: Pet, note = ''): Promise<{ message: string }> {
		const petId = pet._id;
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{ message: string }>((resolve, reject) => {

			this.http.put(`${environment.firebaseFunctions.archivePet}/${petId}`, { petId: petId, note: '' }, httpOptions).subscribe(
				(result: { message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});

	}


	public async refreshPet(petId: string): Promise<{ message: string }> {
		
		return new Promise<{ message: string }>((resolve, reject) => {

			this.http.put(`${environment.firebaseFunctions.refreshPetInformation}/${petId}`, { petId: petId }).subscribe(
				(result: { message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});

	}

	public async generatePetHistoryFile(mg: MeetGreetAppointment): Promise<{ success: boolean, message: string }> {

		let endpointUrl: string = environment.firebaseFunctions.generatePetHistory.replace("{petId}", mg.petId[0]);

		return new Promise<{ success: boolean,  message: string }>((resolve, reject) => {

			this.http.put(endpointUrl, { petId: mg.petId[0] }).subscribe(
				(result: { success: boolean, message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});
	}

    public async generatePetHistoryFileVersionTwo(meetAndGreetId: string, onBoardingFormId: string): Promise<{ success: boolean, message: string, id: string }> {

		let endpointUrl: string = environment.firebaseFunctions.generatePetHistoryVersionTwo;

		return new Promise<{ success: boolean,  message: string, id: string }>((resolve, reject) => {

			this.http.put(endpointUrl, {meetAndGreetId: meetAndGreetId,  onBoardingFormId: onBoardingFormId }).subscribe(
				(result: { success: boolean, message: string , id: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});
	}

	public async getDogBreeds(locationId: string):Promise<PetExecBreed[]> {

		
		let endpointUrl: string = environment.firebaseFunctions.getPetBreeds.replace("{locationId}", locationId);

		return new Promise<PetExecBreed[]>((resolve, reject) => {
			this.http.get(endpointUrl)
				.subscribe(
					(result) => {
						window.adminlog.print("result?",result)
						resolve(<PetExecBreed[]>((<any>result).breeds));
					},
					(error) => {
						window.adminlog.print(error);
						reject(error);
					}
				);
		});

	}

	public async getExecutivePet(petId: string): Promise<PetStruct> {

		let endpointUrl: string = environment.firebaseFunctions.getPet.replace("{petId}", petId);

		return new Promise<PetStruct>((resolve, reject) => {
			this.http.get(endpointUrl)
				.subscribe(
					(result) => {
						resolve(<PetStruct>((<any>result).pet));
					},
					(error) => {
						window.adminlog.print(error);
						reject(error);
					}
				);
		});

	}
	public async getExecutivePetShots(petId: string): Promise<ShotDetailsStruct[]> {

		let endpointUrl: string = environment.firebaseFunctions.getPetShots.replace("{petId}", petId);

		return new Promise <ShotDetailsStruct[]>((resolve, reject) => {
			this.http.get(endpointUrl)
				.subscribe(
					(result) => {
						resolve(<ShotDetailsStruct[]>((<any>result).shots.filter( shot => shot.expiration || shot.isRequired ).map(shot => {
                            shot.expiration = firebase.firestore.Timestamp.fromMillis(shot.expiration);
                            return shot;
						})));
					},
					(error) => {
						window.adminlog.print(error);
						reject(error);
					}
				);
		});

	}


	public update(dogId: string, updatedData: any):Promise<void> {
		return this.dbService.updateDoc(this.collection, dogId, updatedData);
	}

	public mapDogToPet(dogObj: DogStruct):PetStruct {

		let petObject: PetStruct = {
			petName: dogObj.name || "",
			birthDate: dogObj.birth_date || null,
			breedid: dogObj.breedId || 0,
			breedname: dogObj.breedname || "",
			gender: dogObj.gender || "",
			authorizedToPickup: dogObj.authorizedToPickup,
			weight: dogObj.weight,
			neuteredState: dogObj.neuteredState,
			description: dogObj.color,

			functional: {
				locationId: "",
				companyID: "",
				dogs: {},
				access: "Pending",
			},
		};

		if(dogObj.vaccines){
			petObject.functional.vaccines = dogObj.vaccines;
		}

		if(dogObj.agreements){
			petObject.functional.agreements = dogObj.agreements;
		}

		return petObject;

	}

	public mapPetToDog(petObj: PetStruct):DogStruct {

		let dogBirthDate = null;

		if(typeof petObj.birthDate === "string"){

			dogBirthDate = (petObj.birthDate) ? firebase.firestore.Timestamp.fromDate(new Date(petObj.birthDate)) : null;

		}else if(petObj.birthDate instanceof Date){

			dogBirthDate = firebase.firestore.Timestamp.fromDate(petObj.birthDate);

		}else if(petObj.birthDate){

			dogBirthDate = petObj.birthDate;

		}

		let dogObject: DogStruct = {
			name: petObj.petName || "",
			gender: petObj.gender || "",
			birth_date: dogBirthDate || null,
			breedId: petObj.breedid || 0,
			breedid: petObj.breedid || 0,
			breed: petObj.breedname || petObj['breedName'] || "",
			breedname: petObj.breedname || petObj['breedName'] || "",

			color: petObj.description,
			authorizedToPickup: petObj.authorizedToPickup || "",
			neuteredState: petObj.neuteredState || null,
			weight: petObj.weight || ""
		};

		if(petObj.hasOwnProperty("functional")){

			if(petObj.functional.vaccines){
				dogObject.vaccines = petObj.functional.vaccines;
			}

			if(petObj.functional.agreements){
				dogObject.agreements = petObj.functional.agreements;
			}

		}

		return dogObject;

	}

	public async createPet(pet: PetStruct, addtnlInfo: { accountId?: string, locationId?: string, companyId?: string, access?: string } = {}):Promise<string> {

		pet.authorizedToPickup = pet.authorizedToPickup || "";
		pet.neuteredState = pet.neuteredState || "Unknown";
		pet.ptid = pet.ptid || 2;
		pet.vetid = pet.vetid || 0;

		// Set Pet `functional` field default values
		let updatedPetFunctionalObj = {
			ownerId: addtnlInfo.accountId || "",
			locationId: addtnlInfo.locationId || "",
			companyID: addtnlInfo.companyId || "",
			dogs: {},
			access: addtnlInfo.access || "Pending",
		} as any;

		// If pet data already has a functional obj, add any present values to the updated object data
		if(pet.functional){

			for(const property in pet.functional){

				if(pet.functional[property]){
					if(pet.functional[property]){

						updatedPetFunctionalObj[property] = pet.functional[property];
					}
				}

			}

		}

		// Set functional field back to updated object
		pet.functional = updatedPetFunctionalObj;
		if(pet.functional.vaccines) window.adminlog.print("pet shots before deploy", pet.functional.vaccines.map(vac => vac.shots));

		const petDoc = await this.dbService.insertDoc(this.collection, pet);

    	return petDoc.id;

	}

	public uploadVaccineDoc(vaccineFile: File):Promise<string> {

		return this.fileUploadService.uploadFile(this.fileUploadFolder, vaccineFile);

    }

    // Get Pet History
	public getPetHistory(petId: string): Observable<any> {
		return this.dbService.getDoc(this.historyCollection, petId).pipe(map(petref => {
			if (petref.exists) {
				const petHistory = petref.data();
				petHistory.id = petref.id;
				return petHistory;
			}
			return null;

		}));
	}

	// Set Pet History

	public insertPetHistory(id: string, history: any): Promise<any> {

		history.updatedAt = new Date(); // firebase.firestore.FieldValue.serverTimestamp();


		return new Promise((resolve, reject) => {
			this.dbService.db.collection(this.historyCollection).doc(id).set(history, { merge: true }).then(res => {
				window.adminlog.print("id of pet???", id);
				resolve(res);
			}).catch(err => reject(err));
		});
	}


	public uploadHistoryDoc(agreementFile: File): Promise<string> {

		return this.fileUploadService.uploadFile(this.historyUploadFolder, agreementFile);

	}

	public getPetErrors(pet: PetStruct, shots: ShotDetailsStruct[], currentDate = new Date()): PetErrors {

		const errors:any = {};
		//console.log("pet", pet);
        if(!pet) return errors;

		if (!pet.passedtemptest && !pet['passedTempTest']) errors.tempermentFailed = true;

		

		if (!shots.length) errors.shotsEmpty = true;
		else {
            for (let i in shots) {
                // expirydate date < appointment then error
               if (moment(currentDate).isAfter(shots[i].expiration.toDate())) {
                    errors.shotsExpired = true;
					break;
				}
			}
		}
		let isYoung = false;


        if (pet.birthDate) { 
            const cutoff = new Date(currentDate.toDateString());
            console.log("cutOffDate", cutoff);
            cutoff.setMonth(cutoff.getMonth() - 7);
			const birthDate = moment((pet.birthDate as string), 'YYYY-MM-DD').toDate();
            if (birthDate > cutoff) isYoung = true;            
		} 

		if (pet.neuteredState != "Neutered" && pet.neuteredState != "Spayed" && !isYoung) errors.isNotNeutered = true;
		if (Object.keys(errors)) return errors;
		return null;
	}

	
}

// For Form Validation of Pet
export type PetErrors = {
	tempermentFailed ?: boolean,
	shotsExpired?: boolean,
	shotsEmpty?: boolean,
	isNotNeutered?: boolean,
	petexecError?: boolean
}
