import {
	LeadStruct,
	LeadSource,
	LeadTag,
	LeadState,
	PreferredTime,
	RecordMap,
	LeadTagHistoryItem,
	LeadMeetGreetTemplate,

	UserStruct,
	DogStruct,
	UserRequestStruct,
	DogRequestStruct,
	InquiryStruct,
	DropoffStruct
} from "src/models/structs";
import firebase from 'firebase';
import { Entity } from "src/models/entity";
import moment from 'moment-timezone';

// Validates and Creates Admin. Publishes to Firebase a new admin or any update.
export class Lead extends Entity<Lead> implements LeadStruct {

	static readonly PREFERRED_TIME_AM: PreferredTime	= "AM";
	static readonly PREFERRED_TIME_PM: PreferredTime	= "PM";

	user: UserStruct;
	dog?: DogStruct;

	request?: UserRequestStruct;
	requestAccess?: DogRequestStruct;
	inquiry?: InquiryStruct;
	dropoff?: DropoffStruct;

	meetGreetTemplates?: {
		[hashId: string]: LeadMeetGreetTemplate
	};

	location: string;
	tag: LeadTag;
	status: LeadState;
	source: LeadSource;
	campaign?: string;

	prefDate?: firebase.firestore.Timestamp;
	prefTime?: PreferredTime;

	records: RecordMap;

	tagMap?: {
		[tag: number]: number
	};
	tagHistory: LeadTagHistoryItem[];
	score: number;

	indexed?: number;

	renew?: {
		at: number,
		as?: LeadTag,
		isTask?: boolean
	};

	lead_data?: [
        {
            adminLabel?: string,
            label?: string,
            value?: string
        }
    ];

    influencerCode? : string;
    influencerName? : string;
    influencerID? : string;
    offer?: string;
    mgID?: string;

	history?: {
		meetGreetIds: string[],
		leadIds: string[]
	};

	recent_history?: {
		meetGreetId: string,
		leadId: string
	};
	isRead?: boolean;

	createdAt: firebase.firestore.Timestamp;
	updatedAt: firebase.firestore.Timestamp;
	timezone: string;

    duplicate? : {
        status? : string, 
        updatedAt? : firebase.firestore.Timestamp
    };

	//private currTime;
	constructor(data?: LeadStruct, id?: string){
		super(data, id);
		//this.currTime = new Date();
        
	}
	public get renewAtDate() {
		if (this.isRenewable) {
			return moment(this.renew.at).format('MM/DD/YYYY');
		} else return null;
	}
	public get renewAtTime() {
		if (this.isRenewable) {
			return moment(this.renew.at).format('h:mm A');
		} else return null;
	}


	public get isRenewable() {
        
		return this.renew
			&& this.renew.at
			//&& (this.renew.at > this.currTime);
			;
	}
    
	public get isScheduledTask() {
		return this.isRenewable && this.renew.isTask;
	}
    
    public set isScheduledTask( newValue ){ 
        this.isScheduledTask =  null;
    }
    

	public getTitle():string {

		let title = "";
        //console.log("getTitle()", this.user );
		if(this.user.firstName){

			title = this.user.firstName;

			if(this.user.lastName){
				title += " " + this.user.lastName;
			}

		}else if(this.user.email){

			title = this.user.email;

		}

		return title;

	}


}
