import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meetAndGreetTimeSlotToMinutesString'
})
export class MeetAndGreetTimeSlotToMinutesStringPipe implements PipeTransform {

  transform(value: any): any {
    if( value === 0 ){ 
        return "None";
    }
    return value + " minutes";
  }

}
