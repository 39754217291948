import { Component,Input, OnInit } from '@angular/core';

import { NavParams, Events, PopoverController } from "@ionic/angular";


interface SortOption { 
    value: String;
    label: String;
}

@Component({
  selector: 'app-sort-selection-overiew-popover',
  templateUrl: './sort-selection-overiew-popover.component.html',
  styleUrls: ['./sort-selection-overiew-popover.component.scss'],
})
export class SortSelectionOveriewPopoverComponent implements OnInit {

    public sortOptions = [];
    public get extended() {
        return this.sortOptions.length > 6;
    }
    private selected;
    //private screenSizeRequiringOverflow = false;

  constructor(
    public navParams: NavParams, 
    public ionEventBus: Events,
    public popoverController: PopoverController
  ) { }

    
  ngOnInit() {
      //if( window.innerHeight < 700 ){ 
      //    if( this.sortOptions.length > 6 ){ 
      //      this.screenSizeRequiringOverflow = true;
      //    }
      //}
      //if( window.innerHeight < 600 ){ 
      //  this.screenSizeRequiringOverflow = true;
      //}
        this.sortOptions = this.navParams.data.sortOptions;
        for( let x = 0; x < this.sortOptions.length; x++ ){ 
            if( this.sortOptions[x].value == this.navParams.data.sortSelected.value ){ 
                this.selected = this.sortOptions[x];
            }
        }
      
      window.adminlog.print( this.sortOptions );
  }

  sortOptionSelected(sortOption){
      for( let x = 0; x < this.sortOptions.length ; x++  ){ 
        if( sortOption == this.sortOptions[x].value ){ 
            this.selected = this.sortOptions[x];
        }
      }
      this.ionEventBus.publish("user-sort-selected", this.selected);
      this.popoverController.dismiss();
  }


}
