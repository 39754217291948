import { Entity } from "../entity";

import { AdminStruct } from "../structs";

// Validates and Creates Admin.
export class Admin extends Entity<Admin> implements AdminStruct {

	static readonly ROLE_SUPER_ADMIN: string = "Super Admin";
	static readonly ROLE_STORE_ADMIN: string = "Store Admin";

	adminId: string;
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	role: string;
	defaultLocation: string;
	locations: string[];
	
	constructor(data?: AdminStruct, id?: string) {
		super(data, id);
	}

	public isSuperAdmin():boolean {

		return (this.role === Admin.ROLE_SUPER_ADMIN) ? true : false;

	}
}
