import { Pipe, PipeTransform } from "@angular/core";

import { Observable } from "rxjs";

import { AccountService, UserService } from "src/services";

@Pipe({
	name: "getPetParentName"
})

export class GetPetParentNamePipe implements PipeTransform {

	constructor(private userService: UserService, private accountService: AccountService ) { }

	public async transform( appointment: any ):Promise<string> {

        if( appointment.userId ){

            const user = await this.userService.getById( appointment.userId );
		    return user.firstName + " " + user.lastName;

        }else if( appointment.accountId ){
            
            const account = await this.accountService.getById( appointment.accountId );
            return account.firstName + " " + account.lastName;

        }else{
            return '';
        }
		
		
	}

}