import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, OnDestroy} from "@angular/core";

import { Events } from "@ionic/angular";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import algoliasearch from "algoliasearch/lite";

import { environment } from "src/environments/environment";

import { LocationService } from "src/services";

import { CustomSearchbarWidgetComponent } from "./custom-searchbar-widget/custom-searchbar-widget.component";

import { SearchParameters } from "angular-instantsearch/instantsearch/instantsearch";

import { SearchResultUserStruct, DogStruct, AccountStruct } from "src/models/structs";
import { searchConfiguration } from '../dashboard-title-bar/dashboard-title-bar.component';





@Component({
	selector: "dashboard-searchbar",
	templateUrl: "dashboard-searchbar.component.html",
	styleUrls: ['dashboard-searchbar.component.scss']
})

export class DashboardSearchbarComponent {

	@Input() numAutocompleteItems: number;
	@Input() searchbarPlaceholder: string;
	//private _searchbarPlaceholder: string = '';
	//@Input() public set searchbarPlaceholder(val: string) {
	//	if (val !== this._searchbarPlaceholder) window.adminlog.print(val, this._searchbarPlaceholder);
	//	this._searchbarPlaceholder = val;
	//};
	//public get searchbarPlaceholder(): string {
	//	return this._searchbarPlaceholder;
	//}
	@Input() useAutocomplete: boolean;
	@Input() filterByLocation: boolean;
	@Input() showDogName: boolean;
	@Input() configurations: { indexName: string, searchClient: string, tag?: string}[] = [];
	@Input() autoCompleteOverlay: boolean = true;
    public searchQuery: string = '';
    public selectAllFilter: boolean = false;

	public get primaryTag() {
		return (this.configurations.length > 0 ? this.configurations[0].tag || this.configurations[0].indexName : '')
	}
	public get primaryConfig() {
		if (this.configurations.length > 0) {
			return this.configurations[0];
		} else return null;
	}

	public hasFocus: boolean = false;

	@Output("onResults") public resultsEvt: EventEmitter<{ entities: any[], indexName: string, tag: string }> = new EventEmitter();
	@Output("onItemSelected") public entitySelectedEvt: EventEmitter<{ entity: any, indexName: string, tag: string }> = new EventEmitter();
	@Output("onQuery") public query: EventEmitter<string> = new EventEmitter();

	public get searchbar(): CustomSearchbarWidgetComponent{
		return CustomSearchbarWidgetComponent.Instance['default'];
	}

	public dropdownItems = [];

	// @ViewChild(CustomSearchbarWidgetComponent) public searchbar: CustomSearchbarWidgetComponent;
	@ViewChild("autocompleteList", { static: false }) autocompleteList: ElementRef;

	public autoCompleteItems: { [tagName: string]: any[] } = {};


	public static searchClient = null;
	public get searchClient(){
		return DashboardSearchbarComponent.searchClient;
	}
	public set searchClient(val: any){
		DashboardSearchbarComponent.searchClient = val;
	}

	public highlightedIndex:number = 0;

	public lastMousePos:{x:number, y:number} = {x: 0, y: 0};

	constructor(public ionEventBus: Events, public element:ElementRef, public locationService:LocationService) {
		this.searchClient = algoliasearch(environment.algolia.searchClientParams.appID, environment.algolia.searchClientParams.appKey);

		this.numAutocompleteItems = 20;
		this.searchbarPlaceholder = "";
		this.useAutocomplete = false;
		this.filterByLocation = true;
		this.showDogName = true;


		// To do add listener to ion event reset so we reset dropdown list.
		this.ionEventBus.subscribe("dashboard-searchbar:reset", this.emptyList);
		// to do when destroyed unsubscribe.
		// applythe unsubscribe change to all overviews with a listener.
	}


	public ngOnInit():void {
        this.ionEventBus.subscribe('search-filter', filterData  => {
            console.log( "filterDate", filterData );
            this.selectAllFilter = null;
            this.searchQuery = null;
            if( filterData.filterType != null ){ 
                console.log( "filterType", filterData );
                this.selectAllFilter = filterData.filterType === 100 ? true : false;
                this.searchQuery = `locations.${filterData.locationId}.execAccess=${filterData.filterType}`;
            }
        });

	}
	public ngOnDestroy(): void {
		this.ionEventBus.unsubscribe("dashboard-searchbar:reset");
	}
	protected emptyList = () => {
		window.adminlog.print("emptying list?", this.dropdownItems);
		this.dropdownItems = [];
		this.autoCompleteItems = {};
	}

	public ngAfterViewInit(): void {
		window.adminlog.print(this.searchbar);
	}


	public getSearchParams(config: any): SearchParameters {
		let searchParams: SearchParameters = {
			length: this.numAutocompleteItems,
			offset: 0,
		};

		if (config.filters) {
			searchParams.filters = config.filters || '';
		}
        
        if(this.selectAllFilter) {
            searchParams.filters = config.filters;
        } else  {
            if(this.searchQuery) {
                searchParams.filters += (searchParams.filters === '') ? this.searchQuery : ` AND ${this.searchQuery}`;
            }
        }



        //console.log("searchParams", searchParams.filters);
        
        
        

		return searchParams;

	}
	public getSettings(config: searchConfiguration) {
		// window.adminlog.print(config);
		return { searchClient: this.searchClient, indexName: config.indexName };
	}



	public setSearchbarQuery(value:string):void {
		if(this.searchbar) this.searchbar.setSearchQuery(value);
        else window.adminlog.error("Search Bar Deactivated");

	}

	public publishSearchResultsEvt(indexName: string, tag: string):void {
		this.resultsEvt.emit({ entities: this.autoCompleteItems[tag || indexName] || [], indexName: indexName, tag: tag});
	}

	@HostListener("mousemove", ['$event'])
	onMouseMove(evt:MouseEvent):void {
		this.lastMousePos = {x: evt.screenX, y: evt.screenY};
	}

	public onArrowKeyPress(evt:KeyboardEvent):void {

		if( !this.showAutocompleteDropdown() ){
			evt.preventDefault();
			return;
		}

		if(evt.key === "ArrowUp"){

			if(this.highlightedIndex < 1){

				this.highlightedIndex = (this.dropdownItems).length - 1;

			}else{

				this.highlightedIndex--;

			}

		}else if(evt.key === "ArrowDown"){

			if (this.highlightedIndex >= ((this.dropdownItems).length - 1)) {

				this.highlightedIndex = 0;

			}else{

				this.highlightedIndex++;

			}

		}

		const listItemElements = this.element.nativeElement.querySelectorAll(".autocomplete-dropdown-item");

		listItemElements[this.highlightedIndex].scrollIntoView({block: "nearest"});

	}

	public onItemHovered(evt:MouseEvent, hoveredItemIndex:number):void {

		if((evt.screenX === this.lastMousePos.x) && (evt.screenY === this.lastMousePos.y)){

			evt.preventDefault();

		}else{

			this.highlightedIndex = hoveredItemIndex;

		}

	}

	public onItemSelected(entity: { label: string, value: any }= null, config = this.primaryConfig): void {


		if (!entity) {
			entity = this.dropdownItems[this.highlightedIndex];
		}
		this.showValue = this.getEntitySearchName(entity.value);

		const selectedEntity = entity.value;
		const label = entity.label || config.indexName;
		if (selectedEntity && this.showAutocompleteDropdown()){

			this.highlightedIndex = 0;

			this.setSearchbarQuery(this.getEntitySearchName(selectedEntity));

			// this.searchbar.instantBlur();

			this.entitySelectedEvt.emit({ entity: selectedEntity, indexName: label, tag: config.tag });

		}


		if (this.copyValue) {

			window.adminlog.print("Emitting query ", this.copyValue);
			this.query.emit(this.copyValue);
		}

	}

	public copyValue: string = "";
	@Input() showValue: string = "";
	public onSearchQueryChange(searchQuery:any):void {
        if (searchQuery === "") {
			this.entitySelectedEvt.emit(null);
        } else {

			this.copyValue = searchQuery.detail.value;

			window.adminlog.print("Emitting query ", this.copyValue);
			this.query.emit(this.copyValue);

		}


	}

	public getDogListStr(dogs:DogStruct[]):string {

		let dogNames = [];

		for(let dog of dogs){
			dogNames.push(dog.name);
		}
		return dogNames.join(", ");


	}

	public showAutocompleteDropdown(): boolean {

		return (
			this.useAutocomplete &&
			this.dropdownItems &&
			this.configurations.length >= 1 &&
			this.hasFocus &&
			(this.copyValue !== "") &&
			(this.copyValue.length > 0)
		);

	}

	public emitItems(indexName: string, tag: string):Function {

		return (items: any[]) => {

			this.autoCompleteItems[indexName || tag] = items;
			//window.adminlog.print( indexName, tag, items.length );
            console.log( { entities: items, indexName: indexName, tag: tag } );
			this.resultsEvt.emit({ entities: items, indexName: indexName, tag: tag });
			// set dropdown list.
			this.dropdownItems = [];
			Object.keys(this.autoCompleteItems).forEach(config_label => this.dropdownItems =
				this.dropdownItems.concat(this.autoCompleteItems[config_label].map(entity => {
					return { label: config_label, value: entity };
				}))
			);

			//window.adminlog.print(this.dropdownItems);
			return items;

		};
	}


		// public instantBlur():void {
		//
		// 	this.hasFocus = false;
		//
		// 	this.element.nativeElement.querySelector('input[type="search"]').blur();
		//
		// }

	public onBlur():void {

		if(this.hasFocus){
			setTimeout(() => {
				this.hasFocus = false;
			}, 300);
		}

		if (this.copyValue) {
			window.adminlog.print("Emitting query ", this.copyValue);
			this.query.emit(this.copyValue);
		}

	}

	public onFocus():void {
		this.hasFocus = true;
	}

	public algoliasearchlabels = {
		account: (entity: AccountStruct) => {
			if (this.showDogName) {
				return `${entity.firstName} ${entity.lastName} ( ${(entity.pets || []).map(pet => pet.petName).join('-') || 'No Pets' } )`;
			} else return `${entity.firstName} ${entity.lastName}`;

		},
		user: (entity: any) => {
			if (this.showDogName) {

				return `${entity.firstName} ${entity.lastName}`;
			} else return `${entity.firstName} ${entity.lastName}`;
		},
		lead: (entity: any) => {
			if (this.showDogName) {
				return `${entity.customer_name} ( ${(entity.pets || []).join('-') || 'No Pets'} )`
			} else return `${entity.customer_name}`;
		},
		meetgreet: (entity: any) => {
			if (this.showDogName ) {
				return `${entity.customer_name} ( ${entity.pet? entity.pet.petName || 'No Pet' : 'No Pet'} )`;
			} else return `${entity.customer_name}`;
		}
	}


	public getEntitySearchName(entity: any) {
		return entity.customer_name || `${entity.firstName} ${entity.lastName}`;
	}

	public getEntitySearchLabel(entity: any) {
		if (this.algoliasearchlabels[entity.label]) {
			return this.algoliasearchlabels[entity.label](entity.value);
		}

		else {
			window.adminlog.error('absent record label', entity);
		}
	}


}
