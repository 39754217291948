import { Injectable } from "@angular/core";
import { environment } from '../environments/environment';
@Injectable({
	providedIn: "root"
})

export class FooterInfoService {

	constructor(){ }

	public copyrightYear = (new Date()).getFullYear();
	public version = environment.version || '';
    
	public getHelpLink():string {
		return "#";
	}

	public getFAQLink():string {
		return "#";
	}

}