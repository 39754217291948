import firebase from 'firebase';
import { AccountAccess } from './account';
import { TimeKeeperStruct } from './record';
import { MemoStruct } from './memo';
// export type RequestStatus = "pending" | "declined" | "approved" | "cancelled";
export enum AccessRequestStatus {
    Pending = 0,
    Completed = 1,
    Invalid = 2,
    Existing = 3
}
export enum AccessRequestAction {
    Pending = 0,
    Invite = 1,
    Lead = 2,
    Invalid = 3,
    Existing = 4
}
export interface AccessRequestStruct extends TimeKeeperStruct {
    locationId: string,

    firstName: string,
    lastName: string,
    email?: string,
    phoneNumber?: string,
    customerMessage?: string,
    status: AccessRequestStatus,
    action?: AccessRequestAction,

    accountId?: string,
    accountAccess?: AccountAccess,
    leadId?: string;
    inviteId?: string;
    userId?: string;
    // SubCollection User Messages.
    memos?: MemoStruct[];

    // SubCollection Log Equivalent to Activity.
    log?: LogStruct[];



}
export interface LogStruct {
    action?: string;
    createdAt: firebase.firestore.Timestamp;
    editedBy?: string;
    msg?: string;
    message: string;
}
