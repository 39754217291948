import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { DogService } from "./dog.service";

import { Location, DogRequest, Dog } from "src/models/entities";

import { DogRequestStruct, RequestStatus } from "src/models/structs";


import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class DogRequestService extends EntityServiceAbstract<DogRequest> {

	public collection = "dogRequestAccess";

	protected statusColorMap:object = {
		"pending": 		"primary",
		"approved":		"success",
		"cancelled":	"danger",
		"declined":		"danger",
	};

	private queryItemsLimit = 100;

	constructor (
		protected dbService: FirestoreService, 
		private logService: ActivityLogService,
		private dogService: DogService
	){
		super();
	}

	public getById(dogRequestId:string):Observable<DogRequest> {

		return this.dbService.getDoc(this.collection, dogRequestId).pipe(
			map((dogRequestDoc) => {
				
				if(dogRequestDoc.exists){
					return new DogRequest(dogRequestDoc.data() as DogRequestStruct, dogRequestDoc.id);
				}

			})
		);

	}

	public update(dogRequestId:string, updatedData:any):Promise<void> {

		if(!updatedData.updatedAt){
			
			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, dogRequestId, updatedData);

	}

	public getDogRequestByDogId(locationId: string, dogId:string):Observable<DogRequest|null> {

		const queryConditions = [
			["locationId", "==", locationId],
			["dogId", "==", dogId]
		];

		return this.dbService.getCollection(this.collection, {conditions: queryConditions} as queryOptions).pipe(
			map((dogRequestDocs) => {
				return (dogRequestDocs.length > 0) ? this.mapDocsToObjs(dogRequestDocs)[0] : null;
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getDogRequestsByUserId(locationId: string, userId: string):Observable<DogRequest[]> {

		const queryConditions = [
			["locationId", "==", locationId],
			["userId", "==", userId]
		];

		return this.dbService.getCollection(this.collection, {conditions: queryConditions} as queryOptions).pipe(
			map((dogRequestDocs) => {
				return this.mapDocsToObjs(dogRequestDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getNewDogRequests(locationId:string, userIdToFilter:string = ""):Observable<DogRequest[]> {
		
		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", DogRequest.PENDING]
			],
			orderByField: "createdAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((dogRequestDocs) => {
				return this.mapDocsToObjs(dogRequestDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getCompletedDogRequests(locationId:string, userIdToFilter:string = ""):Observable<DogRequest[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
		};

		if(userIdToFilter){
			queryOptions.conditions.push(["userId", "==", userIdToFilter]);
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((dogRequestDocs) => {
				return this.mapDocsToObjs(dogRequestDocs)
					.filter((dogRequest:DogRequest) => {
						return (dogRequest.status !== DogRequest.PENDING);
					})
					.slice(0, this.queryItemsLimit);
			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw(error);
			})
		);

	}

	public approveDogRequest(dogRequestId: string, dog: Dog, location: Location|string, note: string):Promise<any> {

		return this.dogService.updateLocationAccess(dog, location, true).then((result) => {

			return this.completeDogRequest(dogRequestId, DogRequest.APPROVED, note);

		});
		
	}

	public declineDogRequest(dogRequestId: string, dog: Dog, location: Location|string, note: string):Promise<any> {

		return this.dogService.updateLocationAccess(dog, location, false).then((result) => {

			return this.completeDogRequest(dogRequestId, DogRequest.DECLINED, note);

		}).catch((error) => {
			window.adminlog.print("ERROR UPDATING DOG LOCATION ACCESS", error);
		});

	}

	private completeDogRequest(dogRequestId: string, status: RequestStatus, note: string):Promise<any> {

		let updatedValues = {
			status: status,
			note: note
		};

		let dogRequestUpdatePromise = this.update(dogRequestId, updatedValues);

		let logEntryPromise = this.logService.addLogEntry(this.collection, dogRequestId, status, note);

		return Promise.all([dogRequestUpdatePromise, logEntryPromise]);

	}

	private mapDocsToObjs(dogRequestDocs:firebase.firestore.QueryDocumentSnapshot[]):DogRequest[] {

		return dogRequestDocs.map((dogRequestDoc) => {
					
			const dogRequest = new DogRequest(dogRequestDoc.data() as DogRequestStruct, dogRequestDoc.id);

			return dogRequest;

		});

	}

}