import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNeuterdState'
})
export class GetNeuterdStatePipe implements PipeTransform {

  transform( state: string ): string {
      if(state == "Unknown" || state == "Not Fixed"){ 
        return "No";
      } 
      return "Yes";
  }

}


