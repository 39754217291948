import { Component, OnInit } from '@angular/core';
import { Events, PopoverController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

import { faCheck } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-active-drop-off-sort-popover',
  templateUrl: './active-drop-off-sort-popover.component.html',
  styleUrls: ['./active-drop-off-sort-popover.component.scss'],
})
export class ActiveDropOffSortPopoverComponent implements OnInit {
    
    public faCheck = faCheck;
    public selected = "all";
    
    constructor(
        public ionEventBus: Events,
        public settingsService : AuthService,
        public popoverController: PopoverController
    ) { }

    ngOnInit() {
        this.settingsService;
        console.log( this.settingsService.pageSettings ); 
        if( this.settingsService.pageSettings != null ){ 
            if( this.settingsService.pageSettings["activeDropOff"] != undefined ){ 
                this.selected = this.settingsService.pageSettings["activeDropOff"];
            }
        }
        else if( this.settingsService.pageSettings === null) { 
            this.selected = "all";
            this.settingsService.pageSettings["activeDropOff"] = "all";
        }
    }

    public selectionChange( status ){
        this.settingsService["pageSettings"]["activeDropOff"] = status;
        this.selected = status;
        this.ionEventBus.publish("sort-active-doropoffs", { } );
    }

    public _(){ 
        this.ionEventBus.publish("sort-active-doropoffs", { } );
    }

}
