import { OwnerStruct } from './owner';

export interface AccountStruct extends OwnerStruct {
	// Essential, stored on firestore, not on petexec
	functional: {
		original_userId: string,
		locationId: string,
		companyID: string | number,
		credentialId?: string,
		access: {
			[userId: string]: {granted: AccountAccess }
		};
		status?: OwnerAccessState
	}
}

export enum AccountAccess {
	None = 0,
	Minimal = 1,
	Full = 10
}
export type OwnerAccessState = "Pending" | "Approved" | "Declined";
