import { Pipe, PipeTransform } from "@angular/core";

import { Observable } from "rxjs";

import { UserService } from "src/services";

@Pipe({
	name: "getUserAccess"
})

export class GetUserAccessPipe implements PipeTransform {

	constructor() { }

	public transform(access/*userId: string, locationId: string*/):string {
        switch (access) {
            case 10:
                return "Full access";
            case 1:
                return "Guest Access";
            case 0: 
                return "No Access";
            default:
                return "No Access";
        }
    }
}
        /*
        
		const user = await this.userService.getById(userId);

        const access = user.locations[locationId].execAccess;
        switch (access) {
            case 10:
                return "Full access";
            case 1:
                return "Guest Access";
            case 0: 
                return "No Access";
            default:
                return "No Access";
        }
		
	}
    */
