import { Injectable } from "@angular/core";

import { Observable, combineLatest, BehaviorSubject, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

import firebase from "firebase/app";

import { FirestoreService, queryOptions } from "./firestore.service";

import { User, Location, Account } from "src/models/entities";

import { UserStruct, LocationsProperty, AccountAccess } from "src/models/structs";
import { MatchState } from "src/models/structs/location";

import * as moment from "moment-timezone";
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: "root"
})

export class UserService {

	public collection = "users";

	private queryItemsLimit = 15;


    // User detail update start
    /* 
    *
    *   Used for communicating between the user-detail component and user-overview component 
    *       - passing userId and newest accesslevel for latest updated user
    */
    // observable used for passing newest access level from user-detail component to user-overview component
    private userDetailsToOverviewDataMapper : BehaviorSubject<{userId: string, accessLevel: number}> = new BehaviorSubject<{userId: string, accessLevel: number}>({userId: null , accessLevel : null});
    // function for reading userDetailsToOverviewDataMapper to retrieve latest access level change
    public readLatestUserDetailsChange() : {userId: string, accessLevel: number}{ 
        return this.userDetailsToOverviewDataMapper.getValue()
    }
    // function for updating userDetailsToOverviewDataMapper to update latest access level change
    public updateLatestUserDetails( obj : {userId: string, accessLevel: number} ){ 
        this.userDetailsToOverviewDataMapper.next( obj );
    }
    // user detail update end

	constructor (private dbService: FirestoreService, private http: HttpClient){ }

	public static cleanPhoneNumber(phoneNumber: string):string {

		// Remove all characters except numbers
		let strippedPhoneNumber = phoneNumber.replace(/\D+/g, "");

		// If the number begins with "1", remove it.  We'll add it back on later
		if(strippedPhoneNumber.substring(0, 1) === "1" && strippedPhoneNumber.length !== 10){
			strippedPhoneNumber = strippedPhoneNumber.substring(1);
		}

		// Strip off anything more than 10 characters
		if(strippedPhoneNumber.length > 10){
			strippedPhoneNumber = strippedPhoneNumber.substring(0, 9);
		}

		// Add the "1" back on
		// strippedPhoneNumber = "1" + strippedPhoneNumber;

		return strippedPhoneNumber;

	}

	public getById(userId: string):Promise<User> {

		return new Promise((resolve, reject) => {

			this.dbService.getDocSnapshot(this.collection, userId)
				.then((result) => {
					resolve(new User(result.data() as UserStruct, userId));
				})
				.catch((error) => {
					reject(error);
				});

		});

	}

	protected accessStatusColorMap: object = {
		[AccountAccess.Full]: "primary",
		[AccountAccess.Minimal]: "success",
		[AccountAccess.None]: "danger",
	};

	public getAccountAccessStatusColor(status: AccountAccess) {
		return this.accessStatusColorMap[status];
	}

	public getByEmailAndLocation(email: string, locationId: string):Promise<User|void> {

		const queryOptions: queryOptions = {
			conditions: [
				[ "locations." + locationId + ".access", "==", true ],
				[ "email", "==", email ]
			],
			limit: 1
		};

		return new Promise((resolve, reject) => {

			this.dbService.getCollectionOnce(this.collection, queryOptions)
				.then((result) => {

					if(result.length > 0){

						let user = new User(result[0].data() as UserStruct, result[0].id);

						resolve(user);

					}else{
						resolve();
					}

				})
				.catch((error) => {
					reject(error);
				});

		});

	}



	public getUserByPhonnoAndLocation(locationId: string, phoneno: string = ''):Promise<void | User> {

        const queryOptions: queryOptions = {
			conditions: [
			],
		};
        let userPromise = [];

        if(phoneno !== '') {
            let phoneNumbers = [];

            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1($1) $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1($1)-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1($1)$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1$1-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1$1 $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1$1$2$3"));

            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1)-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1)$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 $1-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 $1 $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "+1 $1$2$3"));

            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 ($1) $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 ($1)-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 ($1)$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 $1-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 $1 $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1 $1$2$3"));

            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1($1) $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1($1)-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1$1)$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1$1-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1$1 $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "1$1$2$3"));

            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2-$3"));
            phoneNumbers.push(phoneno.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3"));


            const chunkSize = 10;
            let phoneNumbersList = [];
            
            for (let i = 0; i < phoneNumbers.length; i += chunkSize) {
                phoneNumbersList.push(phoneNumbers.slice(i, i + chunkSize));
                // do whatever
            }
            console.log("phoneNumbersList", phoneNumbersList);
            
            phoneNumbersList.forEach((phoneNumberChunk) => {
                let phoneNumberQueryOptions: queryOptions = {
                    conditions: [
        				[ "locations." + locationId + ".access", "==", true ],
                        ["phoneNumber", "in", phoneNumberChunk]
                    ]
                };
    
                userPromise.push(this.dbService.getCollectionOnce(this.collection, phoneNumberQueryOptions));
            })
    

        }

        let userArray: User[] = [];
		return new Promise((resolve, reject) => {

            Promise.all(userPromise).then((userDocs) => {
                userDocs.forEach((userPromiseDoc) => {
                    if(userPromiseDoc.length > 0) {

                        let userData = userPromiseDoc.map((userDoc) => {
                            const lead = new User((userDoc.data() as UserStruct), userDoc.id);
                            return lead;
                        });
                        
                        userArray = userArray.concat(userData);
                    }
                });

                resolve(userArray[0]);
            });
        });

	}


	public getByEmail(email: string):Promise<User> {

		const queryOptions: queryOptions = {
			conditions: [
				["email", "==", email]
			],
			limit: 1
		};

		return new Promise((resolve, reject) => {

			this.dbService.getCollectionOnce(this.collection, queryOptions)
				.then((result) => {

					if(result.length > 0){

						let user = new User(result[0].data() as UserStruct, result[0].id);

						resolve(user);

					}else{
						resolve();
					}

				})
				.catch((error) => {
					reject(error);
				});

		});

	}

	//#region Wrappers for if you have an account and want to display the associated users.
	public async getByAccount(account: Account, opts: {access: AccountAccess, match?: MatchState} = { access: AccountAccess.None}){
		return this.getByLocation(account.functional.locationId, {execId: account._id, execAccess: opts.access, match: opts.match})
	}

	public async getAllUsersByAccount(account: Account, _opts: { access: AccountAccess, match?: MatchState } = { access: AccountAccess.None }) {
		return this.getAllByLocation(account.functional.locationId, { execId: account._id })
	}
	public getByAccountObs(account: Account, opts: {access: AccountAccess, match?: MatchState} = { access: AccountAccess.None}){
		return this.getByLocationObs(account.functional.locationId, {execId: account._id, execAccess: opts.access, match: opts.match})
	}
//#endregion


	public getByLocationObs(location: string, opts: {email?: string, execId?: string, match?: MatchState, limit?: number, execAccess?: AccountAccess, showPriority?: number} = {}):Observable<User[]> {

		const queries: queryOptions = {
			conditions: [
				[`locations.${location}.access`, '==', true]
			],
			limit: opts.limit || this.queryItemsLimit
		};

		if(opts.email){
			queries.conditions.push(['email', '==', opts.email]);
		}

		if(opts.execId){
			queries.conditions.push([`locations.${location}.execId`, '==', opts.execId]);
		}

		if(opts.match !== undefined){
			queries.conditions.push([`locations.${location}.matchState`, '==', opts.match]);
		}

		if(opts.execAccess !== undefined){
			queries.conditions.push([`locations.${location}.execAccess`, '==', opts.execAccess]);
		}

		if(opts.showPriority !== undefined){
			queries.conditions.push(["locations." + location + ".showPriority", "==", opts.showPriority]);
		}

		try {
			return this.dbService.getCollection(this.collection, queries).pipe(map((userDocs) => {
				return userDocs.map(userDoc => {
					return new User(<UserStruct>userDoc.data(), userDoc.id);
				});
			}));
		}
		catch (err) {
			throw err;
		}

	}

	public async getAllByLocation(location: string, opts: { email?: string, execId?: string, match?: MatchState, limit?: number, execAccess?: AccountAccess, showPriority?: number } = {}): Promise<User[]> {

		const queries: queryOptions = {
			conditions: [
				[`locations.${location}.access`, '==', true]
			],
			limit: opts.limit || this.queryItemsLimit
		};

		if (opts.email) {
			queries.conditions.push(['email', '==', opts.email]);
		}

		if (opts.execId) {
			queries.conditions.push([`locations.${location}.execId`, '==', opts.execId]);
		}

		if (opts.match !== undefined) {
			queries.conditions.push([`locations.${location}.matchState`, '==', opts.match]);
		}

		if (opts.execAccess !== undefined) {
			queries.conditions.push([`locations.${location}.execAccess`, '==', opts.execAccess]);
		}

		if (opts.showPriority !== undefined) {
			queries.conditions.push(["locations." + location + ".showPriority", "==", opts.showPriority]);
		}
		window.adminlog.print(queries);
		try {
			const userDocs = await this.dbService.getCollectionOnce(this.collection, queries);
			return userDocs.map(userDoc => (new User((userDoc.data() as UserStruct), userDoc.id)));
		}
		catch (err) {
			throw err;
		}

	}

	public async getByLocation(location: string, opts: {email?: string, execId?:string, match?: MatchState, limit?:number, execAccess?: AccountAccess, showPriority?: number} = {}):Promise<User> {

		const queries: queryOptions = {
			conditions: [
				[`locations.${location}.access`, '==', true]
			],
			limit: opts.limit || this.queryItemsLimit
		};

		if(opts.email){
			queries.conditions.push(['email', '==', opts.email]);
		}

		if(opts.execId){
			queries.conditions.push([`locations.${location}.execId`, '==', opts.execId]);
		}

		if(opts.match !== undefined){
			queries.conditions.push([`locations.${location}.matchState`, '==', opts.match]);
		}

		if(opts.execAccess !== undefined){
			queries.conditions.push([`locations.${location}.execAccess`, '==', opts.execAccess]);
		}

		if(opts.showPriority !== undefined){
			queries.conditions.push(["locations." + location + ".showPriority", "==", opts.showPriority]);
		}

		try {
			const userDocs = await this.dbService.getCollectionOnce(this.collection, queries);
			return (new User((userDocs[0].data() as UserStruct), userDocs[0].id));
		}
		catch (err) {
			throw err;
		}

	}

	public getUnmatchedUsers(locationId: string):Observable<User[]> {

		let unmatchedUserObservables:Observable<User[]>[] = [];

		// Create observables for each showPriority value up to 5
		for(let i = 0; i < 6; i++){

			unmatchedUserObservables.push(this.getByLocationObs(locationId, {
				match: MatchState.Unmatched,
				showPriority: i,
				limit: this.queryItemsLimit
			}));

		}

		// Combine them together and sort by showPriority and return the top 100
		return combineLatest(unmatchedUserObservables).pipe(
			map((unmatchedUsersArrays) => {
				return [].concat.apply([], unmatchedUsersArrays)
					.sort((userA: User, userB: User):number => {
						return (userA.locations[locationId].showPriority > userB.locations[locationId].showPriority) ? 1 : -1;
					})
					.slice(0, this.queryItemsLimit);
			})
		);

	}

	public getUsers(location: string, access: AccountAccess = AccountAccess.None, limit: number = 20):Observable<User[]> {

		const queries: queryOptions = {
			conditions: [
				[`locations.${location}.access`,'==', true],
				[`locations.${location}.execAccess`, '==', access]],
			limit: limit
		};

		return this.dbService.getCollection(this.collection, queries).pipe(map((userDocs) => {
			return userDocs.map(userDoc => {
				return new User(<UserStruct>userDoc.data(), userDoc.id);
			});
		}));

	}

	public getByIdObs(userId: string):Observable<User> {

        if( userId == ''){
            return of({} as User);
        }

		return this.dbService.getDoc(this.collection, userId).pipe(
			map((userDoc) => {
				return new User(userDoc.data() as UserStruct, userId);
			})
		)

	}

	public getAvatarImgUrl(userId: string):Promise<string> {

		return new Promise((resolve) => {

			this.getById(userId).then((user) => {
				resolve((user.photo) ? user.photo : "");
			}).catch((error) => {
				resolve("");
			});

		});

	}

	public update(userId: string, updatedData: any):Promise<void> {

		if(!updatedData.updatedAt){

			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, userId, updatedData);

	}

	public async updateLocationAccess(user: User, location: Location, giveAccess: boolean):Promise<void> {

		//TODO Update to make user variable a string id and get user from an internal list of cached users

		let userLocations:LocationsProperty = {};

		if(user.locations){
			userLocations = user.locations;
		}

		userLocations[location._id] = {
			access: giveAccess,
			name: location.name,
			storeId: location.storeId
		};

		let updatedValues:any = {
			locations: userLocations,
			// If user is being granted access (giveAccess = true), set approved property to true
			// Otherwise, keep approved property the same value, or set to false if it's not been set
			approved: ((giveAccess) ? giveAccess : user.approved || false)
		};

		// If user is being granted access, update the default location to the request's location ID, if it's not been set
		if(giveAccess){
			updatedValues.defaultLocation = user.defaultLocation || location._id;
		}

		return this.update(user._id, updatedValues);

	}

	public createUser(user: UserStruct):Promise<string> {

		return this.dbService.insertDoc(this.collection, user).then((userDoc) => {
			return userDoc.id;
		});
		// literally no point in creating a user this way.
		
	}
	public async writeLog(user: User, adminId: string, locationId: string, text: string) {
		return this.dbService.setSubCollectionDocument([[this.collection, user._id], ["logs", `${Date.now()}`]], {
			adminId: adminId,
			locationId: locationId,
			action: text
		});
	}

	public async getAuthDiagnostic(user: User) {

		return new Promise<{ success: boolean, results: any[], couldNotFindBy: any[]}>((resolve, reject) => {

			this.http.get(`${environment.firebaseFunctions.authDiagnostic}/${user._id}?email=${user.email}&phone=${user.phoneNumber}`).subscribe(
				(result: { success: boolean, results: any[], couldNotFindBy: any[] }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});
	}

	

}
