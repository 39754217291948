import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";

import { ModalController } from "@ionic/angular";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import firebase from "firebase/app";

// import { MbscCalendarOptions } from "@mobiscroll/angular";

import { LocationService, PetService } from "src/services";

import { PetexecShot, VaccinesStruct } from "src/models/structs";

import * as moment from "moment-timezone";

type ShotEntry = {
	shotId: string, // shotId is forced to be a string here because select input values can only be strings
	dateStr: string,
	expireDateStr: string,
};

@Component({
	selector: "app-add-vaccination-modal",
	templateUrl: "./add-vaccination-modal.component.html",
	styleUrls: ["./add-vaccination-modal.component.scss"],
})

export class AddVaccinationModalComponent implements OnInit, OnDestroy {

	@ViewChild("hiddenFileUpload", { static: true }) fileUploadInput: ElementRef;

	public  vaccinationObj: {
		file: string,
		shots: ShotEntry[]
	};

	public  vaccinationShots: {
		[shotId: number]: string
    };
    
    public datePickerOptions = {
        controls: ['calendar'],
        theme: 'ios',
        themeVariant :"light",
        display: 'anchored',
        dateFormat: "MMM D, YYYY",
        min: moment(),
    };

	// public  datePickerOptions: MbscCalendarOptions = {
	// 	dateFormat: "m/d/yy",
	// 	//min: moment(),
	// };

	public  areVaccinationShotsLoading: boolean = false;

	public  isSubmitting: boolean = false;

	public fileToUpload: File;

	public unsubscriber: Subject<void> = new Subject();

	constructor(public modalController: ModalController, public locationService: LocationService, public petService: PetService) {

		this.vaccinationObj = {
			file: "",
			shots: [
				{
					shotId: "",
					dateStr: "",
					expireDateStr: "",
				}
			]
		};

	}

	public ngOnInit():void {

		this.areVaccinationShotsLoading = true;

		this.locationService.getCurrUserLocationObs()
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((currUserLocation) => {

				this.locationService.getVaccinationShots().then((result) => {

					this.vaccinationShots = {};

					result.forEach((shotData: PetexecShot) => {
						this.vaccinationShots[shotData.shotid] = shotData.shotname;
					});

					this.areVaccinationShotsLoading = false;

				});

			});

	}

	public ngOnDestroy():void {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	public  keepOriginalOrder(a: any, b: any):string {
		return a.key;
	}

	public  isPrimaryBtnDisabled():boolean {

		return this.isSubmitting || !this.isVaccineObjValid();

	}

	public  onChooseFileBtnClicked():void {

		this.fileUploadInput.nativeElement.click();

	}

	public  onFileSelected():void {

		if(this.fileUploadInput.nativeElement.files.length > 0){

			this.fileToUpload = this.fileUploadInput.nativeElement.files[0];

		}

	}

	public  addShotEntry():void {

		this.vaccinationObj.shots.push({
			shotId: "",
			dateStr: "",
			expireDateStr: "",
		});

	}

	public  removeShotEntry(index: number):void {

		if(this.vaccinationObj.shots[index]){
			this.vaccinationObj.shots.splice(index, 1);
		}

	}

	public  onVaccinationDateSelected(evt: any, index: number, dateField: "dateStr" | "expireDateStr"):void {

		if(this.vaccinationObj.shots[index]){
			window.adminlog.print(evt, evt.valueText);
			this.vaccinationObj.shots[index][dateField] = evt.valueText;
		}

	}

	public  onSecondaryBtnClicked():void {

		this.modalController.dismiss();

	}

	public  onPrimaryBtnClicked():void {

		window.adminlog.print(this.fileToUpload);
		window.adminlog.print(this.vaccinationObj);

		this.isSubmitting = true;

		this.petService.uploadVaccineDoc(this.fileToUpload).then((uploadedFileUrl) => {

			let vaccineObj: VaccinesStruct = {
				file: uploadedFileUrl,
				shots: [],
			};

			this.vaccinationObj.shots.forEach((shotEntry) => {

				let shotDate = new Date(moment(shotEntry.dateStr, "M/D/YYYY").format());
				let expirationDate = new Date(moment(shotEntry.expireDateStr, "M/D/YYYY").format());

				vaccineObj.shots.push({
					shotid: parseInt(shotEntry.shotId),
					shotname: ((this.vaccinationShots[shotEntry.shotId]) ? this.vaccinationShots[shotEntry.shotId] : ""),
					//shotDate: firebase.firestore.Timestamp.fromDate(shotDate),
					expiration: firebase.firestore.Timestamp.fromDate(expirationDate),
				});
				if (shotEntry.dateStr) {
					vaccineObj.shots[vaccineObj.shots.length - 1].shotDate = firebase.firestore.Timestamp.fromDate(new Date(moment(shotEntry.dateStr, "M/D/YYYY").format()));
				}

			});

			this.isSubmitting = false;
			
			this.modalController.dismiss(vaccineObj);

		});

	}

	public isVaccineObjValid():boolean {

		if(!this.fileToUpload){
			return false;
		}

		for(let shotEntry of this.vaccinationObj.shots){

			if ((shotEntry.shotId === "") || (shotEntry.expireDateStr === "")){
				return false;
			}

		};

		return true;

	}

}
