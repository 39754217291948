import { MeetGreetStruct, MeetGreetStatus, MeetGreetResult, MeetGreetPreferredTime, LeadSource } from "src/models/structs";

import { Entity } from "src/models/entity";
import firebase from 'firebase';

// Validates and Creates Admin. Publishes to Firebase a new admin or any update.
export class MeetGreet extends Entity<MeetGreet> implements MeetGreetStruct {

	static readonly PENDING:MeetGreetStatus	= "pending";
	static readonly DOING:MeetGreetStatus 	= "doing";
	static readonly DONE:MeetGreetStatus 	= "done";

	static readonly PREFERRED_TIME_AM:MeetGreetPreferredTime 		= "AM";
	static readonly PREFERRED_TIME_PM:MeetGreetPreferredTime 		= "PM";
	static readonly PREFERRED_TIME_ANYTIME:MeetGreetPreferredTime	= "Anytime";

	status: MeetGreetStatus;
	locationId: string;
	dogId: string[];
	userId: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	phone?: string;
	userCreated?: boolean;
	date: firebase.firestore.Timestamp;
	preferedTime?: MeetGreetPreferredTime;
	message?: string;
	marketing?: boolean;
	consent?: boolean;
	signInId?: string;
	result?: MeetGreetResult;
	leadId?: string;
	createdAt: firebase.firestore.Timestamp;
	updatedAt?: firebase.firestore.Timestamp;
	timezone: string;
    leadSource?: LeadSource;

	constructor(data?: MeetGreetStruct, id?: string) {
		super(data, id);
	}
}
