import { Entity } from "../entity";

import { DogStruct, DogParentProperty, VaccinesStruct, DogGender, LocationsProperty } from "../structs";

import * as moment from "moment-timezone";
import firebase from 'firebase';
export class Dog extends Entity<Dog> implements DogStruct {
	name: string;
	breed: string;
	gender: DogGender;
	birth: string;
	birth_date?: firebase.firestore.Timestamp;
	img: string;
	parent: DogParentProperty;
	locations: LocationsProperty;
	meetAndGreet: LocationsProperty;
	vaccines?: VaccinesStruct[];
    disabled: Boolean;
    neuteredState?: "Unknown" | "Not Fixed" | "Neutered" | "Spayed";

	private dateFormat:string = "M/D/YYYY";

	constructor(data?: DogStruct, id?: string) {

		super({}, id);

		this.vaccines = [];

		this.hydrate(data);

		if(!this.img){
			this.img = "assets/images/default-dog-avatar.jpg";
		}

	}

	public getBirthDateStr():string {

		if(this.birth_date){

			return moment(this.birth_date.toDate()).format(this.dateFormat);

		}else if(this.birth){

			return moment(this.birth, "YYYY/M/D").format(this.dateFormat);

		}else{

			return "N/A";

		}

	}

    public getNeuteredState() : "Unknown" | "Not Fixed" | "Neutered" | "Spayed"{ 
        if( !this.neuteredState ){ 
            return "Unknown";
        }
        else { 
            return this.neuteredState;
        }
    }

    public checkIfPetHasVaccines() : boolean { 
        if( this.vaccines.length>0 ){ 
            return true;
        }
        return false;
    }

    public getEarliestVaccineExpiration(){ 
        console.log( this.vaccines );
        if( this.vaccines && this.vaccines.length > 0 ){
            let firstVaccineToExpire = this.vaccines[0].shots[0];
            for( let x = 0; x < this.vaccines.length ; x++ ){ 
                for( let y = 0 ; y < this.vaccines[x].shots.length; y++ ){ 
                    if( this.vaccines[x].shots[y].expiration.toDate() < firstVaccineToExpire.expiration.toDate() ){ 
                        firstVaccineToExpire = this.vaccines[x].shots[y];
                    }
                }
            }
            console.log( "pet vaccines" );
            console.log( this.vaccines );
            console.log( firstVaccineToExpire );
            return firstVaccineToExpire;
        }
    }

}
