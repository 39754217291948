import { Entity } from "src/models/entity";

import { WebcamUserStruct } from "src/models/structs";

export class WebcamUser extends Entity<WebcamUser> implements WebcamUserStruct {
	email: string;
	firstName: string;
	lastName: string;
	access: boolean;

	constructor(data?: WebcamUserStruct, id?: string){
		super(data, id);
	}
}
