import { UserRequestStruct, RequestStatus, RequestSource } from "src/models/structs";

import { Entity } from "src/models/entity";
import firebase from 'firebase';

// Validates and Creates Admin. Publishes to Firebase a new admin or any update.
export class UserRequest extends Entity<UserRequest> implements UserRequestStruct {

	static readonly APPROVED:RequestStatus = "approved";
	static readonly DECLINED:RequestStatus = "declined";
	static readonly CANCELLED:RequestStatus = "cancelled";
	static readonly PENDING:RequestStatus = "pending";

	locationId: string;
	userId: string;
	status: RequestStatus;
	customerMessage?: string;
	note: string;
	source: RequestSource;
	version: string;
	timezone: string;
	createdAt: firebase.firestore.Timestamp;
	updatedAt: firebase.firestore.Timestamp;

	constructor(data?: UserRequestStruct, id?: string){
		super(data, id);
	}
}
