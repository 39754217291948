import { AppointmentStruct, AptService, AptStatus } from "src/models/structs";

import { Entity } from "src/models/entity";
import firebase from 'firebase';
import { PetexecAppointment } from '../../structs/appointment';

// Validates and Creates Admin. Publishes to Firebase a new admin or any update.
export class Appointment extends Entity<Appointment> implements AppointmentStruct {

	static readonly APPROVED: AptStatus 	= "Approved";
	static readonly DECLINED: AptStatus		= "Declined";
	static readonly CANCELLED: AptStatus	= "Cancelled";
	static readonly PENDING: AptStatus		= "Pending";
    static readonly CANCELDECLINED: AptStatus      = "Cancel Declined";
    static readonly WAITLIST : AptStatus    = "Waitlist";
    static readonly ONHOLD : AptStatus      = "On Hold";


	static readonly DAYCARE: AptService	= "Daycare";
	static readonly BOARDING: AptService = "Boarding";
	static readonly SPA: AptService = "Spa";
	static readonly GROOMING: AptService = "Grooming";
	static readonly TRAINING: AptService = "Training";

	locationId: string;
	service: AptService;
	status: AptStatus;
	userId: string;
	date: firebase.firestore.Timestamp;
	pickupDate?: firebase.firestore.Timestamp;
	comments?: string;
	accountId: string;
	petId: string;

    onHoldReason?: string;

	dogId: string;
	dogName: string;
	note: string;
	completed: Boolean;
	cancelled?: Boolean;
	source: string;
	version: string;
    cancelledAt?: firebase.firestore.Timestamp;
	createdAt: firebase.firestore.Timestamp;
	updatedAt: firebase.firestore.Timestamp;
	timezone: string;
	user_timezone: string;
	petexec: PetexecAppointment;
    daycareid?:string;
    cancellationFeeAccepted? :boolean;
	constructor(data?: AppointmentStruct, id?: string){
		super(data, id);
	}

	public daycareLink(petid: number, server: string): string {
		if ( !this.daycareid || !petid) return '';
		return `${server}daycareScheduleMod.php?petId=${petid}&dsid=${this.daycareid}`;
	}
}
