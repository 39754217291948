import { NgModule } from "@angular/core";

import { NgAisModule } from "angular-instantsearch";

import { DashboardCommonModule } from "./dashboard-common.module";

import { CustomSearchbarWidgetComponent } from "../dashboard/dashboard-searchbar/custom-searchbar-widget/custom-searchbar-widget.component";
import { DashboardSearchbarComponent } from "../dashboard/dashboard-searchbar/dashboard-searchbar.component";

@NgModule({
	declarations: [
		CustomSearchbarWidgetComponent,
		DashboardSearchbarComponent,
	],
	entryComponents: [],
	imports: [
		NgAisModule.forRoot(),
		DashboardCommonModule
	],
	exports: [
		NgAisModule,
		CustomSearchbarWidgetComponent,
		DashboardSearchbarComponent,
	]
})

export class DashboardSearchbarModule { }
