/**
 * @swagger
 * components:
 *  schemas:
 *    MeetGreetStruct:
 *      required: [userId, locationId, dogId, status, date, createdAt]
 *      properties:
 *        activities:
 *          type: array
 *          items:
 *            type: object
 *            properties:
 *              message:
 *                type: string
 *              createdAt:
 *                type: string
 *                format: date-time
 *        leadId:
 *          description: Lead ID associated with Meet and greet. Usually, or always, created side-by-side.
 *          type: string
 *          format: uuid
 *        approval:
 *          description: After the meet and greet happens, staff decides if the pet passed, failed, should be temporarily denied, or was absent.
 *          type: string
 *          enum: [pass, fail, denied, absent]
 *        dogId:
 *          description: list of dogs at meet and greet. even though the field name is not plural.
 *          type: array
 *          items:
 *            type: string
 *            format: uuid
 *        userId:
 *          type: string
 *          format: uuid
 *        userCreated:
 *          type: boolean
 *        locationId:
 *          type: string
 *          format: uuid
 *        firstName:
 *          type: string
 *        lastName:
 *          type: string
 *        email:
 *          type: string
 *        phone:
 *          type: string
 *        date:
 *          type: string
 *          format: date
 *        preferredTime:
 *          type: string
 *          format: time
 *        message:
 *          type: string
 *        createdAt:
 *          type: string
 *        updatedAt:
 *          type: string
 *        timezone:
 *          type: string
 *        marketing:
 *          type: boolean
 *        consent:
 *          type: boolean
 *        signInId:
 *          type: string
 *          format: uuid
 *        status:
 *          type: string
 *          enum: [doing, done, pending]
 *
 *  responses:
 *  requestBodies:
 *  parameters:
*/


import { TimeKeeperStruct } from "./record";
import firebase from 'firebase';
import { LeadSource } from "./lead";
export interface MeetGreetStruct extends TimeKeeperStruct {
	status: MeetGreetStatus,
	locationId: string,
	dogId: string[],
	userId?: string,
	firstName?: string,
	lastName?: string,
	email?: string,
	phone?: string,
	userCreated?: boolean,
	date: firebase.firestore.Timestamp,
	preferedTime?: MeetGreetPreferredTime,
	message?: string,
	marketing?: boolean,
	consent?: boolean,
	signedInId?: string,
	result?: MeetGreetResult,
	leadId?: string,
    leadSource?: LeadSource,
}

// Pass is user created, dog created on petexec server. access granted. fail is hard fail, no reneweal.  denied is rewew lead after a certain amount of time. absent is if they didn't show up.
export enum MeetGreetResult {
	NA = 0,
	New = 1,
	InProgress = 2,
	Done = 3,
	Pass = 4,
	NoPass_FollowUp = 5,
	NoShow = 6,
	NoPass_DNC = 7
}

export type MeetGreetStatus = "doing" | "done" | "pending" | "noshow" | "schedulelater"

export type MeetGreetPreferredTime = "AM" | "PM" | "Anytime";
