import { InquiryStruct, InquiryStatus, InquiryService } from "src/models/structs";

import { Entity } from "src/models/entity";
import firebase from 'firebase';

// Validates and Creates Admin. Publishes to Firebase a new admin or any update.
export class Inquiry extends Entity<Inquiry> implements InquiryStruct {

	static readonly PENDING:InquiryStatus	= "pending";
	static readonly DOING:InquiryStatus 	= "doing";
	static readonly DONE:InquiryStatus 		= "done";

	location: string;
	status: InquiryStatus;
	userId?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	phone?: string;
	message: string;
	service?: InquiryService[];
	marketing?: boolean;
	consent?: boolean;
	createdAt: firebase.firestore.Timestamp;
	updatedAt?: firebase.firestore.Timestamp;
	timezone?: string;

	constructor(data?: InquiryStruct, id?: string) {
		super(data, id);
	}
}
