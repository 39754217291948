import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';



@Component({
  selector: 'app-complete-cancellation',
  templateUrl: './complete-cancellation.component.html',
  styleUrls: ['./complete-cancellation.component.scss'],
})
export class CompleteCancellationComponent implements OnInit {
    
    public override: any;
    
    constructor(
        private modalController: ModalController,
    ) { 
        
    }

    ngOnInit() {}


    public approveAppt(){ 
        this.modalController.dismiss({
            dismiss: false,
            override: this.override 
        }); 
    }

    public dismiss(){ 

        this.modalController.dismiss({
            dismiss:true,
            override: false
        });
    }

}
