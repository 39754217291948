import { NgModule } from "@angular/core";

import { DashboardCommonModule } from "../dashboard-common.module";

import { PipesModule } from "src/pipes/pipes.module";

import { EntityActivityLogComponent } from "./entity-activity-log/entity-activity-log.component";
import { EntityMemosComponent } from "./entity-memos/entity-memos.component";
import { EntityMessageActionComponent } from './entity-message-action/entity-message-action.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { InfoPopoverComponent } from "./entity-message-action/info-popover/info-popover.component";

@NgModule({
	declarations: [
		EntityActivityLogComponent,
		EntityMemosComponent,
		EntityMessageActionComponent,
        InfoPopoverComponent
	],
	imports: [
		DashboardCommonModule,
		ReactiveFormsModule,
		PipesModule,
        FontAwesomeModule
	],
	exports: [
		EntityActivityLogComponent,
		EntityMemosComponent,
		EntityMessageActionComponent
	],
    entryComponents:[InfoPopoverComponent]
})

export class EntityDetailModule { }
