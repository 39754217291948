import { Injectable } from "@angular/core";

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";

import { AuthService } from "../auth.service";

import { Admin } from "src/models/entities";


@Injectable({
	providedIn: "root",
})

export class SuperAdminGuard implements CanActivate {

	constructor(private authService: AuthService, private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean> {

		return this.checkIsSuperAdmin();

	}

	private async checkIsSuperAdmin():Promise<boolean> {
		
		return this.authService.getLoggedInUser()
					.then((admin) => {
						
						if(admin.role === Admin.ROLE_SUPER_ADMIN){
							
							return true;

						}else{

							this.router.navigate(['/appointments']);

							return false;

						}

					})
					.catch((error) => {

						this.router.navigate(['/appointments']);

						return false;
						
					});
		
	}

}