import { Pipe, PipeTransform } from "@angular/core";

import { Observable } from "rxjs";

import { UserService } from "src/services";

@Pipe({
	name: "getUserEmail"
})

export class GetUserEmailPipe implements PipeTransform {

	constructor(private userService: UserService) { }

	public async transform(userId: string):Promise<string> {

		const user = await this.userService.getById(userId);
			
		return user.email;
		
	}

}