import { Component, OnInit, Input } from '@angular/core';
import { Events, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-select-all-dropoffs-popover',
  templateUrl: './select-all-dropoffs-popover.component.html',
  styleUrls: ['./select-all-dropoffs-popover.component.scss'],
})
export class SelectAllDropoffsPopoverComponent implements OnInit {

    @Input() allSelectedCheckbox: string;
    public selected = "none";

    constructor(
        public ionEventBus: Events,
        public popoverController: PopoverController
    ) { }

    ngOnInit() {
        console.log( this.allSelectedCheckbox );
        if( this.allSelectedCheckbox ){ 
            this.selected = "selectAll";
        }
        else{ 
            this.selected = "none";
        }
    }

    public selectionChange( status ){
        if( this.selected != status ){ 
            this.selected = status;
            this.ionEventBus.publish("select-all-dropoffs", { "status": this.selected } );
            this.popoverController.dismiss();
        }
    }


}
