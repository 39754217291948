import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



import { routeDataOptionsObj } from "../dashboard/dashboard-routing.module";
import { AccessRequestsOverviewComponent } from './access-requests-overview/access-requests-overview.component';
import { AccessRequestsDetailComponent } from './access-requests-detail/access-requests-detail.component';

const userRequestRoutes: Routes = [
	{
		path: ":id",
		component: AccessRequestsDetailComponent,
		data: {
			fixedTitleBarFooter: false,
			contentWrapClass: "",
			mainContentCardClass: "entity-details-card",
			titleBarOptions: {
				title: "Request Detail",
			}
		} as routeDataOptionsObj
	},
	{
		path: "",
		component: AccessRequestsOverviewComponent,
		data: {
			fixedTitleBarFooter: true,
			contentWrapClass: "",
			mainContentCardClass: "section-overview-content-card",
			titleBarOptions: {
				title: "Requests",
				showSearchbar: true,
				searchbarPlaceholder: "Search requests by email...",
				searchConfigurations: []
			}
		} as routeDataOptionsObj
	},
];

@NgModule({
	imports: [RouterModule.forChild(userRequestRoutes)],
	exports: [RouterModule]
})

export class AccessRequestsRoutingModule { }
