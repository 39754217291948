import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { VaccineReleaseFormService } from 'src/services/vaccine-release-form.service';

@Component({
  selector: 'app-vrfdog-vet',
  templateUrl: './vrfdog-vet.component.html',
  styleUrls: ['./vrfdog-vet.component.scss'],
})
export class VRFDogVetComponent implements OnInit {

    @Input() showVRF;

    @Input() locationId;
    @Input() vaccineReleaseFormId;
    @Input() vaccineReleaseFormTitle;

    @Input() petsVet;

    @Input() vrfVerifierContactInfo;

    constructor(
        public spinner: NgxSpinnerService,
        private vaccineReleaseFormService : VaccineReleaseFormService
    ) { 
    }

    ngOnInit() {
        if( this.showVRF === null ){ 
            this.showVRF = true;
        }

        if( this.petsVet === null ){ 
            this.petsVet = {
                vetname: "",
                companyname: "",
                email: "",
                phone: "",
                fax: ""
            };
        }
        if( this.vaccineReleaseFormId === null ){ 
            this.vaccineReleaseFormId = "";
        }
        if( this.vaccineReleaseFormTitle === null ){
            this.vaccineReleaseFormTitle = "";
        }
        if( this.vrfVerifierContactInfo === null ){ 
            this.vrfVerifierContactInfo = {
                locationName : "",
                locationEmail: "",
                locationPhone: "",
                locationFax : ""
            };
        }
    }


    public generateVaccineReleaseFromPDF(){ 
        //window.open( this.vaccineRelease.agreementDoc , "_blank" );
        //Object.assign(document.createElement('a'), {
        //    target: '_blank',
        //    href: this.vaccineReleaseForm.agreementDoc,
        //  }).click();

        this.spinner.show();

          this.vaccineReleaseFormService.getDownloadBuffer(this.locationId, this.vaccineReleaseFormId ).then( buffer => {
            //console.log( "buffer" );
            
            var encodedUri = window.URL.createObjectURL(buffer);
            console.log( "uri", encodedUri );
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${this.vaccineReleaseFormTitle}.pdf`);
            document.body.appendChild(link); // Required for FF
            link.click();
            link.remove();

            this.spinner.hide();
            
          }).catch( err => {
             console.log( err );
             this.spinner.hide();
          }); 

    }

}

