// TODO RENAME TO SectionOverviewColumnsModule

import { NgModule } from "@angular/core";

import { RouterModule } from "@angular/router";

import { DashboardCommonModule } from "../dashboard/dashboard-common.module";

import { PipesModule } from "src/pipes/pipes.module";

import { SectionOverviewColumnsComponent } from "./section-overview-columns/section-overview-columns.component";
import { SectionOverviewColumnComponent } from "./section-overview-column/section-overview-column.component";

import { OverviewListItemBodyTemplateDirective } from "src/directives";

import {SortSelectionOveriewPopoverComponent} from "./sort-selection-overiew-popover/sort-selection-overiew-popover.component";


import { MatSelectModule } from '@angular/material/select';

@NgModule({
	declarations: [
		SectionOverviewColumnsComponent,
		SectionOverviewColumnComponent,
        OverviewListItemBodyTemplateDirective,
        SortSelectionOveriewPopoverComponent
    ],
    entryComponents: [
        SortSelectionOveriewPopoverComponent
    ],	
    imports: [
		RouterModule,
		DashboardCommonModule,
		PipesModule,
		MatSelectModule
	],
	exports: [
		SectionOverviewColumnsComponent,
		SectionOverviewColumnComponent,
		OverviewListItemBodyTemplateDirective
	]
})

export class SectionOverviewModule { }
