import { Injectable, ElementRef } from "@angular/core";

import { AlertController, ModalController } from "@ionic/angular";

//import { HTMLIonOverlayElement } from "@ionic/core";
import { DiagnosticStruct } from '../models/structs';
import { MatDialog } from '@angular/material';
import { SearchMenuPopupComponent, getPosition } from '../app/dashboard/search-menu-popup/search-menu-popup.component';
import { Overlay } from '@angular/cdk/overlay';
import { DateSelectPopoverComponent } from '../app/dashboard/date-select-popover/date-select-popover.component';
import { IDateSelectOptions } from './interfaces';
import { PopoverController, Events } from "@ionic/angular";
import { LocationSelectPopoverComponent } from '../app/dashboard/location-select-popover/location-select-popover.component';
import { CompleteCancellationComponent } from "../app/dashboard/complete-cancellation/complete-cancellation.component";
import { AppointmentChangeStatusComponent } from "../app/dashboard/appointment-change-status/appointment-change-status.component"; 

import { VaccinePopoverComponent } from "../app/dashboard/vaccine-popover/vaccine-popover.component";
import { GroupedAppointmentChangeStatusComponent } from "src/app/dashboard/grouped-appointment-change-status/grouped-appointment-change-status.component";
import { OnHoldAllPopoverComponent } from "../app/dashboard/on-hold-all-popover/on-hold-all-popover.component";
import { CancellationPolicyUserFacingMsgPopupComponent } from "src/app/dashboard/cancellation-policy-user-facing-msg-popup/cancellation-policy-user-facing-msg-popup.component";
import { ActiveDropOffSortPopoverComponent } from "../app/drop-offs/active-drop-off-sort-popover/active-drop-off-sort-popover.component";
import { CancellationPolicyLinkPopupComponent } from "../app/dashboard/cancellation-policy-link-popup/cancellation-policy-link-popup.component"; 
import { ToastService } from "./toast.service";
import { RescheduleMeetGreetPopoverComponent } from "src/app/dashboard/mg-reschedule-popover/mg-reschedule-popover.component";

declare var window;

@Injectable({
	providedIn: "root"
})
export class AlertService {

	constructor(
		private alertController: AlertController,

		public dialog: MatDialog,
		public overlay: Overlay,
		public modalController: ModalController,
		public popoverController: PopoverController,
		public ionEventBus: Events,
        private toastService: ToastService

	) { }

	public async showDateSelect(options: IDateSelectOptions): Promise<any> {
		const selectDateModal = await this.modalController.create({
			component: DateSelectPopoverComponent,
			componentProps: options,
			cssClass: "select-date-modal",
			backdropDismiss: true
		});

		const prom = selectDateModal.onDidDismiss().then((result) => {
			//console.log( "result", result );
			return result.data; // as Date;
		});
		selectDateModal.present();
		return prom;
		
	}

    public async showCompleteCancellationModal(): Promise<any> { 
            const modal = await this.modalController.create({
                component: CompleteCancellationComponent,
                cssClass: "completeCancellationModal",
                componentProps: {},
                backdropDismiss:false
            });       
            const promise = modal.onDidDismiss().then((result) => {
			
                return result.data;
            });
            modal.present();
            return promise;
    }

    public async showChangeStatusModal( currentStatus : string ): Promise<any> { 
        const modal = await this.modalController.create({
            component: AppointmentChangeStatusComponent,
            cssClass: "completeCancellationModal",
            componentProps: { 
                currentStatus: currentStatus
            },
            backdropDismiss:false
        });       
        const promise = modal.onDidDismiss().then((result) => {
        
            return result.data;
        });
        modal.present();
        return promise;
    }
    public async showChangeStatusGroupedApptsModal(groupedAppts, currentStatus : string ): Promise<any> { 
        const modal = await this.modalController.create({
            component: GroupedAppointmentChangeStatusComponent,
            cssClass: "completeCancellationModal",
            componentProps: { 
                groupedAppts: groupedAppts,
                currentStatus: currentStatus
            },
            backdropDismiss:false
        });       
        const promise = modal.onDidDismiss().then((result) => {
        
            return result.data;
        });
        modal.present();
        return promise;
    }

    public async onHoldCallPopover() { 
        const modal = await this.modalController.create({
            component: OnHoldAllPopoverComponent,
            cssClass: "completeCancellationModal",
            componentProps: { 
            },
            backdropDismiss:false
        });       
        const promise = modal.onDidDismiss().then((result) => {
        
            return result.data;
        });
        modal.present();
        return promise;
    }

    public async showCanellationPolicyUserFacingEditMsg(userFacingMsg : string, locationId: string ) { 
        const modal = await this.modalController.create({
            component: CancellationPolicyUserFacingMsgPopupComponent,
            cssClass: "completeCancellationModal",
            componentProps: { 
                currentMsg: userFacingMsg,
                locationId: locationId
            },
            backdropDismiss:false
        });       
        const promise = modal.onDidDismiss().then((result) => {
        
            return result.data;
        });
        modal.present();
        return promise;
    }

    public async showCancellationPolicyLinkEditMsg( cancellationLink: string, locationId) { 
        const modal = await this.modalController.create({
            component: CancellationPolicyLinkPopupComponent,
            cssClass: "completeCancellationModal",
            componentProps: { 
                cancellationLink: cancellationLink,
                locationId: locationId
            },
            backdropDismiss:false
        });       
        const promise = modal.onDidDismiss().then((result) => {
        
            return result.data;
        });
        modal.present();
        return promise;
    }


	public async showLocationSelectPopover(evt: Event, locations: any[]): Promise<string> {

		return new Promise(async (res, rej) => {
			const popover = await this.popoverController.create({
				component: LocationSelectPopoverComponent,
				event: evt,
				componentProps: {
					locations: locations
				},
				cssClass: "header-menu-popover location-select-popover",
				showBackdrop: false
			});

			const updateHandler = id => res(id);
			this.ionEventBus.subscribe("user-location-selected", updateHandler);

			popover.onDidDismiss().then(() => {
				this.ionEventBus.unsubscribe("user-location-selected", updateHandler);
			});

			popover.present();
		});
		
    }
    
    public async showVaccinePopover(evt: Event ,shots: any[], timezone = null ){ 
        const popover = await this.popoverController.create( {
            component: VaccinePopoverComponent,
            event: evt,
            componentProps: {
                vaccines: shots,
                timezone
            },
            //showBackdrop: false,
            cssClass: "vaccine-popover"
        });

        popover.present();
    }

    public async activeDropoffsSortPopover( evt: Event ){ 
        const popover = await this.popoverController.create( {
            component: ActiveDropOffSortPopoverComponent,
            event: evt,
            componentProps: {
            },
            //showBackdrop: false,
            cssClass: "activeDropoffSort"
        });

        popover.present();
    }

	// Returns entity when dialog is closed or null if no entity selected.
	public showSearchMenu(container: ElementRef<any>, locationId: string, options: {
		searchConfigurations: { indexName: string, filters?: string  }[],
        showDogName?: boolean, searchbarPlaceholder?: string
	}): Promise<any> {
		return new Promise((res, rej) => {
			const rect = container.nativeElement.getBoundingClientRect();
			//Data Saved Open client agreement now.
			const diapos = getPosition(container);
			const diag = this.dialog.open(SearchMenuPopupComponent, {
				width: `${rect.right - rect.left}px`,
				maxHeight: '630px',
				data: {
					containerRef: container,
					titleBarOptions: { searchByLocation: !(!(locationId)), ...options },
					locationId: locationId
				},
				hasBackdrop: true,
				closeOnNavigation: true,
				position: diapos,
				panelClass: ['mat-dialog-container--no-padding', 'search-menu-dropdown'],
				autoFocus: true,
				scrollStrategy: this.overlay.scrollStrategies.reposition(),
			});

			diag.afterClosed().subscribe(
				data => res(data), err => rej(err)
			);    


		});
	}


	public async showSuccess(title:string, subTitle:string):Promise<void> {

		const alert = await this.alertController.create({
			header: title,
			subHeader: subTitle, 
			buttons: ['Dismiss'],
		});
		
		return alert.present();

    }
    
    public async showSingleDaycareConfirm( title : string , subTitle: string ){ 
        return new Promise( ( resolve ) => { 

            this.alertController.create({
                header: title,
                subHeader: subTitle,
                buttons: [{
                    text: "Dismiss",
                    handler: (() => { 
                        resolve(true);
                    })
                }]
            }).then( alertModal => { 
                alertModal.present()
            });

        } );
    }

	public async showError(title: string, error: string): Promise<void> {
		const alert = await this.alertController.create({
			header: title,
			message: error,
			cssClass: 'error-alert',
			buttons: ['Dismiss'],
		});

		return alert.present();
	}

	public showDiagnostic(diagnostic: DiagnosticStruct, userDiagnostic? : boolean ) {
		const buttons = [
			{
				text: 'Dismiss'
			},
			{
				text: 'Copy to Clipboard',
				handler: (() => {
					window.navigator.clipboard.writeText(diagnostic.message).then(_s => window.adminlog.print("copied log")).catch(err => window.adminlog.error(err));
					this.toastService.show( "Copied to clipboard" );
                    return false;
				})
			}
		];

        let css = diagnostic.isError ? "diagnostic-alert error-alert": "diagnostic-alert";
        if( userDiagnostic ){ 
            css = diagnostic.isError ? "diagnostic-alert-user-info error-alert": "diagnostic-alert-user-info";
        }

		this.alertController.create({
			header: diagnostic.title || "Diagnostic",
			subHeader: diagnostic.actionable || '',
			message: diagnostic.message,
			buttons: buttons,
			cssClass: css
		}).then((confirm) => {
			confirm.present();
		});
	}

	public showDiagnostics(diagnostics: DiagnosticStruct[], title?: string) {

        const messageRegex = /(<([^>]+)>)/ig;
		const message = diagnostics.map(diagnostic => {
			return `${diagnostic.title}${diagnostic.actionable ? `<hr><br>${diagnostic.actionable}` : ''}<hr><br>${diagnostic.message}`;
		}).join('<br><br>');
        
		const buttons = [
			{
				text: 'Dismiss'
			},
			{
				text: 'Copy to Clipboard',
				handler: (() => {
					window.navigator.clipboard.writeText(message.replace(messageRegex, "")).then(_s => window.adminlog.print("copied log")).catch(err => window.adminlog.error(err));
					return false;
				})
			}
		];
		this.alertController.create({
			header: title || "Diagnostic",
			message: message,
			buttons: buttons,
			cssClass: "diagnostic-alert"
		}).then((confirm) => {
			confirm.present();
		});
	}

	public showConfirm(title: string, message: string, cancelBtnText: string, okBtnText: string, approveButtonCssClass?:string):Promise<boolean> {

		return new Promise((resolve) => {

			this.alertController.create({
				header: title,
				message: message,
				buttons: [
					{
						text: cancelBtnText,
						role: "cancel",
						cssClass: "secondary",
						handler: (() => {
							resolve(false);
						})
					},
					{
						text: okBtnText,
                        cssClass: approveButtonCssClass || "",
						handler: (() => {
							resolve(true);
						})
					}
				],
				cssClass: "confirm-alert-wrapper"
			})
			.then((confirm) => {
				confirm.present();
			});

		});

	}

	public async petExecBookingSuccess(title:string, subTitle:string):Promise<boolean> {
		return new Promise((resolve) => {

			this.alertController.create({
				header: title,
				subHeader: subTitle, 
				buttons: [{
					text: 'Finalize Daycare',
					handler: (() => {
						resolve(true);
					})
				}],
			}).then( confirm => { 
				confirm.present();
			});
			

		});
		
    }
    public async petExecBookingSuccessBoardingSpa(title:string, subTitle:string):Promise<boolean> {
		return new Promise((resolve) => {

			this.alertController.create({
				header: title,
				subHeader: subTitle, 
				buttons: [
                    {
						text: "cancel",
						role: "cancel",
						handler: (() => {
							resolve(false);
						})
                    },
                    {
                        text: 'confirm',
                        handler: (() => {
                            resolve(true);
                        })
                    }
                ],
			}).then( confirm => { 
				confirm.present();
			});
			

		});
		
	}
    public async rescheduleMeetAndGreetSelect(options: any): Promise<any> {
		const selectDateModal = await this.modalController.create({
			component: RescheduleMeetGreetPopoverComponent,
			componentProps: options,
            cssClass: 'meet-greet-reschedule-popover',
			backdropDismiss: true
		});

		const prom = selectDateModal.onDidDismiss().then((result) => {
			//console.log( "result", result );
			return result.data; // as Date;
		});
		selectDateModal.present();
		return prom;
		
	}

}
