import { Injectable } from "@angular/core";

import { HttpHeaders, HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map, catchError, take } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { FirestoreService, queryOptions } from "./firestore.service";

import { AuthService } from "./auth.service";

import { WebcamUser } from "src/models/entities";

import { WebcamUserStruct } from "src/models/structs";

@Injectable({
	providedIn: "root"
})

export class WebcamAccessService {

	public collection = "webcamWhitelist";

	constructor (private dbService: FirestoreService, private authService: AuthService, private http: HttpClient) { }

	public getById(webcamUserId:string):Observable<WebcamUser> {

		if(!webcamUserId){
			return of(null);
		}

		return this.dbService.getDoc(this.collection, webcamUserId).pipe(
			map((webcamUserDoc) => {
				
				if(webcamUserDoc.exists){
					return new WebcamUser(webcamUserDoc.data() as WebcamUserStruct, webcamUserDoc.id);
				}

			})
		);

	}

	public getWebcamAccessUsers(userFirstNameToFilter:string = "", userLastNameToFilter:string = ""):Observable<WebcamUser[]> {

		const queryOptions:queryOptions = {
			orderByField: "email",
		};

		const queryConditions = [];

		if(userFirstNameToFilter){
			queryConditions.push(["firstName", "==", userFirstNameToFilter]);
		}

		if(userLastNameToFilter){
			queryConditions.push(["lastName", "==", userLastNameToFilter]);
		}

		if(queryConditions.length > 0){
			queryOptions.conditions = queryConditions;
		}

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((webcamUserDocs) => {
				return webcamUserDocs.map((webcamUserDoc) => {
					
					return new WebcamUser(webcamUserDoc.data() as WebcamUserStruct, webcamUserDoc.id);

				});
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public deleteWebcamUser(webcamUserId: string):Promise<void> {

		return this.dbService.deleteDoc(this.collection, webcamUserId);

	}

	public addWebcamUser(webcamUserData: WebcamUserStruct):Promise<void> {

		if(webcamUserData.id){

			delete webcamUserData.password;

			return this.dbService.setDoc(this.collection, webcamUserData.id, webcamUserData);

		}else{

			delete webcamUserData.id;

			return this.createWebcamUser(webcamUserData);

		}

	}

	public async createWebcamUser(webcamUserData: WebcamUserStruct):Promise<void> {

		let bearerToken = await this.authService.getBearerToken();

		const httpOptions = {
			headers: new HttpHeaders({          
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		webcamUserData.email = webcamUserData.email.toLocaleLowerCase();
		
		return new Promise<void>((resolve, reject) => {
			this.http.post(environment.firebaseFunctions.addWebcamUser, webcamUserData, httpOptions)
				.pipe(
					take(1)
				)
				.subscribe(
					(result) => {
						window.adminlog.print(result);
						resolve();
					},
					(error) => {
						window.adminlog.print(error);
						reject(error);
					}
				);
		});

	}
	
	public updateWebcamUser(webcamUserId: string, updatedData: any):Promise<void> {

		delete updatedData.id;
		delete updatedData.password;

		return this.dbService.updateDoc(this.collection, webcamUserId, updatedData);

	}
	
}
