import { NgModule } from "@angular/core";

import { GetUserNamePipe } from "./get-user-name.pipe";
import { GetUserEmailPipe } from "./get-user-email.pipe";
import { GetUserPhonePipe } from "./get-user-phone.pipe";

import { GetAccountNamePipe } from "./get-account-name.pipe";
import { GetAdminNamePipe } from "./get-admin-name.pipe";
import { GetDogNamePipe, GetPetNamePipe } from "./get-dog-name.pipe";
import { GetLocationNamePipe } from "./get-location-name.pipe";

import { GetUploadedFileNamePipe } from "./get-uploaded-file-name.pipe";

import { MinutesAwayPipe } from "./minutes-away.pipe";

import { FormatPhoneNumberPipe } from "./format-phone-number.pipe";

import { ForInLoopPipe } from "./for-in-loop.pipe";
import { GetNeuterdStatePipe } from './get-neuterd-state.pipe';
import { GetAppointmentDateTextPipe } from './get-appointment-date-text.pipe';
import { CapitalizeFirstLetterPipe } from './capitalize-first-letter.pipe';
import { GetPetParentNamePipe } from "./get-pet-parent-name.pipe";
import { DropOffBadgeTextPipe } from './drop-off-badge-text.pipe';
import { DropOffSortNamePipe } from './drop-off-sort-name.pipe';
import { GetFullSortNamePipe } from './get-full-sort-name.pipe';
import { MeetAndGreetTimeSlotToMinutesStringPipe } from './meet-and-greet-time-slot-to-minutes-string.pipe';
import { SettingsToggleTransformerPipe } from './settings-toggle-transformer.pipe';
import { GetFullFilterNamePipe } from "./get-full-filter-name.pipe";
import { SelectedLocationsToStringPipe } from './selected-locations-to-string.pipe';
import { GetUserAccessPipe } from "./get-user-access.pipe";
import { UserFilterPipe } from './user-filter.pipe';

@NgModule({
	declarations: [
		GetUserNamePipe,
		GetUserEmailPipe,
        GetUserAccessPipe,
		GetUserPhonePipe,
		GetAccountNamePipe,
		GetAdminNamePipe,
		GetDogNamePipe,
		GetPetNamePipe,
		GetLocationNamePipe,
		GetUploadedFileNamePipe,
		MinutesAwayPipe,
		FormatPhoneNumberPipe,
		ForInLoopPipe,
		GetNeuterdStatePipe,
		GetAppointmentDateTextPipe,
		CapitalizeFirstLetterPipe,
        GetPetParentNamePipe,
        DropOffBadgeTextPipe,
        DropOffSortNamePipe,
        GetFullSortNamePipe,
        GetFullFilterNamePipe,
        MeetAndGreetTimeSlotToMinutesStringPipe,
        SettingsToggleTransformerPipe,
        SelectedLocationsToStringPipe,
        UserFilterPipe,
	],
	exports: [
		GetUserNamePipe,
		GetUserEmailPipe,
        GetUserAccessPipe,
		GetUserPhonePipe,
		GetAccountNamePipe,
		GetAdminNamePipe,
		GetDogNamePipe,
		GetPetNamePipe,
		GetLocationNamePipe,
		GetUploadedFileNamePipe,
		MinutesAwayPipe,
		FormatPhoneNumberPipe,
		ForInLoopPipe,
		GetNeuterdStatePipe,
		GetAppointmentDateTextPipe,
		CapitalizeFirstLetterPipe,
        GetPetParentNamePipe,
        DropOffBadgeTextPipe,
        DropOffSortNamePipe,
        GetFullSortNamePipe,
        GetFullFilterNamePipe,
        MeetAndGreetTimeSlotToMinutesStringPipe,
        SettingsToggleTransformerPipe,
        SelectedLocationsToStringPipe,
        UserFilterPipe,
	],
    providers: [
        DropOffBadgeTextPipe 
    ]
})

export class PipesModule { }
