import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFullFilterName'
})
export class GetFullFilterNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
      switch (value) {
          case 'noshowreschedule':
              return 'No Show / Reschedule';
          case 'scheduled':
              return "Scheduled";
          default:
              return "Show All";
      }

  }

}
