import { Injectable } from '@angular/core';

import firebase from "firebase/app";
import { FirestoreService, queryOptions } from './firestore.service';


import { Update } from '../models/entities/update';
import { combineLatest, Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {

    private collection : string = "updates"

    constructor(
        protected dbService: FirestoreService,
    ) { }

  public getById( updateId: string ){ 
    return this.dbService.getDoc(this.collection, updateId).pipe(
        map((updateDoc) => {

            if(updateDoc.exists){
                return new Update(updateDoc.data() as Update, updateDoc.id);
            }

        })
    );
  }

    public getNewUpdates( locationId : string){ 
        return new Observable((subscriber) => {

            this.dbService.db.collection(this.collection)
            .where('locationId', '==', locationId)
            .where("petexec.uploaded", "==", false)
            .where( "deleted", "==", false  )
            .where( "archived" , "==", false) 
            .onSnapshot((querySnapshot) => {
                subscriber.next(querySnapshot.docs);
            },
            (error) => {
                window.adminlog.error("Get Collection error: ", error)
            })
        }).pipe(
            map((updateDocs) => {
                return this.mapDocsToObjs(updateDocs);
            }),
            catchError((error) => {
                window.adminlog.print(error);
                throw error;
            })
        );
    }

    public update(updateId: string, updatedData: any):Promise<void> {
        return this.dbService.updateDoc(this.collection, updateId, updatedData);
    }

    public completeUpdate( updateId: string ){
        return this.dbService.updateDoc(this.collection, updateId, { "petexec.uploaded": true, "petexec.uploadedAt":new Date() });
    }

    public createUpdate( update: Update ){ 
        return new Promise((resolve, reject) => {    
            this.dbService.db.collection(this.collection).add(update).then((docRef) => {
                resolve(docRef);    
            });
        });
    }

    public getHistory( locationId: string ){ 
        const orderByField = "createdAt";
		const orderByDir = "desc";
        const locationIdCondition:any = ["locationId", "==", locationId];
        let archivedHistory:queryOptions = {
			conditions: [
				locationIdCondition,
				["archived", "==", true],
			],
			orderByField: orderByField,
			orderByDir: orderByDir,
			limit: 100,
		};
        let uploadedHistoryQueryOptions:queryOptions = {
			conditions: [
				locationIdCondition,
				["petexec.uploaded", "==", true]
			],
			orderByField: orderByField,
			orderByDir: "asc",
			limit: 100,
		};

        let archivedHistoryObs = this.dbService.getCollection(this.collection, archivedHistory);
		let uploadedHistoryQueryOptionsObs = this.dbService.getCollection(this.collection, uploadedHistoryQueryOptions);
		
        return combineLatest(archivedHistoryObs, uploadedHistoryQueryOptionsObs).pipe(
			map(([cancelledDropoffDocs, doneDropoffDocs]) => {
				
				return this.mapDocsToObjs(cancelledDropoffDocs.concat(doneDropoffDocs))
					.sort((updateA:Update, updateB:Update):number => {
						return (updateA[orderByField] > updateB[orderByField]) ? -1 : 1;
					});

			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw(error);
			})
		);
    }


  public mapDocsToObjs(updateDocs):Update[] {

    return updateDocs.map((updateDoc) => {

        const update = new Update(updateDoc.data() as Update, updateDoc.id);

        return update;

    });

}


}
