import { Component, OnInit, ViewChild, ViewChildren, QueryList, Input, EventEmitter, Output } from "@angular/core";

import { Location as NgLocation } from "@angular/common";

import { ActivatedRoute, ParamMap } from "@angular/router";

import { NgModel, NgForm } from "@angular/forms";

import { Observable, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

import { FirestoreService, ToastService, UserService, AccountService, PetService, DogService, LocationService } from "src/services";

import { User, Account, Dog, Pet } from "src/models/entities";
import { AccountStruct, AccountAccess, PetStruct, MatchState } from "src/models/structs";

type userAccessSelectOption = {
	label: string,
	value: AccountAccess
};
export type userInfoInterface = {firstName: string, lastName: string, email: string, phoneNumber: string};
@Component({
	selector: "app-petexec-account-simple-lookup",
	templateUrl: "./petexec-account-lookup.component.html",
	styleUrls: ["./petexec-account-lookup.component.scss"],
})

export class PetexecAccountLookupSimpleComponent implements OnInit {



	public  userObs: Observable<User>;
	protected _userId: string;
	public get userId(): string{
		return this._userId;
	}
	@Input() public set userId(val: string){
		if(val && val !== this.userId){
			this._userId = val;
			this.getUser(this.userId);
		}
	}

	protected _userInfo: userInfoInterface;
	public get userInfo(): userInfoInterface{
		return this._userInfo;
	}
	@Input() public set userInfo(val: userInfoInterface){
		if(this.userObs || this.user || this.userId){
			return;
		}
		this._userInfo = val;
	}
	@Output() protected onAccountSelected = new EventEmitter<Account>();
	@Output() protected onCancel = new EventEmitter<void>();
	public  user: User;

	public  petsByPetExecAccountId: {
		[petExecAccountId: number]: Pet[]
	} = {};

	public  accountLookupResults: Account[];

	public  selectedAccount: Account;

	public  lookupSearchTerm: string = "";


	public  userAccessSelectPopoverOptions: any = {
		header: "Select Access",
	};

	public  petMatchSelectPopoverOptions: any = {
		header: "Select Match",
	};

	public  isSearchSubmitting: boolean = false;
	public  isSubmitting: boolean = false;

	public unsubscriber: Subject<void> = new Subject();

	public userLocationSet = false;

	public currUserLocationId: string;
	public companyID: string;

	// Faked variable to show and hide list of PetExec accounts
	public  hasSearched: boolean = false;

	constructor(
		public route: ActivatedRoute,
		public urlLocation: NgLocation,
		public toastService: ToastService,
		public  userService: UserService,
		public  accountService: AccountService,
		public  dogService: DogService,
		public  petService: PetService,
		public  locationService: LocationService,
		public  firestoreService: FirestoreService
	) { }


	public getUser(userId: string){

		if(!userId){


			this.unsubscriber.next();
			this.unsubscriber.complete();

			this.user = null;
			this.userId = '';
			return;
		}
		// Reset observable if user Id changes.
		if(this.userObs) {

			this.unsubscriber.next();
			this.unsubscriber.complete();

		}

		this.userObs = this.userService.getByIdObs(userId);


		this.userObs
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((user: User) => {

				this.userId = user._id;

				this.user = user;
				this.userInfo = {
					firstName: this.user.firstName,
					lastName: this.user.lastName,
					email: this.user.email,
					phoneNumber: this.user.phoneNumber
				}



			});

	}
	public ngOnInit():void {


		this.locationService.getCurrUserLocationObs()
			.pipe(
				takeUntil(this.unsubscriber)
			)
			.subscribe((currUserLocation) => {

				if(this.userLocationSet){
					this.goBackToList();
				}

				this.userLocationSet = true;

				this.currUserLocationId = currUserLocation._id;
				this.companyID = currUserLocation.credential;

			});

	}

	public ngOnDestroy():void {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	public  onSearchbarSubmitted():void {

		if(!this.lookupSearchTerm){
			this.accountLookupResults = null;
			this.petsByPetExecAccountId = {};
			return;
		}

		this.isSearchSubmitting = true;

		this.accountService.getOfficialAccountsByEmail(this.currUserLocationId, this.lookupSearchTerm.toLowerCase())
		 	.then((accounts) => {

		 		this.accountLookupResults = accounts.map(account => {

					return new Account({data: account, fromExec: true});
				});

		 		// Populate this.petsByPetExecAccountId with account.userid and account's pets.
		 		accounts.forEach((account) => {

					if(!account.pets) account.pets = [];

		 			this.petsByPetExecAccountId[account.userid] = account.pets.map(pet => {
						return new Pet({data: pet, fromExec: true});
					});

					});
				  window.adminlog.print(this.petsByPetExecAccountId);
				this.isSearchSubmitting = false;

		 	})
		 	.catch((error) => {
		 		window.adminlog.print(error);
		 	});

	}

	public  selectAccount(selectedAccount: Account):void {

		this.selectedAccount = selectedAccount;

	}

	public  onSelectDifferentAccountBtnClicked():void {

		this.selectedAccount = null;

	}

	public  confirmMatch():void|boolean {

		if(this.selectedAccount){
			this.onAccountSelected.emit(this.selectedAccount);

		}

	}

	public showSuccessNotif():Promise<void> {
		return this.toastService.show("Success! You have matched this admin dashboard account.", "medium");
	}

	public goBackToList():void {
		// this.urlLocation.back();
		this.cancel();
	}

	public cancel(){
		this.onCancel.emit();
	}
}
