import { Injectable } from "@angular/core";

import { Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { UserRequestService } from "./user-request.service";
import { DogRequestService } from "./dog-request.service";
import { MeetGreetService } from "./meet-greet.service";
import { MeetGreetAppointmentService } from "./meet-greet-appointment.service";
import { AppointmentService } from "./appointment.service";
import { DropoffService } from "./drop-off.service";
import { LeadService } from "./lead.service";
import { InquiryService } from "./inquiry.service";
import { AccessRequestService } from './access-request.service';
import { UpdatesService } from './updates.service';
import { UploadService } from "./upload.service";

@Injectable({
	providedIn: "root"
})

export class BadgeCountService {

	constructor(
		private userRequestService: UserRequestService,
		private dogRequestService: DogRequestService,
		private meetGreetService: MeetGreetService,
		private meetGreetAppointmentService: MeetGreetAppointmentService,
		private appointmentService: AppointmentService,
		private dropoffService: DropoffService,
		private leadService: LeadService,
		private inquiryService: InquiryService,
		private requestService: AccessRequestService,
        private updatesService: UpdatesService,
        private uploadService: UploadService
	) {}

	public getUserRequestsCountObs(locationId: string):Observable<number> {

		return this.userRequestService.getNewUserRequests(locationId).pipe(
			map((newUserRequests) => {
				return newUserRequests.length;
			})
		);

	}

	public getDogRequestsCountObs(locationId: string):Observable<number> {

		return this.dogRequestService.getNewDogRequests(locationId).pipe(
			map((newDogRequests) => {
				return newDogRequests.length;
			})
		);

	}

	public getMeetGreetsCountObs(locationId: string):Observable<number> {

		return this.meetGreetService.getNewMeetGreets(locationId).pipe(
			map((newMeetGreets) => {
				return newMeetGreets.length;
			})
		);

	}

	public getMeetGreetAppointmentsCountObs(locationId: string):Observable<number> {

		return this.meetGreetAppointmentService.getNewMeetGreets(locationId).pipe(
			map((newMeetGreets) => {
				return newMeetGreets.length;
			})
		);

	}

	public getAppointmentsCountObs(locationId: string):Observable<number> {

		return this.appointmentService.getNewAppointments(locationId).pipe(
			map((newAppointments) => {
				return newAppointments.length;
			})
		);

	}

	public getDropoffsCountObs(locationId: string):Observable<number> {

		return this.dropoffService.getActiveDropoffs(locationId).pipe(
			map((newDropoffs) => {
				return newDropoffs.length;
			})
		);

	}

	public getLeadsCountObs(locationId: string):Observable<number> {

		return this.leadService.getNewLeads(locationId).pipe(
			map((newLeads) => {
				return newLeads.length;
			})
		);

	}
	public  getAccessRequestsCountObs(locationId: string): Observable<number>{
		return this.requestService.getNewAccessRequests(locationId).pipe(
			map((newRequests) => {
				return newRequests.length;
			})
		);
	}
	public getInquiriesCountObs(locationId: string):Observable<number> {

		return this.inquiryService.getNewInquiries(locationId).pipe(
			map((newInquiries) => {
				return newInquiries.length;
			})
		);

	}
    //getUpdatesCountObs
    public getUpdatesCountObs(locationId: string):Observable<number> {
		return this.updatesService.getNewUpdates(locationId).pipe(
			map((newInquiries) => {
				return newInquiries.length;
			})
		);

	}

    /**
     * Get count for new uploads on active M&Gs
     * @param locationId 
     * @returns 
     */
    public getNewUpdatesForMeetAndGreets( locationId : string ) :Observable<number>{ 

        return new Observable(  sub => {
            this.uploadService.getListOfUploadsThatHaveNotBeenUploadedToPetexec( locationId ).subscribe( newUploads => {
                //console.log("****newUploads" ,newUploads);
                let count = 0;
                let meetAndGreetIds = [];
                for( let x = 0; x < newUploads.length; x++ ){
                    if( !meetAndGreetIds.includes( newUploads[x].meetAndGreetId ) ){
                        // Add to list early so we dont lookup this M&G again
                        meetAndGreetIds.push( newUploads[x].meetAndGreetId );
                        //console.log("get MG detaiils for ", newUploads[x].meetAndGreetId );
                        this.meetGreetAppointmentService.getById( newUploads[x].meetAndGreetId ).subscribe( (mg) => {
                            //console.log( "mg", mg );
                            if( mg ){
                                if( mg.status ){
                                    if( mg.status !== 'done' ){
                                        // Add Count, this should be looked at by the admin
                                        count++;
                                        // Broiadcast count update
                                        sub.next(count);
                                    }else{
                                        // Nothing to do just for debugging
                                        //console.log( "***  mg.status",  mg.status );
                                    }
                                }
                            }
                        });
                    }
                }
            });
        });

    }

}
