import { Injectable } from '@angular/core';
import { OverviewColumn } from '../../models/structs/overview-column';
import { QueryStore, QueryStoreFactoryService } from '../stores/query.store';
import { OverviewListItem } from '../../models/structs/overview-list-item';
import { Entity } from '../../models/entity';
import { Subject } from 'rxjs';
import { IDataColumn, ISectionOverviewAbstractComponent, IQueryStore, IEntityMap, IQuerySortOverview } from '../interfaces';
import { takeUntil } from 'rxjs/operators';
import firebase from 'firebase';
import { AuthService } from '../auth.service';
export const DEFAULT_QUERY_SETTING = 'data_column_setting';

/**
 * Overview column data store
 * */
@Injectable({ providedIn: 'root' })
export class OVDataColumnFactory {

    constructor(private qsf: QueryStoreFactoryService,
                public settingsService: AuthService,
        ){}
    public createDataColumn<E extends Entity<any>>(id: string, overviewComponent : ISectionOverviewAbstractComponent<E> & IQuerySortOverview<E> , entityMap: IEntityMap<E>, config: OverviewColumn,  isGrouped = false, ...options: Array< & {query: firebase.firestore.Query, name: string }>){

        /*
        if( overviewComponent.sectionUrlRoot == "/appointments" && id == "new" ) { 
            
        }
        */
        // create queries from queryOptions
        const queryStore: QueryStore<E> = this.qsf.createQuerySet(overviewComponent.entityService.collection, entityMap, ...options);

        // create column from queryStore and other components
         
        const dc = new DataColumn(overviewComponent, entityMap, queryStore, this.settingsService, isGrouped, id);
        
        Object.assign(dc, config);
        dc.id = id;
        return dc;
        
        

    }

}



/**
 * Meant to store only one kind of database entity
 *
 * Store query Options & names
 * switch between them
 * expose results of queries
 *
 * configuration for column
 *
 * */
export class DataColumn<E extends Entity<any>> implements OverviewColumn, IDataColumn<E>{

    public static query_name_to_label = {
        "date_asc": "Date ascending",
        "date_desc": "Date descending",
        "default": "Default",
        "follow_up_date_asc": "Date of follow up ascending",
        "follow_up_date_desc": "Date of follow up descending",
        "alphabetical_asc": "Alphabetical (A to Z)",
        "alphabetical_desc": "Alphabetical (Z to A)",
        "appointment_date_desc": "Date descending",
        "appointment_date_asc": "Date ascending",

        "appointment_updated_asc": "Last update ascending",
        "appointment_updated_desc": "Last update descending",
        "appointment_createdAt_asc": "Created at ascending",
        "appointment_createdAt_desc": "Created at descending",

        "newest_request_date_asc": "Created at ascending", 
        "oldest_request_date_desc": "Created at descending",


    }

    public get sortOptions(): Array<{ value: string, label: string }> {
        return this.available_queries;
    }

    // how the data column should be tracked by the overview component
    public id: string;
    // the setting field for stored options
    public setting: string = DEFAULT_QUERY_SETTING;

    public disableSort: false;


    public get base_query() {
        if (this.overviewComponent.base_query[this.id])
            return this.overviewComponent.base_query[this.id];
        else return null;
    }

    protected _selectedQuery: { value: string, label: string };
    public get selectedQuery() {
        return this._selectedQuery;
    }
    public setSelectedQuery() {
        this._selectedQuery = {
            value: this.query.names[this.query.active_index],
            label: DataColumn.query_name_to_label[this.query.names[this.query.active_index]] || this.query.names[this.query.active_index]
        };
    }

    /**
     * Overview Column Fields
     */
    title: string;
    headerBtnLink?: string;
    headerBtnIcon?: string;
    listItems: OverviewListItem<any>[];
    hideListCount?: boolean;
    isLoading?: boolean;

    protected delist = new Subject<void>();

    /**
     * Functional Fields
     */
    protected overviewComponent: ISectionOverviewAbstractComponent<E> & IQuerySortOverview<E>;
    public query: IQueryStore<E>;
    public entityMap: IEntityMap<E>;
    protected available_queries: Array<{ value: string, label: string }> = [];


    public settingsService: AuthService; 
    /**
     * Initialized via factory.
     * @param overviewComponent the angular component context that holds the overview flow
     * @param query the query store responsible for receiving database records
     */

    public isGrouped = false;
    constructor(overviewComponent: ISectionOverviewAbstractComponent<E> & IQuerySortOverview<E>,
         entityMap: IEntityMap<E>, 
         query: QueryStore<E>,
         settingService = null,
         isGrouped = false,
         id: string
         ) {
        this.id = id;
        this.isGrouped = isGrouped;
        this.overviewComponent = overviewComponent;
        this.query = query;
        this.entityMap = entityMap;
        this.settingsService = settingService;
        this.resumeResultSubscription();
    }

    public cancelResultSubscription() {
        this.delist.next();
    }

    /**
     * clears list of queries in store. updates the list.
     * */
    public clear() {
        this.query.clear();
        this.setAvailableQueries();
    }

    /**
     * query store flatten methods for query store.
     * @param query_name only accepts strings since using a query object at this level will be a bit tedious to do properly and probably hard to maintain.
     * only use strings after the store is implemented, from an overview class at least.
     */
    public switchQuery(query_name: string): void {
        this.listen(query_name);
        this.resumeResultSubscription();
        //console.log('****** switchQuery',this.overviewComponent, this.overviewComponent.settingsService);
        this.overviewComponent.settingsService.setSettings({ [`${this.setting}`]: { [this.id]: query_name } }, this.overviewComponent.sectionUrlRoot.replace('/', '_2f_') )
            .then(() => window.adminlog.print("Updated User Settings", `${this.setting}.${this.id}`))
            .catch(e => window.adminlog.error(e));
    }
    public listen(query?: string | firebase.firestore.Query) {

        const listq = this.query.listen(query);
        this.setSelectedQuery();
        return listq;
    }

    public addQueries(...options: Array<{ query: firebase.firestore.Query, name: string }>) {
        this.query.addQueries(...options);
        this.setAvailableQueries();

    }
    public addQuery(val: firebase.firestore.Query, name?: string) {
        this.query.addQuery(val, name);
        this.setAvailableQueries();

    }

    protected resumeResultSubscription() {
        this.isLoading = true;
        this.delist = new Subject<void>();
        
        this.query.result.pipe(takeUntil(this.delist)).subscribe((results) => {
            window.adminlog.print(this.id, "results");
            //console.log( "results", results )
            //results.filter( result => !result["cancelled"] == true )
            //console.log( this.overviewComponent.sectionUrlRoot );
            /*
            if( this.overviewComponent.sectionUrlRoot == "/appointments" ) {
                //this.listItems = this.overviewComponent.convertToListItemsArr(results, this.overviewComponent.badgeTextProperty, this.overviewComponent.sectionUrlRoot);
                if( this.settingsService.pageSettings[DEFAULT_QUERY_SETTING]["new"] != "default" ){ 
                    this.listItems = this.overviewComponent.convertToListItemsArr(results, this.overviewComponent.badgeTextProperty, this.overviewComponent.sectionUrlRoot);
                }
                else { 
                    this.listItems = this.overviewComponent.convertToListItemsArr(results, this.overviewComponent.badgeTextProperty, this.overviewComponent.sectionUrlRoot);
                }
            }
            else if(this.overviewComponent.sectionUrlRoot != "/appointments" ){ 
                this.listItems = this.overviewComponent.convertToListItemsArr(results, this.overviewComponent.badgeTextProperty, this.overviewComponent.sectionUrlRoot);
            }
            */
            //console.log( this.isGrouped );
            // To not refresh the grouped appointments listitems when there is new data added to the new appointments collection (new appointment conditional)
            //console.log( "query", this.query );
            //console.log( "results", this.query )
            /*
            if( this.overviewComponent.sectionUrlRoot === "/appointments" && this.settingsService.pageSettings[DEFAULT_QUERY_SETTING]["new"] === "default"){ 
                this.isGrouped = true;
            }
            else{ 
                this.isGrouped = false;
            }
            */
            //if( !this.isGrouped ){
            if( this.id != "on hold") { 
                this.listItems = this.overviewComponent.convertToListItemsArr(results, this.overviewComponent.badgeTextProperty, this.overviewComponent.sectionUrlRoot);
                /*
                console.log( "listItems " + this.id , this.listItems )
                for( let x =0; x < this.listItems.length ; x++ ){ 
                   if( this.listItems[x].contextObj.source === "Reacquisition Campaign" ){ 

                   }
                }
                */
            } 
            
            //}
            //console.log( this.overviewComponent.sortColumn );
            //console.log(results[0]["status"] );
            //console.log( this.overviewComponent.sectionUrlRoot )
            this.isLoading = false;
            if( this.overviewComponent.sectionUrlRoot == "/appointments" && results[0] ) {
                if( results[0]["status"] == "onHold" || results[0]["status"] == "Pending" ){
                    //this.isLoading = true;
                }
            }
            else if(this.overviewComponent.sectionUrlRoot != "/appointments" ) { 
                this.isLoading = false;
            }
        });
    }
    protected setAvailableQueries() {
        this.available_queries = Object.values(this.query.names).filter(name => !(!name)).map(real_name => {
            return { value: real_name, label: DataColumn.query_name_to_label[real_name] || real_name };
        });
        return this.available_queries;
    }

}
