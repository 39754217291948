import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { LocationService } from "./location.service";

import { Dog, Location } from "src/models/entities";
import { DogStruct, LocationsProperty } from "src/models/structs";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class DogService {

	public collection = "dogs";

	constructor (private dbService: FirestoreService, private locationService: LocationService){}

	public getById(dogId: string):Observable<Dog> {

		return this.dbService.getDoc(this.collection, dogId).pipe(
			map((dogDoc) => {
				return new Dog(dogDoc.data() as DogStruct, dogId);
			})
		)

	}

	/*public getById(userId: string):Promise<User> {

		return new Promise((resolve, reject) => {

			this.dbService.getDocSnapshot(this.collection, userId)
				.then((result) => {
					resolve(new User(result.data() as UserStruct, userId));
				})
				.catch((error) => {
					reject(error);
				});

		});

	}*/

	public update(dogId: string, updatedData: any):Promise<void> {

		return this.dbService.updateDoc(this.collection, dogId, updatedData);

	}

	public async getDogsByUserId(userId:string, locationId?:string):Promise<Dog[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["parent." + userId, "==", true],
			]
		};

		if(locationId){
			queryOptions.conditions.push([`locations.${locationId}.access`,'==', true]);
		}

		try {
			
			const dogDocs = await this.dbService.getCollectionOnce(this.collection, queryOptions);
			
			return dogDocs.map((dogDoc) => {
				const dog = new Dog((dogDoc.data() as DogStruct), dogDoc.id);
				return dog;
			});

		}
		catch (err) {
			throw err;
		}

	}

	public getDogsByUserIdObs(userId:string):Observable<Dog[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["parent." + userId, "==", true]
			]
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((dogDocs) => {
				return dogDocs.map((dogDoc) => {

					const dog = new Dog(dogDoc.data() as DogStruct, dogDoc.id);

					return dog;

				});
			})
		);

	}

	public async updateLocationAccess(dog: Dog, location: Location|string, giveAccess: boolean):Promise<void> {

		if(typeof location === "string"){
			location = await this.locationService.getById(location);
		}

		// Update to get location from database if location variable is string
		let locations:LocationsProperty = {};

		if(dog.locations){
			locations = dog.locations;
		}

		locations[location._id] = {
			access: giveAccess,
			name: location.name,
			storeId: location.storeId
		};

		return this.update(dog._id, {locations: locations});

	}

}
