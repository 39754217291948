import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { MeetGreet } from "src/models/entities";

import { MeetGreetStruct, MeetGreetStatus } from "src/models/structs";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class MeetGreetService extends EntityServiceAbstract<MeetGreet> {

	public collection = "meetAndGreetRequest";

	protected statusColorMap:object = {
		"pending": 	"primary",
		"doing":	"secondary",
		"done":		"completed",
        "noshow":   "alert",
        "schedulelater": "dark"
	};

	private queryItemsLimit = 100;

	constructor (
		protected dbService: FirestoreService, 
		private logService: ActivityLogService
	){
		super();
	}

	public getStatusBadgeText<MeetGreet>(entityObj: MeetGreet, badgeTextProperty: string):string {
		
		let badgeText = "";

		switch(entityObj[badgeTextProperty]) {

			case MeetGreet.DOING:
				badgeText = "in progress";
				break;

			case MeetGreet.DONE:
				badgeText = "completed";
				break;

			default:
				badgeText = entityObj[badgeTextProperty];

		}
		
		return badgeText;

	}

	public getById(meetGreetId:string):Observable<MeetGreet> {

		return this.dbService.getDoc(this.collection, meetGreetId).pipe(
			map((meetGreetDoc) => {
				
				if(meetGreetDoc.exists){
					return new MeetGreet(meetGreetDoc.data() as MeetGreetStruct, meetGreetDoc.id);
				}

			})
		);

	}

	public update(meetGreetId:string, updatedData:any):Promise<void> {

		if(!updatedData.updatedAt){
			
			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, meetGreetId, updatedData);

	}

	public updateStatus(meetGreetId: string, newStatus: MeetGreetStatus):Promise<any> {

		const updatedValues = {
			status: newStatus
		};

		let meetGreetUpdatePromise = this.update(meetGreetId, updatedValues);

		let logEntryPromise = this.logService.addLogEntry(this.collection, meetGreetId, "Status Change to " + newStatus, newStatus);

		return Promise.all([meetGreetUpdatePromise, logEntryPromise]);

	}

	public getNewMeetGreets(locationId:string):Observable<MeetGreet[]> {
		
		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", MeetGreet.PENDING]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getDoingMeetGreets(locationId:string):Observable<MeetGreet[]> {
		
		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", MeetGreet.DOING]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getDoneMeetGreets(locationId:string):Observable<MeetGreet[]> {
		
		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", MeetGreet.DONE]
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	private mapDocsToObjs(meetGreetDocs:firebase.firestore.QueryDocumentSnapshot[]):MeetGreet[] {

		return meetGreetDocs.map((meetGreetDoc) => {
					
			const meetGreet = new MeetGreet(meetGreetDoc.data() as MeetGreetStruct, meetGreetDoc.id);

			return meetGreet;

		});

	}

}