import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";

import { FormBuilder, FormGroup, AbstractControl, Validators } from "@angular/forms";

import { AuthService, FooterInfoService, ToastService } from "src/services";

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.scss"],
})

export class ForgotPasswordComponent implements OnInit {

	public forgotPasswordForm:FormGroup;

	public generalLoginError:string = "";

	constructor(
		public authService: AuthService,
		public toastService: ToastService,
		public footerInfoService: FooterInfoService,
		public formBuilder: FormBuilder,
		public router: Router
	) { }

	ngOnInit():void {

		this.forgotPasswordForm = this.formBuilder.group({
			email: ["", Validators.compose([
				Validators.required,
				Validators.email
			])]
		});
		
	}

	get emailField():AbstractControl {
		return this.forgotPasswordForm.get("email");
	}

	onFormSubmit():void {
		
		this.generalLoginError = "";

		this.authService
			.resetPassword(this.emailField.value)
			.then((result) => {
				
				this.toastService.show("Password recovery email sent! Please check your email for further instructions.", "large", 10000).then(() => {
					this.router.navigate(['/login']);
				});
			
			})
			.catch((err) => {
				this.generalLoginError = err;
			});

	}

}
