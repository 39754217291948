import { Pipe, PipeTransform } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { DogService, PetService } from "src/services";

@Pipe({
	name: "getDogName"
})

export class GetDogNamePipe implements PipeTransform {

	constructor(private dogService: DogService) { }

	public transform(dogId: string):Observable<string> {

		return this.dogService.getById(dogId).pipe(
			map((dog) => {
				return dog.name;
			})
		);
		
	}

}

@Pipe({
	name: "getPetName"
})

export class GetPetNamePipe implements PipeTransform {

	constructor(private petService: PetService) { }

	public transform(petId: string): Observable<string> {

		return this.petService.getById(petId).pipe(
			map((dog) => {
				return dog.petName;
			})
		);

	}

}
