import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpDetectService {

  private apiKey:string = "6149vez1a59igr";
  private InterceptorSkipHeader = 'X-Skip-Interceptor';
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set(this.InterceptorSkipHeader, '')
  };

  constructor( private http:HttpClient ) { }
  // Uses http.get() to load data from a single API endpoint
  lookup() {
    return this.http.get('https://api.ipregistry.co/?key=' + this.apiKey, this.httpOptions );
  }

}
