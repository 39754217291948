export enum ActivityLogRequestType {
    Update,
    Addition,
    Deletetion
}
export enum DifferentState {
    different,
    notDifferent    
}
export class SettingsActivityLog{ 
    action : ActivityLogRequestType[];
    createdAt: any;
    editedBy: string;
    msg: string;
    dataObject: any;
}

export class MeetAndGreetDuration{ 
    label: string;
    duration: Number;
    selected: Boolean;
}