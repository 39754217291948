import { Entity } from "src/models/entity";

import { ClientAgreementStruct } from "src/models/structs";
import firebase from 'firebase';

export class ClientAgreement extends Entity<ClientAgreement> implements ClientAgreementStruct {
  isPdfReady: boolean;
  finalText: string;
  signature?: { dateTime: firebase.firestore.Timestamp; dataURL: string; ip: string; sigId: string; };
  locationId: string;
  meetGreetId: string;
  timezone?: string;
	petId: string;
	userId: string;
	accountId: string;
	signed: boolean;
	agreementDoc?: string;
	createdAt: firebase.firestore.Timestamp;
	updatedAt: firebase.firestore.Timestamp;

	constructor(data?: ClientAgreementStruct, id?: string){
		super(data, id);
	}
}
