import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IDateSelectOptions } from '../../../services/interfaces';
import moment from 'moment-timezone';
import { MbscDatepicker, MbscDatepickerOptions } from '@mobiscroll/angular';
import { first } from 'rxjs/operators';
// import { MbscCalendarOptions, MbscDatetimeOptions } from '@mobiscroll/angular';



@Component({
  selector: 'app-date-select-popover',
  templateUrl: './date-select-popover.component.html',
  styleUrls: ['./date-select-popover.component.scss'],
})
export class DateSelectPopoverComponent implements OnInit, IDateSelectOptions{
    public submit_button: string = "Confirm";
    public date_select: string = "Select Date";
    public time_select: string = "Select Time";
    public title: string = "Select a Date and Time";
    public timezone: string;
    // public datePickerOptions: MbscCalendarOptions = {
    //     dateFormat: "mm/dd/yy",
    //     min: moment(),
    // };
    @ViewChild('datePicker', {static: false}) datePicker: MbscDatepicker;
    public datePickerOptions: MbscDatepickerOptions = {
        controls: ['calendar'],
        theme: 'ios',
        themeVariant :"light",
        display: 'anchored',
        min: moment(),
        dateFormat: "MMM D, YYYY",
        };


    // public timePickerOptions: MbscDatetimeOptions = {
    //     timeFormat: "h:ii A",
    //     steps: {
    //         minute: 15
    //     }
    // };

    @ViewChild('timePicker', {static: false}) timePicker: MbscDatepicker;
    timePickerOptions: MbscDatepickerOptions = {
        controls: ['time'],
        themeVariant :"light",
        theme: 'ios',
        timeFormat: 'h:mm A',
        stepMinute: 15
      };


    public selectedDate: any;
    public selectedTime: string = "12:00 AM";

    public selectedDate_object = new Date();

    public disableButtonClicks;
    ngAfterContentChecked(): void
    {
        if( this.datePicker ){ 
            this.datePicker.onOpen.pipe(first()).subscribe( data => {
                this.disableButtonClicks = true;
            } );    
            this.datePicker.onClose.pipe(first()).subscribe( data=> { 
                this.disableButtonClicks = false;
            } );
        }
        if( this.timePicker ){ 
            this.timePicker.onOpen.pipe(first()).subscribe( data => {
                this.disableButtonClicks = true;
            } );    
            this.timePicker.onClose.pipe(first()).subscribe( data=> { 
                this.disableButtonClicks = false;
            } );
        }
    }
    
    constructor(private modalController: ModalController) {
        if (!this.timezone) this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //const date = moment(new Date());
        //this.selectedDate = date.format("MM/DD/YYYY");
        //this.selectedTime = date.format("h:mm A");
    }



    ngOnInit() { }

    onDaySelected(date) {
        this.selectedDate = date.valueText;

        //this.selectedDate_object = new Date( date.value );
        //this.selectedDate_object.setHours
        //this.selectedDate = date.value
        //console.log( this.selectedDate );
    }
    onTimeSelected(time) {
        this.selectedTime = time.valueText;
        //console.log( time.value );
    }

    onSubmit() {
        //window.adminlog.print(this.selectedTime, this.selectedDate, moment(this.selectedDate + " " + this.selectedTime, "MM/DD/YYYY h:mm A").tz( this.timezone, true ).toDate());
        this.modalController.dismiss(moment(this.selectedDate + " " + this.selectedTime, "MMM/DD/YYYY h:mm A").tz( this.timezone, true )/*.toDate()*/);
    }

    cancel() {
        this.modalController.dismiss();
    }

}
