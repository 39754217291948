import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "forInLoop"
})

export class ForInLoopPipe implements PipeTransform {

	constructor() { }

	public transform(numInterations: number):number[] {

		return new Array(numInterations).fill(1).map((val, i) => (val * i));
		
	}

}