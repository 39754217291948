import { Injectable } from '@angular/core';

import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { environment } from "../environments/environment";
import { AuthService } from './auth.service';
import { ShotDetailsStruct } from 'src/models/structs';
import { PetexecShot } from 'src/models/entities/petexecShot';

@Injectable({
  providedIn: 'root'
})
export class PetExecService {

  constructor(
    private http: HttpClient,
    protected authService: AuthService,
  ) { }



  async createPetExecAppointment(locationId: string, apptId : string, adminId?:string ){ 
    let bearerToken = await this.authService.getBearerToken();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + bearerToken
      })
    };
    return new Promise<{ message: string }>((resolve, reject) => {

      this.http.post( environment.firebaseFunctions.executive+"/locations/"+locationId+"/daycare/"+ apptId+"?adminId="+ adminId, {apptId:apptId}, httpOptions ).subscribe(
				(result: { message: string }) => {
                    console.log( "result", result );
					resolve(result);
				}, (error) => {
					reject(error);
				});;
      
    });
  }

  async getAccountEnrollmentPackages( accountId: string, locationId: string ){
    let endpointUrl: string = environment.firebaseFunctions.enrollmentStatus.replace("{accountId}", accountId);//.replace("{locationId}", locationId);
    console.log("endpointURL", endpointUrl);

    /*
    let bearerToken = await this.authService.getBearerToken();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + bearerToken,
        //'X-Skip-Interceptor': ''
      })
    };
    */

    return new Promise <any>((resolve, reject) => {
        this.http.get(endpointUrl)
            .subscribe(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    window.adminlog.print(error);
                    reject(error);
                }
            );
    });
  }

  async uploadFileToPetexec(accountId:string, fileUrl: string, fileName: string){ 
    let bearerToken = await this.authService.getBearerToken();
    const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": "Bearer " + bearerToken
        })
      };

    let endpointUrl: string = environment.firebaseFunctions.uploadDocumentsToPetexec;

    return new Promise<any>((resolve, reject) => {

      this.http.post( endpointUrl, {accountId:accountId, fileUrl: fileUrl, fileName: fileName}, httpOptions ).subscribe(
				(result ) => {
                    console.log( "result", result );
					resolve(result);
				}, (error) => {
					reject(error);
				});;
      
    });

  }

  async uploadPetHistoryToPetexex( meetAndGreetId : string, onBoardingFormId : string ){ 
    let bearerToken = await this.authService.getBearerToken();
    const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": "Bearer " + bearerToken
        })
      };
      let endpointUrl: string = environment.firebaseFunctions.uploadPetHistoryToPetexec;

    return new Promise<any>((resolve, reject) => {

      this.http.put( endpointUrl, {meetAndGreetId:meetAndGreetId, onBoardingFormId: onBoardingFormId }, httpOptions ).subscribe(
				(result ) => {
                    console.log( "result", result );
					resolve(result);
				}, (error) => {
					reject(error);
				});;
      
    });
  }


  async uploadVaccineExpirationDates( petId: string, shotDetailsarray:ShotDetailsStruct[] ){ 
    let bearerToken = await this.authService.getBearerToken();
    const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": "Bearer " + bearerToken
        })
      };

    let endpointUrl: string = environment.firebaseFunctions.uploadVaccineExpirationDates;

    return new Promise<any>((resolve, reject) => {

      this.http.post( endpointUrl, {firebasePetId: petId, vaccines: shotDetailsarray}, httpOptions ).subscribe(
				(result ) => {
                    console.log( "result", result );
					resolve(result);
				}, (error) => {
					reject(error);
				});;
      
    });
  }


   async getLocationPetShots(locationId: string ){ 
    let endpointUrl: string = environment.firebaseFunctions.getLocationPetShots.replace("{locationId}", locationId);

    return new Promise <any>((resolve, reject) => {
        this.http.get(endpointUrl)
            .subscribe(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    window.adminlog.print(error);
                    reject(error);
                }
            );
    });
   }  
   async getLocationVets(locationId: string ){ 
    let endpointUrl: string = environment.firebaseFunctions.getLocationVets.replace("{locationId}", locationId);

    return new Promise <any>((resolve, reject) => {
        this.http.get(endpointUrl)
            .subscribe(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    window.adminlog.print(error);
                    reject(error);
                }
            );
    });
   }  




  async deletePetExecAppointment( locationId:string, appointmentExecId:string){ 
    let bearerToken = await this.authService.getBearerToken();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + bearerToken
      })
    };

    return new Promise<{ message: string }>((resolve, reject) => {
        this.http.delete( environment.firebaseFunctions.executive+"/locations/"+locationId+"/daycare/"+ appointmentExecId, httpOptions ).subscribe(
            (result: { message: string }) => {
                resolve(result);
            }, (error) => {
                reject(error);
            });;
    });
  }

  async deletePetExecMeetGreetAppointment( locationId:string, appointmentExecId:string){ 
    let bearerToken = await this.authService.getBearerToken();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + bearerToken
      })
    };

    return new Promise<{success: any; message: string }>((resolve, reject) => {
        this.http.delete( environment.firebaseFunctions.executive+"/locations/"+locationId+"/meet-greet/"+ appointmentExecId, httpOptions ).subscribe(
            (result: { success: boolean, message: string }) => {
                resolve(result);
            }, (error) => {
                reject(error);
            });;
    });
  }

    /**
     * Function to fetch the PPA agreement.
     * @param accountId 
     * @returns 
     */
    async getPetParentSigned(accountId: string, locationId: string) {

        let endpointUrl: string = environment.firebaseFunctions.executive + "/pet-parent-agreement/{accountId}/{locationId}".replace("{accountId}", accountId).replace("{locationId}", locationId);

        return new Promise<any>((resolve, reject) => {
            this.http.get(endpointUrl)
                .subscribe(
                    (result) => {
                        //console.log()
                        resolve(result);
                    },
                    (error) => {
                        console.error(error);
                        reject(error);
                    }
                );
        });
    }

    /**
     * Function to sign the PPA agreement.
     * @param accountId 
     * @param agreementId 
     * @returns 
     */
    async signPetParentAgreement(accountId: string, agreementId: string) {

        let endpointUrl: string = environment.firebaseFunctions.executive + "executive/pet-parent-agreement/sign";

        return new Promise<any>((resolve, reject) => {
            this.http.post(endpointUrl, { accountId: accountId, agreementId: agreementId })
                .subscribe(
                    (result) => {
                        //console.log()
                        resolve(result);
                    },
                    (error) => {
                        console.error(error);
                        reject(error);
                    }
                );
        });
    }
}
