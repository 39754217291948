import { Pipe, PipeTransform } from "@angular/core";

import { AccountService } from "src/services";

@Pipe({
	name: "getAccountName"
})

export class GetAccountNamePipe implements PipeTransform {

	constructor(private accountService: AccountService) { }

	public async transform(accountId: string):Promise<string> {

        if( accountId ){
            const account = await this.accountService.getById(accountId);
		    return account.firstName + " " + account.lastName;
        }else{
            return '';
        }
		
		
	}

}