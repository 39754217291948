import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApprovalSelectedTabService {

    private selectedTab: BehaviorSubject<any> = new BehaviorSubject<any>({ meetAndGreetIdSelected: false });

    constructor() { }

    public setSelectedTab(selectedTab: any) {
        this.selectedTab.next(selectedTab);
    }

    public getSelectedTab(){ 
        return this.selectedTab.asObservable();
    }


}
