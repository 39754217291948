import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DaysOfTheWeekFilter } from 'src/models/entities/meetAndGreetSettingsCopyFilter';



@Injectable({
  providedIn: 'root'
})
export class SettingsMeetAndGreetAppointmentsCopyService {

    private daysOfTheWeekFilterSubject = new BehaviorSubject<DaysOfTheWeekFilter>(null);
    private day = new BehaviorSubject<string>(null);
    private applyFilterSubject = new BehaviorSubject<boolean>(false);

    constructor() { }

    public updateFilter( filter: DaysOfTheWeekFilter ): void{
        this.daysOfTheWeekFilterSubject.next(filter);
    }
    public getFilter():Observable<DaysOfTheWeekFilter>{ 
        return this.daysOfTheWeekFilterSubject.asObservable();
    }
    public applyFilter(): void{
        this.applyFilterSubject.next(true);
    }
    public setDay ( day: string ): void{
        this.day.next(day);
    }
    public getDay (): Observable<string>{
        return this.day.asObservable();
    }
}
