import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "hover-label-button",
	templateUrl: "./hover-label-button.component.html",
	styleUrls: ["./hover-label-button.component.scss"],
})

export class HoverLabelButtonComponent implements OnInit {

	@Input() label: string = "";

	constructor() { }

	public ngOnInit():void {}

}
