import { Component, OnInit } from "@angular/core";

@Component({
	selector: "refresh-icon",
	templateUrl: "./refresh-icon.component.html",
	styleUrls: ["./refresh-icon.component.scss"],
})

export class RefreshIconComponent implements OnInit {

	constructor() { }

	public ngOnInit():void {}

}
