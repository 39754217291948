/**
 * Contains Entity Logic for Transactions with Firestore, and Document Validation. M Should extend Entity.
 * */

export type prepFunction = (en: Entity<any>) => any;
export type Preps = {
	[property: string]: prepFunction;
}

export class Entity<M> {
	
	public _id: string = "";
	public get id() {
		return this._id;
	}

	public static doNotWriteOn: string[] = ["_id", "_preps", "_cutOff"];
	
	protected _preps: Preps = {};

	constructor(data?: any, id?: string){

		if(id !== ""){
			this._id = id;
		}
		
		this.hydrate(data);

	}

	/**
	 * Self Validates
	 */
	protected get isValid():boolean {
		return true;
	}

	/**
	 * protected hydrate - Imports an object into the child data class. No type protection on fields so care must be taken. Types should be protected by the isValid method and the Prepare method, and the use of Interfaces at compile time.
	 *
	 * @param  {type} data: any description
	 * @return {type}           description
	 */
	protected hydrate(data: any):void {
		for(const key in data){
			if(
				(typeof (this[key]) !== "function") && 
				(data[key] !== null) &&
				(data[key] !== undefined) &&
				(key !== 'id')
			){
				this[key] = data[key];
			}
		}
	}
	
	/**
	 * Return a publishable Entity.
	 * Meant to be overridden in children.
	 *
	 */
	public prepare():object {
		
		const data = {};
		
		Object.entries(this).forEach((entry: [string, any]) => {
			if(typeof(entry[1]) !== "function" && !Entity.doNotWriteOn.includes(entry[0])){
				data[entry[0]] = entry[1];
			}
		});
		
		for(const field in this._preps){
			data[field] = this._preps[field](this);
		}
		
		return data;
	
	}
	
	public static objectCompare(obj1: any, obj2: any):boolean {
		
		//Loop through properties in object 1
		for(const p in obj1){
			
			//Check property exists on both objects
			if(obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)){
				return false;
			}
			
			switch(typeof (obj1[p])){
				//Deep compare objects
				case "object":
					if(!this.objectCompare(obj1[p], obj2[p])){
						return false;
					}
					break;
				//Compare function code
				case "function":
					if((typeof (obj2[p]) === "undefined") || ((p !== "compare") && (obj1[p].toString() !== obj2[p].toString()))){
						return false;
					}
					break;
				//Compare values
				default:
					if(obj1[p] !== obj2[p]){
						return false;
					}
			}
		}
		
		//Check object 2 for any extra properties
		for(const p2 in obj2){
			if(typeof (obj1[p2]) === "undefined"){
				return false;
			}
		}
		
		return true;
	
	}
	
}
