import { Injectable } from '@angular/core';

import firebase from "firebase/app";
import { FirestoreService } from './firestore.service';


import { Upload } from '../models/entities/upload';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from "rxjs/operators";
import { MeetGreetAppointmentService } from './meet-greet-appointment.service';
import { collections } from 'src/models/collections';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    protected dbService: FirestoreService,
    private meetGreetAppointmentService: MeetGreetAppointmentService
  ) { }


    public getUploadsByMeetAndGreetIdObservable( meetAndGreetId : string ){ 
        if( !meetAndGreetId ){ 
            return of( {} );
        }
        return new Observable((subscriber) => {

            this.dbService.db.collection('uploads')
            .where('meetAndGreetId', '==', meetAndGreetId)
            //.where( "petexec.uploaded", "==", false )
            .where("deleted", "!=", true)
            .onSnapshot((querySnapshot) => {
                subscriber.next(querySnapshot.docs);
            },
            (error) => {
                window.adminlog.error("Get Collection error: ", error)
            })
        }).pipe(
			map((uploadDocs) => {
				return this.mapDocsToObjs(uploadDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
    }
    public getUploadByMeetAndGreetIdPromise( meetAndGreetId : string ): Promise<any>{ 
        return new Promise((resolve, reject) => { 
            this.dbService.db.collection('uploads')
            .where('meetAndGreetId', '==', meetAndGreetId)
            .where("deleted", "!=", true)
            .get()
            .then((querySnapshot) => {
                resolve(querySnapshot.docs);
            },
            (error) => {
                window.adminlog.error("Get Collection error: ", error)
                reject(error);
            })
        });
    }   

    public getUploadsByAccountId( accountId: string ){ 
        return new Observable((subscriber) => {

            this.dbService.db.collection('uploads')
            .where('accountId', '==', accountId)
            //.where( "petexec.uploaded", "==", false )
            .where("deleted", "!=", true)
            .onSnapshot((querySnapshot) => {
                subscriber.next(querySnapshot.docs);
            },
            (error) => {
                window.adminlog.error("Get Collection error: ", error)
            })
        }).pipe(
			map((uploadDocs) => {
				return this.mapDocsToObjs(uploadDocs);
			}),
			catchError((error) => {
                console.log(error);
				window.adminlog.print(error);
				throw error;
			})
		);
    }

    public getUploadsByUpdateId( updateId: string ){ 
        return new Observable((subscriber) => {

            this.dbService.db.collection('uploads')
            .where('updateId', '==', updateId)
            //.where( "petexec.uploaded", "==", false )
            .where("deleted", "!=", true)
            .onSnapshot((querySnapshot) => {
                subscriber.next(querySnapshot.docs);
            },
            (error) => {
                window.adminlog.error("Get Collection error: ", error)
            })
        }).pipe(
			map((uploadDocs) => {
				return this.mapDocsToObjs(uploadDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
    }

    public getListOfUploadsThatHaveNotBeenUploadedToPetexec( locationId ){ 
        return new Observable((subscriber) => {

            this.dbService.db.collection('uploads')
            .where('locationId', '==', locationId)
            //.where( "petexec.uploaded", "==", false )
            .where("deleted", "!=", true)
            .where( "petexec.uploaded", "==", false )
            .where("from", "==", "meetAndGreet")
            .where("type", "==", "vaccination")
            .onSnapshot((querySnapshot) => {
                subscriber.next(querySnapshot.docs);
            },
            (error) => {
                window.adminlog.error("Get Collection error: ", error)
            })
        }).pipe(
			map((uploadDocs) => {
				return this.mapDocsToObjs(uploadDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
    }



    public upload( document:any ): Promise<any>{ 
        return new Promise( async (resolve, reject) => { 
            let docRef;
            
            let idNotFound = true;
            let autoId = "";
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            while( idNotFound ){ 
                autoId           = '';
                for ( var i = 0; i < 20; i++ ) {
                    autoId += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                let existingUploadData = await this.dbService.db.collection("uploads").doc(autoId).get();
                if( !existingUploadData.exists ){ 
                    let newUploadDocument = await this.dbService.db.collection('uploads').doc(autoId).set(document);
                    docRef = newUploadDocument; 
                    console.log( "docRef", autoId );   
                    idNotFound = false;
                }
            }
            
            resolve( docRef );
        });

    }

    public uploadFileAndReturnData( document:any ): Promise<any>{ 
        return new Promise( async (resolve, reject) => { 
            let docRef;
            
            let idNotFound = true;
            let autoId = "";
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            while( idNotFound ){ 
                autoId           = '';
                for ( var i = 0; i < 20; i++ ) {
                    autoId += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                let existingUploadData = await this.dbService.db.collection("uploads").doc(autoId).get();
                if( !existingUploadData.exists ){ 
                    let newUploadDocument = await this.dbService.db.collection('uploads').doc(autoId).set(document);
                    docRef = newUploadDocument; 
                    idNotFound = false;
                    document['id'] = autoId;
                    console.log( "uploadData", document, autoId );   
                    resolve( document );
                    
                } else {
                    console.log("uploadFileAndReturnData: existingUploadData", existingUploadData);

                    resolve(existingUploadData);
                }
            }
            
            resolve( docRef );
        }).catch((e) => {
            console.log("uploadFileAndReturnDataError: " + e);
        });

    }

    public completeUpload( uploadId:string ): Promise<any>{
        return new Promise((resolve, reject) => { 
            this.dbService.db.collection('uploads').doc(uploadId).update({ "petexec.uploaded": true, "petexec.uploadedAt":new Date() }).then( data=>{
                resolve(data);
            });
        });
    }

    public deleteUpload( uploadId:string ): Promise<any>{
        return new Promise((resolve, reject) => { 
            this.dbService.db.collection('uploads').doc(uploadId).update({ deleted: true }).then( data=>{
                resolve(data);
            });
        });
    }


    public mapDocsToObjs(uploadDocs):Upload[] {

		return uploadDocs.map((uploadDoc) => {

			const upload = new Upload(uploadDoc.data() as Upload, uploadDoc.id);

			return upload;

		});

	}
}
