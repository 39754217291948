import { Component, OnInit, Input } from "@angular/core";

import { combineLatest, Observable } from "rxjs";

import { ActivityLogService } from "src/services";

import { LogEntryStruct } from "src/models/structs";
import { defaultIfEmpty, filter, map } from "rxjs/operators";

@Component({
	selector: "entity-activity-log",
	templateUrl: "./entity-activity-log.component.html",
	styleUrls: ["./entity-activity-log.component.scss"]
})

export class EntityActivityLogComponent implements OnInit {

	@Input() parentCollection: string = "";
	@Input() parentId: string = "";

    @Input() collections = [];

	public logEntries: Observable<any[]>;

    public approvalLogEntries: [];
    

	constructor(public logService: ActivityLogService) { }

    ngOnChanges(){
        console.log(this.collections)
        if( this.collections.length === 0){ 
            if(this.parentCollection && this.parentId){
                this.logEntries = this.logService.getEntityLogEntries(this.parentCollection, this.parentId);
            }
        }
        else{ 
            console.log("from activity log component")
            this.logEntries = this.logService.getEntityLogEntries(this.collections[0].collection, this.collections[0].id);
        }
    }

	public ngOnInit():void {

        console.log(this.collections)
        if( this.collections.length === 0){ 
            if(this.parentCollection && this.parentId){
                this.logEntries = this.logService.getEntityLogEntries(this.parentCollection, this.parentId);
            }
        }
        else { 
            console.log("from activity log component")
            this.logEntries = this.logService.getEntityLogEntries(this.collections[0].collection, this.collections[0].id);

            //let observables = [];
            //for( let x = 0; x < this.collections.length; x++){
                //console.log( this.collections[x].collection )
                //console.log( this.collections[x].id ); 
            //    observables.push(this.logService.getEntityLogEntries(this.collections[x].collection, this.collections[x].id) );
            //}


            /*
            this.logEntries = combineLatest(observables).pipe(
                map( (observables) => {
                    let logEntries = [];
                    for( let x = 0; x < observables.length; x++){
                        logEntries = logEntries.concat(observables[x]);
                    }
                    // order log entries by createdAt date descending
                    logEntries.sort( (a, b) => {
                        return new Date(b.createdAt.toDate()).getTime() - new Date(a.createdAt.toDate()).getTime();
                    });
                    return logEntries;
                }),
                defaultIfEmpty([])
            );
            */

        }
	}

  createdAtConverter(date) {
    if(isNaN(date)) {
      return date.toDate();
    } else {  // if milliseconds
      let result = new Date(date);
      if(isNaN(result.getTime())) {  // if valid date(firebase timestamp)
        return date.toDate();
      } else {
        return result;
      }
    }
  }



}
