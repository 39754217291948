import { Subscriber, Subscription, Subject, timer } from "rxjs";
import { take, debounceTime, debounce } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IDatum, IFetch } from './interfaces/datum';

export class Datum<T> extends Subject<T> implements IDatum<T>{
    /**
     * Function to call when retrieving new data.
     */
    public fetchData: IFetch<T>;

    /**
     * Context to run fetchData in
     */
    public dataService: any;

    /**
     * Arguments with which to run the fetch transaction
     */
    public fetchArguments: any[];
    
    protected get fetchIsEnabled(): boolean {
        return !(!(this.fetchData && this.dataService && this.fetchArguments));
    }

    public dataStore: any;
    protected _data: T;

    public get data() {
        return this._data;
    }
    public debounceTimer = 1000;
    protected debounceFetch: Subject<void> = new Subject<void>();

    public constructor(data?: T) {
        super();
        if (data) this._data = data;
        this.debounceFetch.pipe(debounce(() => timer(this.debounceTimer))).subscribe(() => this.getNewData());
    }
    /**
     * Updates data store. Emits new value
     * @param value
     */
    public next(value?: T) {
        this._data = value;
        return super.next(value);
    }

    /**
     * Queries for new data.
     * Emits event for subscribers in async
     * */
    public async getNewData(): Promise<T> {
        if (this.fetchIsEnabled) {
            this._data = await this.fetchData.bind(this.dataService)(this, ...this.fetchArguments);
        }
        if (this.data) this.next(this.data);
        return this.data || null;
    }
    public refresh() {
        this.debounceFetch.next();
    }
    

    _subscribe(subscriber: Subscriber<T>): Subscription {
        const subs = super._subscribe(subscriber);
        this.refresh();
        return subs;
    }

    public takeOne(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void) {
        this.getNewData();
        return this.pipe(take(1)).subscribe(next, error, complete);
    }

}



@Injectable({
    providedIn: 'root'
})
export class DatumService {
    public createDatum<T>(dataService: any, fetch: IFetch<T>, ...args: any[]): IDatum<T> {
        const datum = new Datum<T>();
        datum.fetchData = fetch;
        datum.fetchArguments = args;
        datum.dataService = dataService;
        return datum;
    }

    public copyDatum<T>(datum: Datum<T>, options: {
        fetchData?: IFetch<T>,
        fetchArguments?: any[],
        dataService?: any
    } = {}): IDatum<T> {
        const newdatum = new Datum<T>(datum.data);
        newdatum.fetchArguments = (options.fetchArguments || datum.fetchArguments).slice();
        newdatum.fetchData = options.fetchData || datum.fetchData;
        newdatum.dataService = options.dataService || datum.dataService;
        return newdatum;
    }
}
