import { Component, OnInit, ViewChild } from "@angular/core";

import { Router, ActivationEnd } from "@angular/router";

import {Events, MenuController} from "@ionic/angular";

import { filter, map, takeUntil } from "rxjs/operators";

import { routeDataOptionsObj } from "../dashboard-routing.module";

import { LocationService } from "src/services";
import { Location } from 'src/models/entities';
import { SearchResultUserStruct } from "src/models/structs";

import { DashboardSearchbarComponent } from "../dashboard-searchbar/dashboard-searchbar.component";
import { Subject, zip } from 'rxjs';

export type searchConfiguration = {
	filters?: string,
	tag?: string,
	indexName: string
}

@Component({
	selector: "app-dashboard-title-bar",
	templateUrl: "./dashboard-title-bar.component.html",
	styleUrls: ["./dashboard-title-bar.component.scss"],
})

export class DashboardTitleBarComponent implements OnInit {

	@ViewChild(DashboardSearchbarComponent, { static: false }) public dashboardSearchbar: DashboardSearchbarComponent;

	public pageTitle: string;
	public showCreateBtn: boolean;
	public createBtnUrl: string;
	public showSearchbar: boolean;
	public searchbarPlaceholder: string;
	public searchByLocation: boolean;
	public useAutocomplete: boolean;
	public showDogNameInAutocomplete: boolean;
	public numAutocompleteItems: number = 20;
	public searchConfigurations: { filters: string, indexName: string }[] = [];

	public locationId: string = "nolocation";

	public unsubscriber: Subject<void> = new Subject();


	public titleBarOptions: any;
	constructor(public router: Router, public ionEventBus: Events, public locationService: LocationService, private menu: MenuController) { }

	ngOnInit() {
		this.locationService.getCurrUserLocationObs().subscribe(location => {
			if (!location && !this.locationId) return;
            if (location) this.locationId = location._id;
            console.log(this.locationId);

			this.compileConfiguration();
		});

		// TODO Should probably convert to Input property and have parent pass it in
		zip(this.router.events
			.pipe(
				filter((event) => {
					return (event instanceof ActivationEnd);
				}),
				map((event: ActivationEnd) => {
					return event.snapshot.data;
				})
			)).pipe(
				takeUntil(this.unsubscriber)
		).subscribe(result => {
			// Gather results
            //console.log( "result", result )
			let routeData: any;
			[routeData] = result;
			if (!routeData.titleBarOptions && !this.titleBarOptions) return;

			if (routeData.titleBarOptions) {
				if (JSON.stringify(routeData.titleBarOptions) !== JSON.stringify(this.titleBarOptions)) {
					window.adminlog.print("Updating query");
					if (this.dashboardSearchbar) this.dashboardSearchbar.showValue = '';
					if (this.dashboardSearchbar) this.dashboardSearchbar.copyValue = '';
				}
				this.titleBarOptions = JSON.parse(JSON.stringify(routeData.titleBarOptions));
			}

			this.compileConfiguration();
		}, err => {
			window.adminlog.error(err);
		});



		this.locationService.getCurrUserLocationObs().subscribe((currUserLocation) => {

			if(this.dashboardSearchbar){
				this.dashboardSearchbar.setSearchbarQuery("");
			}

		});

	}

	public compileConfiguration() {
		if (this.locationId && this.titleBarOptions) {
			// Set Interface Options
			window.adminlog.print(this.locationId, JSON.stringify(this.titleBarOptions.searchConfigurations));
			this.pageTitle = this.titleBarOptions.title;
            //console.log( this.pageTitle )
			this.showCreateBtn = this.titleBarOptions.showCreateBtn || false;
			this.createBtnUrl = this.titleBarOptions.createBtnUrl || "";
			this.showSearchbar = (this.titleBarOptions.showSearchbar && this.titleBarOptions.searchConfigurations) || false;
			this.searchbarPlaceholder = this.titleBarOptions.searchbarPlaceholder || "";
			this.searchByLocation = (this.titleBarOptions.searchByLocation === undefined) ? true : this.titleBarOptions.searchByLocation;
			this.useAutocomplete = (this.titleBarOptions.useAutocomplete === undefined) ? true : this.titleBarOptions.useAutocomplete;
			this.showDogNameInAutocomplete = (this.titleBarOptions.showDogNameInAutocomplete === undefined) ? true : this.titleBarOptions.showDogNameInAutocomplete;
			this.numAutocompleteItems = this.titleBarOptions.numAutocompleteItems || this.numAutocompleteItems;
            
			if (this.locationId !== 'nolocation' && (typeof this.titleBarOptions.searchByLocation === 'undefined' || this.titleBarOptions.searchByLocation)) {
				if (this.titleBarOptions.searchConfigurations) {
					this.searchConfigurations = this.titleBarOptions.searchConfigurations.map(config => {
						const modconfig = JSON.parse(JSON.stringify(config));

						window.adminlog.print(modconfig.filters);
						if (modconfig.filters) {
							modconfig.filters = (<string>modconfig.filters).concat(` AND locList:${this.locationId}`)
						} else {
							modconfig.filters = `locList:${this.locationId}`;
						}
						window.adminlog.print(modconfig.filters);
						return modconfig;
					});
				}

			} else {
				//
				this.searchConfigurations = [];
			}


			if (this.dashboardSearchbar) {

				this.dashboardSearchbar.setSearchbarQuery("");

				if (this.titleBarOptions.useSearchbarResults && this.titleBarOptions.searchConfigurations.length > 0) {
					this.dashboardSearchbar.publishSearchResultsEvt(this.titleBarOptions.searchConfigurations[0].indexName, this.titleBarOptions.searchConfigurations[0].tag);
				}

			}
			window.adminlog.print("search configuration", this.searchConfigurations);

			this.emitResetEvent();
        }
        window.adminlog.print(  );
	}

	public  onFilterByItemSelected(selectedEntity: {entity: any, indexName: string, tag: string}):void {

		if(selectedEntity && selectedEntity.entity){

			this.ionEventBus.publish("dashboard-searchbar:entity-selected", selectedEntity);

		}else{

			this.emitResetEvent();

		}

	}

	public  onResultsPublished(searchResults: {entities: any[], indexName: string, tag: string}):void {

		this.ionEventBus.publish("dashboard-searchbar:search-results", searchResults);

	}

	public emitResetEvent():void {
		this.ionEventBus.publish("dashboard-searchbar:reset");
	}
	public onUpdatedQuery(qry: string) {
		if (qry === '') {
			//window.adminlog.print("reset");
			this.emitResetEvent();
		}
		this.ionEventBus.publish("dashboard-searchbar:query", qry);
	}

    public openMenu() {
        this.menu.enable(true, 'menu');
        this.menu.open('menu');
    }



}
