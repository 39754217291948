import { Component, OnInit, Input } from '@angular/core';

import { faCaretDown, faCheck } from '@fortawesome/pro-light-svg-icons';
import { ModalController } from '@ionic/angular';



@Component({
  selector: 'app-grouped-appointment-change-status',
  templateUrl: './grouped-appointment-change-status.component.html',
  styleUrls: ['./grouped-appointment-change-status.component.scss'],
})
export class GroupedAppointmentChangeStatusComponent implements OnInit {
    @Input() currentStatus;
    @Input() groupedAppts;
    
    public faCaretDown = faCaretDown;
    public faCheck = faCheck;

    public showOptions : boolean = false;
    public showOnHoldTextBox : boolean = false;
    public status: string = null;

    public onHoldTextarea = null;

    constructor(
        private modalController: ModalController,
    ) { }

    ngOnInit() {
        this.status = this.currentStatus;
        console.log( this.status )
    }

    selectionChange( status ){ 
        this.status = status;
    }

    public toggleStatus() { 
        this.showOnHoldTextBox = false;
        this.showOptions = !this.showOptions;
    } 

    public dismiss() { 
        this.modalController.dismiss({
            "noAction": true,
        });
    }

    public update( ){ 
        console.log( this.status )
        if( this.status === "no action" ){ 
            this.modalController.dismiss({
                "noAction": true,
            });
        }
        if( this.status === "On Hold") { 
            if( this.showOnHoldTextBox ){ 
                this.modalController.dismiss({
                    "noAction": false,
                    "status": this.status,
                    "onHoldreason": this.onHoldTextarea
                });
            }
            else{ 
                this.showOptions = false;
                this.showOnHoldTextBox = true;
            }
        }
        else { 
            this.modalController.dismiss({
                "noAction": false,
                "status": this.status,
                "onHoldreason": this.onHoldTextarea
            });
        }
    }

}
