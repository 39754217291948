import { MeetGreetAppointmentStruct, MeetGreetStatus, MeetGreetResult, LeadSource } from "src/models/structs";

import { Entity } from "src/models/entity";
import firebase from 'firebase';

type Notification = {
  msgId: string,
  sent: boolean
};

// Validates and Creates Admin. Publishes to Firebase a new admin or any update.
export class MeetGreetAppointment extends Entity<MeetGreetAppointment> implements MeetGreetAppointmentStruct {

	static readonly PENDING: MeetGreetStatus	        = "pending";
	static readonly DOING: MeetGreetStatus 		        = "doing";
	static readonly DONE: MeetGreetStatus 		        = "done";
	static readonly NOSHOW: MeetGreetStatus 		    = "noshow";
	static readonly SCHEDULELATER: MeetGreetStatus 		= "schedulelater";

	petId: string[];
	accountId: string;
	userId: string;
	locationId: string;
	date: firebase.firestore.Timestamp;
	preferedTime?: string;
	status: MeetGreetStatus;
	result?: MeetGreetResult;
	message?: string;
	userInfoComplete: boolean;
	petInfoComplete: boolean;
	signInId?: string;
	sourceLeadId?: string;
	requestId?: string;
	dogRequestIds?: string[];
	createdAt: firebase.firestore.Timestamp;
	updatedAt?: firebase.firestore.Timestamp;
	timezone?: string;
	daycareid?: string;
    leadSource? : LeadSource;
  petParentAgreementOverride? : boolean;
  vaccineReleaseFormOverride? : boolean;
  notificationTexts?: { newlyCreated: Notification, twentyFourHours: Notification, threeHours: Notification, missed: Notification }
    renewIn?: number;
	constructor(data?: MeetGreetAppointmentStruct, id?: string) {
		super(data, id);
	}

	public daycareLink(petid: number, server: string): string {
		if ( !this.daycareid || !petid) return '';
		return `${server}daycareScheduleMod.php?petId=${petid}&dsid=${this.daycareid}`;
	}

}
