import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { InquiryStruct, InquiryStatus } from "src/models/structs";

import { Inquiry } from "src/models/entities";

import * as moment from "moment-timezone";

@Injectable({
	providedIn: "root"
})

export class InquiryService extends EntityServiceAbstract<Inquiry> {

	public collection = "inquiry";

	protected statusColorMap:object = {
		"pending": 	"primary",
		"doing":	"secondary",
		"done":		"completed",
	};

	private queryItemsLimit = 100;

	constructor (protected dbService: FirestoreService, private logService: ActivityLogService){
		super();
	}

	public getStatusBadgeText<Inquiry>(entityObj: Inquiry, badgeTextProperty: string):string {
		
		let badgeText = "";

		switch(entityObj[badgeTextProperty]) {

			case Inquiry.DOING:
				badgeText = "in progress";
				break;

			case Inquiry.DONE:
				badgeText = "completed";
				break;

			default:
				badgeText = entityObj[badgeTextProperty];

		}
		
		return badgeText;

	}

	public getById(inquiryId:string):Observable<Inquiry> {

		return this.dbService.getDoc(this.collection, inquiryId).pipe(
			map((inquiryDoc) => {
				
				if(inquiryDoc.exists){
					return new Inquiry(inquiryDoc.data() as InquiryStruct, inquiryDoc.id);
				}

			})
		);

	}

	public update(inquiryId:string, updatedData:any):Promise<void> {

		if(!updatedData.updatedAt){
			
			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, inquiryId, updatedData);

	}

	public updateStatus(inquiryId: string, newStatus: InquiryStatus):Promise<any> {

		const updatedValues = {
			status: newStatus
		};

		let inquiryUpdatePromise = this.update(inquiryId, updatedValues);

		let logEntryPromise = this.logService.addLogEntry(this.collection, inquiryId, "Status Change to " + newStatus, newStatus);

		return Promise.all([inquiryUpdatePromise, logEntryPromise]);

	}

	public getNewInquiries(locationId:string):Observable<Inquiry[]> {
		
		const queryOptions:queryOptions = {
			conditions: [
				["location", "==", locationId],
				["status", "==", Inquiry.PENDING]
			],
			orderByField: "createdAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((inquiryDocs) => {
				return this.mapDocsToObjs(inquiryDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getInProgressInquiries(locationId:string):Observable<Inquiry[]> {
		
		const queryOptions:queryOptions = {
			conditions: [
				["location", "==", locationId],
				["status", "==", Inquiry.DOING],
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((inquiryDocs) => {
				return this.mapDocsToObjs(inquiryDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public getDoneInquiries(locationId:string):Observable<Inquiry[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["location", "==", locationId],
				["status", "==", Inquiry.DONE],
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((inquiryDocs) => {
				return this.mapDocsToObjs(inquiryDocs);
			}),
			catchError((error) => {
				window.adminlog.print("ERROR", error);
				throw(error);
			})
		);

	}

	private mapDocsToObjs(inquiryDocs:firebase.firestore.QueryDocumentSnapshot[]):Inquiry[] {

		return inquiryDocs.map((inquiryDoc) => {
					
			const inquiry = new Inquiry(inquiryDoc.data() as InquiryStruct, inquiryDoc.id);

			return inquiry;

		});

	}

}