

export class ErrorLog {
    id: string;

    firstSeen: any;
    lastSeen: any;
    type: string;
    objectId: string;
    errorCode: ErrorCode;
    resolved: boolean;

    errors: ErrorLogInstance[];

    constructor( data? : ErrorLog , id? :string){ 

        if( id ){ 
            this.id = id;
        }

        if( data ){
            this.firstSeen = data.firstSeen || 0;
            this.lastSeen = data.lastSeen || 0;
            this.type = data.type || "unknown";
            this.objectId = data.objectId || "unknown";
            this.errorCode = data.errorCode || ErrorCode.UNKNOWN;
            this.resolved = data.resolved || false;
            this.errors = data.errors || [];
        }
        else{
            this.firstSeen = Date.now();
            this.lastSeen = Date.now();
            this.type = "unknown";
            this.objectId = "unknown";
            this.errorCode = ErrorCode.UNKNOWN;
            this.resolved = false;
            this.errors = [];
        }

    }
}

export class ErrorLogInstance {
    errorMsg: string;
    date : any;

    constructor( data? : ErrorLogInstance){
        if( data ){
            this.errorMsg = data.errorMsg || "unknown";
            this.date = data.date || 0;
        }
        else{
            this.errorMsg = "unknown";
            this.date = 0;
        }
    }
}

export enum ErrorCode { 
    "ACCOUNT_DEACTIVATED",
    "Account:renewCredentials",
    "UNKNOWN"
}