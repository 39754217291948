import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NavOption } from 'src/app/settings/definers/NavOption';
import { SettingsMobileNavService } from 'src/services/settings-mobile-nav.service';

@Component({
  selector: 'app-mobile-nav-popover',
  templateUrl: './mobile-nav-popover.component.html',
  styleUrls: ['./mobile-nav-popover.component.scss'],
})
export class MobileNavPopoverComponent implements OnInit {
    public navOptions : Array<NavOption> = [];

    public showDropdown: boolean = false;


    constructor(
        private settingsMobileNavService:SettingsMobileNavService,
        private popoverController: PopoverController
    ) { }

    ngOnInit() {
        this.settingsMobileNavService.getNavOptions().subscribe(navOptions => {
            this.navOptions = navOptions;
        });
    }

    public selectNavOption(navOption : NavOption){
        for( let x = 0; x < this.navOptions.length; x++ ) {
            this.navOptions[x].selected = false;
        }
        navOption.selected = true;

        this.settingsMobileNavService.setNavOptions( this.navOptions );

        this.popoverController.dismiss();
    }

    /*
    public onNavOptionSelected(navOption : NavOption){
        this.navOptions.forEach(navOption => {
            navOption.selected = false;
        });
        navOption.selected = true;
        //this.navOptionSelected.emit(this.navOptions);
    }
    */
}
