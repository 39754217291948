import { OnInit } from "@angular/core";

import { EntityServiceAbstract } from "src/services/entity-service-abstract";

import { OverviewColumn, OverviewColumnsList, OverviewListItem } from "src/models/structs";
import { Entity } from '../../models/entity';
import { DataColumn } from '../../services/factories/overview_column.factory';
import { ISectionOverviewAbstractComponent } from '../../services/interfaces';
import firebase from 'firebase';
/*
@Component({
	selector: "app-section-overview-abstract",
	template: "",
})
*/
export abstract class SectionOverviewAbstractComponent<E extends Entity<any>> implements ISectionOverviewAbstractComponent<E>, OnInit {



	public abstract sectionUrlRoot: string;
	public abstract badgeTextProperty: string;

	public  currUserLocationId:string;

	public  overviewColumns: OverviewColumnsList;

	constructor(public entityService: EntityServiceAbstract<E> ) { }

	public ngOnInit():void {}

	public  getOverviewColumnsArr():OverviewColumn[] {
		return Object.values(this.overviewColumns);
	}

	public get dataColumns(): DataColumn<E>[] {
		return Object.values(this.overviewColumns).filter(dC => dC instanceof DataColumn) as DataColumn<E>[];
		//.map(dC => dC as DataColumn<E>);
	}

	public  keepOriginalOrder(a: any, b: any):string {
		return a.key;
	}

	public  getStatusColor(status: string):string {
		return this.entityService.getStatusColor(status);
	}

	// Set up as a pipe function to allow extending classes to override with their own function
	public  getBadgeText(entityObj: E, badgeTextProperty: string):string {
		return this.entityService.getStatusBadgeText(entityObj, badgeTextProperty);
	}

	public  convertToListItemsArr(entitiesArr: E[], badgeTextProperty: string, itemUrlRoot: string):OverviewListItem<E>[] {
        return entitiesArr.map((entityObj) => {
            /*
            let itemUrl;
            if( entitiesArr['isGroup'] ) { 
                itemUrl = "/accounts/"+entityObj["accountId"]+"/pending";
            }
            else { 
                itemUrl = itemUrlRoot;
            }
            */
			return this.convertToListItem(entityObj, badgeTextProperty, itemUrlRoot);

		});

	}

	public convertToListItem(entity: E, badgeTextProperty: string, itemUrlRoot: string): OverviewListItem<E> {
        //console.log( " from section overview ");
        return {
			contextObj: entity,
			itemUrlRoot: itemUrlRoot,
			badgeText: this.getBadgeText(entity, badgeTextProperty),
			badgeColor: this.getStatusColor(entity[badgeTextProperty])
		};
	}

	public sortQuery(fs_query: firebase.firestore.Query, ...sortBy: [string, firebase.firestore.OrderByDirection][]){
		let new_query: firebase.firestore.Query = fs_query;
		sortBy.forEach(sort => {
			new_query.orderBy(sort[0], sort[1]);
		});
		return new_query;
	}


}
