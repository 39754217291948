import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFullSortName'
})
export class GetFullSortNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    if( value === "asc" ){ 
        return "Ascending";
    }
    else if( value === "desc" ){ 
        return "Descending";
    }
    else if( value === "meetAndGreetCreationDate_asc"){ 
        return "Meet & Greet Creation Date Ascending"
    }
    else if( value === "meetAndGreetCreationDate_desc" ){ 
        return "Meet & Greet Creation Date Descending";
    }
    else if( value === "meetAndGreetApptDate_acs"){ 
        return "Meet & Greet Appointment Date Ascending";
    }
    else if( value === "meetAndGreetApptDate_desc"){ 
        return "Meet & Greet Appt Date Descending";
    }
    else{ 
        return "Default"
    }

  }

}
