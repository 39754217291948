import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";

const appRoutes: Routes = [
	{
		path: "forgot-password", 
		component: ForgotPasswordComponent, 
	},
	{
		path: "login", 
		component: LoginComponent, 
	},
	{
		path: "", 
		loadChildren: () => import("./dashboard/dashboard.module").then((mod) => mod.DashboardModule),
	},
	{ path: "**", redirectTo: "" }
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})

export class AppRoutingModule { }
