import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map, catchError, take } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { FirestoreService, queryOptions } from "./firestore.service";

import { AuthService } from "./auth.service";

import { Notification } from "src/models/entities";

import { NotificationStruct } from "src/models/structs";

@Injectable({
	providedIn: "root"
})

export class NotificationService {

	public collection = "notifications";

	private queryItemsLimit = 100;

	constructor (private dbService: FirestoreService, private authService: AuthService) { }

	public getById(notificationId:string):Observable<Notification> {

		if(!notificationId){
			return of(null);
		}

		return this.dbService.getDoc(this.collection, notificationId).pipe(
			map((notificationDoc) => {
				
				if(notificationDoc.exists){
					return new Notification(notificationDoc.data() as NotificationStruct, notificationDoc.id);
				}

			})
		);

	}

	public getLocationNotifications(locationId:string):Observable<Notification[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["location", "==", locationId]
			],
			orderByField: "firstName",
			limit: this.queryItemsLimit,
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((notificationDocs) => {
				return notificationDocs.map((notificationDoc) => {
					
					return new Notification(notificationDoc.data() as NotificationStruct, notificationDoc.id);

				});
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public deleteNotification(notificationId: string):Promise<void> {

		return this.dbService.deleteDoc(this.collection, notificationId);

	}

	public createNotification(notificationData: NotificationStruct):Promise<string> {

		notificationData.createdBy = this.authService.user._id;
		
		return this.dbService.insertDoc(this.collection, notificationData).then((notificationDoc) => {
			return notificationDoc.id;
		});

	}
	
	public updateNotification(notificationId: string, updatedData: any):Promise<void> {

		return this.dbService.updateDoc(this.collection, notificationId, updatedData);

	}

	public updateNotificationStatus(notificationId: string, status: boolean):Promise<void> {

		return this.dbService.updateDoc(this.collection, notificationId, {status: status});

	}
	
}