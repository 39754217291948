import { Injectable } from "@angular/core";

import { OrderByDirection, WhereFilterOp, DocumentData } from "@firebase/firestore-types";

import { Observable } from "rxjs";
import firebase from 'firebase';

@Injectable({
	providedIn: "root"
})

export class FirestoreService {

	public db:firebase.firestore.Firestore;

	constructor(){
		this.db = firebase.firestore();

  	}

	/**
	 * Query object transform to firestore:Query
	 * @param collection
	 * @param options
	 */
	public getQuery(collection: string, options: queryOptions): firebase.firestore.Query {
		return this.getCollectionQuery(collection, options) as firebase.firestore.Query;
	}


	public getCollectionQuery(collection:string, options:queryOptions = {}):firebase.firestore.CollectionReference | firebase.firestore.Query {

		const optionDefaults:queryOptions = {
			conditions: [],
			orderByField: "",
			orderByDir: "asc",
			limit: 0,
            startAt: null
		};
		// window.adminlog.print("options", options);
		options = Object.assign(optionDefaults, options);

		let collectionRef = this.db.collection(collection);

		let result:firebase.firestore.CollectionReference | firebase.firestore.Query = null;

		if(options.conditions.length > 0){

			let conditions = options.conditions;

			let query = collectionRef.where(...conditions[0]);

			// window.adminlog.print("condition", conditions[0], 'i', 0);
			// If there is more than one condition, add them to the query object
			if(conditions.length > 1){
				conditions.forEach((condition, i) => {
					if(i > 0){
						// window.adminlog.print("condition", condition, 'i', i);
						query = query.where(...condition);
					}
				});
			}

			result = query;

		}else{

			result = collectionRef;

		}

		if(options.orderByField !== ""){
			

            if(options.startAt !== null){
                result = result.orderBy(options.orderByField, options.orderByDir).startAt( firebase.firestore.Timestamp.fromDate( options.startAt ) );
            }else{
                result = result.orderBy(options.orderByField, options.orderByDir);
            }
		}

       

		if(options.limit > 0){
			result = result.limit(options.limit);
		}
		// window.adminlog.print("limit", options.limit, "options conditions", options.conditions);
		return result;

	}

	public setSubCollectionDocument(path: [string, string][], updatedData: any): Promise<void> {

		let ref: firebase.firestore.DocumentReference;

		path.forEach(reference => {
			ref = ref? ref.collection(reference[0]).doc(reference[1]): this.db.collection(reference[0]).doc(reference[1]);
		});

		return ref.set(updatedData);

	}
	public updateSubCollectionDocument(path: [string, string][], updatedData: any): Promise<void> {

		let ref: firebase.firestore.DocumentReference;

		path.forEach(reference => {
			ref = ref ? ref.collection(reference[0]).doc(reference[1]) : this.db.collection(reference[0]).doc(reference[1]);
		});

		return ref.update(updatedData);

	}


	public getSubCollectionDocument(path: [string, string][]): Promise<firebase.firestore.DocumentSnapshot> {

		let ref: firebase.firestore.DocumentReference;

		path.forEach(reference => {
			ref = ref ? ref.collection(reference[0]).doc(reference[1]) : this.db.collection(reference[0]).doc(reference[1]);
		});

		return ref.get();

	}

	public getDoc(collection:string, docId:string):Observable<firebase.firestore.DocumentSnapshot> {

		return new Observable((subscriber) => {

			this.db.collection(collection).doc(docId).onSnapshot(
				(documentSnapshot) => {
					subscriber.next(documentSnapshot);
				},
				(error) => {
					window.adminlog.error("Get Document error: ", error);
				}
			);

		});

	}

	public getDocSnapshot(collection:string, docId:string):Promise<firebase.firestore.DocumentSnapshot> {

		return this.db.collection(collection).doc(docId).get();

	}

	public insertDoc(collection:string, docData:DocumentData):Promise<firebase.firestore.DocumentReference> {

		return this.db.collection(collection).add(docData)

	}

	public setDoc(collection:string, docId:string, docData:DocumentData, merge:boolean = false):Promise<void> {

		return this.db.collection(collection).doc(docId).set(docData, { merge: merge });

	}

	public updateDoc(collection:string, docId:string, updateData:firebase.firestore.UpdateData):Promise<void> {

		return this.db.collection(collection).doc(docId).update(updateData);

	}

	public deleteDoc(collection:string, docId:string):Promise<void> {

		return this.db.collection(collection).doc(docId).delete();

	}

	public getCollection(collection: string, options?: queryOptions):Observable<firebase.firestore.QueryDocumentSnapshot[]> {

		return new Observable((subscriber) => {

			this.getCollectionQuery(collection, options).onSnapshot(
				(querySnapshot) => {
					subscriber.next(querySnapshot.docs);
				},
				(error) => {
					window.adminlog.error("Get Collection error: ", error)
				}
			);

		});
	}

	public getCollectionOnce(collection: string, options?: queryOptions):Promise<firebase.firestore.QueryDocumentSnapshot[]> {

		return this.getCollectionQuery(collection, options).get().then((querySnapshot) => {
			return querySnapshot.docs;
		});

    }
    
}

export type queryOptions = {
	conditions?: Array<[string, WhereFilterOp, any]>,
	orderByField?: string,
	orderByDir?: OrderByDirection,
	limit?: number,
    startAt? : Date | null 
};
