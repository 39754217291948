import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, zip, combineLatest } from 'rxjs';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Transaction, outbox } from '../../../../models/structs/notification.structs';
import { take, takeUntil } from 'rxjs/operators';
import { IMessagePreviewContext } from './entity-message-action-context.component';
import firebase from 'firebase';
import { IDatum } from '../../../../services/interfaces';
import { ActivityLogService, AlertService, AppointmentService, FirestoreService, LocationService } from '../../../../services';
import { PetexecService } from '../../../../models/structs/location';
import { PetExecService } from 'src/services/pet-exec.service';
import moment from 'moment-timezone';
import { faTimes, faCheckCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import { ConsoleService } from '@ng-select/ng-select/ng-select/console.service';
import { AlertController, PopoverController } from '@ionic/angular';
import { InfoPopoverComponent } from './info-popover/info-popover.component';
import { Location as RouteLocation } from '@angular/common';
import { AppointmentAction, Entity_Details_ErrorMessages, IAppointmentActionConfig } from '../model/entity-detail.model';


@Component({
    selector: 'app-entity-message-action',
    templateUrl: './entity-message-action.component.html',
    styleUrls: ['./entity-message-action.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EntityMessageActionComponent {
    public faTimes = faTimes;
    public faCheckCircle = faCheckCircle;
    public faMinusCircle = faMinusCircle;

    @Input() public appointmentGroups: any;
    @Input() public actionsList: IAppointmentActionConfig[] = [];
    @Input() public account: any;
    @Output("onConfirm") onConfirm: EventEmitter<Error | boolean> = new EventEmitter();
    @Output("onMessageSent") onMessageSent: EventEmitter<Error | any> = new EventEmitter();
    @Output("onAppointmentsBooked") onAppointmentsBooked: EventEmitter<Error | any> = new EventEmitter();

    private unsubscriber: Subject<void> = new Subject<void>();

    private actionsListCache = [];
    public actionableList = [];
    public actionableAppointmentGroups = [];
    public showableAppointments: any[];
    public emailMesageText = '';
    public lockAppointments: boolean = false;
    public showEmailEdit: boolean = false;
    public appointmentActions: typeof AppointmentAction = AppointmentAction;
    public entityMessages: typeof Entity_Details_ErrorMessages = Entity_Details_ErrorMessages;

    private daycareServices: any[];

    private _context: IMessagePreviewContext;
    @Input("previewContext") public set context(val: IMessagePreviewContext) {
        if (val != this._context) {
            this._context = val;
            this.onInit();
        }
    }
    public get context() {
        return this._context;
    }
    @Input("disableForm") public disableForm: boolean = false;
    @Input("showConfirm") public showConfirm: boolean = true;

    private transaction: Transaction<string>;
    protected get isTemplated() {
        return this.transaction.options.to == outbox.customer || this.transaction.options.email.templateMode;
    }

    public get messageService() {
        return this.context.messageService;
    }

    public daycare_services$: IDatum<PetexecService[]>;

    constructor(
        protected routeLocation: RouteLocation,
        private alertService: AlertService,
        private alertController: AlertController,
        public locationService: LocationService,
        public appointmentService: AppointmentService,
        public petExecService: PetExecService,
        public popoverController: PopoverController,
        public firestore: FirestoreService,
        private logService: ActivityLogService,
    ) {

    }


    public ionViewWillLeave() {
        this.onDestroy();
    }

    ngOnChanges() {

        // check if list is locked or not.
        // send up notification to lock changes when not in use
        if (!this.lockAppointments) {

            if (JSON.stringify(this.actionsListCache) !== JSON.stringify(this.actionsList)) {
                this.actionsListCache = this.actionsList;
                console.log("actionList", this.actionsList);
                const filterList = this.actionsList.filter(action => (action.status == 'Approved' || action.status == 'Declined' || action.status == 'Waitlist' || action.status == 'On Hold'));
                this.actionableList = JSON.parse(JSON.stringify(filterList));
                this.actionableAppointmentGroups = this.appointmentGroups;


            }

        }

    }



    getServiceName(pet_appointment) {
        return this.daycare_services$.data.find((service) => service.serviceid == pet_appointment.serviceid).servicename;
    }


    protected onInit() {
        this.context.onNewTemplate$.asObservable().subscribe((transaction) => {
            this.transaction = transaction;
        });

        this.locationService.daycareServiceDatum.getNewData().then( services => {
            this.daycareServices = services;
            console.log( "services_from_getNewData()", services );
        });
    }


    @Input("messageSource") public messageSource = '';
    public sendMessage(batch?: firebase.firestore.WriteBatch) {
        const transaction = this.applyContent();
        return this.messageService.queueMessage(transaction, { source: this.messageSource || "appointments", batch: batch }).then(res => {
            console.log("** sent message", res);
            this.onMessageSent.emit(res);
        }).catch((err) => {
            console.log("** sent message errr", err);
            this.onMessageSent.emit(err);
        });
    }

    //#region Helpers

    public sanitizeMessage(message: string, bp: boolean) {
        //return wpautop(message, bp);
        return wpautop(message.replace(/\n/g, '<br>'));
    }

    protected applyContent(): Transaction<string> {

        const content = this.sanitizeMessage(this.emailMesageText, false);
        const transaction = JSON.parse(JSON.stringify(this.transaction)) as Transaction<string>;
        if (this.emailMesageText) {
            if (this.isTemplated) {
                const paras = content.split('\n');
                transaction.options.email.templateData = { paras: paras };
            } else {
                transaction.options.email.content = content;
            }
        }
        transaction.options.email.subject = 'Appointments';

        console.log("**transaction", transaction);
        return transaction;
    }
    //#endregion

    public onDestroy() {
        this.unsubscriber.next();
    }

    public bookAppointments() {
        console.log('bookAppointments, Lock actions');
        this.lockAppointments = true;
        // Change appointments on main view
        //this.actionableAppointmentGroups[0].appointments[0].error = true;
        this.onConfirm.emit(true);
        this.runAppointmentBooking();

    }
    // check if it has PE error
    public hasError(appt) {
        return (appt && appt.appointmentUploaded && !appt.success);
    }
    // check if booked in PE
    isInPetexec(appt) {
        const data = this.actionableAppointmentGroups[appt.petindex].appointments[appt.appointmentindex].data;
        return (data.petexec && data.petexec.locked);
    }
    // check if recently booked
    public isBooked(appt) {
        return (appt && appt.appointmentUploaded && appt.success);
    }

    public selectForce(index) {
        //console.log( index);
        //this.actionableList[ index ].force = true;
        console.log(this.actionableList[index]);
    }

    async presentPopover(ev: any, data: string) {
        console.log(ev)
        const popover = await this.popoverController.create({
            component: InfoPopoverComponent,
            cssClass: 'my-custom-class',
            event: ev,
            backdropDismiss: true,
            mode: 'md',
            componentProps: { data }
        });
        await popover.present();

        const { role } = await popover.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
    }

    setAppointmentStatus(petindex, appointmentindex, status, success, onHoldReason = '') {
        console.log('setAppointmentStatus', petindex, appointmentindex, success);
        if (status === 'Approved') {
            if (success) {
                this.actionableAppointmentGroups[petindex].appointments[appointmentindex]
                    .data.petexec = {
                    tempstatus: 'Approved',
                    locked: true,
                    emailsent: false,
                    bypass_trigger_notifications: true
                };
            } else {
                this.actionableAppointmentGroups[petindex].appointments[appointmentindex]
                    .data.petexec = {
                    tempstatus: 'Approved',
                    locked: false,
                    emailsent: false,
                    bypass_trigger_notifications: true,
                    error: true,
                    block: true // set to block so we dont try it again Or not? ask shelley about it.
                };
            }

        } else if (status === 'Declined') {
            this.actionableAppointmentGroups[petindex].appointments[appointmentindex]
                .data.petexec = {
                tempstatus: "Declined",
                locked: false,
                emailsent: false,
                bypass_trigger_notifications: true,
            };
        } else if (status === "Waitlist" || status === "On Hold") {


            this.actionableAppointmentGroups[petindex].appointments[appointmentindex]
                .data.petexec = (status === "On Hold") ? {
                    tempstatus: status,
                    locked: false,
                    emailsent: false,
                    bypass_trigger_notifications: true,
                    onHoldReason: onHoldReason
                } : {
                        tempstatus: status,
                        locked: false,
                        emailsent: false,
                        bypass_trigger_notifications: true
                    };

        }

        // send info upstream
        this.onAppointmentsBooked.emit(this.actionableAppointmentGroups);
        // return updated data
        return this.actionableAppointmentGroups[petindex].appointments[appointmentindex].data;

    }
    canBook(aptData) {
        const canBook = !(aptData.petexec && (aptData.petexec.block || aptData.petexec.locked));
        console.log('canBook?', canBook, aptData);
        return canBook;
    }

    getCurrentStep() {
        // Check if the whole list is done.
        if (this.showEmailEdit) {
            return 3;
        } else if (this.actionableList.length > 0) {
            if (this.actionableList.length === this.actionableList.filter(action => (action.done)).length) {
                return 2;
            } else {
                return 1;
            }
        } else {
            return 0;
        }

    }

    showEditEmail() {
        // Update email content
        this.updateEmailContent();
        // show email edit
        this.showEmailEdit = true;
    }

    noEmailComplete() {
        console.log('No email Complete');
        this.completeActionList(false);
    }
    sendEmailComplete() {
        console.log('SendEmail Complete');
        this.completeActionList(true);
    }
    completeActionList(sendEmail) {
        let batch = this.firestore.db.batch();
        this.actionableList.forEach(action => {
            const tempInfo = this.actionableAppointmentGroups[action.petindex].appointments[action.appointmentindex].data;
            const docRef = this.firestore.db.collection('appointments').doc(action.appointmentId);
            if (tempInfo.petexec && tempInfo.petexec.tempstatus) {
                if (tempInfo.petexec.tempstatus === "Waitlist") {
                    batch.update(docRef, { status: tempInfo.petexec.tempstatus });
                } else if (tempInfo.petexec.tempstatus === "On Hold") {

                    batch.update(docRef, { status: tempInfo.petexec.tempstatus, onHoldReason: (tempInfo.petexec.onHoldReason) ? tempInfo.petexec.onHoldReason : '' });

                } else if (tempInfo.petexec.error && !tempInfo.petexec.daycareid) {
                    if (action.force) {
                        batch.update(docRef, { status: tempInfo.petexec.tempstatus, completed: true })
                    }
                } else {
                    batch.update(docRef, { status: tempInfo.petexec.tempstatus, completed: true })
                }
            }
            this.logService.addLogEntry("appointments", action.appointmentId, action.status, "");
        });

        batch.commit().then(() => {


            console.log('Appointments commit done...');
            if (sendEmail) {
                console.log('send email');
                this.sendMessage();
            }

            this.alertService.showSuccess("Appointments updated", "success");

        })
            .catch((e) => this.alertService.showError("Error updating appointments.", e.message))
            .finally(() => {
                console.log('Appointments completed finalize, Go back to list');
                this.routeLocation.back();
            });
    }

    /**
     * Booking into PE logic -- grabbed from modal
     */
    async runAppointmentBooking() {

        await this.locationService.getCurrUserLocation().then(async location => {
            const actionableApp = this.actionableList;

            for (let index = 0; index < actionableApp.length; index++) {
                console.log('**** Run Appointment .. ', index);
                await this.runAppointmentIndex(index, location);
                console.log('**** complete Run Appointment .. ', index);
            }

        });
    }
    // TODO
    async runAppointmentIndex(x, location) {

        return new Promise((resolve, reject) => {

            const actionableApp = this.actionableList;
            this.actionableList[x].done = this.isInPetexec(this.actionableList[x]);
            this.actionableList[x].loading = !this.actionableList[x].done;
            this.appointmentService.update(actionableApp[x].appointmentId, { 'petexec.serviceid': actionableApp[x].serviceid }).then(data => {
                // grab appointment data from actionable group instead of query.
                const aptData = this.actionableAppointmentGroups[actionableApp[x].petindex].appointments[actionableApp[x].appointmentindex].data;
                // console.log( 'aptData', aptData );
                // load workable data struct
                const appointmentData = {
                    appointmentId: actionableApp[x].appointmentId,
                    date: moment(aptData.date.toDate()).format('ddd D/MM/YYYY, h:mm A'),
                    dogName: aptData.dogName,
                    appointmentUploaded: false,
                    success: this.actionableList[x].done, // set to success true if its in PE and done already @todo check Business rules.
                    status: actionableApp[x].status,
                    appointment: aptData // load appointmen in showable appointments to use later
                };
                // console.log( "apptData" ,appointmentData );
                this.actionableList[x].d = appointmentData;

                console.log("actionanble list", appointmentData.appointment.date.toDate());
                console.log("actionanble list", appointmentData.appointment.pickupDate.toDate());

                if (actionableApp[x].status == "Approved" && this.canBook(aptData)) {

                    console.log('Trying to book:', appointmentData, location);
                    this.bookAppointment(appointmentData, location).then(appointment => {
                        console.log('appontment booking response:', appointment);
                        this.actionableList[x].d = appointment;

                        if (appointment.success) {
                            console.log("appointment == success");
                            const data = { 'petexec.tempstatus': "Approved", 'petexec.locked': true, 'petexec.emailsent': false, 'behaviors.bypass_trigger_notifications': true };
                            this.appointmentService.update(
                                appointment.appointmentId,
                                data
                            ).then(apptUpdateData => {
                                console.log("before log entry created");
                                console.log("appointments/" + actionableApp[x].appointmentId);
                                //this.logService.addLogEntry("appointments", actionableApp[x].appointmentId, actionableApp[x].status, "" );

                                // resolve true
                                resolve(true);

                            });

                            // update loaded appointments
                            this.actionableList[x].d.appointment = this.setAppointmentStatus(actionableApp[x].petindex, actionableApp[x].appointmentindex, appointment.status, true);



                        } else {
                            // PE Error update status, dont lock it incase they want to decline it
                            const data = {
                                'petexec.tempstatus': "Approved", 'petexec.error': true, 'petexec.errorMsg': appointment.petexecResponseMsg,
                                'petexec.locked': false, 'petexec.emailsent': false, 'behaviors.bypass_trigger_notifications': true
                            };
                            this.appointmentService.update(
                                appointment.appointmentId,
                                data
                            )
                            // update loaded appointments
                            this.actionableList[x].d.appointment = this.setAppointmentStatus(actionableApp[x].petindex, actionableApp[x].appointmentindex, 'Approved', false);

                            // resolve false @todo should we reject?
                            resolve(false);
                        }
                        // disable loading
                        this.actionableList[x].loading = false;
                        //keep track of what is done
                        this.actionableList[x].done = true;

                    });

                }
                else if (actionableApp[x].status === "Declined") {
                    this.appointmentService.update(actionableApp[x].appointmentId,
                        {
                            'petexec.tempstatus': "Declined",
                            'behaviors.bypass_trigger_notifications': true,
                            'petexec.emailsent': false,
                            'petexec.locked': false
                        }
                    ).then(data => {
                        appointmentData.appointmentUploaded = true;
                        appointmentData.success = true;
                        this.actionableList[x].d = appointmentData;
                        // update loaded appointments
                        this.actionableList[x].d.appointment = this.setAppointmentStatus(actionableApp[x].petindex, actionableApp[x].appointmentindex, 'Declined', true);
                        // disable loading
                        this.actionableList[x].loading = false;
                        //keep track of what is done
                        this.actionableList[x].done = true;
                        //this.logService.addLogEntry("appointments", actionableApp[x].appointmentId, actionableApp[x].status, "" );

                        // resolve true
                        resolve(true);

                    })
                }
                else if (actionableApp[x].status === "Waitlist" || actionableApp[x].status === "On Hold") {

                    const aptUpdate = (actionableApp[x].status === "On Hold") ? {
                        'petexec.tempstatus': actionableApp[x].status,
                        'petexec.onHoldReason': (actionableApp[x].onHoldReason) ? actionableApp[x].onHoldReason : '',
                        'behaviors.bypass_trigger_notifications': true,
                        'petexec.emailsent': false,
                        'petexec.locked': false
                    } : {
                            'petexec.tempstatus': actionableApp[x].status,
                            'behaviors.bypass_trigger_notifications': true,
                            'petexec.emailsent': false,
                            'petexec.locked': false
                        };

                    this.appointmentService.update(actionableApp[x].appointmentId, aptUpdate).then(
                        data => {
                            appointmentData.appointmentUploaded = true;
                            appointmentData.success = true;
                            this.actionableList[x].d = appointmentData;
                            // update loaded appointments
                            this.actionableList[x].d.appointment = (actionableApp[x].status === "On Hold") ? this.setAppointmentStatus(actionableApp[x].petindex, actionableApp[x].appointmentindex, actionableApp[x].status, true, actionableApp[x].onHoldReason) : this.setAppointmentStatus(actionableApp[x].petindex, actionableApp[x].appointmentindex, actionableApp[x].status, true);
                            // disable loading
                            this.actionableList[x].loading = false;
                            //keep track of what is done
                            this.actionableList[x].done = true;
                            //this.logService.addLogEntry("appointments", actionableApp[x].appointmentId, actionableApp[x].status, "" );

                            // resolve true
                            resolve(true);

                        })

                    /*
                    console.log( "actionableApp", actionableApp );
                    let firebaseObj = {};
                    firebaseObj["status"] = actionableApp[x].status;
                    console.log( "appt groups",this.actionableAppointmentGroups );
                    if(actionableApp[x].status === "On Hold"){ 
                        firebaseObj["onHoldReason"] = actionableApp[x].onHoldReason;   
                    }
                    this.appointmentService.update( actionableApp[x].appointmentId, firebaseObj )
                    // disable loading
                    
                    this.actionableList[ x ].loading = false;
                    //keep track of what is done
                    this.actionableList[ x ].done = true;

                    resolve(true);
                    */
                }
                else {
                    // catch all just incase.
                    // disable loading
                    this.actionableList[x].loading = false;
                    //keep track of what is done
                    this.actionableList[x].done = true;

                    // resolve true
                    resolve(true);

                }

            });

        });

    }

    async bookAppointment(appointment, location) {

        return await this.petExecService.createPetExecAppointment(
            location._id, appointment.appointmentId
        ).then(petExecResponse => {

            console.log("pet exec api response ");

            if (petExecResponse["status"] == "success") {
                appointment.success = true;
                appointment.petexecResponseMsg = petExecResponse["msg"];

            }
            else if (petExecResponse["status"] == "failed") {
                appointment.success = false;

                if (petExecResponse["from"] == "petexec") {
                    let msg = "Petexec error: ";

                    if (this.isJson(petExecResponse["msg"])) {

                        console.log("msg", JSON.parse(petExecResponse["msg"]));
                        let petexecMsg = JSON.parse(petExecResponse["msg"]);
                        for (let x = 0; x < petexecMsg["errors"].length; x++) {
                            msg += petexecMsg["errors"][x] + "\n";
                        }
                        appointment.petexecResponseMsg = msg;

                    } else {
                        appointment.petexecResponseMsg = msg + petExecResponse["msg"];
                    }

                }
                else if (petExecResponse["from"] = "local") {
                    appointment.petexecResponseMsg = petExecResponse["msg"];
                }

            }
            appointment.appointmentUploaded = true;
            // log appointment data past manipulation and return
            console.log(appointment);
            return appointment;
        }).catch(e => {

            console.log("There was an error completing the petExecService.createPetExecAppointment Api call", e);
            return appointment;
        });

    }

    public getAppointmentDateText(appointment) {
        console.log(appointment)
        const at = moment(appointment.date.toDate()).tz(appointment.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
        let str = at.format('ddd MM/DD/YYYY, h:mm A').toUpperCase();
        if (appointment.pickupDate) {
            const pickupdate = moment(appointment.pickupDate.toDate()).tz(appointment.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
            if (at.format('YYYY/MM/DD') == pickupdate.format('YYYY/MM/DD')) {
                str += ` to ${pickupdate.format('h:mm A')}`;

            } else {
                str += ` to ${pickupdate.format('ddd MM/DD/YYYY, h:mm A').toUpperCase()}`;
            }
        }
        return str;
    }

    updateEmailContent() {
        this.locationService.getCurrUserLocationObs().subscribe( locationdata => {  
            
            const paras = [];
            const actions = this.sortByDate(this.actionableList);
            const actions_approved = actions.filter(action => (action.status == 'Approved' && action.d && action.d.success));
            const actions_declined = actions.filter(action => (action.status == 'Declined' && action.d && action.d.success));
            const actions_onhold = actions.filter(action => (action.status == 'On Hold' && action.d && action.d.success));
            const actions_waitlist = actions.filter(action => (action.status == 'Waitlist' && action.d && action.d.success));
            //const actions_approved = actions.filter(action => ( action.status == 'Approved' ) );
            //const actions_declined = actions.filter(action => ( action.status == 'Declined' ) );
            // @todo check if there is a custom email 
    
    
            console.log( "actions__", actions );

    
            if (actions_approved.length || actions_declined.length || actions_onhold.length || actions_waitlist.length) {
                paras.push(`Hi ${this.account.data.firstName}`+',');
    
                if (actions_approved.length) {                    
                    paras.push('You are confirmed for the following daycare appointments:');
                    paras.push(...this.prepareEmailString(actions_approved, actions))
                    paras.push('\n')
                }
    
                if (actions_declined.length) {
                    paras.push('You are declined for the following appointments:');
                    paras.push(...this.prepareEmailString(actions_declined, actions))
                    paras.push('\n')
                }
                if (actions_waitlist.length) {
                    paras.push('You are waitlisted for the following appointments:');
                    paras.push(...this.prepareEmailString(actions_waitlist, actions))
                    paras.push('\n')
                }
    
                if (actions_onhold.length) {
                    paras.push('You have been put on hold for the following appointments:');
                    paras.push(...this.prepareEmailString(actions_onhold, actions))
                    paras.push('\n')
                }
                
                paras.push(`Dogtopia of ${locationdata.name}`)
    
            }
            const email_content = paras.join('\n\r');
    
            this.emailMesageText = email_content;
        });
        
    }

    protected sortAppointmentIntoPetBucket(appointment, petIds: string[], appointments: { [petindex: number]: string[] }) {
        const petindex = petIds.findIndex((id) => id == appointment.petId);
        // sort into bundles by petId
        if (petindex >= 0) {
            appointments[petindex].push(appointment);
        } else {

            petIds.push(appointment.petId);
            appointments[petIds.length - 1] = [appointment];

        }
    }

    private isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    private prepareEmailString(actionsItems, actions) {
        const paras = [];
        const pets: string[] = [];
        const sorted_appointments: { [petindex: number]: any[] } = {};
        actionsItems.forEach(action => {
            const appointment = action.d.appointment;
            this.sortAppointmentIntoPetBucket(appointment, pets, sorted_appointments);
        });

        for (let petindex in pets) {
            const appointments = sorted_appointments[petindex];
            paras.push(`Dog name: ${appointments[0].dogName}`);
            appointments.forEach(appt => {
                let dateString = this.getAppointmentDateText(appt);
                const index = actions.find(m => m.appointmentId === appt._id);
                if (index) {
                    console.log( "INDEX", index );
                    let serviceName = this.daycareServices.filter( daycareService => daycareService.serviceid == index.serviceid );
                    dateString += ',  ' + serviceName[0].servicename;
                }

                if (appt.onHoldReason) {
                    dateString += "\n" + `Reason: ${appt.onHoldReason}`
                    //paras.push(`Reason: ${appt.onHoldReason}`);
                }
                paras.push(dateString);

            });
        }
        return paras;
    }

    public sortByDate(appointments: any[]) {
        if (appointments.length > 1) {
            return appointments.sort((d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime());
        }
        return appointments;
    }


}



// source: https://github.com/andymantell/node-wpautop
function _autop_newline_preservation_helper(matches) {
    return matches[0].replace("\n", "<WPPreserveNewline />");
}

function wpautop(pee, br?) {
    if (typeof (br) === 'undefined') {
        br = true;
    }

    var pre_tags = {};
    if (pee.trim() === '') {
        return '';
    }

    pee = pee + "\n"; // just to make things a little easier, pad the end
    if (pee.indexOf('<pre') > -1) {
        var pee_parts = pee.split('</pre>');
        var last_pee = pee_parts.pop();
        pee = '';
        pee_parts.forEach(function (pee_part, index) {
            var start = pee_part.indexOf('<pre');

            // Malformed html?
            if (start === -1) {
                pee += pee_part;
                return;
            }

            var name = "<pre wp-pre-tag-" + index + "></pre>";
            pre_tags[name] = pee_part.substr(start) + '</pre>';
            pee += pee_part.substr(0, start) + name;

        });

        pee += last_pee;
    }

    pee = pee.replace(/<br \/>\s*<br \/>/, "\n\n");

    // Space things out a little
    var allblocks = '(?:table|thead|tfoot|caption|col|colgroup|tbody|tr|td|th|div|dl|dd|dt|ul|ol|li|pre|form|map|area|blockquote|address|math|style|p|h[1-6]|hr|fieldset|legend|section|article|aside|hgroup|header|footer|nav|figure|figcaption|details|menu|summary)';
    pee = pee.replace(new RegExp('(<' + allblocks + '[^>]*>)', 'gmi'), "\n$1");
    pee = pee.replace(new RegExp('(</' + allblocks + '>)', 'gmi'), "$1\n\n");
    pee = pee.replace(/\r\n|\r/, "\n"); // cross-platform newlines

    if (pee.indexOf('<option') > -1) {
        // no P/BR around option
        pee = pee.replace(/\s*<option'/gmi, '<option');
        pee = pee.replace(/<\/option>\s*/gmi, '</option>');
    }

    if (pee.indexOf('</object>') > -1) {
        // no P/BR around param and embed
        pee = pee.replace(/(<object[^>]*>)\s*/gmi, '$1');
        pee = pee.replace(/\s*<\/object>/gmi, '</object>');
        pee = pee.replace(/\s*(<\/?(?:param|embed)[^>]*>)\s*/gmi, '$1');
    }

    if (pee.indexOf('<source') > -1 || pee.indexOf('<track') > -1) {
        // no P/BR around source and track
        pee = pee.replace(/([<\[](?:audio|video)[^>\]]*[>\]])\s*/gmi, '$1');
        pee = pee.replace(/\s*([<\[]\/(?:audio|video)[>\]])/gmi, '$1');
        pee = pee.replace(/\s*(<(?:source|track)[^>]*>)\s*/gmi, '$1');
    }

    //pee = pee.replace(/\n\n+/gmi, "\n\n"); // take care of duplicates

    // make paragraphs, including one at the end
    var pees = pee.split(/\n\s*\n/);
    pee = '';
    pees.forEach(function (tinkle) {
        pee += '<p>' + tinkle.replace(/^\s+|\s+$/g, '') + "</p>\n";
    });

    pee = pee.replace(/<p>\s*<\/p>/gmi, ''); // under certain strange conditions it could create a P of entirely whitespace
    pee = pee.replace(/<p>([^<]+)<\/(div|address|form)>/gmi, "<p>$1</p></$2>");
    pee = pee.replace(new RegExp('<p>\s*(</?' + allblocks + '[^>]*>)\s*</p>', 'gmi'), "$1", pee); // don't pee all over a tag
    pee = pee.replace(/<p>(<li.+?)<\/p>/gmi, "$1"); // problem with nested lists
    pee = pee.replace(/<p><blockquote([^>]*)>/gmi, "<blockquote$1><p>");
    pee = pee.replace(/<\/blockquote><\/p>/gmi, '</p></blockquote>');
    pee = pee.replace(new RegExp('<p>\s*(</?' + allblocks + '[^>]*>)', 'gmi'), "$1");
    pee = pee.replace(new RegExp('(</?' + allblocks + '[^>]*>)\s*</p>', 'gmi'), "$1");

    if (br) {
        pee = pee.replace(/<(script|style)(?:.|\n)*?<\/\\1>/gmi, _autop_newline_preservation_helper); // /s modifier from php PCRE regexp replaced with (?:.|\n)
        pee = pee.replace(/(<br \/>)?\s*\n/gmi, "<br />\n"); // optionally make line breaks
        pee = pee.replace('<WPPreserveNewline />', "\n");
    }

    pee = pee.replace(new RegExp('(</?' + allblocks + '[^>]*>)\s*<br />', 'gmi'), "$1");
    pee = pee.replace(/<br \/>(\s*<\/?(?:p|li|div|dl|dd|dt|th|pre|td|ul|ol)[^>]*>)/gmi, '$1');
    pee = pee.replace(/\n<\/p>$/gmi, '</p>');

    if (Object.keys(pre_tags).length) {
        pee = pee.replace(new RegExp(Object.keys(pre_tags).join('|'), "gi"), function (matched) {
            return pre_tags[matched];
        });
    }

    return pee;
}
