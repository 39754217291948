import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { FirestoreService, queryOptions } from "./firestore.service";

import firebase from "firebase/app";

import { ActivityLogService } from "./activity-log.service";

import { EntityServiceAbstract } from "./entity-service-abstract";

import { MeetGreetAppointment, Admin } from "src/models/entities";

import { MeetGreetAppointmentStruct, MeetGreetStatus, MeetGreetResult } from "src/models/structs";

import * as moment from "moment-timezone";
import { AuthService } from './auth.service';
import { environment } from '../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { collections } from 'src/models/collections';
import { IEntityMap } from './interfaces';
import ca from "@mobiscroll/angular/dist/js/i18n/ca";

@Injectable({
	providedIn: "root"
})

export class MeetGreetAppointmentService extends EntityServiceAbstract<MeetGreetAppointment> implements IEntityMap<MeetGreetAppointment> {

	public collection = "meetAndGreetAppointments";

	public enumLabels: {
		result: {
			[enumVal: number]: string
		}
	};

	protected statusColorMap:object = {
		"pending": 	        "primary",
		"doing":	        "secondary",
		"done":		        "completed",
        "noshow":           "danger",
        "schedulelater":    "dark",
        
	};

	private queryItemsLimit = 150;

	constructor (
		protected dbService: FirestoreService,
		private logService: ActivityLogService,
		protected authService: AuthService,
		private http: HttpClient,
	){

		super();

		this.enumLabels = {
			result: {},
		};

		this.enumLabels.result[MeetGreetResult.Pass] 			= "Pass";
		this.enumLabels.result[MeetGreetResult.NoPass_DNC] 		= "No Pass (NGF)";
		this.enumLabels.result[MeetGreetResult.NoPass_FollowUp] = "No Pass (Follow Up)";
		this.enumLabels.result[MeetGreetResult.NoShow] 			= "No Show";

	}

	public getStatusBadgeText<MeetGreet>(entityObj: MeetGreet, badgeTextProperty: string):string {

		let badgeText = "";

        if( entityObj["liveMeetAndGreet"] ){ 
            badgeText = "Live Book";
        }else{ 
            switch(entityObj[badgeTextProperty]) {

                case MeetGreetAppointment.DOING:
                    badgeText = "in progress";
                    break;
    
                case MeetGreetAppointment.DONE:
                    badgeText = "completed";
                    break;
                case MeetGreetAppointment.NOSHOW:
                    badgeText = "No show";
                    break;
                case MeetGreetAppointment.SCHEDULELATER:
                    badgeText = "schedule later";
                    break;
                default:
                    badgeText = entityObj[badgeTextProperty];
    
            }
        }
		

		return badgeText;

	}

	public getById(meetGreetId:string):Observable<MeetGreetAppointment> {

		return this.dbService.getDoc(this.collection, meetGreetId).pipe(
			map((meetGreetDoc) => {

				if(meetGreetDoc.exists){
					return new MeetGreetAppointment(meetGreetDoc.data() as MeetGreetAppointmentStruct, meetGreetDoc.id);
				}

			})
		);

	}

    public getByIdPromise( meetGreetId: string ) : Promise<any>{ 

        return new Promise((resolve, reject) =>{ 

            this.dbService.getDocSnapshot( this.collection , meetGreetId)
            .then( (meetAndGreetDoc) => {
                resolve( new MeetGreetAppointment(meetAndGreetDoc.data() as MeetGreetAppointmentStruct, meetAndGreetDoc.id));
            }).catch( (err) => {
                reject(err);
            }
        );
        });
    }


	public update(meetGreetId: string, updatedData: any):Promise<void> {

		if(!updatedData.updatedAt){

			let updateAtDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			updatedData.updatedAt = firebase.firestore.Timestamp.fromDate(updateAtDate);

		}

		return this.dbService.updateDoc(this.collection, meetGreetId, updatedData);

	}

	public updateStatus(meetGreetId: string, newStatus: MeetGreetStatus):Promise<any> {

		const updatedValues = {
			status: newStatus
		};

		let meetGreetUpdatePromise = this.update(meetGreetId, updatedValues);

		let logEntryPromise = this.logService.addLogEntry(this.collection, meetGreetId, "Status Change to " + newStatus, newStatus);

		return Promise.all([meetGreetUpdatePromise, logEntryPromise]);

	}

	public updateResult(meetGreetId: string, newResult: MeetGreetResult):Promise<void> {

		const updatedValues = {
			result: newResult
		};

		return this.update(meetGreetId, updatedValues);

	}

  public getMeetGreetsByPetId( petId: string ): Observable<any>{
      return new Observable((subscriber) => {

      this.dbService.db.collection(this.collection).where("petId", "array-contains", petId ).where( "status", "==", "pending" ).onSnapshot(
        (documentSnapshot) => {
          subscriber.next(documentSnapshot);
        },
        (error) => {
          window.adminlog.error("Get Document error: ", error);
        }
      );

    });
      //return this.dbService.db.collection(this.collection).where("petId", "array-contains", petId ).where( "status", "==", "pending" ).onSnapshot().;
  }

  
	public newMeetGreetsQuery(locationId: string) {
		return this.dbService.db.collection(this.collection)
			.where('locationId', '==', locationId)
			.where('status', '==', MeetGreetAppointment.PENDING)
			.limit(this.queryItemsLimit);
	}

	public getNewMeetGreets(locationId:string):Observable<MeetGreetAppointment[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", MeetGreetAppointment.PENDING]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

    public getApprovalMeetGreets(locationId:string):Observable<MeetGreetAppointment[]> {
        
        // limit approvals M&Gs to 3 maximum of 3 weeks past today.
        // Get the current date
        var currentDate = new Date();
        // Subtract 3 weeks (3 * 7 days) from the current date
        var wksAgo = new Date(currentDate);
        wksAgo.setDate(currentDate.getDate() - 21);

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
                ["date", ">=", wksAgo],
				["status", "in", [MeetGreetAppointment.PENDING, MeetGreetAppointment.DOING, MeetGreetAppointment.NOSHOW, MeetGreetAppointment.SCHEDULELATER]]
			],
			orderByField: "date",
            orderByDir: "desc",
			limit: 300
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

  public getMeetGreetsByPeriod(locationId:string, start: Date, end: Date):Observable<MeetGreetAppointment[]> {
		const queryOptions: queryOptions = {
			conditions: [
				["locationId", "==", locationId],
                ["date", ">=", start],
                ["date", "<=", end],
                ["status", "in", [MeetGreetAppointment.PENDING, MeetGreetAppointment.DOING, MeetGreetAppointment.DONE]],
			],
			orderByField: "date",
			orderByDir: "asc",
			limit: 250
		};
		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
                console.log("meetGreetDocscheck", meetGreetDocs);
                
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
	}

  public getMeetGreetByLeadId(locationId: string, leadId: string): Promise<any> {
    return this.dbService.db.collection(this.collection).where("locationId", "==", locationId).where("leadId", "==" ,leadId).get();
  }

  public getMeetGreetsByAccountIdandLocationId( accountId: string, locationId: string ): Observable<MeetGreetAppointment[]> {
    const queryOptions: queryOptions = {
			conditions: [
                ["accountId", "==", accountId],
                ["locationId", "==", locationId],
                ["status", "in", [MeetGreetAppointment.PENDING, MeetGreetAppointment.DOING, MeetGreetAppointment.DONE]]
			],
			orderByField: "date",
			orderByDir: "asc",
		};
		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);
}

	public doingMeetGreetsQuery(locationId: string) {
		return this.dbService.db.collection(this.collection)
			.where('locationId', '==', locationId)
			.where('status', '==', MeetGreetAppointment.DOING)
			.limit(this.queryItemsLimit);
	}

	public getDoingMeetGreets(locationId:string):Observable<MeetGreetAppointment[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", MeetGreetAppointment.DOING]
			],
			orderByField: "date",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public doneMeetGreetsQuery(locationId: string) {
		return this.dbService.db.collection(this.collection)
			.where('locationId', '==', locationId)
			.where('status', '==', MeetGreetAppointment.DONE)
			.limit(this.queryItemsLimit);
	}

	public getDoneMeetGreets(locationId:string):Observable<MeetGreetAppointment[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["status", "==", MeetGreetAppointment.DONE]
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

    public noShowMeetGreetsQuery(locationId: string) {
    return this.dbService.db.collection(this.collection)
        .where('locationId', '==', locationId)
        .where('status', 'in', [MeetGreetAppointment.NOSHOW, MeetGreetAppointment.SCHEDULELATER])
        .limit(this.queryItemsLimit);
    }

    public getNoShowMeetGreetsQuery(locationId:string):Observable<MeetGreetAppointment[]> {

		const queryOptions:queryOptions = {
			conditions: [
				["locationId", "==", locationId],
				["result", "in", [MeetGreetAppointment.NOSHOW, MeetGreetAppointment.SCHEDULELATER]]
			],
			orderByField: "updatedAt",
			orderByDir: "desc",
			limit: this.queryItemsLimit
		};

		return this.dbService.getCollection(this.collection, queryOptions).pipe(
			map((meetGreetDocs) => {
				return this.mapDocsToObjs(meetGreetDocs);
			}),
			catchError((error) => {
				window.adminlog.print(error);
				throw error;
			})
		);

	}

	public createMeetGreetAppointment(meetGreetData: MeetGreetAppointmentStruct):Promise<string> {

		if(!meetGreetData.createdAt){

			let nowDate = new Date(moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format());

			meetGreetData.createdAt = firebase.firestore.Timestamp.fromDate(nowDate);

		}

		return this.dbService.insertDoc(this.collection, meetGreetData).then((meetGreetAppointmentDoc) => {
			return meetGreetAppointmentDoc.id;
		});

	}

	public mapDocsToObjs(meetGreetDocs:firebase.firestore.QueryDocumentSnapshot[]):MeetGreetAppointment[] {

		return meetGreetDocs.map((meetGreetDoc) => {

			const meetGreet = new MeetGreetAppointment(meetGreetDoc.data() as MeetGreetAppointmentStruct, meetGreetDoc.id);

			return meetGreet;

		});
 
	}

	public async buildDaycare(mg: MeetGreetAppointment): Promise<{ message: string }> {
		const mgId = mg._id;
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{ message: string }>((resolve, reject) => {

			this.http.put(`${environment.firebaseFunctions.buildDaycare}/${mgId}`, { mgId: mgId }, httpOptions).subscribe(
				(result: { message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});

	}

	/**
	 *
	 * Archives a meet and greet by making an api call. Note doesn't strictly need to be an api, but I think it's better this way. - Connor.
	 * @param mg Mg to archive
	 * @param authUser Sign archival with user sig.
	 */
	public async archive(mg: MeetGreetAppointment, authUser: Admin): Promise<{ message: string }> {
		const mgId = mg._id;
		let bearerToken = await this.authService.getBearerToken();
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerToken
			})
		};

		return new Promise<{ message: string }>((resolve, reject) => {

			this.http.put(`${environment.firebaseFunctions.archiveMG}/${mgId}`, { mgId: mgId, note: `Archived by ${authUser.firstName} ${authUser.lastName} - ${authUser._id}` }, httpOptions).subscribe(
				(result: { message: string }) => {
					resolve(result);
				}, (error) => {
					reject(error);
				});

		});
	}

	public getSignatureById(id: string):Observable<any> {
		return this.dbService.getDoc(collections.tempSignatureCollection, id).pipe(map(res => {
			if (res.exists === false) {
				return null;
			} else {
				return res.data();
			}
		}));
	}

	public insertSignature(id:string, agreement: any): Promise<void>{
		return this.dbService.setDoc(collections.tempSignatureCollection, id, agreement);
	}

}
