import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedLocationsToString'
})
export class SelectedLocationsToStringPipe implements PipeTransform {

  transform(value: any): any {
      let selectedLocationString = "";
        for( let x = 0; x < value.length; x++ ){ 
            if( value[x].selected ) { 
                selectedLocationString += value[x].label;
                selectedLocationString += ", ";
            }
        }

        return selectedLocationString.substring( 0, selectedLocationString.length - 2 );
  }

}
