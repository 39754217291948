// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const firebase_staging = {
    apiKey: "AIzaSyAbH1UgZYtEFb0KgATZE5Xf1MJk0X3Q-Mo",
    authDomain: "dogtopia-staging.firebaseapp.com",
    databaseURL: "https://dogtopia-staging.firebaseio.com",
    projectId: "dogtopia-staging",
    storageBucket: "dogtopia-staging.appspot.com",
    messagingSenderId: "893876241567"
};

const firebase_live = {
    apiKey: "AIzaSyB9t1fBgSOoYtTKhhVRBQh9iRKI_j9AvjQ",
    authDomain: "com-dogtopia-app.firebaseapp.com",
    databaseURL: "https://com-dogtopia-app.firebaseio.com",
    projectId: "com-dogtopia-app",
    storageBucket: "com-dogtopia-app.appspot.com",
    messagingSenderId: "186889550136"
};

const algolia_index_names = {
	user: "user",
	request: "requests",
  account: "account"
};

const algolia_staging = {
	indexNames: algolia_index_names,
	searchClientParams: {
	  appID: "03TPYPUP1S",
	  appKey: "f6852a3c40e5c55ae3b49e7a5503d25d"
	}
};

var algolia_production = {
	indexNames: algolia_index_names,
	searchClientParams: {
		appID: "ETACGVNGES",
		appKey: "36abdd0f7cca2fb419e9a7a65c711b37"
	},
};

var functions_prod = {
	key: "9b4f425ca88bb129fbb2b3bcc505f09512686279",
	windigo: "https://us-central1-com-dogtopia-app.cloudfunctions.net/windigo",
	executive: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive",
	url: "https://us-central1-com-dogtopia-app.cloudfunctions.net/windigo/admin",
	addWebcamUser: "https://us-central1-com-dogtopia-app.cloudfunctions.net/windigo/webcamUser",

	authDiagnostic: "https://us-central1-com-dogtopia-app.cloudfunctions.net/windigo/diagnostic/firebase_user",

	generatePetHistory: "https://us-central1-com-dogtopia-app.cloudfunctions.net/windigo/pet/{petId}/generate-pethistory-pdf",
	generatePetHistoryVersionTwo: "https://us-central1-com-dogtopia-app.cloudfunctions.net/windigo/pet/generate-pethistory-pdf-version-two",
    uploadPetHistoryToPetexec: "https://us-central1-com-dogtopia-app.cloudfunctions.net/windigo/pet/upload-pet-history-pdf-to-petexec",


	refreshAccount: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/owners/refresh",
	refreshPetInformation: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/refresh/pet",
	refreshAccountInformation: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/refresh/account",

	getPet: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/pets/{petId}",
	getPetShots: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/pets/{petId}/shots/adminAccess",
	buildPet: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/build/pet",
	buildAccount: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/build/account",
	buildDaycare: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/build/daycare",

    getLocationPetShots: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/locations/{locationId}/shots",
    getLocationVets: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/locations/{locationId}/vets",

	archivePet: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/archive/pet",
	archiveAccount: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/archive/account",
	archiveMG: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/archive/mgapt",

	searchPetExecOwnersByEmail: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/owners/search",

	getPetBreeds: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/locations/{locationId}/breeds?showAll=true",
	getVaccinationShots: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/locations/{locationId}/shots",
	getVets: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/locations/{locationId}/vets",

	getDaycareServices: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/locations/{locationId}/daycare/services",

    getMessagePreview: "https://us-central1-com-dogtopia-app.cloudfunctions.net/windigo/message/preview",
    getClientAgreementPreview: "https://us-central1-com-dogtopia-app.cloudfunctions.net/clientAgreementPreview",

    getPreviewVaccineReleaseForm: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/preview-vaccine-release-form",


    uploadDocumentsToPetexec: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/uploadVaccine",
    uploadVaccineExpirationDates: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/updatePetShots",

    userDashboard_uploadVaccineScreen: "https://www.dogtopia.com/dashboard/vaccine-upload/{petId}/{accountId}",

    enrollmentStatus: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/getEnrollmentPackages/{accountId}", /*/location/{locationId}"*/

    vaccineReleaseFormBufferDownload: "https://us-central1-com-dogtopia-app.cloudfunctions.net/executive/vaccineReleaseFormBufferDownload",

    sbaCheckPhoneNumber: "https://service.socialbrandamplifier.com/api/leads/lookup/phone?number="
};
var functions_staging = {
	key: "9b4f425ca88bb129fbb2b3bcc505f09512686279",
	windigo: "https://us-central1-dogtopia-staging.cloudfunctions.net/windigo",
	executive: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive",
	url: "https://us-central1-dogtopia-staging.cloudfunctions.net/windigo/admin",
	addWebcamUser: "https://us-central1-dogtopia-staging.cloudfunctions.net/windigo/webcamUser",

	authDiagnostic: "https://us-central1-dogtopia-staging.cloudfunctions.net/windigo/diagnostic/firebase_user",

	generatePetHistory: "https://us-central1-dogtopia-staging.cloudfunctions.net/windigo/pet/{petId}/generate-pethistory-pdf",
    generatePetHistoryVersionTwo: "https://us-central1-dogtopia-staging.cloudfunctions.net/windigo/pet/generate-pethistory-pdf-version-two",
    uploadPetHistoryToPetexec: "https://us-central1-dogtopia-staging.cloudfunctions.net/windigo/pet/upload-pet-history-pdf-to-petexec",


	refreshAccount: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/owners/refresh",
	refreshAccountInformation: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/refresh/account",
	refreshPetInformation: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/refresh/pet",

	getPet: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/pets/{petId}",
	getPetShots: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/pets/{petId}/shots/adminAccess",
	buildPet: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/build/pet",
	buildAccount: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/build/account",
	buildDaycare: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/build/daycare",

    getLocationPetShots: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/locations/{locationId}/shots",
    getLocationVets: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/locations/{locationId}/vets",

	archivePet: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/archive/pet",
	archiveAccount: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/archive/account",
	archiveMG: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/archive/mgapt",

	searchPetExecOwnersByEmail: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/owners/search",
	getPetBreeds: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/locations/{locationId}/breeds?showAll=true",
	getVaccinationShots: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/locations/{locationId}/shots",
	getVets: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/locations/{locationId}/vets",

	getDaycareServices: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/locations/{locationId}/daycare/services",

    getMessagePreview: "https://us-central1-dogtopia-staging.cloudfunctions.net/windigo/message/preview",
    getClientAgreementPreview: "https://us-central1-dogtopia-staging.cloudfunctions.net/clientAgreementPreview",

    getPreviewVaccineReleaseForm: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/preview-vaccine-release-form",

    uploadDocumentsToPetexec: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/uploadVaccine",
    uploadVaccineExpirationDates: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/updatePetShots",

    enrollmentStatus: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/getEnrollmentPackages/{accountId}",/*/location/{locationId}"*/


    userDashboard_uploadVaccineScreen: "https://sf-staging.dogtopia.com/dashboard/vaccine-upload/{petId}/{accountId}",

    vaccineReleaseFormBufferDownload: "https://us-central1-dogtopia-staging.cloudfunctions.net/executive/vaccineReleaseFormBufferDownload",

    sbaCheckPhoneNumber: "https://dev-service.socialbrandamplifier.com/api/leads/lookup/phone?number="
}

import production from './production';

export const environment = {
	production: production,
	firebase: (production) ? firebase_live : firebase_staging,
	firebaseFunctions: (production) ? functions_prod : functions_staging,
	algolia: (production) ? algolia_production : algolia_staging,
    version: 'v.1.6.007'
};
//console.log( 'prod', environment );
console.log( environment );


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
