import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";

import { FormBuilder, FormGroup, AbstractControl, Validators } from "@angular/forms";

import { AuthService, FooterInfoService } from "src/services";

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

	public loginForm:FormGroup;

	public generalLoginError:string = "";

	constructor(
		private authService: AuthService,
		public footerInfoService: FooterInfoService,
		private formBuilder: FormBuilder,
		private router: Router
	) {
        
     }

	ngOnInit():void {

		this.loginForm = this.formBuilder.group({
			email: ["", Validators.compose([
				Validators.required,
				Validators.email
			])],
			password: ["", Validators.required]
		});

	}
    public ngOnDestroy(){ 
    }
    public ionViewDidLeave(): void {
        this.loginForm = this.formBuilder.group({
			email: ["", Validators.compose([
				Validators.required,
				Validators.email
			])],
			password: ["", Validators.required]
		});
		this.ngOnDestroy();
	}
    public ionViewDidEnter(): void {
        this.ngOnInit();
    }
	get emailField():AbstractControl {
		return this.loginForm.get("email");
	}

	get passwordField():AbstractControl {
		return this.loginForm.get("password");
	}

	onFormSubmit():void {
		
		this.generalLoginError = "";

		this.authService
			.loginWithEmail(this.emailField.value, this.passwordField.value)
			.then((result) => {
				
				if(result){
					this.router.navigate(["/"]);
				}

			})
			.catch((err) => {
				this.generalLoginError = err;
			});

	}

}
